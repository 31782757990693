<template>
    <tr v-on:mouseenter="onHover(_uid)" v-on:mouseleave="onHover()" height="50px"
        :class="{ 'active-row-l': hover && !dark, 'active-row-d': hover && dark, }">
        <CellDesign :design="design" :hover="hover" :oldrev="not_latest" />
        <CellQuantityPhase v-for="phase in phases" :key="phase" :design="design" :phase="phase" :hover="hover"
            :oldrev="not_latest" />
        <td align="right">
            <v-list-item-title class="title" :class="{ 'warning--text': !is_total_ok }" v-text="totals_text">
            </v-list-item-title>
            <v-list-item-subtitle v-text="$store.getters.t('assemblies')"></v-list-item-subtitle>
        </td>
    </tr>
</template>

<style lang="scss">
@import "./tables.scss";
</style>


<script>
import CellDesign from "./CellDesign.vue";
import CellQuantityPhase from "./CellQuantityPhase.vue";
import { rowMixin } from "./mixins"
export default {
    mixins: [rowMixin],
    components: {
        CellDesign,
        CellQuantityPhase,
    },
    computed: {
        row_total() {
            if (!this.design) return 0;
            if (this.design.level == "assembly") {
                return this.$store.getters["v2/quantities/getAssdProjectTotal"](this.design);
            }
            if (this.design.level == "module") {
                return this.$store.getters["v2/quantities/getModdProjectTotal"](this.design);
            }
            return 0
        },
        target_row_total() {
            return this.project?.meta?.full_counts?.[this.design.name] || 0;
        },
        is_total_ok() {
            return this.row_total == this.target_row_total
        },
        totals_text() {
            return `${this.row_total}/${this.target_row_total}`
        }
    }
}
</script>