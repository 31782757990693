<template>
    <v-container fluid class="fill-height">
        <v-row justify="center">
            <div>
                <qrcode-stream @decode="onDecode"></qrcode-stream>
            </div>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped>
.scanner {
    width: 50%;
}
</style>

<script>

import bus from '/src/utils/event_bus'
import { QrcodeStream } from "vue-qrcode-reader";
import { backend_api_v2 } from "/src/utils/apiv2";


export default {
    components: {
        "qrcode-stream": QrcodeStream,
    },
    data: () => ({}),
    methods: {
        fetchUrlByClientID(client_id) {

            console.log("FETCHING", client_id)
            return backend_api_v2
                .get(`/constructibles/actuals/find/?client_order_id=${client_id}`)
                .then(({ data }) => {
                    if (data.status == "found") {
                        bus.$emit('notification', { timeout: 3000, text: `Redirecting to Client ID: ${client_id}`, color: "success" })
                        this.$router
                            .push(`viewer/${data.level}/${data.uuid}`)
                            .catch(() => console.log("[!] Can't update route."));
                    }
                })
                .catch((e) => bus.$emit('notification', { timeout: 3000, text: `Client ID not found: ${client_id}: ${e}`, color: "warning" }))
        },
        standardRoutePush(decoded_string) {
            if (!decoded_string.includes("/app")) {
                bus.$emit('notification', { timeout: 3000, text: `Not valid QR for produuz.it`, color: "error" })
                return
            }
            let route = decoded_string.substr(
                decoded_string.indexOf("/app") + 4
            );
            bus.$emit('notification', { timeout: 3000, text: `Going to URL: ${route}`, color: "success" })
            this.$router
                .push(route)
                .catch(() => console.log("[!] Can't update route."));
        },
        onDecode(decoded_string) {
            bus.$emit('notification', { timeout: 1000, text: "Found QR. Data: " + decoded_string, color: "info" })
            try {
                if (decoded_string.startsWith("po:")) {
                    this.fetchUrlByClientID(decoded_string.substring(3))
                }
                else {
                    this.standardRoutePush(decoded_string)
                }
            } catch (e) {
                bus.$emit('notification', { timeout: 5000, text: "Error reading the QR:" + e, color: "error" })
            }
        },
    },
};
</script>