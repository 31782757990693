<template>
    <v-card outlined class="px-3 mt-4">
        <v-row class="justify-space-between">
            <v-card-subtitle class="mt-2 ml-4">
                {{ $store.getters.t("actual_to_report") }}
            </v-card-subtitle>
            <v-switch v-model="by_priority" dense class="mr-4 mt-2"
                :label="$store.getters.t('sort_by_priority')"></v-switch>
        </v-row>
        <v-row>
            <v-col>
                <SelectDate :disabled="loading" v-model="selected_prod_date_gte" :label="$store.getters.t('from')" />
            </v-col>
            <v-col>
                <SelectDate :disabled="loading" v-model="selected_prod_date_lte" :label="$store.getters.t('to')" />
            </v-col>

        </v-row>
        <v-treeview class="mb-3" item-disabled="locked" dense selectable v-model="selected_actuals"
            :items="actuals_tree">
            <template v-slot:label="{ item }">
                <span class="grey--text" v-if="item.ready">
                    {{ item.name }}
                </span>
                <span v-else> {{ item.name }} </span>
                <v-chip outlined x-small v-if="item.ready" color="green">
                    ready
                </v-chip>
            </template>
        </v-treeview>
    </v-card>
</template>

<script>
import _ from "lodash"
import { nameSort } from "/src/utils/basics.js"
import { selectTreeActualsMixin } from "./selectTreeActualsMixin"
import { getMondayString, getFridayString } from "../../utils/time"
import SelectDate from '../fields/SelectDate';
import { paginated_factory_v2 } from "/src/utils/apiv2";
import { getUrlQueryFromPayload } from "/src/utils/urls"

export default {
    mixins: [selectTreeActualsMixin],
    components: {
        SelectDate
    },
    data: () => ({
        actuals: [],
        loading: false,
        selected_prod_date_gte: getMondayString(),
        selected_prod_date_lte: getFridayString(),
    }),
    computed: {
        scheduled_prod_dates() {
            // Takes current actuals and returns a list of ISO dates.
            const dates = this.actuals.map(a => a.scheduled_prod_date)
            return [...new Set(dates)]
        },
        actuals_tree() {
            let actuals_tree = []
            this.scheduled_prod_dates.forEach(date => {

                // GENERATE DATE
                let children_actuals = []
                this.actuals
                    .filter(actual => actual.scheduled_prod_date == date)
                    .forEach((actual) => {
                        children_actuals.push({
                            ready: actual.status == "ready",
                            id: actual.uuid,
                            design_name: actual.design_name,
                            priority: actual.priority,
                            name: `${actual.design_name} [${actual.uuid.substring(0, 8)}]`,
                        })
                    })

                // NOW SORT CHILDREN
                let sorted_children
                if (this.by_priority) {
                    sorted_children = _.orderBy(
                        children_actuals,
                        "priority"
                    )
                } else {
                    sorted_children = children_actuals.sort((a, b) =>
                        nameSort(a.design_name, b.design_name)
                    )
                }
                actuals_tree.push({
                    id: date,
                    name: date,
                    locked: sorted_children.length == 0,
                    children: sorted_children,
                })
            })
            if (this.loading) {
                return [{ "id": 0, "name": "Loading...", locked: true }]
            }
            else if (actuals_tree.length == 0) {
                return [{ "id": 0, "name": "No constructibles for the selected period.", locked: true }]
            } else {
                return actuals_tree
            }

        },
    },
    watch: {
        selected_prod_date_gte() {
            this.get_actuals_from_back()
        },
        selected_prod_date_lte() {
            this.get_actuals_from_back()
        },
    },
    methods: {
        get_actuals_from_back() {
            this.loading = true
            const payload = {
                scheduled_prod_date__gte: this.selected_prod_date_gte,
                scheduled_prod_date__lte: this.selected_prod_date_lte,
                compact_serializer: true
            }
            const url_query = getUrlQueryFromPayload(payload)
            const plulevel = this.level == "assembly" ? "assemblies" : "modules"
            return paginated_factory_v2(`constructibles/${plulevel}/?${url_query}`)
                .then(({ data }) => { this.actuals = data })
                .finally(() => { this.loading = false })
        }
    },
    mounted() {
        this.get_actuals_from_back()
    }
}
</script>