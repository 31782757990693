<template>
    <v-hover v-slot:default="{ hover }">
        <div
            :class="{
                'on-hover': hover,
                dark,
                light: !dark,
                [batch.status]: true,
            }"
            class="batch"
            :style="batch_style"
            @click="click_batch"
        >
            <div class="batchname d-flex">
                <span class="text-truncate" :class="dark ? 'dmain' : 'lmain'">
                    {{ batch.name }}
                </span>
                <v-spacer />
                <span class="text-truncate" :class="dark ? 'dmain' : 'lmain'">
                    {{ batch.status_text }}
                </span>
            </div>
            <div
                v-if="!loose"
                :style="batch_inside_style"
                class="d-flex batch-inside-box"
            >
                <v-hover
                    v-slot:default="{ hover }"
                    v-for="panel in batch.panels"
                    :key="panel.uuid"
                >
                    <div
                        @click.stop="click_panel(panel)"
                        class="panel text-truncate"
                        :class="{
                            dark,
                            light: !dark,
                            [panel.status]: true,
                            'on-hover': hover,
                        }"
                        :style="panel_style(panel)"
                    >
                        <span class="text-truncate"> {{ panel.name }} </span>
                    </div>
                </v-hover>
            </div>
        </div>
    </v-hover>
</template>


<style lang="scss" scoped>
@import "./style.scss";
</style>

<script>
import bus from "/src/utils/event_bus";
import { get_position } from "/src/components/pm/timesteps";
export default {
    props: {
        timeframe: String,
        batch: null,
        loose: Boolean,
        loose_idx: Number,
    },
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        name() {
            return this.batch?.name;
        },
        tsl() {
            return this.$store.state.overview.tsl;
        },
        left() {
            if (this.loose) return this.loose_idx * 8;
            const start = this.batch.actual_start || this.batch.fc_start;
            return get_position(this.timeframe, start, this.tsl);
        },
        right() {
            const end = this.batch.actual_end || this.batch.fc_end;
            return get_position(this.timeframe, end, this.tsl);
        },
        width() {
            if (this.loose) return 0.95 * 8;
            return Math.max(
                this.right - this.left - 0.15,
                this.batch.panels.length * 0.1
            );
        },
        batch_style() {
            return {
                position: "absolute",
                left: this.left + "em",
                width: this.width + "em",
            };
        },
        batch_inside_style() {
            return {
                width: this.width - 5 + "em",
            };
        },
        batchname_style() {
            return {
                width: this.width / 2 + "em",
            };
        },
    },
    methods: {
        click_batch() {
            bus.$emit("po_detail", { cat: "batch", item: this.batch });
        },
        click_panel(panel) {
            bus.$emit("po_detail", { cat: "panel", item: panel });
        },
        panel_style(panel) {
            const start = panel.actual_start || panel.fc_start;
            const end = panel.actual_end || panel.fc_end;
            const left =
                get_position(this.timeframe, start, this.tsl) - this.left;
            let right = get_position(this.timeframe, end, this.tsl) - this.left;
            right = Math.min(this.width - 0.15, right);
            const width = left && right ? right - left : 0.1;
            return {
                position: "absolute",
                left: left + "em",
                width: width + "em",
            };
        },
    },
};
</script>