
<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                {{ $store.getters.t("notifications") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                {{ $store.getters.t("notifications") }}
            </v-card-title>

            <v-card-text>
                {{ $store.getters.t("notifications_setup_help") }}
                <br />
                <br />
                <v-switch color="accent" class="ma-0" dense v-for="nop in notification_options" :key="nop"
                    v-model="noti_setup[nop]" :label="$store.getters.t(nop)"> </v-switch>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" color="warning" @click="save">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    data: () => ({
        loading: false,
        dialog: false,
        noti_setup: {},
        notification_options: [
            "noti_errors_on_report",
            "noti_produuzit_updates",
            "noti_panels_ready",
            "noti_batches_ready",
            "noti_scheduler_warnings",
        ]
    }),
    computed: {
        user() {
            return this.$store.state.v2.session.user_object;
        },
    },
    watch: {
        dialog(d) {
            if (d) this.refresh()
        }
    },
    methods: {
        save() {
            this.loading = true;
            return backend_api_v2
                .post("/my_user/patch_noti_setup/", { noti_setup: this.noti_setup })
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("v2/session/getUserObject");
                })
                .catch((e) => console.log(`Cant update notification setup: ${e}`))
                .finally(() => (this.loading = false));
        },
        refresh() {
            this.noti_setup = { ...this.user.auth.noti_setup };
        }
    },
    mounted() {
        this.refresh()
    },
};
</script>