import { backend_api_v2 } from '/src/utils/apiv2'
import { generate_timesteps } from '/src/components/pm/timesteps'


export default {
    namespaced: true,
    state: {
        loading: false,
        background_loading: false,
        touched_schedule: false,
        production_overview: {
            built_timestamp: "unloaded",
            tl: "2020-01-01",
            tr: "2020-02-01",
            production_lines: [],
            machines: []
        },
        loose_batches: {
            projects: [],
        },
        timesteps: [],
        tsl: new Date(),
        timeframe: "w",
    },
    mutations: {
        setPO: (state, data) => state.production_overview = data,
        setLooseBatches: (state, data) => state.loose_batches = data,
        setLoading: state => state.loading = true,
        setLoaded: state => state.loading = false,
        setBGLoading: state => state.background_loading = true,
        setBGLoaded: state => state.background_loading = false,
        setTouched: state => state.touched_schedule = true,
        setUntouched: state => state.touched_schedule = false,
        setTimeframe: (state, data) => {
            state.timesteps = generate_timesteps(
                data,
                state.production_overview.tl,
                state.production_overview.tr
            )
            state.tsl = state.timesteps[0]
            state.timeframe = data
        },
        rebuildTimesteps: state => {
            state.timesteps = generate_timesteps(
                state.timeframe,
                state.production_overview.tl,
                state.production_overview.tr
            )
            state.tsl = state.timesteps[0]
        },
        localPatchPL: (state, payload) => {
            const pls = state.production_overview.production_lines
            let idx = pls.findIndex(pl => pl.uuid == payload.uuid)
            pls[idx] = { ...pls[idx], ...payload }
            state.production_overview = {
                ...state.production_overview,
                production_lines: [...pls]
            }
        }

    },
    actions: {
        loadPO(context, pr_uuid) {
            console.log("loadPO", pr_uuid)
            if (context.state.background_loading == false) {
                if (context.state.production_overview.built_timestamp == "unloaded") {
                    context.commit("setLoading")
                }
                context.commit("setBGLoading")

                const query = pr_uuid ? "?project=" + pr_uuid : ""

                const po_promise = backend_api_v2
                    .get('/overview/production/' + query)
                    .then(({ data }) => { context.commit('setPO', data); context.commit("rebuildTimesteps") })
                    .catch(e => { console.log(`Cant load production overview: ${e}`) })

                const lb_promise = backend_api_v2
                    .get('/overview/batches/' + query)
                    .then(({ data }) => { context.commit('setLooseBatches', data) })
                    .catch(e => { console.log(`Cant load loose batches: ${e}`) })

                Promise.all([po_promise, lb_promise])
                    .finally(() => {
                        context.commit("setLoaded")
                        context.commit("setBGLoaded")
                        context.commit("setUntouched")
                    })
            }
        },

    },
    getters: {

    }
}