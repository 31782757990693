<template>
    <div class="d-flex flex-column drawing-ifc-main-box">
        <div style="height: 0.3rem"></div>
        <div v-if="pdf_mode" class="d-flex flex-column scrollmenu flex-grow-1">
            <div class="d-flex flex-row">
                <span class="body-1 mb-0 ml-9 font-weight-light">
                    {{ view_name }}
                </span>
                <v-spacer></v-spacer>
                <v-btn icon @click="recenterPDF" class="mr-5 fronted">
                    <v-icon>mdi-image-filter-center-focus</v-icon>
                </v-btn>
            </div>
            <PDFView :url="url" v-if="url" name="left_panel" />
        </div>
        <IFCViewerAssembly v-if="level == 'assembly'" :hide="pdf_mode" />
        <IFCViewerModule v-if="level == 'module'" :hide="pdf_mode" />
        <div>
            <LeftPanelButtons />
        </div>
    </div>
</template>


<style lang="scss" scoped>
.scrollmenu {
    overflow: scroll;
    // white-space: nowrap;
}

.drawing-ifc-main-box {
    // background-color: lightcoral;
    height: 100vh;
}

.fronted {
    z-index: 1;
}
</style>

<script>
import bus from "/src/utils/event_bus";
import LeftPanelButtons from "./LeftPanelButtons.vue";
import PDFView from "../PDFView.vue";
import IFCViewerAssembly from "../../ifc_viewer/IFCViewerAssembly.vue";
import IFCViewerModule from "../../ifc_viewer/IFCViewerModule.vue";

export default {
    name: "LeftPanel",
    components: {
        PDFView,
        IFCViewerAssembly,
        IFCViewerModule,
        LeftPanelButtons,
    },
    computed: {
        level() {
            return this.$store.state.v2.viewer.level
        },
        drawings() {
            return this.$store.getters["v2/viewer/getDrawings"];
        },
        dwg_number() {
            return this.$store.state.v2.viewer.left_panel_dwg_number
        },
        cf() {
            return this.drawings.find(cf => cf?.meta?.dwg_number == this.dwg_number)
        },
        url() {
            return this.cf?.presigned_url;
        },
        view_name() {
            return this.cf?.meta?.dwg_name;
        },
        pdf_mode() {
            return this.$store.state.v2.viewer.pdf_mode;
        },
    },
    methods: {
        recenterPDF() {
            bus.$emit("events/viewer/recenter_pdf_left_panel");
        },
    },
};
</script>
