<template>
    <v-select outlined dense class="mx-1" v-model="selected_ifcps" :items="ifc_parsing_setups" :rules="rules"
        :clearable="!required" :label="$store.getters.t('ifc_parsing_setup')">
    </v-select>
</template>

<script>
export default {
    props: {
        value: { default: null },
        required: { type: Boolean, default: false },
    },
    data: () => ({
        selected_ifcps: "",
    }),
    computed: {
        ifc_parsing_setups() {
            return this.$store.state.v2.company.ifc_ps.map((ips) => ({
                text: ips.name,
                value: ips.uuid,
            }));
        },
        rules() {
            if (!this.required) return [];
            return [(v) => !!v || "An BTL Parsing Setup is required"];
        },
        not_admin() {
            return !this.$store.getters["v2/session/iam_admin"]
        }
    },
    watch: {
        selected_ifcps(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_ifcps = v;
            },
            immediate: true,
        },
    },
};
</script>