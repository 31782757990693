<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("webhooks") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="expanded" color="accent" text @click="new_webhook">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">UUID</th>
                        <th class="text-left">
                            {{ $store.getters.t("created") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("modified") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("url") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("event") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="webhook in webhooks" :key="webhook.uuid">
                        <td>{{ webhook.uuid.substring(0, 8) }}</td>
                        <td class="text-left">
                            <Timestamp :value="webhook.created" />
                        </td>
                        <td class="text-left">
                            <Timestamp :value="webhook.modified" />
                        </td>
                        <td class="text-left">{{ webhook.name }}</td>
                        <td class="text-left">{{ webhook.url }}</td>
                        <td class="text-left">{{ webhook.event }}</td>
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(webhook.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(webhook.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_webhook") }}
                        </v-card-title>
                        <v-card-text>
                            {{
                                $store.getters.t("this_webhook_will_be_deleted")
                            }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_webhook" color="error" :disabled="delete_loading"
                                :loading="delete_loading">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="800">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("webhook") }}
                        </v-card-title>
                        <v-card-text class="py-3">
                            <v-form ref="form" @keyup.enter.native="post_webhook">
                                <PitTextField label="Name" v-model="form.name" maxlength="80">
                                </PitTextField>
                                <v-select outlined dense label="event" v-model="form.event" :rules="[v => !!v]"
                                    :items="['constructible_start', 'constructible_ready', 'station_ready']"></v-select>
                                <SelectProductionLine v-model="form.production_line" clearable />
                                <v-select outlined dense multiple label="Payload content" v-model="form.payload_fields"
                                    :items="payload_items"></v-select>
                                <DynamicForm v-if="show_dynamic_form" :title="$store.getters.t('payload_extra_fields')"
                                    form_name="webhook_nicelabel_print" v-model="form.meta" />
                                <v-select outlined dense label="auth header type" v-model="form.auth_header_type"
                                    :items="auth_header_items"></v-select>
                                <v-text-field v-if="form.auth_header_type" outlined dense v-model="form.secret"
                                    label="Secret / Token / SubscriptionKey"></v-text-field>
                                <v-text-field outlined dense v-model="form.url" :rules="[v => !!v, v => isURL(v)]"
                                    label="URL"></v-text-field>
                                <MultiFieldWebhookHeaders v-model="form.custom_headers" />
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_webhook" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import DynamicForm from "/src/components/fields/DynamicForm";
import { nameSort } from "/src/utils/basics";
import PitTextField from "../fields/PitTextField";
import MultiFieldWebhookHeaders from "../fields/MultiFieldWebhookHeaders"
import Timestamp from "/src/components/renderers/Timestamp";
import SelectProductionLine from "../fields/SelectProductionLine"
import _ from "lodash";
export default {
    props: [],
    components: { DynamicForm, PitTextField, Timestamp, MultiFieldWebhookHeaders, SelectProductionLine },
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
        auth_header_items:
            [
                { value: '', text: "None" },
                { value: 'Secret', text: "Secret" },
                { value: 'Bearer', text: "Bearer" },
            ],
        payload_items: [
            'serialized_event', 'serialized_station', 'serialized_constructible', 'nicelabel_print'
        ]
    }),
    computed: {
        webhooks() {
            return [...this.$store.state.v2.integrations.webhooks]
                .sort((a, b) => nameSort(a.name, b.name));
        },
        show_dynamic_form() {
            return this.form?.payload_fields?.includes('nicelabel_print')
        }
    },
    methods: {
        launch_edit(uuid) {
            let webhook = this.webhooks.find((webhook) => webhook.uuid == uuid);
            this.form = _.cloneDeep(webhook);
            this.dialog = true;
        },
        uploaded(webhook) {
            this.form = _.cloneDeep(webhook);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_webhook() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/integrations/deleteWebhook", this.delete_uuid)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },

        new_webhook() {
            this.form = {
                name: "Default_Webhook",
                secret: "",
            };
            this.dialog = true;
        },
        post_webhook() {
            if (!this.$refs.form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("Updating Webhook");
                this.$store
                    .dispatch("v2/integrations/putWebhook", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("Creating Webhook");
                this.$store
                    .dispatch("v2/integrations/postWebhook", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
        isURL(str) {
            let url;

            try {
                url = new URL(str);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        },
    },
    mounted() {
        this.$store.dispatch("v2/integrations/loadWebhooks");
    },
};
</script>