<template>
    <v-card outlined class="fill-height">
        <v-card-title>
            {{ $store.getters.t("production_proof") }}
            <v-spacer></v-spacer>
            <ButtonClearProof v-if="rcp && assembly.status != 'ready'" :rcp="rcp" :multiple="false"  />
        </v-card-title>
        <v-card-subtitle v-if="rcp">
            {{ rcp.text }}
        </v-card-subtitle>
        <v-card-text v-if="rcp">
            <span v-for="(chi, i) in checklist" :key="i">
                <h4>
                    [{{ chi.production_line }}] [{{ chi.production_station }}]:
                    {{ chi.text }}
                    <IconBoolean :value="chi.checked" />
                    <Timestamp :value="chi.timestamp" />
                </h4>
                <span v-if="chi.pictures.length == 0">No pictures</span>
                <a v-for="(pic, j) in chi.pictures" :href="pic.presigned_url" target="_blank" :key="i + '_' + j">
                    {{ pic.name }}
                    <br />
                </a>
            </span>
        </v-card-text>
        <v-card-text v-else> No recorded proof </v-card-text>
    </v-card>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2"
import IconBoolean from "/src/components/renderers/IconBoolean";
import Timestamp from "/src/components/renderers/Timestamp";
import ButtonClearProof from "./ButtonClearProof.vue";
export default {
    components: { IconBoolean, Timestamp, ButtonClearProof },
    props: ["assembly"],
    data: () => ({
        pck: null,
    }),
    computed: {
        rcp() {
            return this.$store.state.v2.records.rcp;
        },
        checklist() {
            let checklist = []
            this.rcp.checklist.forEach(chi => checklist.push({
                ...chi,
                pictures: this.pck?.files.filter(cf => {
                    if (cf.meta?.text != chi.text) return false
                    if (cf.meta?.production_station != chi.production_station) return false
                    if (cf.meta?.production_line != chi.production_line) return false
                    return true
                }) || []
            }))
            return checklist
        },
    },
    watch: {
        rcp: {
            immediate: true,
            handler() {
                this.loadPck()
            }
        },
    },
    methods: {
        loadPck() {
            let proof = this.rcp?.uuid
            if (!proof) {
                return
            }
            console.log("[i] Creating new package for this")
            backend_api_v2
                .post("/cloudstorage/packages/get_or_create/", {
                    name: "checklist",
                    proof,
                })
                .then(({ data }) => { this.pck = data })
        }
    },
};
</script>