<template>
    <v-container fluid class="fill-height">
        <v-row class="fill-height" no-gutters v-if="m_bom">
            <v-card width="100%" outlined>
                <v-card-title>
                    <v-row no-gutters>
                        {{ $store.getters.t("reports") }}
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="ml-1 mr-1" @click="newReport">
                            <v-icon left>mdi-clipboard-list-outline</v-icon>
                            {{ $store.getters.t("new_report") }}
                        </v-btn>
                        <DialogReportForm ref="repdialog" :project="project" v-model="report" />
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <TableReports :reports="reports" :loading="loading" />
                </v-card-text>
            </v-card>
        </v-row>
        <v-row no-gutters v-else>
            <v-alert type="info">{{
        $store.getters.t("not_report_module")
                }}</v-alert>
        </v-row>
    </v-container>
</template>


<script>
import bus from "/src/utils/event_bus";
import DialogReportForm from "/src/components/reports/DialogReportForm.vue";
import TableReports from "/src/components/reports/TableReports.vue";
export default {
    components: {
        DialogReportForm,
        TableReports,
    },
    data: () => ({
        report: null,
    }),
    computed: {
        loading() {
            return this.$store.state.v2.reports.loading
        },
        m_bom() {
            return this.$store.getters["m_bom"];
        },
        reports() {
            let pr_uuid;
            if (!this.project || this.project.uuid == "all") pr_uuid = null;
            else pr_uuid = this.project.uuid;
            return this.$store.state.v2.reports.reports.filter(
                (bdj) => bdj.project == pr_uuid
            );
        },
        project() {
            return this.$store.state.v2.selected.selected_project;
        },
    },
    methods: {
        newReport() {
            this.$refs.repdialog.newReport();
            bus.$emit("report_dialog");
        },
    },
};
</script>