export var selectTreeActualsMixin = {
    props: ["value", "level"],
    data: () => ({
        by_priority: false,
        selected_actuals: [],
    }),
    watch: {
        value: {
            handler: function (v) {
                this.selected_actuals = v
            },
            deep: true,
            immediate: true,
        },
        selected_actuals(sa) {
            this.$emit("input", sa)
        },
        level(level) {
            console.log("[i] Remounting SelectTreeActualsXXX with level:", level)
        },
    },

}

