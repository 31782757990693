<template>
    <v-navigation-drawer :value="show_drawer" absolute stateless disable-route-watcher right width="900">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title> Design Detail </v-list-item-title>
                </v-list-item-content>
                <v-btn icon :to="{ name: 'constructibles_manager', query: $route.query }">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
        <v-divider></v-divider>

        <!-- Detail -->
        <DrawerAssemblyDesign v-if="assd_uuid" hide_constructibles />
        <DrawerModuleDesign v-if="modd_uuid" hide_constructibles />

    </v-navigation-drawer>
</template>

<script>
import DrawerAssemblyDesign from "/src/components/designs/DrawerAssemblyDesign.vue";
import DrawerModuleDesign from "/src/components/designs/DrawerModuleDesign.vue";

export default {
    components: {
        DrawerAssemblyDesign,
        DrawerModuleDesign
    },
    data: () => ({}),
    computed: {
        assd_uuid() {
            return this.$route.params.assd_uuid;
        },
        modd_uuid() {
            return this.$route.params.modd_uuid;
        },
        show_drawer() {
            return this.assd_uuid || this.modd_uuid
        },
    },
};
</script>