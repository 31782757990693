<template>
    <v-card outlined class="px-3 pb-3 my-3">
        <div class="d-flex my-3">
            <h4 class="mb-2 font-weight-light grey--text">
                {{ $store.getters.t("materials") }}
            </h4>
        </div>
        <v-alert dense outlined type="info" v-if="precondition_required">
            {{ $store.getters.t("prepare_viewer_required") }}
        </v-alert>
        <v-select outlined dense multiple v-model="selected_materials" :loading="loading ? 'accent' : false"
            :disabled="loading" :items="materials" :label="$store.getters.t('materials')">
        </v-select>
    </v-card>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";

export default {
    props: ["value", "ass_uuids", "part_tipo"],
    data: () => ({
        materials: [],
        loading: false,
        selected_materials: [],
        precondition_required: false,
    }),
    watch: {
        value: {
            handler: function (v) {
                if (Array.isArray(v))
                    this.selected_materials = v;
                else this.selected_materials = []
            },
            immediate: true,
        },
        selected_materials: {
            handler: function (sm) {
                this.$emit("input", sm);
            },
            deep: true,
        },
        ass_uuids: {
            handler: function () {
                this.fetch_materials();
            },
            immediate: true,
        },
        part_tipo: {
            handler: function () {
                this.fetch_materials();
            },
        },
    },
    methods: {
        fetch_materials() {
            console.log("[i] Fetching materials.");
            this.loading = true;
            let url = `/logistics/sizes/${this.part_tipo}s/names/`
            backend_api_v2
                .post(url, {
                    assemblies: this.ass_uuids,
                })
                .then((r) => {
                    if (r.status == 200) {
                        console.log("[i] Updating materials form");
                        this.materials = r.data;
                        this.precondition_required = false;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 428) {
                        this.precondition_required = true;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        this.fetch_materials();
    },
};
</script>