// import * as PDFJS from "pdfjs-dist/build/pdf";
// import PdfjsWorker from "worker-loader!pdfjs-dist/build/pdf.worker.js"
// import * as PdfjsWorker from "pdfjs-dist/build/pdf.worker"
var PDFJS = window['pdfjs-dist/build/pdf'];
// var PdfjsWorker = window['pdfjs-dist/build/pdf.worker'];

if (typeof window !== 'undefined' && 'Worker' in window) {
    // PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();
    PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js'
}

export default {
    "PDFJS": PDFJS
};