<template>
    <div class="d-flex flex-column align-space-between mb-1">
        <v-btn small color="secondary" class="ml-1 mr-1" @click="dialog = true">
            <v-icon left>mdi-message-alert-outline</v-icon>
            {{ $store.getters.t("note") }}
        </v-btn>
        <v-dialog v-model="dialog" max-width="700">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("new_issue_for_actual") }}:
                    {{ dname }}
                </v-card-title>
                <v-card-subtitle>
                    {{ $store.getters.t("issue_attached_to_constructible") }}:
                    {{ actual_uuid.substring(0, 8) }}
                </v-card-subtitle>
                <v-card-text>
                    <v-form ref="form" v-model="form_valid">
                        <SelectIssueCat v-model="category" />
                        <v-row>
                            <v-checkbox :label="$store.getters.t('notify_others')" v-model="notify_on_production"
                                class="ml-2 mb-2">
                            </v-checkbox>
                            <v-spacer></v-spacer>
                            <div class="mr-3 mt-4">
                                <DialogContextualSend ref="messages" v-model="message_users"
                                    :text="message_crafted_text" />
                            </div>
                        </v-row>
                        <v-text-field outlined dense :label="$store.getters.t('title')" :rules="[rules.required]"
                            v-model="title"></v-text-field>
                        <v-textarea outlined dense :label="$store.getters.t('content')" :rules="[rules.required]"
                            v-model="text"></v-textarea>
                    </v-form>
                    <div class="uppy-picture ma-3"></div>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="addPieceInfo()" color="secondary">
                        {{ $store.getters.t("attach_piece_info") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="submitIssue()" color="success" :loading="loading" :disabled="loading">
                        <v-icon left>mdi-check</v-icon>
                        {{ $store.getters.t("submit") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

// IMPORTANT
// This issue to be updated with packages 🚀
// Needs to use a pck with name "pictures" linked to the issue 
// It's needed a backend migration to migrate resources to pck  🚀
// Needed to update Issues report.
// Need to delete "resources" field.
// IMPORTANT



import SelectIssueCat from "/src/components/fields/SelectIssueCat.vue"
import DialogContextualSend from "/src/components/chat/DialogContextualSend";
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "/src/utils/apiv2";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";
import Webcam from "@uppy/webcam";

export default {
    components: { SelectIssueCat, DialogContextualSend },
    data: () => ({
        form_valid: false,
        uppy: null,
        pck_uuid: null,
        dialog: false,
        loading: false,
        title: "",
        text: "",
        category: "prod_issue",
        status: "open",
        notify_on_production: false,
        message_users: [],
    }),
    computed: {
        rules() {
            return {
                required: (v) => !!v || "required",
            };
        },
        actual() {
            return this.$store.getters["v2/selected/annotatedRouteActual"]
        },
        project_uuid() {
            return this.$store.state.v2.selected.pr_selection
        },
        design() {
            return this.$store.getters["v2/selected/annotatedRouteDesign"]
        },
        project_name() {
            return this.$store.state.v2.selected.selected_project?.name;
        },
        dname() {
            return this.design?.name || "---";
        },
        actual_uuid() {
            return this.actual?.uuid || ""
        },
        message_crafted_text() {
            return `[New Issue] [Project: ${this.project_name
                }]\n[Actual: ${this.actual_uuid.substring(0, 8)}] [Design: ${this.dname
                }]\n[Title: ${this.title}]\n${this.text}`;
        },
        selected_parts_text() {
            let uuids = this.$store.state.v2.viewer.selected_parts_ifc_ids;
            if (!this.design || uuids.length == 0) {
                return "";
            } else {
                let text = "\n\n<---- BEGINING OF RELATED PIECES INFO ------>";
                this.$store.state.v2.selected.selected_parts
                    .forEach((part) => {
                        text += "\n\nIFC guid: " + part.gid;
                        text += "\nName: " + part.meta?.name;
                        text += "\nPart DName: " + part.design?.name;
                        text += "\nObject type: " + part.meta?.object_type;
                        text += "\nZone: " + part.meta?.zone;
                    });
                text += "\n\n<---- END OF RELATED PIECES INFO ------>";
                return text;
            }
        },
    },
    watch: {
        dialog(newDialog) {
            // Build the upload when dialog launch. Destroy on dialog close.
            if (newDialog) this.$nextTick(this.reloadUppy);
            else this.uppy.close();
        },
    },
    methods: {
        addPieceInfo() {
            this.text += this.selected_parts_text;
        },
        submitIssue() {
            // validate, 
            // submit, 
            // on receive get_or_create package,
            // on receive create start upload.
            if (!this.$refs.form.validate()) return
            console.log("[i] Submitting issue.")
            this.$store.dispatch("v2/records/postIssue", {
                title: this.title,
                text: this.text,
                category: this.category,
                status: this.status,
                assembly: this.actual_uuid,
                assembly_design: this.design.uuid,
                // module: null,
                // module_design: null,
                notify_on_production: this.notify_on_production,
            }).then(({ data }) => {
                backend_api_v2
                    .post("/cloudstorage/packages/get_or_create/", {
                        name: "pictures",
                        issue: data.uuid,
                    })
                    .then(({ data }) => {
                        this.pck_uuid = data.uuid
                        this.uppy.upload()
                        this.submittedIssue(data)
                    })
            });
        },
        submittedIssue(issue) {
            this.$refs.messages.send_messages({
                issue: issue.uuid,
                issue_project: this.project_uuid,
                assembly: this.actual_uuid,
            });
        },
        get_upload_parameters(file) {
            return backend_api_v2
                .post(`/cloudstorage/packages/${this.pck_uuid}/presign_for_new_file/`, {
                    name: file.name,
                    type: file.type,
                })
                .then((r) => r.data)
        },
        reloadUppy() {
            this.uppy = new Uppy({ debug: true })
                .use(Dashboard, {
                    theme: this.$vuetify.theme.dark ? "dark" : "light",
                    target: ".uppy-picture",
                    inline: true,
                    hideUploadButton: true,
                    width: 750,
                    height: 400,
                    proudlyDisplayPoweredByUppy: false,
                    locale: {
                        strings: {
                            dropPasteImport:
                                this.$store.getters.t("uploader_help_text"),
                        },
                    },
                })
                .use(AwsS3, {
                    metaFields: ["name"],
                    getUploadParameters: this.get_upload_parameters,
                })
                .use(Webcam, {
                    target: Dashboard,
                    modes: ["picture"],
                    facingMode: "environment",
                    mirror: false,
                });
            this.uppy.on("upload", () => (this.loading = true))
            this.uppy.on("complete", () => {
                this.loading = false;
                this.dialog = false;
                setTimeout(
                    () => this.$store.dispatch("v2/selected/loadHotIssues"),
                    5000
                );
                bus.$emit("notification", {
                    timeout: 3000,
                    text: `Issue sended.`,
                    color: "info",
                });
            });
        },
    },
};
</script>