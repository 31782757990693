<template>
    <v-card outlined class="px-3 mt-4">
        <v-row class="justify-space-between">
            <v-card-subtitle class="mt-2 ml-4">
                {{ $store.getters.t("actual_to_report") }}
            </v-card-subtitle>
            <v-switch v-model="by_priority" dense class="mr-4 mt-2"
                :label="$store.getters.t('sort_by_priority')"></v-switch>
        </v-row>
        <v-treeview class="mb-3" item-disabled="locked" dense selectable v-model="selected_actuals" :items="actuals_tree">
            <template v-slot:label="{ item }">
                <span class="grey--text" v-if="item.ready">
                    {{ item.name }}
                </span>
                <span v-else> {{ item.name }} </span>
                <v-chip outlined x-small v-if="item.ready" color="green">
                    ready
                </v-chip>
            </template>
        </v-treeview>
    </v-card>
</template>

<script>
import _ from "lodash"
import { nameSort } from "/src/utils/basics.js"
import { selectTreeActualsMixin } from "./selectTreeActualsMixin"
export default {
    mixins: [selectTreeActualsMixin],
    props: ["project"],
    computed: {
        batches() {
            let batches = this.$store.state.v2.constructibles.batches
            // return batches
            if (this.by_priority) return _.orderBy(batches, "priority")
            else return batches.slice().sort((a, b) => nameSort(a.name, b.name))
        },
        actuals() {
            let actuals = []
            if (this.level == "assembly") actuals = this.$store.state.v2.constructibles.assemblies
            else if (this.level == "module") actuals = this.$store.state.v2.constructibles.modules
            return actuals.filter((actual) => this.batches.map((b) => b.uuid).includes(actual.batch))
        },
        actuals_tree() {
            let actuals_tree = []

            // ITERATE PHASES
            this.project.phases.forEach((phase) => {

                // ITERATE PHASE BATCHES
                let children_batches = []
                this.batches
                    .filter((b) => b.phase == phase)
                    .forEach((b) => {
                        // GENERATE BATCH CHILDREN
                        let children_actuals = []
                        this.actuals
                            .filter((actual) => actual.batch == b.uuid)
                            .forEach((actual) => {
                                children_actuals.push({
                                    ready: actual.status == "ready",
                                    id: actual.uuid,
                                    design_name: this.get_design_name(actual.design),
                                    priority: actual.priority,
                                    name: `${this.get_design_name(actual.design)} [${actual.uuid.substring(0, 8)}]`,
                                })
                            })

                        // NOW SORT CHILDREN
                        let sorted_children
                        if (this.by_priority) {
                            sorted_children = _.orderBy(
                                children_actuals,
                                "priority"
                            )
                        } else {
                            sorted_children = children_actuals.sort((a, b) =>
                                nameSort(a.design_name, b.design_name)
                            )
                        }

                        // ADD BATCH TO PHASE CHILDREN
                        children_batches.push({
                            id: b.uuid,
                            name: b.name,
                            locked: sorted_children.length == 0,
                            children: sorted_children,
                        })
                    })

                // ADD PHASE TO TREE
                actuals_tree.push({
                    id: phase,
                    name: phase,
                    locked: children_batches.length == 0,
                    children: children_batches,
                })
            })
            return actuals_tree
        },
    },
    methods: {
        get_design_name(duuid) {
            return this.$store.getters["v2/constructibles/designNameByUuid"](duuid)
            // let d = this.designs.find((d) => d.uuid == duuid)
            // return `${d ? d?.name : ""} rev ${d?.revision}`
        },
    },
}
</script>