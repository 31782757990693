<template>
    <v-btn-toggle outlined mandatory class="ma-3" v-model="mode">
        <v-btn text color="accent" width="125" height="60" value="manage">
            <div class="dl-flex flex-column">
                <v-icon color="accent" outlined class="ml-2 mr-1 my-1">
                    mdi-laptop
                </v-icon>
                <br />
                {{ $store.getters.t("management_mode_short") }}
            </div>
        </v-btn>
        <v-btn text color="accent" width="125" height="60" value="logistics" :disabled="disable_viewer">
            <div class="dl-flex flex-column">
                <v-icon color="accent" outlined class="ml-2 mr-1 my-1">
                    mdi-forklift
                </v-icon>
                <br />
                {{ $store.getters.t("logistics_mode_short") }}
            </div>
        </v-btn>
        <v-btn text color="accent" width="125" height="60" value="production" :disabled="disable_viewer">
            <div class="dl-flex flex-column">
                <v-icon color="accent" outlined class="ml-2 mr-1 my-1">
                    mdi-floor-plan
                </v-icon>
                <br />
                {{ $store.getters.t("production_mode_short") }}
            </div>
        </v-btn>
    </v-btn-toggle>
</template>


<script>
export default {
    components: {},
    data: () => ({ mode: "production" }),
    computed: {
        store_mode() {
            return this.$store.state.app_mode;
        },
        stdlib_mode() {
            return this.$store.state.v2.selected.stdlib_mode
        },
        disable_viewer() {
            if (this.stdlib_mode) return true
            else return this.$store.getters["companyWide"] && !this.$store.getters["v2/session/work_by_date"];
        },
    },
    watch: {
        store_mode: {
            handler: function (sm) {
                this.mode = sm;
            },
            immediate: true,
        },
        mode(m) {
            this.$store.commit("setMode", m);
        },
    },
    methods: {},
};
</script>
