<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title>
                        <v-icon left> mdi-card-account-details </v-icon>
                        {{ $store.getters.t("account") }}
                    </v-card-title>
                    <v-card-subtitle> </v-card-subtitle>
                    <v-card-text>
                        Username: {{ user.username }} <br>
                        {{ $store.getters.t("production_line") }}:
                        {{ user.auth.selected_production_line_name }}
                        <br />
                        {{ $store.getters.t("production_station") }}:
                        {{ user.auth.selected_production_station_name }}
                        <br />
                    </v-card-text>
                    <br />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonLanguage v-if="user.auth.multilang" />
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title> <v-icon left> mdi-lock </v-icon> 2FA </v-card-title>
                    <v-card-subtitle>
                        Enabled:
                        <IconBoolean :value="user.auth.otp_enabled" />
                    </v-card-subtitle>
                    <v-card-text>
                        {{ $store.getters.t("otp_help_text") }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <Button2FASetup v-if="!user.auth.otp_enabled" />
                        <Button2FADisable v-else />
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card outlined class="fill-height">
                    <v-card-title> <v-icon left> mdi-bell-outline </v-icon> Notifications </v-card-title>
                    <v-card-text>
                        {{ $store.getters.t("notifications_setup_help") }}
                    </v-card-text>
                    <br><br><br>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonNotificationsSetup />
                        <div class="mx-1"></div>
                        <ButtonEmailMessagesSetup />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped></style>

<script>
import IconBoolean from "/src/components/renderers/IconBoolean";
import Button2FASetup from "/src/components/account/Button2FASetup";
import Button2FADisable from "/src/components/account/Button2FADisable";
import ButtonLanguage from "/src/components/account/ButtonLanguage";
import ButtonNotificationsSetup from "/src/components/account/ButtonNotificationsSetup.vue";
import ButtonEmailMessagesSetup from "/src/components/account/ButtonEmailMessagesSetup.vue";
export default {
    components: {
        IconBoolean,
        Button2FASetup,
        Button2FADisable,
        ButtonLanguage,
        ButtonNotificationsSetup,
        ButtonEmailMessagesSetup,
    },
    data: () => ({}),
    computed: {
        user() {
            return this.$store.state.v2.session.user_object;
        },
    },
};
</script>