<template>
    <div class="hscrollmenu">
        <v-btn v-for="cf in button_cfs" :key="cf.uuid" class="mx-1" color="secondary" @click="selectCF(cf.uuid)">
            {{ cf.short_name }}
        </v-btn>
        <!-- Now extra packages -->
        <v-btn v-for="pck in extra_pcks" :key="pck.uuid" class="mx-1" @click="selectCF(pck.files[0].uuid)">
            {{ pck.name }}
        </v-btn>
    </div>
</template>

<style lang="scss" scoped>
.hscrollmenu {
    overflow: scroll;
    white-space: nowrap;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import { nameSort } from "/src/utils/basics";
import { EXTRA_PCKS_FOR_VIEWER } from "../setup";
export default {
    name: "RightPanelButtons",
    computed: {
        ...mapGetters({
            pcks: "v2/viewer/allPackages",
            res_pck: "v2/viewer/resPackage",
        }),
        ...mapState({
            mode: "v2.viewer.right_panel_mode",
        }),
        panel_mode() {
            return this.$store.state.v2.viewer.right_panel_mode;
        },
        details() {
            return this.res_pck?.files.filter(cf => cf.meta.section == "detail") || []
        },
        docs() {
            return this.res_pck?.files.filter(cf => cf.meta.section == "doc") || []
        },
        button_cfs() {
            if (this.panel_mode == "details") {
                return [...this.details].sort((a, b) => nameSort(a.short_name, b.short_name))
            } else {
                return [...this.docs].sort((a, b) => nameSort(a.short_name, b.short_name))
            }
        },
        extra_pcks() {
            if (this.panel_mode == "details") return [];
            return this.pcks.filter(pck => EXTRA_PCKS_FOR_VIEWER.includes(pck.name))
        }
    },
    methods: {
        selectCF(cfuuid) {
            this.$store.commit("v2/viewer/setRightPanelCFUuid", cfuuid);
        },
    },
};
</script>