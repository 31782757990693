<template>
    <div class="ma-3">
        <v-card-title v-if="pd_uuid">
            <v-icon left>mdi-lightbulb-fluorescent-tube-outline</v-icon>
            {{ $store.getters.t("part_data") }}
        </v-card-title>
        <v-card-title v-else>
            <v-icon left>mdi-lightbulb-fluorescent-tube-outline</v-icon>
            {{ $store.getters.t("Please, select a part") }}
        </v-card-title>
        <v-card-text v-if="pd_uuid">
            <div class="mb-3">
                <CardMaterialPropsWrapperLogistics />
            </div>
            <div class="mb-3">
                <PartDrawing :pd_uuid="pd_uuid" />
            </div>
        </v-card-text>
    </div>
</template>

<script>
import CardMaterialPropsWrapperLogistics from "./CardMaterialPropsWrapperLogistics.vue";
import PartDrawing from "/src/components/viewer/right/PartDrawing.vue";

export default {
    components: {
        PartDrawing,
        CardMaterialPropsWrapperLogistics,
    },
    data: () => ({}),
    computed: {
        pd_uuid() {
            return this.$store.state.v2.selected_logistics.selected_part || "";
        },
        selected_part() {
            return this.$store.state.v2.selected_logistics.selected_part || "";
        },
    },
};
</script>
