
export function get_subsets_from_pl(pl) {
    let subsets = []
    pl.stations.forEach(sta => {
        sta.disabled_subsets.forEach(subset => {
            if (subset && !subsets.includes(subset)) subsets.push(subset)
        })
        sta.checklist_items?.forEach(chi => {
            chi.subsets?.forEach(subset => {
                if (subset && !subsets.includes(subset)) subsets.push(subset)
            })
        })
    })
    return subsets
}