<template>
    <v-dialog v-model="dialog" width="900">
        <template v-slot:activator="{ attrs, on }">
            <v-btn color="success" small class="ml-2" v-bind="attrs" v-on="on">
                <v-icon left small> mdi-format-list-checkbox </v-icon>
                {{ $store.getters.t("form") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title> Form ({{ title }}) </v-card-title>
            <v-card-text>
                <v-form ref="form" @keyup.enter.native="save">
                    <DynamicField v-for="(field, i) in schema" v-model="form[i]" :schema="field" :key="i" />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" @click="save">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="close">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DynamicField from "/src/components/fields/DynamicField"
export default {
    components: {
        DynamicField,
    },
    props: ["value", "title", "schema"],
    data: () => ({
        dialog: false,
        form: {},
    }),
    watch: {
        dialog(d) {
            if (d) this.form = { ...this.value }
            this.$nextTick(this.validate)
        }
    },
    methods: {
        close() {
            this.dialog = false
        },
        save() {
            this.validate()
            this.$emit("update", this.form)
        },
        validate() {
            this.form.valid = this.$refs.form.validate()
        }
    },
    mounted() {
        this.form = { ...this.value }
    }
}
</script>