<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="refresh" :loading="loading" :disabled="loading">
                <v-icon> mdi-refresh </v-icon>
            </v-btn>
        </template>
        <span>
            {{ $store.getters.t("reset_counts_to_existent") }}
        </span>
    </v-tooltip>
</template>

<script>
import bus from "/src/utils/event_bus"

export default {
    data: () => ({
        loading: false,
    }),
    computed: {
        project() {
            return this.$store.state.v2.selected.selected_project
        },
        project_meta() {
            return this.$store.state.v2.selected.selected_project?.meta
        },
    },
    methods: {
        refresh() {
            this.$emit("reset")
            this.clean_meta()
        },
        clean_meta() {
            this.loading = true
            let meta = { ...this.project_meta }
            delete meta.quantities
            this.$store
                .dispatch("v2/company/putProject", {
                    uuid: this.project.uuid,
                    meta,
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        bus.$on("quantities_reset_button_clean_meta", () => this.clean_meta())
    },
    beforeDestroy() {
        bus.$off("quantities_reset_button_clean_meta")
    }
}
</script>