<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y
        min-width="auto">

        <template v-slot:activator="{ on, attrs }">
            <v-text-field :loading="loading" :disabled="disabled" outlined dense v-model="selected_date" :label="label"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>

        <v-date-picker v-model="selected_date" no-title scrollable>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: ["value", "label", "loading", "disabled"],
    data: () => ({
        selected_date: new Date().toISOString().substr(0, 10),
        menu: false,
    }),
    methods: {
    },
    watch: {
        selected_date(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_date = v;
            },
            immediate: true,
        },
    }
};
</script>
  