<template>
    <v-select hide-details dense :solo="!asyncmode" :flat="!asyncmode" :outlined="asyncmode"
        :loading="loading ? 'accent' : false" :disabled="loading || locked" :items="selection_list" item-text="name"
        item-value="uuid" class="project-select mr-1" :value="selected_value" :label="$store.getters.t('production_line')"
        @change="updateBatchProductionLine($event)"></v-select>
    <!-- UPDATE THIS PLEASE WITH THE SUBSET SELECTION -->
</template>

<script>
import { get_subsets_from_pl } from "/src/utils/company";
import bus from "/src/utils/event_bus";
export default {
    props: ["batch", "asyncmode"],
    data: () => ({ loading: false }),
    computed: {
        selected_value() {
            if (this.batch.task_subset) {
                return `${this.batch.production_line}__${this.batch.task_subset}`
            } else {
                return this.batch.production_line
            }
        },
        production_lines() {
            return this.$store.state.v2.factories.production_lines;
        },
        selection_list() {
            let slist = []
            this.production_lines.forEach(pl => {
                slist.push(pl)
                let subsets = get_subsets_from_pl(pl)
                subsets.forEach(subset => {
                    slist.push({
                        "name": `${pl.name}: ${subset}`,
                        "uuid": `${pl.uuid}__${subset}`
                    })
                })
                slist.push({ divider: true })
            })
            //     { divider: true },
            //     { header: "Header Title" },
            slist.pop()  // Remove last divider
            return slist
        },
        locked() {
            if (this.asyncmode) return false;
            else
                return this.$store.getters["v2/constructibles/isBatchReadyByUuid"](
                    this.batch.uuid
                );
        },
    },
    methods: {
        updateBatchProductionLine(event) {
            let production_line, task_subset
            if (event.includes("__")) {
                let splitted = event.split("__")
                production_line = splitted[0]
                task_subset = splitted[1]
            }
            else {
                production_line = event
                task_subset = ""
            }
            let new_batch = { ...this.batch, production_line, task_subset };
            this.loading = true;
            this.$store.commit("overview/setTouched");
            this.$store
                .dispatch("v2/constructibles/putBatch", new_batch)
                .then(() =>
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: `Production line updated.`,
                        color: "success",
                    })
                )
                .finally(() => (this.loading = false));
        },
    },
    mounted() { },
};
</script>
