<template>
    <div>
        <!-- <v-btn icon @click="set_camera('back')" class="mr-5">
            <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn icon @click="set_camera('front')" class="mr-5">
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn> -->
        <!-- <v-btn small @click="set_camera('top')"> top </v-btn>
        <v-btn small @click="set_camera('left')"> left </v-btn>
        <v-btn small @click="set_camera('bottom')"> bottom </v-btn>
        <v-btn small @click="set_camera('right')"> right </v-btn> -->
        <v-btn outlined small @click="set_camera('front')" class="mr-1"> {{ $store.getters.t("front") }} </v-btn>
        <v-btn outlined small @click="set_camera('back')"> {{ $store.getters.t("back") }} </v-btn>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus";
export default {
    methods: {
        set_camera(view) {
            bus.$emit("events/viewer/3d_set_camera", view);
        },
    },
};
</script>