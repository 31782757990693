<template>
    <div>
        <v-select
            class="mx-1"
            outlined
            dense
            v-model="selected_role"
            :items="roles"
            label="Role"
            ref="field"
            :rules="[v=>!!v||'Required.']"
        >
        </v-select>
    </div>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        selected_role: "",
        roles: [
            { value: "company_admin", text: "Company Admin - Full access." },
            {
                value: "company_manager",
                text: "Company Manager - Manage projects and users.",
            },
            {
                value: "production_user",
                text: "Production User - View and update panels status. Add annotations and time entries.",
            },
            {
                value: "external_viewer",
                text: "External Viewer - Only view panels.",
            },
            { value: "none", text: "No rights." },
        ],
    }),
    watch: {
        value(v) {
            this.selected_role = v;
        },
        selected_role(sta) {
            this.$emit("input", sta);
        },
    },

    // inject parent VForm component (vuetify v-form validation system)
    inject: ["form"],
    mounted() {
        // Injected "form" has method register to register inputs.
        this.form.register(this);
    },
    methods: {
        validate() {
            return this.$refs.field.validate();
        },
    },
};
</script>