<template>
    <div id="canvas-container" ref="canvasContainer">
        <canvas @click="pick" id="three-canvas" ref="threeCanvas"></canvas>
        <v-overlay :value="loading" :absolute="true" :opacity="0.4">
            <div class="d-flex flex-column align-center">
                <v-progress-circular indeterminate color="white"></v-progress-circular>
                <span class="display-1 font-weight-light mt-4"> {{ $store.getters.t("loading_ifc") }} </span>
            </div>
        </v-overlay>
    </div>
</template>

<style lang="scss" scoped>
#canvas-container {
    height: calc(100% - 100px);
    width: 100%;
}

#three-canvas {
    height: 100%;
    width: 100%;
    // background-color: lightblue;
    // touch-action: none;
}
</style>

<script>
import { ifcViewerMixin, MODEL_KEY } from "./ifcViewerMixin"



export default {
    name: "IFCJSViewModule",
    mixins: [ifcViewerMixin],
    computed: {
        design() { return this.$store.state.v2.selected.selected_modd },
        bom() { return this.$store.state.v2.viewer.bom },
        layer_names() { return this.$store.getters["v2/viewer/module_layers"] },
        selected_layers() { return this.$store.state.v2.viewer.selected_zones },
    },
    watch: {
        selected_layers(layers) {
            this.render_layers(layers)
        },
        guid_maps() {
            this.build_layer_subsets()
        },
        bom() {
            this.build_layer_subsets()
        },
        model() {
            this.build_layer_subsets()
        }
    },
    methods: {
        build_layer_subsets() {
            let assds = this.layer_names

            // Deferrer.
            let model = this.ifc_models[MODEL_KEY]

            if (
                !this.guid_maps[MODEL_KEY] ||
                this.bom.length == 0 ||
                !model
            ) {
                console.log("⌚[produuz.it IFC Viewer] Can't show assds right now. Waiting data updates.")
                return
            }

            let mid = model.mesh.modelID

            // Clean subsets
            Object.keys(this.layer_subsets).forEach(k => {
                this.layer_subsets[k].removeFromParent()
            })
            this.layer_subsets = {}

            try {
                for (const assd of assds) {
                    let assd_guids = this.bom.filter(p => p.assembly_name == assd).map(p => p.gid)
                    let assd_eids = assd_guids.map((uuid) => this.guid_maps[MODEL_KEY]?.[uuid])
                    let subset = this.ifcLoader.ifcManager.createSubset({
                        modelID: mid,
                        scene: this.scene,
                        ids: assd_eids,
                        removePrevious: true,
                        customID: "assd_" + assd,
                    })
                    this.layer_subsets[assd] = subset
                    this.scene.add(subset)
                }
                console.log("✅[produuz.it IFC Viewer] Built zone subsets")
            } catch {
                console.log("⛔[produuz.it IFC Viewer] Can't build zone subsets")
            }
        },
        render_layers(sassds) {
            console.log("[i][produuz.it IFC Viewer] Rendering sassds:", sassds)

            // Remove main model
            this.ifc_models[MODEL_KEY]?.removeFromParent()

            // Render subsets
            let assd_names = Object.keys(this.layer_subsets)
            for (const assd of assd_names) {
                if (sassds.includes(assd)) {
                    this.scene.add(this.layer_subsets[assd])
                }
                else {
                    this.layer_subsets[assd].removeFromParent()
                }
            }
        },
    },
}
</script>
