<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="dialog = true" :loading="loading" :disabled="loading">
                    <v-icon> mdi-table-column-plus-after </v-icon>
                </v-btn>
            </template>
            <span>
                {{ $store.getters.t("add_batch") }}
            </span>
        </v-tooltip>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="headline">{{ $store.getters.t("new_batch_for_project") }}:
                    {{ project ? project.name : "---" }},
                    {{ $store.getters.t("in_phase") }}:
                    {{ phase || "---" }}</v-card-title>
                <v-card-text>
                    <PitTextField @enter="addBatch()" label="New batch" v-model="new_batch_name"></PitTextField>
                </v-card-text>
                <v-card-text v-if="batch_exists">
                    <v-alert type="error">{{
                            $store.getters.t("existing_batch")
                    }}</v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="batch_exists" @click="addBatch()" color="primary">
                        {{ $store.getters.t("add") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PitTextField from '../../fields/PitTextField.vue';

export default {
    name: "ButtonBatchAdd",
    components: { PitTextField },
    props: ["phase"],
    data: () => ({
        new_batch_name: "Batch_",
        dialog: false,
        loading: false,
    }),
    computed: {
        batches() {
            return this.$store.state.v2.constructibles.batches.filter(
                (b) => b.phase == this.phase
            );
        },
        batch_exists() {
            return this.batches
                .map((b) => b.name)
                .includes(this.new_batch_name);
        },
        farest_priority() {
            let max_now = Math.max(...this.batches.map((b) => b.priority));
            return Math.max(max_now, -1);
        },
        project() {
            return this.$store.state.v2.selected.selected_project;
        },
    },
    methods: {
        addBatch() {
            this.loading = true;
            let batch = {
                project: this.project.uuid,
                phase: this.phase,
                name: this.new_batch_name,
                production_line: null,
                priority: this.farest_priority + 1,
            };
            this.$store
                .dispatch("v2/constructibles/postBatch", batch)
                .finally(() => (this.loading = false));
            this.dialog = false;
        },
    },
};
</script>