<template>
    <v-card-text class="pa-0">
        <!-- <p v-for="f in files" :key="f.uuid">{{ f }}</p> -->
        <v-text-field label="Filename search" outlined v-model="search" clearable hide-details></v-text-field>
        <v-checkbox label="Only latest versions" v-model="only_latest" dense hide-details />
        <v-data-table :items="files" :headers="headers" item-key="uuid" sort-by="timestamp" :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, -1],
        }" :items-per-page="25" show-select v-model="selectedFiles">
            <template v-slot:item.version="{ item }"> {{ item.meta.version }} </template>
            <template v-slot:item.section="{ item }">
                <v-icon v-if="item.meta.text" small>
                    mdi-script-text
                </v-icon>
                {{ item.meta.section }}
            </template>
            <template v-slot:top>
                <div v-if="selectedFiles.length == 0"><br /></div>
                <div class="mx-5 my-2" v-else>
                    <ButtonDeleteFiles :cfs="selectedFiles" @completed="selectedFiles = []" />
                    <v-btn @click="downloadSelectedFiles" color="accent" text dense>
                        <v-icon left>mdi-download</v-icon>
                        Download files
                    </v-btn>
                </div>
            </template>
            <template v-slot:item.tipo="{ item }"> {{ item.meta.tipo }} </template>
            <template v-slot:item.status="{ item }">
                <StatusResourceFile v-if="item.meta.status" :value="item.meta.status" />
            </template>
            <template v-slot:item.timestamp="{ item }">
                <Timestamp :value="item.created" />
            </template>
            <template v-slot:item.actions="{ item }">
                <ButtonJSONRender :title="item.name" :json="item.meta" />
                <v-btn icon target="_blank" :disabled="!item.presigned_url || !!item.meta.text" :href="item.presigned_url">
                    <v-icon> mdi-download </v-icon>
                </v-btn>
                <ButtonDeleteFile :file="item" :delete_from_package="pck.uuid" />
            </template>
        </v-data-table>
    </v-card-text>
</template>

<script>
import { nameSort } from "/src/utils/basics.js";
import Timestamp from "/src/components/renderers/Timestamp";
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender";
import StatusResourceFile from "./StatusResourceFile";
import ButtonDeleteFile from "./ButtonDeleteFile";
import ButtonDeleteFiles from "./ButtonDeleteFiles";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export default {
    components: {
        Timestamp,
        ButtonJSONRender,
        StatusResourceFile,
        ButtonDeleteFile,
        ButtonDeleteFiles,
    },
    data: () => ({
        dialog: false,
        search: "",
        only_latest: false,
        selectedFiles: [],
    }),
    computed: {
        headers() {
            return [
                { text: this.$store.getters.t("name"), value: "name" },
                {
                    text: this.$store.getters.t("version"),
                    value: "version",
                },
                {
                    text: this.$store.getters.t("type"),
                    value: "section",
                },
                {
                    text: this.$store.getters.t("tipo"),
                    value: "tipo",
                },
                {
                    text: this.$store.getters.t("status"),
                    value: "status",
                },
                {
                    text: this.$store.getters.t("timestamp"),
                    value: "timestamp",
                },
                {
                    text: "Actions",
                    sortable: false,
                    align: "right",
                    value: "actions",
                },
            ];

        },
        pck() {
            return this.$store.getters["v2/cloudstorage/resourcesPackage"];
        },
        files() {
            if (!this.pck) return [];
            const s = this.search.toLowerCase();
            const files = this.pck?.files
                .filter((f) => !!f)
                .filter(
                    (f) =>
                        f.name?.toLowerCase().includes(s) ||
                        f.meta?.status?.some((status) => status.includes(s)) ||
                        f.meta?.section?.toLowerCase().includes(s)
                )
                .sort((a, b) => nameSort(a.name, b.name));
            if (this.only_latest) {
                const uniqueMap = {};
                files.forEach((obj) => {
                    if (!(obj.name in uniqueMap) || obj.meta.version > uniqueMap[obj.name].meta.version) {
                        uniqueMap[obj.name] = obj;
                    }
                });
                return Object.values(uniqueMap);
            } else {
                return files
            }
        },
    },
    methods: {
        downloadSelectedFiles() {
            const zip = new JSZip();
            const promises = [];

            for (const file of this.selectedFiles) {
                promises.push(
                    fetch(file.presigned_url)
                        .then(response => response.blob())
                        .then(blob => zip.file(file.name, blob))
                );
            }

            Promise.all(promises).then(() => {
                zip.generateAsync({ type: "blob" }).then(blob => {
                    saveAs(blob, "selected-files.zip");
                    this.selectedFiles = [];
                });
            });
        },
    },
};
</script>