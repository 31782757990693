

<template>
    <div class="d-flex">
        <v-checkbox
            class="my-0"
            :label="$store.getters.t('has_own_worktime')"
            v-model="checkbox"
            @change="launch_worktime_dialog($event)"
        />
        <a v-if="week_work_periods" class="ma-2" @click="dialog = true">
            {{ $store.getters.t("open") }}
        </a>
        <span v-if="week_work_periods" class="ma-2">
            {{ week_work_periods.length }} entries
        </span>
        <v-dialog v-model="dialog" width="1000">
            <v-card>
                <v-card-title>
                    {{ $store.getters.t("has_own_worktime") }}
                </v-card-title>
                <v-card-text>
                    <div class="d-flex ma-3 mt-0 mb-5">
                        <h2 class="font-weight-light title">
                            {{ $store.getters.t("work_calendar_subtitle") }}
                        </h2>
                        <v-spacer> </v-spacer>
                        <v-btn small text @click="add_working_hour_period">
                            <v-icon left> mdi-plus </v-icon>
                            {{ $store.getters.t("add_entry") }}
                        </v-btn>
                    </div>
                    <RowWeekHourEntry
                        v-for="(an, i) in week_work_periods"
                        :key="i + '_an'"
                        :i="i"
                        v-model="week_work_periods[i]"
                        @delete_period="delete_working_hour_period(i)"
                    />
                    <br /><br />
                </v-card-text>
                <v-card-actions>
                    <v-spacer> </v-spacer>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import RowWeekHourEntry from "./RowWeekHourEntry";
export default {
    props: ["value"],
    components: { RowWeekHourEntry },
    data: () => ({ dialog: false, week_work_periods: false, checkbox: false }),
    watch: {
        value: {
            handler: function (v) {
                this.checkbox = Array.isArray(v);
                this.week_work_periods = v;
            },
            immediate: true,
        },
        week_work_periods: {
            handler: function (wwp) {
                this.$emit("input", wwp);
            },
            deep: true,
        },
    },
    methods: {
        launch_worktime_dialog(ch) {
            if (ch) {
                this.week_work_periods = [];
                this.dialog = true;
            } else {
                this.week_work_periods = false;
                // this.$emit("input", false);
            }
        },
        add_working_hour_period() {
            this.week_work_periods.push({
                weekdays: [],
                range_dates: false,
                start: null,
                end: null,
                is_a_break: false,
            });
        },
        delete_working_hour_period(i) {
            this.week_work_periods.splice(i, 1);
        },
    },
};
</script>

