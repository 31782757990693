<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-if="icon != undefined" icon>
                <v-icon>mdi-calendar</v-icon>
            </v-btn>
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-else color="accent" text class="mr-2">
                <v-icon left>mdi-calendar</v-icon>
                {{ $store.getters.t("date") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("scheduled_date") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("scheduled_date_set_help_text") }}
                <br><br>
                <v-text-field clearable outlined dense hint="Set date as ISO string. Example: 2023-10-13"
                    :label="$store.getters.t('scheduled_date')" v-model="scheduled_prod_date"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="save()" color="error" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: ["uuids", "icon"],
    data: () => ({
        loading: false,
        dialog: false,
        scheduled_prod_date: "",
    }),
    methods: {
        save() {
            let payload = { uuids: this.uuids, payload: { scheduled_prod_date: this.scheduled_prod_date } }
            this.loading = true
            this.$store
                .dispatch("v2/constructibles/multiPatchActuals", payload)
                .finally(() => {
                    this.loading = false;
                    this.$emit("completed")
                });
            this.dialog = false;
        },
    }
}
</script>