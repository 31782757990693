<template>
    <div>
        <div v-if="show_parts_ready_button == 'manual'">
            <div class="my-2" v-if="selected_actuals.length == 0"><br /></div>
            <div v-else>
                <ButtonSetAsReady :actuals="selected_actuals" />
            </div>
        </div>
        <v-treeview open-all class="mb-3" item-disabled="locked" dense selectable v-model="selected_actuals"
            :items="actuals_tree">
            <template v-slot:label="{ item }">
                <span class="grey--text" v-if="item.ready || item.kitted_parts">
                    {{ item.name }}
                </span>
                <span v-else> {{ item.name }} </span>
                <v-chip outlined x-small v-if="item.ready" color="green">
                    ready
                </v-chip>
                <v-chip outlined x-small v-else-if="item.kitted_parts" color="info">
                    kitted
                </v-chip>
            </template>
        </v-treeview>
    </div>
</template>

<script>
// import _ from "lodash"
import { nameSort } from "/src/utils/basics.js"
import ButtonSetAsReady from "../../components/left_menu/ButtonSetAsReady.vue";

export default {
    components: {
        ButtonSetAsReady,
    },
    data: () => ({
        selected_actuals: [],
    }),
    computed: {
        actuals_tree() {
            let actuals_tree = []

            // ITERATE PHASES
            this.project.phases.forEach((phase) => {
                // console.log("FASE: " + phase);

                // ITERATE PHASE BATCHES
                let children_batches = []
                this.batches
                    .filter((b) => b.phase == phase)
                    .forEach((b) => {
                        // console.log("BATCH: " + b);

                        // GENERATE BATCH CHILDREN
                        let children_actuals = []
                        this.actuals
                            .filter((actual) => actual.batch == b.uuid)
                            .forEach((actual) => {
                                // console.log("ACTUAL: " + actual);
                                children_actuals.push({
                                    kitted_parts: actual.status == "kitted_parts",
                                    ready: actual.status == "ready",
                                    id: actual.uuid,
                                    design_name: this.getDesignName(actual.design),
                                    priority: actual.priority,
                                    name: `${this.getDesignName(actual.design)} [${actual.uuid.substring(0, 8)}]`,
                                })
                            })

                        // NOW SORT CHILDREN
                        let sorted_children
                        // if (this.by_priority) {
                        //     sorted_children = _.orderBy(
                        //         children_actuals,
                        //         "priority"
                        //     )
                        // } else {
                        sorted_children = children_actuals.sort((a, b) =>
                            nameSort(a.design_name, b.design_name)
                        )
                        // }

                        // ADD BATCH TO PHASE CHILDREN
                        children_batches.push({
                            id: b.uuid,
                            name: b.name,
                            locked: sorted_children.length == 0,
                            children: sorted_children,
                        })
                    })

                // ADD PHASE TO TREE
                actuals_tree.push({
                    id: phase,
                    name: phase,
                    locked: children_batches.length == 0,
                    children: children_batches,
                })
            })

            return actuals_tree
        },
        project() {
            return this.$store.state.v2.selected.selected_project || "";
        },
        batches() {
            return this.$store.state.v2.constructibles.batches;
        },
        actuals() {
            return this.$store.getters["v2/constructibles/allActuals"]
        },
        show_parts_ready_button() {
            return this.$store.getters["v2/session/kitted_parts_behavior"]
        }
    },
    // watch: {
    // selected_actuals:
    // this.store.commit.mutacionStore
    // },
    watch: {
        selected_actuals(sa) {
            // console.log(sa);
            this.$store.commit("v2/selected_logistics/setSelectedActuals", sa);
        },
    },
    methods: {
        getDesignName(duuid) {
            return this.$store.getters["v2/constructibles/designNameByUuid"](duuid)
        },
    },
};
</script>