export function get_rules_from_type(data_type) {
    if (data_type == "int")
        return [
            (v) => !v || !isNaN(v),
            (v) => !v || Number.isInteger(Number(v)),
        ];
    else if (data_type == "number") return [(v) => !v || !isNaN(v)];
    else return []
}


export const pit_rules = {
    required: value => !!value || 'Required.',
    illegalName: value => {
        const validChars = /^[a-zA-Z0-9_-]+$/;
        return validChars.test(value) || "Invalid character";
    }
}