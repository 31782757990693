import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"

export default {
    namespaced: true,
    state: {
        rcp: null,
        rcp_loading: false,
        issues: [],
    },
    mutations: {
        setRCP: (state, rcp) => state.rcp = rcp,
        loadingRCP: (state, b) => state.rcp_loading = b,

        // Issues
        setIssues: base.mutations.setter("issues"),
        replaceIssue: base.mutations.updater("issues"),
        createIssue: base.mutations.creator("issues"),
        updateIssue: base.mutations.updater("issues"),
        deleteIssue: base.mutations.deleter("issues"),
    },
    actions: {
        loadRCP(context, payload) {
            context.commit("loadingRCP", true)
            let url = `records/rcps/?${payload.level}=${payload.uuid}`
            return backend_api_v2.get(url)
                .then(({ data }) => {
                    context.commit("loadingRCP", false)
                    // If no proof
                    if (data.results.length == 0) {
                        // If no results, set null cause we may be changing actual.
                        context.commit('setRCP', null)
                    } else {
                        // If results, set first.
                        context.commit('setRCP', data.results[0])
                    }
                })
                .catch(e => {
                    console.log(`Cant load ready constructible proof: ${e}`)
                })
        },
        createRCP(context, payload) {
            let epk = payload.level == "assembly" ? "assemblies" : "modules"
            let url = `constructibles/${epk}/${payload.uuid}/create_proof/`
            return backend_api_v2.get(url)
                .then((response) => {
                    // And finally if really created, reload recursivelly.
                    if ([200, 201].includes(response.status)) {
                        return context.dispatch('loadRCP', payload)
                    }
                })
                .catch(e => {
                    console.log(`Cant create proof: ${e}`)
                    context.commit('setRCP', null)
                })
        },
        putRCP(context, proof) {
            return backend_api_v2.put(`records/rcps/${proof.uuid}/`, proof)
                .then(({ data }) => {
                    context.commit('setRCP', data)
                })
        },
        deleteRCP(context, proof_uuid) {
            return backend_api_v2.delete(`records/rcps/${proof_uuid}/`)
                .then(() => {
                    context.commit('setRCP', null)
                })
        },
        multiRCPFlushByActualUuids(context, actuals_uuids) {
            return backend_api_v2.post('constructibles/actuals/bulk_proof_flush/', actuals_uuids)
                .catch(e => { console.log(`Cant batch flush proofs actuals: ${e}`) })
        },

        // Issues
        loadIssues: base.actions.loader_pr("records/issues/", "Issues"),
        postIssue: base.actions.poster("records/issues/", "Issue"),
        putIssue: base.actions.putter("records/issues/", "Issue"),
        deleteIssue: base.actions.deleter("records/issues/", "Issue"),
        refreshIssue: base.actions.refresher("records/issues/", "Issue"),
    },
    getters: {
        rcpIsRunning: state => state.rcp?.paused || !state.rcp?.start_date
    }
}