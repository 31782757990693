<template>
    <v-data-table :items="table_items" :headers="headers" item-key="uuid" :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1],
    }" :items-per-page="50">
        <template v-slot:item.title="{ item }">
            <v-btn color="accent" text :to="'/projects/' + project_uuid + '/issues/' + item.uuid">
                {{ item.title }}
            </v-btn>
        </template>
        <template v-slot:item.actual="{ item }">
            <v-btn text :to="'/projects/' + project_uuid + '/constructibles/' + item.actual">
                {{ item.design_name }}
                <v-chip small outlined>
                    <v-icon small>mdi-sort-clock-ascending</v-icon> {{ item.actual_priority }}
                </v-chip>
            </v-btn>
        </template>
        <template v-slot:item.created="{ item }">
            <Timestamp :value="item.created" />
        </template>
        <template v-slot:item.user="{ item }">
            {{ item.username }}
        </template>
        <template v-slot:item.status="{ item }">
            <IconBoolean :value="item.status == 'solved'" />
        </template>
        <template v-slot:item.actions="{ item }">
            <ButtonIssueEdit icon :issue="item" />
            <ButtonIssueSolve v-if="item.status != 'solved'" icon :issue="item" />
            <ButtonIssueDelete :issue="item" icon />
        </template>
    </v-data-table>
</template>


<script>
import IconBoolean from "/src/components/renderers/IconBoolean";
import Timestamp from "/src/components/renderers/Timestamp";
import ButtonIssueEdit from "./ButtonIssueEdit";
import ButtonIssueSolve from "./ButtonIssueSolve";
import ButtonIssueDelete from "./ButtonIssueDelete";
export default {
    components: {
        ButtonIssueEdit,
        ButtonIssueSolve,
        IconBoolean,
        Timestamp,
        ButtonIssueDelete,
    },
    data: () => ({}),
    computed: {
        headers() {
            return [
                {
                    value: "title",
                    text: this.$store.getters.t("title"),
                },
                {
                    value: "actual",
                    text: this.$store.getters.t("constructible"),
                },
                {
                    value: "category",
                    text: this.$store.getters.t("category"),
                },
                { value: "user", text: this.$store.getters.t("user") },
                {
                    value: "created",
                    text: this.$store.getters.t("created"),
                },
                {
                    value: "status",
                    text: this.$store.getters.t("status"),
                },
                {
                    text: "Actions",
                    sortable: false,
                    align: "right",
                    value: "actions",
                },
                // { text: "", align: "end", value: "data-table-expand" },
            ];
        },
        issues() {
            return this.$store.state.v2.records.issues;
        },
        project_uuid() {
            return this.$route.params.project_uuid;
        },
        table_items() {
            return this.issues.map((issue) => {
                let design = this.$store.getters["v2/constructibles/allDesigns"]
                    .find(d => d.uuid == issue.assembly_design || d.uuid == issue.module_design)
                let design_name = design?.name || "-"
                let actual = this.$store.getters["v2/constructibles/allActuals"]
                    .find(actual => actual.uuid == issue.assembly || actual.uuid == issue.module)
                let actual_priority = actual?.priority || 0
                return {
                    ...issue,
                    design_name,
                    actual_priority,
                };
            });
        },
    },
};
</script>