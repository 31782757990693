<template>
    <tr v-on:mouseenter="onHover(_uid)" v-on:mouseleave="onHover()" height="50px"
        :class="{ 'active-row-l': hover && !dark, 'active-row-d': hover && dark, }">
        <td lass="title"> {{ total_text }} </td>
        <td v-for="phase in phases" :key="phase">
            <v-list-item-title class="title">
                {{ column_totals[phase] }}
                <AreaIndicator v-if="level == 'assembly'" :value="column_totals_m2[phase]" />
            </v-list-item-title>
        </td>
        <td align="right">
            <v-list-item-title class="title" :class="{ 'warning--text': !is_total_ok }" v-text="totals_text">
            </v-list-item-title>
            <v-list-item-subtitle> {{ $store.getters.t('project_total') }} </v-list-item-subtitle>
        </td>
    </tr>
</template>

<style lang="scss">
@import "./tables.scss";
</style>


<script>
import { rowMixin } from "./mixins"
export default {
    props: ["level"],
    mixins: [rowMixin],
    computed: {
        total_text() {
            return this.$store.getters.t(this.level) + " Totals"
        },
        column_totals() {
            let cts = {}
            this.phases.forEach(p => {
                cts[p] = this.$store.getters["v2/quantities/getColumnTotal"](this.level, p);
            })
            return cts
        },
        column_totals_m2() {
            let cts = {}
            this.phases.forEach(p => {
                cts[p] = this.$store.getters["v2/quantities/getColumnTotalM2"](this.level, p);
            })
            return cts
        },
        target_row_total() {
            return this.$store.getters["v2/quantities/getProjectFullCountTotalByLevel"](this.level)
        },
        row_total() {
            return Object.values(this.column_totals).reduce((a, b) => a + b, 0)
        },
        is_total_ok() {
            return false
        },
        totals_text() {
            return `${this.row_total}/${this.target_row_total}`
        },
    },
    methods: {
    }
}
</script>