<template>
    <v-sheet outlined color="grey" class="pl-1">
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            {{ $store.getters.t("version") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("filename") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("status") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("created") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="cf in sorted_versions" :key="cf.uuid">
                        <td class="text-left">rev {{ cf.meta.version }}</td>
                        <td class="text-left">{{ cf.name }}</td>
                        <td>
                            <v-chip outlined small :color="cf.status_color">
                                {{ cf.meta.status }}
                            </v-chip>
                        </td>
                        <td>
                            <Timestamp :value="cf.created" />
                        </td>
                        <td>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-btn icon target="_blank" v-if="cf.presigned_url && cf.hash" :href="cf.presigned_url">
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                                <v-btn :disabled="deleting.includes(cf.uuid)" :loading="deleting.includes(cf.uuid)" icon
                                    @click="delete_version(cf)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <ButtonJSONRender title="File meta" :json="cf.meta" />
                            </v-row>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-sheet>
</template>


<script>
import _ from "lodash";
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender.vue"
import Timestamp from "/src/components/renderers/Timestamp";
export default {
    props: ["versions"],
    data: () => ({
        deleting: [],
    }),
    components: {
        ButtonJSONRender,
        Timestamp,
    },
    computed: {
        sorted_versions() {
            return _.orderBy(this.versions, "meta.version")
                .map(cf => ({
                    ...cf,
                    status_color: this.getStatusColor(cf.meta.status)
                }))
        }
    },
    methods: {
        getStatusColor(status) {
            if (status == "ready") return "success";
            else if (status == "failed") return "error";
            else if (status == "working") return "warning";
            else if (status == "deleting") return "grey";
            else return "info";
        },
        delete_version(cf) {
            this.deleting.push(cf.uuid)
            this.$store.dispatch("v2/cloudstorage/deleteCloudFile", cf.uuid).then(() => {
                this.deleting = this.deleting.filter(uuid => uuid != cf.uuid)
                this.$emit("refresh")
            })
        },
    },
};
</script>