<template>
    <tr v-on:mouseenter="onHover(_uid)" v-on:mouseleave="onHover()" height="50px"
        :class="{ 'active-row-l': hover && !dark, 'active-row-d': hover && dark, }">
        <td lass="title"> {{ total_text }} </td>
        <td v-for="batch in batches" :key="batch.uuid">
            <v-list-item-title class="title">
                {{ column_totals[batch.uuid] }}
                <AreaIndicator v-if="level == 'assembly'" :value="column_totals_m2[batch.uuid]" />
            </v-list-item-title>
        </td>
        <td align="right">
            <v-list-item-title class="title" :class="{ 'warning--text': !is_total_ok, 'info--text': is_total_ok }"
                v-text="totals_text">
            </v-list-item-title>
            <v-list-item-subtitle> {{ $store.getters.t('phase_total') }} </v-list-item-subtitle>
        </td>
    </tr>
</template>

<style lang="scss">
@import "./tables.scss";
</style>


<script>
import { rowMixin } from "./mixins"
export default {
    mixins: [rowMixin],
    props: ["batches", "level", "phase"],
    computed: {
        total_text() {
            return this.$store.getters.t(this.level) + " Totals"
        },
        column_totals() {
            let cts = {}
            this.batches.forEach(b => {
                cts[b.uuid] = this.$store.getters["v2/quantities/getColumnTotal"](this.level, b.phase, b);
            })
            return cts
        },
        column_totals_m2() {
            let cts = {}
            this.batches.forEach(b => {
                cts[b.uuid] = this.$store.getters["v2/quantities/getColumnTotalM2"](this.level, b.phase, b);
            })
            return cts
        },
        target_row_total() {
            return this.$store.getters["v2/quantities/getColumnTotal"](this.level, this.phase)
        },
        row_total() {
            return Object.values(this.column_totals).reduce((a, b) => a + b, 0)
        },
        is_total_ok() {
            return false
        },
        totals_text() {
            return `${this.row_total}/${this.target_row_total}`
        },
    },
    methods: {
    }
}
</script>