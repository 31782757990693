<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="dialog = true" :loading="loading" :disabled="loading">
                    <v-icon> mdi-table-column-plus-after </v-icon>
                </v-btn>
            </template>
            <span>
                {{ $store.getters.t("add_phase") }}
            </span>
        </v-tooltip>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("new_phase_for_project") }}:
                    {{ project ? project.name : "---" }}</v-card-title>
                <v-card-text>
                    <PitTextField @enter="addPhase()" label="New phase" v-model="new_phase_name"></PitTextField>
                </v-card-text>
                <v-card-text v-if="phase_exists">
                    <v-alert type="error">{{
                            $store.getters.t("existing_phase")
                    }}</v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="phase_exists" @click="addPhase()" color="primary">{{ $store.getters.t("add") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
 import PitTextField from '../../fields/PitTextField.vue';

export default {
    name: "ButtonPhaseAdd",
    components: {PitTextField},
    props: ["project"],
    data: () => ({
        new_phase_name: "Phase_",
        dialog: false,
        loading: false,
    }),
    computed: {
        phase_exists() {
            return this.project?.phases.includes(this.new_phase_name);
        },
    },
    methods: {
        addPhase() {
            this.loading = true;
            let phases = [...this.project.phases, this.new_phase_name];
            this.$store
                .dispatch("v2/selected/putProject", {
                uuid: this.project.uuid,
                phases,
            })
                .finally(() => (this.loading = false));
            this.dialog = false;
        },
    },
};
</script>