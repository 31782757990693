<template>
    <v-btn
        icon
        @click="delete_file"
        :disabled="loading || (file.status && file.status.includes('busy'))"
        :loading="loading"
    >
        <v-icon> mdi-delete </v-icon>
    </v-btn>
</template>


<script>
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "../../utils/apiv2";
export default {
    props: [
        "file", // expects file object
        "delete_from_package", // expects package uuid to be deleted from (preserved for others)
    ],
    data: () => ({ loading: false }),
    methods: {
        delete_file() {
            this.loading = true;
            backend_api_v2
                .delete(
                    `cloudstorage/files/${this.file.uuid}/?delete_from_package=${this.delete_from_package}`
                )
                .then(() =>
                    bus.$emit("events/cloudstorage/reloadProjectPackages")
                )
                .catch(() => {
                    this.loading = false;
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Can't delete!",
                        color: "error",
                    });
                });
        },
    },
};
</script>