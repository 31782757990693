<template>
    <div>
        <v-progress-linear v-if="job.perc != 100" color="accent" :value="job.perc" height="13"> {{ job.status }}
        </v-progress-linear>
        <v-divider v-else-if="['completed'].includes(job.status)" color="success" class="mb-3">
        </v-divider>
        <v-divider v-else-if="['failed'].includes(job.status)" color="error" class="mb-3">
        </v-divider>
    </div>
</template>

<script>
export default {
    props: ["job"]
}
</script>