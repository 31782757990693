<template>
    <v-btn
        small
        class="mx-3"
        color="accent"
        @click="run"
        :loading="running"
        :disabled="running"
    >
        {{ $store.getters.t("run_scheduler") }}
    </v-btn>
</template>

<script>
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    data: () => ({ running: false }),
    computed: {
        company() {
            return this.$store.getters["session/my_company"];
        },
    },
    methods: {
        run() {
            this.running = true;
            backend_api_v2
                .post("/overview/scheduler/")
                .then(({ data }) => {
                    let text, color;
                    if (data.detail == "job_running") {
                        text = "Scheduler already running. Wait.";
                        color = "info";
                    } else {
                        bus.$emit("scheduler_launched");
                        text = "Scheduler started. Wait.";
                        color = "success";
                    }
                    this.running = false;
                    bus.$emit("notification", {
                        timeout: 2000,
                        text,
                        color,
                    });
                })
                .catch(() => {
                    this.running = false;
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Can't launch scheduler.",
                        color: "error",
                    });
                });
        },
    },
};
</script>
