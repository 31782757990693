import { backend_api_v2, paginated_factory_v2 } from '/src/utils/apiv2'
import bus from '/src/utils/event_bus'

function get_or_create_chat(state, nmsg) {
    let chat = state.chats.find(chat => chat.contact == nmsg.contact.id)
    if (!chat) {
        chat = { contact: nmsg.contact.id, contact_name: nmsg.contact.name, messages: [] }
        state.chats.push(chat)
    }
    return chat
}

export default {
    namespaced: true,
    state: {
        // NEW
        unread_count: 0,
        chats: [
            // {contact: 1, contact_name: "fede", messages: []}
        ],
    },
    mutations: {
        parseMessages: (state, data) => {
            let new_counter = 0
            data.forEach(nmsg => {
                let chat = get_or_create_chat(state, nmsg)
                let i = chat.messages.findIndex(msg => msg.uuid == nmsg.uuid)
                if (i == -1) {
                    chat.messages.push(nmsg)
                    if (!nmsg.readed && !nmsg.contact.is_receiver) new_counter += 1
                }
                else chat.messages[i] = nmsg
                chat.messages = chat.messages.slice().sort((a, b) => new Date(a.created) - new Date(b.created))
                state.chat = { ...chat }
            })
            if (new_counter)
                bus.$emit('notification', { timeout: 3000, text: `${new_counter} new message(s)`, color: "info" })
        },
        cleanMessages: (state) => {
            state.chats.forEach((chat) => {
                const lastMessage = chat.messages[chat.messages.length - 1];

                if (lastMessage && lastMessage.text === "---") {
                    chat.messages = [];
                    state.chat = { ...chat };
                }
            });
        },
        updateMessage: (state, nmsg) => {
            let chat = get_or_create_chat(state, nmsg)
            let i = chat.messages.findIndex(msg => msg.uuid == nmsg.uuid)
            if (i == -1) chat.messages.push(nmsg)
            else chat.messages[i] = nmsg
            chat.messages = chat.messages.filter(msg => !!msg.uuid).sort((a, b) => new Date(a.created) - new Date(b.created)) // remove if not uuid
            state.chat = { ...chat }
        },
        removeMessage: (state, nmsg) => {
            let chat = get_or_create_chat(state, nmsg)
            chat.messages = chat.messages
                .filter(msg => nmsg.uuid != msg.uuid)
                .sort((a, b) => new Date(a.created) - new Date(b.created))
            // remove if not uuid
            state.chat = { ...chat }
        },
        setUnreadCount: (state, count) => {
            state.unread_count = count;
        },
    },
    actions: {
        loadLastMessages(context) {
            return backend_api_v2.get('/mail/messages/last_of_each/')
                .then(({ data }) => {
                    // console.log("loadLastMessages:", data[1].text);
                    context.commit('cleanMessages', data)
                    // console.log("loadLastMessages:", data[1].text);
                    context.commit('parseMessages', data)
                })
                .catch(e => console.log(`Cant load last messages: ${e}`))
        },
        loadChat(context, user_id) {
            return paginated_factory_v2('/mail/messages/?other=' + user_id)
                .then(({data}) => {
                    context.commit('parseMessages', data)
                })
                .catch(e => console.log(`Cant load chat: ${e}`))
        },
        loadUnreadCount(context) {
            return backend_api_v2.get('/mail/messages/unread_count/')
                .then(({ data }) => {
                    context.commit('setUnreadCount', data.count);
                })
                .catch(e => console.log(`Cant load unread count: ${e}`))
        },
        sendMessage(context, msg) {
            context.commit("parseMessages", [msg])
            return backend_api_v2.post('/mail/messages/', msg)
                .then(({ data }) => {
                    context.commit('updateMessage', data)
                })
                .catch(e => console.log(`Cant load last messages: ${e}`))
        },
        readMessage(context, msg) {
            return backend_api_v2.put('/mail/messages/' + msg.uuid + "/", { ...msg, readed: true, delivered: true })
                .then(({ data }) => {
                    context.commit('updateMessage', data)
                })
                .catch(e => console.log(`Cant read message: ${e}`))
        },
        deleteMessage(context, msg) {
            return backend_api_v2.delete('/mail/messages/' + msg.uuid + "/")
                .then(() => context.commit('removeMessage', msg))
                .catch(e => console.log(`Cant delete message: ${e}`))
        }
    },
    getters: {
        getChatByContactId: state => contact_id => state.chats.find(chat => chat.contact == contact_id),
    }
}
