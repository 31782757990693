<template>
    <v-select outlined dense class="mx-1" multiple v-model="selected_branches" :items="branches" :hint="hint"
        persistent-hint :label="label || $store.getters.t('branches')" clearable>
    </v-select>
</template>

<script>
import { nameSort } from "/src/utils/basics.js";


// This component lists branches of the IFCPS in the current selected_project
// (in the "selected" store module)
// If not selected_project, it lists ALL the branches found in all IFCPS/BTLPS 
// definitions of the company.


export default {
    props: ["value", "label", "hint"],
    data: () => ({
        selected_branches: "",
    }),
    computed: {
        // This is company wide
        ifc_parsing_setups() {
            return this.$store.state.v2.company.ifc_ps;
        },

        // This is for project specific
        selected_project() {
            return this.$store.state.v2.selected.selected_project;
        },
        selected_ifcps() {
            return this.$store.getters["v2/selected/projectIFCPS"]
        },

        // Takes consideration of selected or full
        branches() {
            let branches = ["unknown"];

            let custom_branches = this.selected_ifcps?.custom_branches

            if (!custom_branches) {
                custom_branches = this.ifc_parsing_setups.map(ifcps => ifcps.custom_branches).flat()
            }

            custom_branches?.forEach((br) =>
                branches.push(br.name.toLowerCase())
            );
            return branches
                .filter((br) => br) // clean empties
                .sort(nameSort)
        },
    },
    watch: {
        selected_branches(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_branches = v;
            },
            immediate: true,
        },
    },
};
</script>