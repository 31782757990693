export function zones_from_viewer_bom(bom, design_name = false) {

    // Filter subassembly if needed
    if (design_name) bom = bom.filter(p => p.subassembly_name == design_name)

    // Now go with zones
    let zones = bom
        // Map parts to zones
        .map(part => part.zone)
        // Remove undefineds &  99 (megawrapper undefined)
        .filter(z => z != undefined && z != 99)
    zones = zones
        // Remove duplicates
        .filter((v, i) => zones.indexOf(v) === i)
        // Order
        .sort((a, b) => a - b)
    return zones
}
