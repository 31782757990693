<template>
    <v-select outlined dense class="mx-1" v-model="selected_size" :items="sizes" :rules="rules"
        :clearable="!required" :label="$store.getters.t('plate_size')">
    </v-select>
</template>

<script>
export default {
    props: {
        value: { default: null },
        required: { type: Boolean, default: false },
    },
    data: () => ({
        selected_size: "",
    }),
    computed: {
        sizes() {
            return this.$store.state.v2.logistics.plate_sizes.map((ps) => ({
                text: `${ps.thickness} mm ${this.get_material_name(ps.material)}`,
                value: ps.uuid,
            }));
        },
        rules() {
            if (!this.required) return [];
            return [(v) => !!v || "A size is required"];
        },
    },
    watch: {
        selected_size(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_size = v;
            },
            immediate: true,
        },
    },
    methods: {
        get_material_name(uuid) {
            return this.$store.state.v2.logistics.materials.find(
                (mat) => mat.uuid === uuid
            )?.name || "-";
        },
    },
};
</script>