<template>
    <div class="d-flex">
        <v-checkbox
            class="my-0"
            :label="$store.getters.t('has_own_holidays')"
            v-model="checkbox"
            @change="launch_holydays_dialog($event)"
        />
        <a v-if="holydays" class="ma-2" @click="dialog = true">
            {{ $store.getters.t("open") }}
        </a>
        <span v-if="holydays" class="ma-2"> {{ holydays.length }} dates </span>
        <v-dialog v-model="dialog" width="800">
            <v-card>
                <v-card-title>
                    {{ $store.getters.t("has_own_holidays") }}
                </v-card-title>
                <v-card-text>
                    <div class="d-flex ma-3">
                        <v-date-picker
                            landscape
                            label="Holydays"
                            v-model="holydays"
                            multiple
                            color="accent"
                        ></v-date-picker>
                        <div class="ml-2">
                            <h2 class="ml-2 font-weight-light title">
                                {{
                                    $store.getters.t("non_working_days")
                                }}
                            </h2>
                            <v-chip-group column>
                                <v-chip
                                    v-for="(hd, i) in holydays"
                                    :key="hd"
                                    close
                                    small
                                    outlined
                                    @click:close="delete_holyday(i)"
                                >
                                    {{ hd }}
                                </v-chip>
                            </v-chip-group>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer> </v-spacer>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ dialog: false, holydays: false, checkbox: false }),
    watch: {
        value: {
            handler: function (v) {
                this.checkbox = Array.isArray(v);
                this.holydays = v;
            },
            immediate: true,
        },
        holydays: {
            handler: function (h) {
                this.$emit("input", h);
            },
            deep: true,
        },
    },
    methods: {
        launch_holydays_dialog(ch) {
            if (ch) {
                this.holydays = [];
                this.dialog = true;
            } else {
                this.holydays = false;
                // this.$emit("input", false);
            }
        },
    },
};
</script>


