<template>
    <div class="fill-height viewer-layout">
        <div class="d-flex flex-row">
            <div v-if="block_left_panel" :class="drawing ? 'left-panel-drawing' : 'left-panel'">
                <v-sheet class="mt-10 pa-10 text-center mx-auto" elevation="12" max-width="600" rounded="lg">
                    <v-icon class="mb-5" color="success" icon="mdi-check-circle" size="112"></v-icon>

                    <h2 class="text-h5 mb-6">Constructible not started</h2>

                    <p class="mb-10 text-medium-emphasis text-body-2">
                        Start it in tasks button to enable 3D and DWGs viewer. <v-icon>mdi-arrow-right</v-icon>
                    </p>

                </v-sheet>
            </div>
            <div v-else-if="full_panel || drawing" :class="drawing ? 'left-panel-drawing' : 'left-panel'">
                <LeftPanel />
            </div>
            <div v-if="full_panel || drawing" class="right-bar">
                <CentralToolbar :left_mode="drawing" :modal_details="drawing" />
            </div>
            <div v-if="full_panel || detail" :class="detail ? 'right-panel-detail' : 'right-panel'">
                <RightPanel />
            </div>
        </div>
        <ViewModeSelector v-model="view_mode" />
    </div>
</template>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<script>
import LeftPanel from "/src/components/viewer/left/LeftPanel.vue";
import RightPanel from "/src/components/viewer/right/RightPanel.vue";
import CentralToolbar from "/src/components/viewer/toolbar/CentralToolbar.vue";
import ViewModeSelector from "/src/components/viewer/ViewModeSelector.vue";
export default {
    components: {
        LeftPanel,
        RightPanel,
        CentralToolbar,
        ViewModeSelector,
    },
    data: () => ({
        view_mode: "drawing", // 'full_panel', 'drawing', 'detail'
    }),
    computed: {
        block_left_panel() {
            return this.$store.getters["v2/session/iam_prod_worker"] && this.$store.getters["v2/session/force_checklist_start"] && this.$store.getters["v2/records/rcpIsRunning"]
        },
        full_panel() {
            return this.view_mode == "full_panel";
        },
        drawing() {
            return this.view_mode == "drawing";
        },
        detail() {
            return this.view_mode == "detail";
        },
    },
};
</script>