<template>
    <div class="d-flex flex-column detail-ifc-main-box">
        <div class="d-flex flex-column flex-grow-1 scrollmenu" v-if="pdf_mode">
            <div class="d-flex flex-row">
                <v-btn icon @click="recenterPDF" class="fronted">
                    <v-icon>mdi-image-filter-center-focus</v-icon>
                </v-btn>
                <span class="body-1 mb-0 ml-2 font-weight-light">
                    {{ detail_name }}
                </span>
                <!-- <v-spacer></v-spacer> -->
            </div>
            <div class="pa-5" v-if="text">{{ text }}</div>
            <PDFView v-else-if="url && !picture_mode" :url="url" name="right_panel" />
            <PictureView v-else-if="url && picture_mode" :url="url"></PictureView>
        </div>
        <div class="d-flex flex-column flex-grow-1 scrollmenu" v-else>
            <w-ifc-view-context-menu></w-ifc-view-context-menu>
        </div>
        <div v-if="pdf_mode">
            <w-right-panel-buttons></w-right-panel-buttons>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.scrollmenu {
    overflow: scroll;
    // white-space: nowrap;
}

.detail-ifc-main-box {
    // background-color: lightcoral;
    height: 100vh;
}

.fronted {
    z-index: 10;
}
</style>

<script>
import { mapGetters } from 'vuex'
import bus from "/src/utils/event_bus";
import RightPanelButtons from "./RightPanelButtons.vue";
import IFCViewContextMenu from "./IFCViewContextMenu.vue";
import { cloudfile_is_picture } from "/src/utils/resources";
import PDFView from "../PDFView.vue";

export default {
    name: "RightPanel",
    components: {
        PDFView,
        "w-right-panel-buttons": RightPanelButtons,
        "w-ifc-view-context-menu": IFCViewContextMenu,
    },
    computed: {
        ...mapGetters({
            pcks: "v2/viewer/allPackages",
        }),
        cf_uuid() {
            return this.$store.state.v2.viewer.right_panel_cf_uuid
        },
        cf() {
            let cfs = this.pcks.map(pck => pck.files).flat(1)
            return cfs.find(cf => cf.uuid == this.cf_uuid)
        },
        url() {
            return this.cf?.presigned_url
        },
        text() {
            return this.cf?.meta?.text
        },
        detail_name() {
            return this.cf?.short_name
        },
        picture_mode() {
            return cloudfile_is_picture(this.cf)
        },
        pdf_mode() {
            return this.$store.state.v2.viewer.pdf_mode;
        },
    },
    methods: {
        recenterPDF() {
            bus.$emit("events/viewer/recenter_pdf_right_panel");
        },
    },
};
</script>
