<template>
    <v-navigation-drawer :value="!!assd_uuid || !!modd_uuid" absolute stateless disable-route-watcher right width="900">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title> Design Detail </v-list-item-title>
                </v-list-item-content>
                <v-btn icon :to="{ name: 'designs', query: $route.query }">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
        <v-divider></v-divider>

        <!-- Detail -->
        <DrawerAssemblyDesign v-if="!!assd_uuid" />
        <DrawerModuleDesign v-else-if="!!modd_uuid" />

    </v-navigation-drawer>
</template>

<script>
import DrawerAssemblyDesign from "./DrawerAssemblyDesign.vue";
import DrawerModuleDesign from "./DrawerModuleDesign.vue";

export default {
    components: {
        DrawerAssemblyDesign,
        DrawerModuleDesign
    },
    data: () => ({}),
    computed: {
        assd_uuid() {
            return this.$route.params.assd_uuid;
        },
        modd_uuid() {
            return this.$route.params.modd_uuid;
        },
        project_uuid() {
            return this.$route.params.project_uuid;
        },
    },
    methods: {
        deleted() {
            this.$router.go(-1);
        },
    },
};
</script>