<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <CardUploads :project="project" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CardUploads from "/src/components/files/CardUploads.vue";
export default {
    components: {
        CardUploads,
    },
    computed: {
        project() {
            return this.$store.state.v2.selected.selected_project;
        },
    },
};
</script>