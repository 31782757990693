import { backend_api_v2 } from '/src/utils/apiv2'
import store from '/src/store'

function send_backend_event(ev) {
    let pr = store.state.v2.selected.selected_project
    if (pr && pr.uuid && pr.uuid != "all") ev.project = pr.uuid

    let mod = store.state.v2.selected.selected_mod
    if (mod && mod.uuid) ev.module = mod.uuid

    let ass = store.state.v2.selected.selected_ass
    if (ass && ass.uuid) ev.assembly = ass.uuid

    return backend_api_v2.post('/records/events/', ev)
        .then(({ data }) => {
            console.log("[i] Posted V2 ProductionEvent:", data.event)
        })
        .catch(() => console.log("[!] Can't post event:", ev.event))
}

export default send_backend_event
