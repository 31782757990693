<template>
    <v-card outlined class="px-3 pb-3 mb-3">
        <div class="d-flex my-3">
            <h4 class="mb-2 font-weight-light grey--text">
                {{ $store.getters.t("full_sizes") }}
            </h4>
            <v-spacer> </v-spacer>
            <v-btn small text @click="add_item()">
                <v-icon left> mdi-plus </v-icon>
                ADD
            </v-btn>
        </div>
        <div class="text-center pa-3 grey" :class="dark ? 'darken-4' : 'lighten-4'" v-if="full_sizes.length == 0">
            <span> Add a row </span>
        </div>
        <div class="d-flex" v-for="(item, i) in full_sizes" :key="i">
            <v-select outlined dense hide-hints v-model="full_sizes[i].material" :items="materials" class="mx-2"
                label="Material" :loading="loading ? 'accent' : false"></v-select>
            <v-select outlined dense hide-hints multiple v-model="full_sizes[i].sizes"
                :items="sizes_by_material[full_sizes[i].material]" class="mx-2" label="Sizes"
                :loading="loading ? 'accent' : false"></v-select>
            <v-btn small class="ml-3 mt-1" icon @click="delete_item(i)">
                <v-icon> mdi-minus </v-icon>
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    props: ["value", "ass_uuids", "part_tipo"],
    data: () => ({
        full_sizes: [],
        loading: false,
        material_sizes: [],
    }),
    watch: {
        value: {
            handler: function (v) {
                if (!v) v = [];
                this.full_sizes = v;
            },
            immediate: true,
        },
        full_sizes: {
            handler: function (fs) {
                this.$emit("input", fs);
            },
            deep: true,
        },
        ass_uuids: {
            handler: function () {
                this.fetch_material_sizes();
            },
            immediate: true,
        },
    },
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        materials() {
            return this.material_sizes.map((ms) => ms.size);
        },
        sizes_by_material() {
            // This depends on the selected material
            let sbm = {};
            this.material_sizes.forEach((ms) => (sbm[ms.size] = ms.actual_dims));
            return sbm;
        },
    },
    methods: {
        add_item() {
            this.full_sizes.push({
                material: null,
                sizes: [],
            });
        },
        delete_item(i) {
            this.full_sizes.splice(i, 1);
        },
        fetch_material_sizes() {
            console.log("[i] Fetching materials for full sizes.");
            this.loading = true;
            let url = `/logistics/sizes/${this.part_tipo}s/names_and_actual_dims/`
            backend_api_v2
                .post(url, {
                    assemblies: this.ass_uuids,
                })
                .then((r) => {
                    if (r.status == 200) {
                        console.log("[i] Updating materials form");
                        this.material_sizes = r.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        this.fetch_material_sizes();
    },
};
</script>