import axios from 'axios'
import defaults from '/src/utils/defaults'
import store from '/src/store'
import bus from "/src/utils/event_bus";


const backend_api_v2 = axios.create({
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: defaults.VUE_APP_ROOT_API,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});
backend_api_v2.interceptors.request.use(config => {
    if (store.state.v2.session.authenticated) {
        config['headers']['Authorization'] = 'Bearer ' + store.state.v2.session.access_token
    } else {
        config['headers']['Authorization'] = 'NO_AUTH'
    }
    return config
})
backend_api_v2.interceptors.response.use(
    response => {
        if (store.state.connection_lost) {
            store.commit('setConnectionLost', false);
            bus.$emit('notification', { timeout: 5000, text: "Connection recovered", color: "success" })
            bus.$emit("back_event", { event: "connection_recovered", meta: {} })
        }
        return response;
    },
    error => {
        if (!error.response) {
            if (!store.state.connection_lost) {
                store.commit('setConnectionLost', true);
                bus.$emit('notification', { timeout: 5000, text: "Error - Coneection lost", color: "error" })
                bus.$emit("back_event", { event: "connection_lost", meta: {} })
            }
            return Promise.reject(error);
        }
        else if (error?.response?.status == 403 && error.response?.data?.code == "token_not_valid") store.dispatch("v2/session/refresh")
        else if (error?.response?.status == 403 && error?.response?.data?.detail == "You do not have permission to perform this action.") {
            console.log("[!] Permission error. Check the Network tab in the browser.")
            return Promise.reject(error)
        }
        else if (error?.response?.status == 403) bus.$emit("events/session/logout")
        else if (error?.response?.status == 401 && error.response?.data?.code == "token_not_valid") bus.$emit("events/session/logout")
        return Promise.reject(error)
    }
)


// returns a complete promise
function paginated_factory_v2(url) {
    function paginatedFetcher(next, obj_list = []) {
        return backend_api_v2.get(next ? next : url)
            .then(({ data }) => {
                obj_list.push(...data.results)
                if (!data.next) return obj_list
                return paginatedFetcher(data.next, obj_list)
            })
    }
    return paginatedFetcher().then(data => ({ data }))
}


export {
    backend_api_v2,
    paginated_factory_v2
};


// LOGOUT FROM SESSION !!!!!!
axios.get(defaults.VUE_APP_ROOT_API + 'auth/logout/')
    .then(console.log("Cleaned session"))