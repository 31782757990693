<template>
    <v-sheet class="pa-2 my-2" outlined rounded>
        <h4>{{ $store.getters.t("ready_panel_proof") }}</h4>

        <div v-for="sta in station_entries" :key="sta.name" class="my-1">
            <h4>{{ sta.name }} ({{ sta.status }})</h4>

            <table>
                <tr v-for="chi in sta.chis" :key="chi.text">
                    <td>
                        <IconBoolean :value="chi.checked" /> {{ chi.text }}:
                    </td>
                    <td>
                        <Timestamp :value="chi.timestamp" />
                    </td>
                    <td>
                        <v-btn small icon v-for="(pic, i) in chi.pictures" :href="pic.presigned_url" target="_blank"
                            :key="i">
                            <v-icon small> mdi-image-move </v-icon>
                            <br />
                        </v-btn>
                    </td>
                </tr>
            </table>
        </div>
    </v-sheet>
</template>
<style scoped>
th,
td {
    padding: 2px;
}
</style>
<script>
import { by_name } from "/src/utils/basics";
import { backend_api_v2 } from "/src/utils/apiv2";
import Timestamp from "/src/components/renderers/Timestamp.vue";
import IconBoolean from "/src/components/renderers/IconBoolean.vue";
export default {
    components: { Timestamp, IconBoolean },
    props: ["panel", "production_site"],
    data: () => ({ rpp: null }),
    computed: {
        stations_status() {
            return this.panel.stations_status;
        },
        station_entries() {
            let stations = [...this.production_site.stations];
            stations.sort(by_name);
            return stations.map((s) => {
                const chis = this.fill_chis(s);
                const status = this.stations_status[s.name];
                return { ...s, status, chis };
            });
        },
        panel_uuid() {
            return this.panel?.uuid;
        },
    },
    watch: {
        panel_uuid: {
            handler: function (puuid) {
                if (puuid) this.load_rpp(puuid);
            },
            immediate: true,
        },
    },
    methods: {
        load_rpp(panel_uuid) {
            let url = `/ready_panel_proofs/?wall_unit=${panel_uuid}`;
            return backend_api_v2
                .get(url)
                .then(({ data }) => {
                    // If no proof
                    if (data.results.length == 0) {
                        this.rpp = null;
                    } else {
                        this.rpp = data.results[0];
                    }
                })
                .catch((e) => console.log(`Cant load rpp: ${e}`));
        },
        fill_chis(station) {
            const base_chis = station.checklist_items;
            const filled_chis =
                this.rpp?.checklist.filter(
                    (chi) =>
                        chi.production_station == station.name &&
                        ["STATION_JOB_READY", "STATION_STARTED"].includes(chi.text)
                ) || [];
            base_chis.forEach((bchi) => {
                const found = filled_chis.find((chi) => chi.text == bchi.text);
                if (!found) filled_chis.push(bchi);
            });
            return filled_chis;
        },
    },
};
</script>