<template>
    <v-card :loading="loading" outlined class="ma-1" height="55">
        <v-btn small block color="secondary" text :to="{
        name: `constructibles_manager_${level}_design_detail`,
        query: {
            ...$route.query,
        },
        params: {
            ...level == 'assembly' && { assd_uuid: duuid },
            ...level == 'module' && { modd_uuid: duuid },
        }
    }">
            <div>
                <span>{{ name }}</span>
                <IconRevision v-if="!loading" :rev="design.revision" />
            </div>
            <br>
        </v-btn>
        <div v-if="!loading" class="d-flex justify-space-around">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ design.meta.dwg_count }}
                        <v-icon small> mdi-floor-plan </v-icon>
                    </span>
                </template>
                <span>{{ design.meta.dwg_count }} drawings.</span>
            </v-tooltip>
            <IconHasIFC :value="design.meta.has_ifc" />
            <IconAnalysisStatus :value="design.meta.analysis" />
        </div>
    </v-card>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
import IconHasIFC from "/src/components/renderers/IconHasIFC";
import IconAnalysisStatus from "/src/components/renderers/IconAnalysisStatus";
import IconRevision from "/src/components/renderers/IconRevision";

export default {
    props: ["duuid", "level", "uuid", "name"],
    components: {
        IconHasIFC,
        IconAnalysisStatus,
        IconRevision,
    },
    data: () => ({
        design: null,
        loading: true,
    }),
    methods: {
        loadDesign(url) {
            this.loading = true;
            return backend_api_v2
                .get(url)
                .then(({ data }) => {
                    if (data) {
                        this.design = data;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        const url = this.level == "assembly"
            ? `/constructibles/assembly_designs/${this.duuid}`
            : `/constructibles/module_designs/${this.duuid}`;

        this.loadDesign(url);
    },
}
</script>
