<template>
    <div class="d-flex">
        <v-checkbox v-if="schema.data_type == 'bool'" v-model="ivalue" :rules="get_rules(schema.data_type, schema.required)"
            :label="$store.getters.t(schema.name)">
            <template v-slot:prepend>
                <a v-if="schema.docs_url" target="_blank" :href="schema.docs_url">
                    <v-icon> mdi-help-circle-outline </v-icon>
                </a>
            </template>
        </v-checkbox>
        <SelectBranches v-else-if="schema.data_type == 'list_branches'" v-model="ivalue"
            :label="$store.getters.t(schema.name)" />
        <CustomTableField v-else-if="schema.data_type == 'table'" v-model="ivalue" :label="$store.getters.t(schema.name)"
            :columns="schema.columns" />
        <v-select v-else-if="schema.data_type == 'multi_select'" outlined dense multiple v-model="ivalue"
            :rules="get_rules(schema.data_type, schema.required)" :items="schema.options"
            :label="$store.getters.t(schema.name)">
            <template v-slot:prepend>
                <a v-if="schema.docs_url" target="_blank" :href="schema.docs_url">
                    <v-icon> mdi-help-circle-outline </v-icon>
                </a>
            </template>
        </v-select>
        <v-select :clearable="!schema.required" v-else-if="schema.options" outlined dense v-model="ivalue"
            :items="schema.options" :rules="get_rules(schema.data_type, schema.required)"
            :label="$store.getters.t(schema.name)">
            <template v-slot:prepend>
                <a v-if="schema.docs_url" target="_blank" :href="schema.docs_url">
                    <v-icon> mdi-help-circle-outline </v-icon>
                </a>
            </template>
        </v-select>
        <v-textarea v-else-if="schema.data_type == 'text_area'" outlined dense v-model="ivalue"
            :label="$store.getters.t(schema.name)" :rules="get_rules(schema.data_type, schema.required)">
            <template v-slot:prepend>
                <a v-if="schema.docs_url" target="_blank" :href="schema.docs_url">
                    <v-icon> mdi-help-circle-outline </v-icon>
                </a>
            </template></v-textarea>
        <v-text-field v-else :label="$store.getters.t(schema.name)" v-model="ivalue" :suffix="schema.suffix"
            :rules="get_rules(schema.data_type, schema.required)" :type="get_html_type(schema.data_type)" outlined dense>
            <template v-slot:prepend>
                <a v-if="schema.docs_url" target="_blank" :href="schema.docs_url">
                    <v-icon> mdi-help-circle-outline </v-icon>
                </a>
            </template>
        </v-text-field>
    </div>
</template>


<script>

import CustomTableField from "/src/components/fields/CustomTableField"
import SelectBranches from "/src/components/ifcps/SelectBranches"
import { get_rules_from_type } from "./utils"

export default {
    components: {
        CustomTableField,
        SelectBranches,
    },
    props: ["schema", "value"],
    data: () => ({
        ivalue: null
    }),
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler: function (v) {
                this.ivalue = v
            }
        },
        ivalue(iv) {
            this.$emit("input", iv)
        }
    },
    methods: {
        get_rules(data_type, required) {
            let type_rules = get_rules_from_type(data_type)
            if (required) {
                type_rules.push(v => !!v)
            }
            return type_rules
        },
        get_html_type(data_type) {
            if (["int", "number", "float"].includes(data_type))
                return "number"
            else return "text"
        },
    }

}
</script>