<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon :color="icon_color" small v-bind="attrs" v-on="on">
                {{ icon_name }}
            </v-icon>
        </template>
        <span>
            {{ tooltip_text }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        icon_name() {
            if (this.value == "working") return "mdi-progress-helper mdi-spin";
            if (this.value == "done") return "mdi-check-circle-outline";
            if (this.value == "error") return "mdi-close-circle-outline";
            if (this.value == "error_no_wrapper") return "mdi-file-hidden";
            if (this.value == "done_subassd") return "mdi-check-circle-outline";
            if (this.value == "waiting") return "mdi-clock";
            return "mdi-help";
        },
        icon_color() {
            if (this.value == "working") return "grey";
            if (this.value == "done") return "green";
            if (this.value == "error") return "red";
            if (this.value == "error_no_wrapper") return "orange";
            // if (["error", "error_no_wrapper"].includes(this.value)) return "red";
            if (this.value == "done_subassd") return "blue";
            if (this.value == "waiting") return "grey";
            return "grey";
        },
        tooltip_text() {
            if (this.value == "working") return "System is working on the analysis.";
            if (this.value == "done") return "Analysis done.";
            if (this.value == "error") return "Unknown error.";
            if (this.value == "error_no_wrapper") return "No BIM file for analysis (.ifc, .btl, ...)";
            if (this.value == "done_subassd") return "Analysis done with parent IFC.";
            if (this.value == "waiting") return "mdi-clock";
            return "unknown status";
        }
    },
};
</script>