<template>
    <div :class="{ 'pa-2 flex-grow-1': true, inverted: dark }" id="pdf-container" ref="pdfContainer">
        <div v-if="div_id" :id="div_id" :ref="div_id">
            <InlineSvg @loaded="launch_hammer" v-if="file_extension == 'svg'" :src="url" width="100%" height="800px" />
            <v-img @load="launch_hammer" v-else class="mt-3 mx-1" :src="url"></v-img>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.inverted {
    filter: invert(64%) contrast(228%) brightness(80%) hue-rotate(180deg);
}

#pdf-container {
    // overflow-y: auto;
    position: relative;
    // max-height: calc(100vh - 65px);
    max-height: 100%;
}
</style>

<script>
import InlineSvg from "vue-inline-svg";
import bus from "/src/utils/event_bus";
import Hammer from "hammerjs";
import { getRelativePosition, scaleFrom } from "/src/utils/pdfgeom";

export default {
    name: "PictureView",
    components: {
        InlineSvg,
    },
    props: {
        url: String,
    },
    data: () => ({
        div_id: null,
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        file_extension() {
            return this.url
                .split(/[#?]/)[0]
                .split(".")
                .pop()
                .trim()
                .toLowerCase();
        },
        picRef() {
            return this.$refs[this.div_id];
        },
        containerRef() {
            return this.$refs.pdfContainer;
        },
        originalSize() {
            return {
                width: this.containerRef.clientWidth,
                height: this.containerRef.clientHeight,
            };
        },
        containerRatio() {
            return (
                this.containerRef.clientWidth / this.containerRef.clientHeight
            );
        },
    },
    methods: {
        launch_hammer() {
            this.recenter();
            this.destroy_hammer();

            // Hammer new
            this.hammertime = new Hammer(this.picRef, {});
            this.hammertime.get("pinch").set({ enable: true });
            this.hammertime.get("pan").set({ threshold: 0 });

            this.currentPos = {
                x: 0,
                y: 0,
                z: 1,
                zooming: false,
                width: this.originalSize.width * 1,
                height: this.originalSize.height * 1,
            };

            this.hammertime.on("pan", (e) => {
                if (this.lastEvent !== "pan") {
                    this.fixHammerjsDeltaIssue = {
                        x: e.deltaX,
                        y: e.deltaY,
                    };
                }
                this.currentPos.x =
                    this.last.x + e.deltaX - this.fixHammerjsDeltaIssue.x;
                this.currentPos.y =
                    this.last.y + e.deltaY - this.fixHammerjsDeltaIssue.y;
                this.lastEvent = "pan";
                this.updateCanvasTransform();
            });

            this.hammertime.on("pinch", (e) => {
                var d = scaleFrom(
                    this.originalSize,
                    pinchZoomOrigin,
                    this.last.z,
                    this.last.z * e.scale
                );
                this.currentPos.x = d.x + this.last.x + e.deltaX;
                this.currentPos.y = d.y + this.last.y + e.deltaY;
                this.currentPos.z = d.z + this.last.z;
                this.lastEvent = "pinch";
                this.updateCanvasTransform();
            });

            var pinchZoomOrigin = undefined;
            this.hammertime.on("pinchstart", (e) => {
                this.pinchStart.x = e.center.x;
                this.pinchStart.y = e.center.y;
                pinchZoomOrigin = getRelativePosition(
                    this.picRef,
                    { x: this.pinchStart.x, y: this.pinchStart.y },
                    this.originalSize,
                    this.currentPos.z
                );
                this.lastEvent = "pinchstart";
            });

            this.hammertime.on("panend", () => {
                this.last.x = this.currentPos.x;
                this.last.y = this.currentPos.y;
                this.lastEvent = "panend";
            });

            this.hammertime.on("pinchend", () => {
                this.last.x = this.currentPos.x;
                this.last.y = this.currentPos.y;
                this.last.z = this.currentPos.z;
                this.lastEvent = "pinchend";
            });

            Hammer.on(this.picRef, "mousewheel", (e) => {
                // console.log("MOUSE WHEEL", e);
                var scrollStart = getRelativePosition(
                    this.picRef,
                    { x: e.x, y: e.y },
                    this.originalSize,
                    this.last.z
                );
                var d = scaleFrom(
                    this.originalSize,
                    scrollStart,
                    this.last.z,
                    this.last.z * (1 - e.deltaY / 5000)
                );
                this.currentPos.x += d.x;
                this.currentPos.y += d.y;
                // this.currentPos.z = d.z;
                this.currentPos.z *= 1 - e.deltaY / 5000;
                this.lastEvent = "wheel";
                this.updateCanvasTransform();

                this.last.x = this.currentPos.x;
                this.last.y = this.currentPos.y;
                this.last.z = this.currentPos.z;
            });

            //  END HAMMER
        },
        updateCanvasTransform() {
            this.currentPos.height =
                this.originalSize.height * this.currentPos.z;
            this.currentPos.width = this.originalSize.width * this.currentPos.z;
            this.picRef.style.transform =
                "translate3d(" +
                this.currentPos.x +
                "px, " +
                this.currentPos.y +
                "px, 0) scale(" +
                this.currentPos.z +
                ")";
        },
        clean_hammer_vars() {
            this.currentPos = {
                x: 0,
                y: 0,
                z: 1,
            };
            this.last = {
                x: 0,
                y: 0,
                z: 1,
            };
            this.fixHammerjsDeltaIssue = {
                x: 0,
                y: 0,
            };
            this.pinchStart = {
                x: 0,
                y: 0,
            };
        },
        destroy_hammer() {
            this.hammertime?.destroy();
        },
        recenter() {
            console.log("[>] Recentering picture.");
            this.clean_hammer_vars();
            this.updateCanvasTransform();
        },
    },
    mounted() {
        this.div_id = this._uid;
        bus.$on("events/viewer/recenter_mob2_operation", this.recenter);
    },
    beforeDestroy() {
        bus.$off("events/viewer/recenter_mob2_operation");
    },
};
</script>
