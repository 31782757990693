<template>
    <v-form @keyup.enter.native="$emit('save')" ref="form">
        <v-row>
            <v-col>
                <PitTextField label="ERP Code" v-model="form.erp_code">
                </PitTextField>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PitTextField label="width" v-model="form.width">
                </PitTextField>
            </v-col>
            <v-col>
                <PitTextField label="height" v-model="form.height">
                </PitTextField>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <!-- <PitTextField outlined dense label="material" v-model="form.material">
                </PitTextField> -->
                <SelectMaterial v-model="form.material" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import PitTextField from "../fields/PitTextField.vue";
import SelectMaterial from "../logistics/SelectMaterial.vue"

export default {
    props: ["value"],
    components: { PitTextField, SelectMaterial },
    data: () => ({
        form: {},
    }),
    computed: {
    },
    watch: {
        value: {
            handler: function (v) {
                this.form = v;
            },
            immediate: true,
        },
        form: {
            handler: function (f) {
                this.$emit("input", f);
            },
            deep: true,
        },
    },
    methods: {
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
    },
    mounted() {
    },
};
</script>
