<template>
    <v-row>
        <v-spacer></v-spacer>
        <v-btn icon v-if="report.last_file && report.last_file.hash" :href="report.last_file.presigned_url">
            <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn disabled icon v-else>
            <v-icon> mdi-download </v-icon>
        </v-btn>
        <v-btn :disabled="deleting" :loading="deleting" icon @click="deleteReport">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn :disabled="deleting" icon @click="editReport">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn :disabled="deleting || final_state_button" icon @click="dialog = true">
            <v-icon>mdi-text-box-check-outline</v-icon>
        </v-btn>
        <v-btn :disabled="deleting" icon @click="cloneReport">
            <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn :disabled="refreshing" :loading="refreshing" icon @click="refreshReport">
            <v-icon>mdi-reload</v-icon>
        </v-btn>
        <ButtonJSONRender title="Report meta" :json="report.meta" />

        <v-dialog v-model="dialog" persistent width="500">
            <v-card>
                <v-card-title>{{ $store.getters.t("set_report_as_final") }}</v-card-title>
                <v-card-text>{{ $store.getters.t("set_report_as_final_text") }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="setReportAsFinal" color="primary" :loading="loading" :disabled="loading">
                        <v-icon left> mdi-check </v-icon>
                        {{ $store.getters.t("save") }}
                    </v-btn>
                    <v-btn color="secondary" @click="dialog = false">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender.vue"
import bus from "/src/utils/event_bus"
import { new_rev_cf } from "./forms"
import _ from "lodash"
export default {
    components: {
        ButtonJSONRender,
    },
    props: ["report"],
    data: () => ({ deleting: false, refreshing: false, loading: false, dialog: false }),
    computed: {
        final_state_button() {
            return this.report.document_status === 'final';
        },
        waiting_state() {
            return this.report.status === 'waiting';
        },
    },
    methods: {
        deleteReport() {
            this.deleting = true
            this.$store.dispatch("v2/reports/deleteReport", this.report.uuid)
                .then(() => this.deleting = false)
        },
        editReport() {
            let cloned_report = _.cloneDeep(this.report)
            let revs = cloned_report.files.map((cf) => cf.meta.version)
            let rev = Math.max(...revs, -1) + 1
            cloned_report.files.push(new_rev_cf(rev))
            bus.$emit("report_dialog_update", cloned_report)
            bus.$emit("report_dialog")
        },
        cloneReport() {
            let cloned_report = _.cloneDeep(this.report)
            cloned_report.files = []
            cloned_report.files.push(new_rev_cf(0))
            delete cloned_report.uuid
            bus.$emit("report_dialog_update", cloned_report)
            bus.$emit("report_dialog")
        },
        refreshReport() {
            this.refreshing = true
            let revs = this.report.files.map((cf) => cf.meta.version)
            let rev = Math.max(...revs, -1) + 1
            let cloned_report = _.cloneDeep(this.report)
            cloned_report.files.push(new_rev_cf(rev))
            cloned_report.last_file = new_rev_cf(rev)
            this.$store.dispatch("v2/reports/putReport", cloned_report)
                .then(() => this.refreshing = false)
        },
        setReportAsFinal() {
            let cloned_report = _.cloneDeep(this.report);
            cloned_report.document_status = 'final';
            console.log("[i] Setting report state to final for report with UUID: ", this.report.uuid);
            this.loading = true;
            this.$store.dispatch("v2/reports/putReportNoJob", cloned_report)
                .then(() => {
                    this.loading = false;
                    this.dialog = false
                });
        },
    }
}
</script>