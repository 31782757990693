<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="warning" class="ml-2">
                <v-icon> mdi-sort-clock-ascending-outline </v-icon>
                Sort
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("constructible_sorting") }}
            </v-card-title>
            <v-card-subtitle>
                {{ $store.getters.t("constructible_sorting_subtitle") }}
            </v-card-subtitle>
            <v-row class="ma-2">
                <v-select outlined dense class="mx-2" v-model="selected_date" :items="production_dates"
                    :label="$store.getters.t('production_date')"></v-select>
                <SelectProductionLine class="mx-2" v-model="selected_pl" :selectables="production_lines" />
            </v-row>
            <v-card-text v-if="async_loading">
                <v-skeleton-loader type="list-item@3"></v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <draggable :list="actuals_list" class="list-group" ghost-class="ghost">
                    <v-hover v-slot:default="{ hover }" v-for="panel in actuals_list" :key="panel.uuid">
                        <v-sheet outlined class="d-flex ma-1 pa-1 outlined drag-item" :class="{ hover: hover }">
                            <v-icon class="" color="black"> mdi-drag </v-icon>
                            <span class="title font-weight-light">
                                {{ panel.design_name }}
                            </span>
                            <v-chip small outlined>
                                <v-icon small>mdi-sort-clock-ascending</v-icon> {{ panel.priority }}
                            </v-chip>
                            <span class="font-weight-light ml-3 ma-1">
                                {{ panel.uuid.substring(0, 8) }}
                            </span>
                            <v-spacer></v-spacer>
                            <ChipConstructibleStatus class="ma-1" inline :constructible="panel" />
                        </v-sheet>
                    </v-hover>
                </draggable>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="save()" color="warning" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ChipConstructibleStatus from "/src/components/renderers/ChipConstructibleStatus.vue";
import SelectProductionLine from "/src/components//fields/SelectProductionLine"
import { backend_api_v2 } from "/src/utils/apiv2";
import draggable from "vuedraggable";

export default {
    props: ["production_dates", "production_lines"],
    components: {
        draggable,
        ChipConstructibleStatus,
        SelectProductionLine,
    },
    data: () => ({
        dialog: false,
        selected_date: "",
        selected_pl: null,
        loading: false,
        async_loading: false,
        actuals_list: [],
    }),
    watch: {
        production_dates(pds) {
            if (pds.length > 0) {
                this.selected_date = pds[0];
            }
        },
        production_lines(pls) {
            if (pls.length > 0) {
                this.selected_pl = pls[0];
            }
        },
        selected_date(nd, od) {
            if (this.selected_date && this.selected_pl && nd !== od) {
                this.constructibles_by_date();
            }
        },
        selected_pl(npl, opl) {
            if (this.selected_date && this.selected_pl && npl !== opl) {
                this.constructibles_by_date();
            }
        },
    },
    methods: {
        constructibles_by_date() {
            this.async_loading = true;

            const assembly_promise = backend_api_v2.get(`constructibles/assemblies/?scheduled_prod_date=${this.selected_date}&production_line=${this.selected_pl}&extended_serializer=true`);
            const module_promise = backend_api_v2.get(`constructibles/modules/?scheduled_prod_date=${this.selected_date}&production_line=${this.selected_pl}&extended_serializer=true`);

            return Promise.all([assembly_promise, module_promise])
                .then(([assemblyData, moduleData]) => {
                    const assemblies = assemblyData.data.results.map(ass => ({ ...ass, level: "assembly" }));
                    const modules = moduleData.data.results.map(mod => ({ ...mod, level: "module" }));

                    this.actuals_list = [...assemblies, ...modules].sort((a, b) => a.priority - b.priority);

                    this.loading = false;
                    this.async_loading = false;
                })
                .catch(error => {
                    console.log(`Can't load both constructibles: ${error}`);
                    this.loading = false;
                    this.async_loading = false;
                });
        },
        save() {
            this.loading = true;
            let new_priority = 1;

            const payload = this.actuals_list.map((actual) => {
                return {
                    uuid: actual.uuid,
                    priority: new_priority++,
                };
            });

            const requests = payload.map((actual, i) => {
                const url = this.actuals_list[i].level === 'assembly'
                    ? `constructibles/assemblies/${actual.uuid}/`
                    : `constructibles/modules/${actual.uuid}/`;

                return backend_api_v2.put(url, actual)
                    .catch(error => {
                        console.error(`Error updating constructible ${actual.uuid}: ${error}`);
                        throw error;
                    });
            });

            Promise.all(requests)
                .then(() => {
                    console.log("All constructibles updated successfully");
                })
                .catch((error) => {
                    console.error(`Error updating constructibles: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.dialog = false;
                    this.selected_date = "";
                    this.actuals_list = [];
                });
        },
    }
};
</script>