import bus from "/src/utils/event_bus";

function get_lowest_dwg_number(cfs) {
    // Extract dwg_numbers from the objects and cast to numbers
    const dwg_numbers = cfs.map(cf => cf.meta && cf.meta?.dwg_number || 100)

    // Filter out undefined, null, and non-numeric values
    const valid_dwg_numbers = dwg_numbers
        .filter(num => num !== undefined && num !== null && !isNaN(parseFloat(num)))
        .map(Number) // Cast strings to numbers

    // Find the minimum dwg_number
    return Math.min(...valid_dwg_numbers)
}

export default function (store) {

    // Load viewer boms
    store.watch(
        (state) => state.v2.cloudstorage.module_design_packages.find(pck => pck.name == "heavy_meta"),
        () => store.dispatch("v2/viewer/loadBom")
    );
    store.watch(
        (state) => state.v2.cloudstorage.assembly_design_packages.find(pck => pck.name == "heavy_meta"),
        () => store.dispatch("v2/viewer/loadBom")
    );
    store.watch(
        (state) => state.v2.cloudstorage.parent_assembly_design_packages.find(pck => pck.name == "heavy_meta"),
        () => store.dispatch("v2/viewer/loadBom")
    );

    // Refresh things when panel is loaded
    store.watch(
        (state) => state.v2.cloudstorage.module_design_packages.find(pck => pck.name == "resources"),
        (pck) => {
            console.log("[i] Setting up viewer store with new module")

            bus.$emit('events/viewer/default_zone_selection')

            // Set correct left buttons index
            const dwgs = pck?.files.filter(cf => cf.meta.section == "drawing")
            if (dwgs && dwgs.length <= store.state.v2.viewer.left_panel_dwg_number) {
                store.commit("v2/viewer/setLeftPanelDWGNumber", get_lowest_dwg_number(pck?.files || []))
            }

            bus.$emit("events/viewer/redraw_left_pdf");
        }
    );
    store.watch(
        (state) => state.v2.cloudstorage.assembly_design_packages.find(pck => pck.name == "resources"),
        (pck) => {
            console.log("[i] Setting up viewer store with new assembly")

            bus.$emit('events/viewer/default_zone_selection')

            // Set correct left buttons index
            const dwgs = pck?.files.filter(cf => cf.meta.section == "drawing")
            if (dwgs && dwgs.length <= store.state.v2.viewer.left_panel_dwg_number) {
                store.commit("v2/viewer/setLeftPanelDWGNumber", get_lowest_dwg_number(pck?.files || []))
            }

            bus.$emit("events/viewer/redraw_left_pdf");
        }
    );

}