<template>
    <div class="px-1">
        <div v-if="multiple">
            <span>
                <v-icon small> mdi-script-text </v-icon>
                {{ $store.getters.t("specs") }}
            </span>
            <v-btn width="100%" v-for="s in specs" :key="s" color="accent" class="mt-1"
                @mousedown.prevent="select_spec(s)" @touchstart.prevent="select_spec(s)" @mouseup="touchend"
                @touchend="touchend">
                {{ s }}
            </v-btn>
        </div>
        <v-btn width="100%" v-else-if="single" color="accent" large @mousedown.prevent="select_spec(spec)"
            @touchstart.prevent="select_spec(spec)" @mouseup="touchend" @touchend="touchend">
            <v-icon left small> mdi-script-text </v-icon>
            {{ $store.getters.t("spec") }}
        </v-btn>

        <v-dialog v-model="mouse_down" fullscreen transition="dialog-top-transition" @mouseup="touchend">
            <v-card @mouseup="touchend">
                <v-toolbar dense dark color="secondary" class="fronted">
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <!-- <v-btn icon dark @click="recenterPDF">
                            <v-icon>mdi-image-filter-center-focus</v-icon>
                        </v-btn>
                        <v-btn icon dark @click="mouse_down = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn> -->
                    </v-toolbar-items>
                </v-toolbar>
                <div class="pa-5" v-if="text">{{ text }}</div>
                <PictureView v-else-if="url && picture_mode" :url="url" />
                <PDFView v-else-if="url && !picture_mode" :url="url" name="dialog_detail" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus";
import PDFView from "../PDFView.vue";
import { cloudfile_is_picture } from "/src/utils/resources";
import { mapGetters } from 'vuex'

export default {
    components: { PDFView },
    data: () => ({
        mouse_down: false,
        selected_uuid: "",
    }),
    computed: {
        ...mapGetters({
            res_pck: "v2/viewer/resPackage",
        }),
        specs() {
            return this.res_pck?.files.filter(cf => cf.meta.section == "spec") || []
        },
        spec() {
            // Used if single spec
            return this.specs[0];
        },
        multiple() {
            // Is this multiple specs?
            return this.specs.length > 1;
        },
        single() {
            // Is this single spec?
            return this.specs.length == 1;
        },
        spec_ref() {
            return this.specs.find((cf) => cf.uuid == this.selected_uuid);
        },
        url() {
            return this.spec_ref?.presigned_url || "";
        },
        text() {
            return this.spec_ref?.meta?.text;
        },
        title() {
            return this.spec_ref?.short_name;
        },
        picture_mode() {
            if (!this.spec_ref) return false;
            return cloudfile_is_picture(this.spec_ref);
        },
    },
    methods: {
        select_spec(spec) {
            this.selected_uuid = spec;
            this.mouse_down = true;
        },
        touchend() {
            // if (this.text || this.picture_mode) this.mouse_down = false;
            // else console.log("hehehe dont close");
            this.mouse_down = false;
        },
        recenterPDF() {
            bus.$emit("events/viewer/recenter_pdf_dialog_detail");
        },
    },
};
</script>