<template>
  <v-text-field outlined dense @keyup.enter="keyUpEnter()" @keyup.esc="keyUpEsc()" @click:append="clickAppend()"
    :append-icon="appendIcon" :label="label" v-model="internalValue" :error="error" :disabled="disabled"
    :loading="loading" :hide-details="hideDetails ? 'auto' : false" :maxlength="maxlength"
    :counter="maxlength != undefined" :rules="[rules.required, rules.illegalName]"></v-text-field>
</template>
  
<script>
import { pit_rules } from "./utils";
export default {
  props: ["label", "value", "appendIcon", "error", "disabled", "loading", "maxlength", "hideDetails"],
  data() {
    return {
      internalValue: '',
      rules: pit_rules,
    }
  },
  methods: {
    keyUpEnter() {
      this.$emit("enter");
    },
    keyUpEsc() {
      this.$emit("esc");
    },
    clickAppend() {
      this.$emit("click_append");
    }
  },
  watch: {
    value: {
      handler: function (v) {
        this.internalValue = v;
      },
      immediate: true,
    },
    internalValue(v) {
      this.$emit("input", v);
    },
  },
};
</script>