<template>
    <v-combobox ref="combo" v-model="phase" outlined dense :items="phases" :rules="[rules.required, rules.illegalName]"
        no-data-text="Write phase name" lazy class="mx-1" :label="$store.getters.t('phase')"></v-combobox>
</template>

<script>
import { pit_rules } from "./utils";
export default {
    props: ["value"],
    data: () => ({
        phase: "",
        rules: pit_rules,
    }),
    computed: {
        phases() {
            return this.$store.state.v2.selected.selected_project?.phases || [];
        },
    },
    methods: {
        validate() {
            return this.$refs.combo.validate()
        }
    },
    watch: {
        phase(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.phase = v;
            },
            immediate: true,
        },
    },
};
</script>