<template>
    <v-container fluid>
        <v-row class="d-flex flex-row mb-3 header-bar" no-gutters>
            <h2 class="mt-0 mx-2" v-if="company_wide">
                {{ $store.getters.t("production_overview") }}
            </h2>
            <h2 class="mt-0 mx-2" v-else>
                {{ $store.getters.t("project_overview") }}
            </h2>
            <span v-if="last_update" class="ma-2">
                {{ $store.getters.t("updated") }}:
                <Timestamp :value="last_update" />
            </span>
            <span v-if="background_loading && !loading" class="ma-2">
                {{ $store.getters.t("reloading") }}...
            </span>
            <v-alert
                v-if="last_status == 'error'"
                class="my-0 mx-2"
                dense
                outlined
                type="error"
            >
                {{ $store.getters.t("last_scheduler_errors") }}
            </v-alert>
            <v-alert
                v-if="touched_schedule"
                class="my-0 mx-2"
                dense
                outlined
                type="warning"
            >
                {{ $store.getters.t("overview_touched") }}
            </v-alert>
            <v-spacer></v-spacer>
            <div>
                <!-- <v-btn icon @click="scroll_to_now">
                    <v-icon> mdi-chart-gantt </v-icon>
                </v-btn> -->
                <ButtonRunScheduler />
                <v-btn-toggle borderless v-model="timeframe" mandatory>
                    <!-- <v-btn small color="accent" text value="1h">1h</v-btn> -->
                    <v-btn small color="accent" text value="6h">6h</v-btn>
                    <v-btn small color="accent" text value="d">d</v-btn>
                    <v-btn small color="accent" text value="w">w</v-btn>
                    <v-btn small color="accent" text value="3w">3w</v-btn>
                    <v-btn small color="accent" text value="6w">6w</v-btn>
                </v-btn-toggle>
            </div>
        </v-row>
        <v-row class="my-3" no-gutters>
            <v-progress-linear
                v-if="progress != 100"
                height="15"
                color="accent"
                :value="progress"
            >
                <template v-slot:default="{}">
                    <span class="caption white--text">
                        {{ $store.getters.t("working_on_schedule") }}:
                        {{ progress }}%.
                    </span>
                </template>
            </v-progress-linear>
            <div class="d-flex mx-auto mt-10" v-if="loading">
                <v-icon x-large class="ma-5" color="grey lighten-2">
                    mdi-table-arrow-down
                </v-icon>
                <span class="mt-5 grey--text text--lighten-2 display-1">
                    {{ $store.getters.t("loading_production") }}
                </span>
            </div>
            <ChartOverview
                v-if="!loading"
                :timeframe="timeframe"
                :production_lines="chart_pls"
            />
            <h3
                class="ma-2 mt-3"
                v-if="!loading && loose_batches.projects.length != 0"
            >
                {{ $store.getters.t("loose_batches") }}
            </h3>
            <ChartLooseBatches
                v-if="!loading && loose_batches.projects.length != 0"
                :timeframe="timeframe"
                :loose_batches="loose_batches"
            />
        </v-row>
    </v-container>
</template>


<style scoped>
.header-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;
}
</style>

<script>
import ChartOverview from "/src/components/pm/ChartOverview";
import ChartLooseBatches from "/src/components/pm/ChartLooseBatches";
import ButtonRunScheduler from "/src/components/pm/ButtonRunScheduler";
import { backend_api_v2 } from "/src/utils/apiv2";
import bus from "/src/utils/event_bus";
import Timestamp from "/src/components/renderers/Timestamp.vue";

export default {
    components: {
        ChartOverview,
        ChartLooseBatches,
        ButtonRunScheduler,
        Timestamp,
    },
    data: () => ({
        timeframe: "w",
        timeframes: ["6h", "d", "w", "3w", "6w"],
        progress: 100,
        current_scheduler: null,
    }),
    computed: {
        company_wide() {
            return this.$store.getters["companyWide"];
        },
        selected_project_uuid() {
            return this.$store.state.projects.selected_project?.uuid;
        },
        production_overview() {
            return this.$store.state.overview.production_overview;
        },
        touched_schedule() {
            return this.$store.state.overview.touched_schedule;
        },
        chart_pls() {
            return this.production_overview.production_lines;
        },
        loose_batches() {
            return this.$store.state.overview.loose_batches;
        },
        last_update() {
            return this.current_scheduler?.meta?.last_update;
        },
        last_status() {
            return this.current_scheduler?.meta?.status;
        },
        loading() {
            return this.$store.state.overview.loading;
        },
        background_loading() {
            return this.$store.state.overview.background_loading;
        },
    },
    watch: {
        current_scheduler(cs) {
            if (cs.meta?.status == "working") {
                console.log("has to get again");
                this.running = true;
                this.progress = cs.meta?.status_perc || 100;
                this.get_scheduler();
            } else {
                this.running = false;
                this.progress = 100;
            }
        },
        timeframe: {
            handler: function () {
                this.declare_timeframe();
            },
            immediate: true,
        },
        company_wide(cw) {
            if (cw) {
                const pn = "production_overview";
                if (this.$route.name !== pn) {
                    this.$router.push({ name: pn });
                }
            } else {
                const pn = "project_overview";
                if (this.$route.name !== pn) {
                    this.$router.push({
                        name: pn,
                        params: { project_uuid: this.selected_project_uuid },
                    });
                }
            }
            this.fetch_po();
        },
    },
    methods: {
        get_scheduler() {
            setTimeout(this.get_scheduler_now, 5000);
        },
        get_scheduler_now() {
            backend_api_v2
                .get("/overview/scheduler/")
                .then(({ data }) => {
                    this.current_scheduler = data;
                })
                .catch((e) => console.log(e));
        },
        declare_timeframe() {
            this.$store.commit("overview/setTimeframe", this.timeframe);
        },
        fetch_po() {
            this.$store.commit("overview/setLoading");
            if (this.$route.name == "production_overview") {
                this.$store.dispatch("overview/loadPO");
            } else {
                const pr = this.$route.params.project_uuid;
                this.$store.dispatch("overview/loadPO", pr);
            }
        },
        scroll_to_now() {
            bus.$emit("scroll_to_now");
        },
    },
    mounted() {
        this.fetch_po();
        this.get_scheduler();
        this.declare_timeframe();
        bus.$on("scheduler_launched", this.get_scheduler);
    },
    beforeDestroy() {
        bus.$off("scheduler_launched");
    },
};
</script>