import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '/src/views/misc/Login'
import Landing from '/src/views/misc/Landing'
import QRReader from '/src/views/misc/QRReader'
import AccountView from '/src/views/misc/AccountView'
import ExternalURLHandler from '/src/views/misc/ExternalURLHandler'
import ReportManager from '/src/views/manager/ReportManager'
import Company from '/src/views/manager/Company'
import ProductionOverview from '/src/views/manager/ProductionOverview'
import ConstructibleQuantities from '/src/views/manager/ConstructibleQuantities'
import ProjectSettings from '/src/views/manager/ProjectSettings'
import FilesView from "/src/views/manager/FilesView"
import Designs from '/src/views/manager/Designs'
import DesignParts from '/src/views/manager/DesignParts'
import Constructibles from '/src/views/manager/Constructibles'
import Issues from '/src/views/manager/Issues'
import OrdersView from '/src/views/manager/OrdersView'
import Viewer from '/src/views/viewer/Viewer'
import ProjectViewer from '/src/views/viewer/ProjectViewer'
import ConstructibleViewer from '/src/views/viewer/ConstructibleViewer'
import multiguard from 'vue-router-multiguard';
import StockProducts from '/src/views/manager/StockProducts';
import ConstructiblesListManager from '/src/views/manager/ConstructiblesListManager';
import Logistics from '/src/views/logistics/Logistics';

import {
    viewerMode,
    managerMode,
    logisticsMode,
    onlyManagersGuard,
    // onlyAdminsGuard,
    hasSessionObjectGuard,
    isAuthenticated,
    hasModuleForecast,
    hasModuleProduction,
    asyncStoreDispatcher,
    afterEachGuard,
}
    from './guards'
import { LEGACY_REDIRECTIONS } from './legacy_redirections'


Vue.use(VueRouter)


const routes = [
    // LEGACY REDIRECTIONS
    ...LEGACY_REDIRECTIONS,

    // GENERAL ROUTES
    {
        path: '/',
        name: 'home',
        component: Landing,
        beforeEnter: multiguard([isAuthenticated]),
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
    },
    {
        path: '/qr',
        name: 'qr',
        component: QRReader
    },
    {
        path: "/account",
        name: "account",
        component: AccountView,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, hasModuleProduction])
    },

    // VIEWER ROUTES
    {
        path: '/viewer/project/:project_uuid',
        name: 'project_viewer',
        component: ProjectViewer,
        beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
    },
    {
        path: '/viewer',
        name: 'viewer',
        component: Viewer,
        beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
    },
    {
        path: '/viewer/assembly_design/:assd_uuid',
        name: 'assd_viewer',
        component: ConstructibleViewer,
        beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
    },
    {
        path: '/viewer/assembly/:ass_uuid',
        name: 'ass_viewer',
        component: ConstructibleViewer,
        beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
    },
    {
        path: '/viewer/module_design/:modd_uuid',
        name: 'modd_viewer',
        component: ConstructibleViewer,
        beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
    },
    {
        path: '/viewer/module/:mod_uuid',
        name: 'mod_viewer',
        component: ConstructibleViewer,
        beforeEnter: multiguard([isAuthenticated, viewerMode, hasSessionObjectGuard, hasModuleProduction])
    },

    // PROJECT SPECIFIC ROUTES
    {
        path: '/projects/:project_uuid/constructibles',
        name: 'constructibles',
        component: Constructibles,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/constructibles/assembly/:ass_uuid',
        name: 'assembly_detail',
        component: Constructibles,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/constructibles/module/:mod_uuid',
        name: 'module_detail',
        component: Constructibles,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/files',
        name: 'files',
        component: FilesView,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/designs',
        name: 'designs',
        component: Designs,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/designs/assembly/:assd_uuid',
        name: 'assembly_design_detail',
        component: Designs,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/designs/module/:modd_uuid',
        name: 'module_design_detail',
        component: Designs,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/designs/:design_uuid/parts',
        name: 'design_parts',
        component: DesignParts,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/overview',
        name: 'project_overview',
        component: ProductionOverview,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, hasModuleForecast, managerMode])
    },
    {
        path: '/projects/:project_uuid/issues',
        name: 'issues',
        component: Issues,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/issues/:issue_uuid',
        name: 'issue_detail',
        component: Issues,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/settings',
        name: 'project_settings',
        component: ProjectSettings,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/quantities',
        name: 'quantities',
        component: ConstructibleQuantities,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/reports',
        name: 'reports',
        component: ReportManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/projects/:project_uuid/logistics',
        name: 'logistics',
        component: Logistics,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, hasModuleForecast, logisticsMode])
    },


    // EXTERNAL ROUTES
    {
        path: '/external/:company_uuid/:project_number/:wall_name',
        name: 'external',
        component: ExternalURLHandler,
        beforeEnter: multiguard([isAuthenticated]),
        props: true,
    },

    // COMPANY WIDE ROUTES
    {
        path: '/company/constructibles_manager',
        name: 'constructibles_manager',
        component: ConstructiblesListManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/constructibles_manager/assembly/:ass_uuid',
        name: 'constructibles_manager_assembly_detail',
        component: ConstructiblesListManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/constructibles_manager/module/:mod_uuid',
        name: 'constructibles_manager_module_detail',
        component: ConstructiblesListManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/constructibles_manager/assembly_design/:assd_uuid',
        name: 'constructibles_manager_assembly_design_detail',
        component: ConstructiblesListManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/constructibles_manager/module_design/:modd_uuid',
        name: 'constructibles_manager_module_design_detail',
        component: ConstructiblesListManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/stock_products',
        name: 'stock_products',
        component: StockProducts,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/settings',
        name: 'company_settings',
        component: Company,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/reports',
        name: 'company_reports',
        component: ReportManager,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, managerMode])
    },
    {
        path: '/company/production_overview',
        name: 'production_overview',
        component: ProductionOverview,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyManagersGuard, hasModuleForecast, managerMode])
    },
    {
        path: "/orders",
        name: 'orders',
        component: OrdersView,
    },
    {
        path: '*',
        name: 'redirect',
        redirect: '/'
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: '/app/',
    routes
})

router.beforeEach(asyncStoreDispatcher)

router.afterEach(afterEachGuard)

export default router
