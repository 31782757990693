<template>
    <div>
        <v-row v-if="loading" dense class="mt-1 mb-3">
            <v-skeleton-loader v-for="index in 3" :key="index" type="button" class="mx-2" />
        </v-row>
        <v-row v-else dense class="mt-1 mb-3">
            <v-row v-if="sizes_length > 5" dense class="mt-1 mb-3">
                <v-menu v-model="menu" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="accent" class="mx-2" outlined>
                            <v-icon left>mdi-arrow-down-drop-circle</v-icon>
                            {{ selected_size ? format_size(selected_size) : 'SIZE SELECTOR' }}
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in sizes" :key="index" @click="select_size(item)">
                            <v-list-item-title>
                                {{ format_size(item) }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-row>
            <v-row v-else class="mt-1 mb-3">
                <v-btn v-for="(item, index) in sizes" :key="index" @click="select_size(item)" outlined class="mx-2"
                    color="accent" text>
                    {{ format_size(item) }}
                </v-btn>
            </v-row>
        </v-row>
    </div>
</template>
  
<script>
export default {
    data: () => ({
        menu: false,
        selected_size: null,
    }),
    computed: {
        sizes() {
            return this.$store.state.v2.selected_logistics.sizes;
        },
        sizes_length() {
            return this.sizes.length;
        },
        loading() {
            return this.$store.getters["v2/selected_logistics/isLoading"]("sizes");
        },
    },
    methods: {
        select_size(item) {
            this.selected_size = item;
            this.$emit('input', item.uuid);
            this.menu = false;
        },
        format_size(item) {
            return item.thickness
                ? `${item.thickness} mm ${item.material ? item.material.name : '---'}`
                : `${item.material ? item.material.name : '---'} ${item.width}*${item.height}`;
        },
    },
};
</script>
  