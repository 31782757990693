<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                bordered
                overlap
                v-model="show_badge"
                :content="selected_count"
            >
                <v-btn v-bind="attrs" v-on="on" small color="secondary">
                    {{ $store.getters.t("send_message_to") }}
                </v-btn>
            </v-badge>
        </template>

        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("message_people_title") }}
            </v-card-title>
            <v-card-subtitle>
                {{ $store.getters.t("message_people_subtitle") }}
            </v-card-subtitle>
            <v-card-text>
                <v-skeleton-loader
                    v-if="contacts.length == 0"
                    type="list-item-three-line@2"
                >
                </v-skeleton-loader>
                <v-list v-else flat subheader two-line>
                    <v-list-item-group
                        v-model="selected_contacts"
                        multiple
                        active-class=""
                    >
                        <v-list-item
                            outlined
                            v-for="contact in contacts"
                            :key="contact.id"
                            :value="contact.id"
                            two-line
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox
                                        :input-value="active"
                                    ></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ contact.username }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{
                                            contact.auth.production_site_name ||
                                            "---"
                                        }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["value", "text"],
    data: () => ({
        dialog: false,
        selected_contacts: [],
    }),
    watch: {
        selected_contacts(su) {
            this.$emit("input", su);
        },
    },
    computed: {
        selected_count() {
            return this.selected_contacts.length;
        },
        show_badge() {
            return this.selected_count != 0;
        },
        contacts() {
            return this.$store.state.v2.company.users;
        },
    },
    methods: {
        send_messages(metadata) {
            console.log("sending");
            this.contacts.forEach((c) => {
                if (this.selected_contacts.includes(c.id)) {
                    let msg = {
                        text: this.text,
                        receiver: c.id,
                        sender: c.id, // this is placeholder. It's ovewriten at backend
                        created: new Date().toISOString(),
                        delivered: false,
                        readed: false,
                        not_in_server: true,
                        contact: {
                            id: c.id,
                            is_receiver: true,
                        },
                        metadata,
                    };
                    this.$store.dispatch("v2/mail/sendMessage", msg);
                }
            });
            setTimeout(() => console.log("sended"));
        },
    },
    mounted() {
        this.$store.dispatch("v2/company/loadUsers");
    },
};
</script>
  