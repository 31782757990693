<template>
    <div class="mx-1">
        <!-- <v-btn-toggle dark background-color="success" v-model="details_mode" class="ml-1 mr-1">
            <v-btn value="details" icon width="50%" @click="setMode('details')">
                <v-icon>mdi-drawing-box</v-icon>
            </v-btn>
            <v-btn value="docs" icon width="50%" @click="setMode('docs')">
                <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>
        </v-btn-toggle>-->
        <v-btn
            v-if="details_mode == 'docs'"
            large
            color="secondary"
            width="100%"
            depressed
            @click="setMode('details')"
        >
            <v-icon left>mdi-file-document-outline</v-icon> DOCS
        </v-btn>
        <v-btn
            v-else
            color="secondary"
            width="100%"
            large
            depressed
            @click="setMode('docs')"
        >
            <v-icon left>mdi-drawing-box</v-icon>DETS
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "SelectRightPanelMode",
    data: () => ({ details_mode: "details" }),
    methods: {
        setMode(mode) {
            this.details_mode = mode;
            this.$store.commit("v2/viewer/setRightPanelMode", mode);
        },
    },
};
</script>