<template>
    <v-sheet outlined rounded class="ma-1 pa-1">
        <div class="d-flex justify-space-between">
            <span> {{ file.short_name }} </span>
            <v-spacer></v-spacer>
            <v-btn :href="file.presigned_url" target="_blank" small class="mt-1" icon>
                <v-icon small> mdi-download </v-icon>
            </v-btn>
            <v-btn :loading="loading" small class="mt-1" icon color="accent" @click="del">
                <v-icon small> mdi-close </v-icon>
            </v-btn>
        </div>
    </v-sheet>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    props: ["file"],
    data: () => ({ loading: false }),
    methods: {
        del() {
            this.loading = true;
            backend_api_v2
                .delete(`/cloudstorage/files/${this.file.uuid}/`)
                .then(() => {
                    this.$emit("deleted");
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>