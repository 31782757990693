<template>
    <v-navigation-drawer :value="!!issue_uuid" absolute stateless disable-route-watcher right width="600">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title> Issue Detail </v-list-item-title>
                </v-list-item-content>
                <v-btn icon :to="{ name: 'issues' }">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
        <v-divider></v-divider>

        <!-- Detail -->

        <v-container fluid class="px-5">
            <v-row v-if="issue">
                <v-col class="pa-1">
                    <v-card outlined class="fill-height">
                        <v-card-title>
                            {{ $store.getters.t("issue") }}:
                            {{ issue.title }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ issue.uuid.substring(0, 8) }}
                        </v-card-subtitle>
                        <v-card-text>
                            {{ $store.getters.t("text") }}: {{ issue.text }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <ButtonIssueEdit :issue="issue" />
                            <div class="mx-1"></div>
                            <ButtonIssueSolve v-if="show_solve_button" :issue="issue" />
                            <div class="mx-1" />
                            <ButtonIssueDelete :issue="issue" @deleted="deleted" />
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="issue">
                <v-col class="pa-1">
                    <v-card outlined class="fill-height">
                        <v-card-title> </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("category") }}:
                            {{ issue.category }} <br />
                            {{ $store.getters.t("status") }}:
                            {{ issue.status }} <br />

                            {{ $store.getters.t("assembly") }}:
                            {{ issue.assembly || "---" }} <br />
                            {{ $store.getters.t("module") }}:
                            {{ issue.module || "---" }} <br />
                            {{ $store.getters.t("design") }}:
                            {{ design.name }}
                            <IconRevision :old="revision_is_old" :rev="design.revision" /> <br />
                            {{ $store.getters.t("user") }}:
                            {{ issue.username }} <br />
                            {{ $store.getters.t("created") }}:
                            <Timestamp :value="issue.created" /><br />
                            {{ $store.getters.t("solved") }}:
                            <IconBoolean :value="issue.solved" /><br />
                            <span v-if="issue.solved">
                                {{ $store.getters.t("solved_in_revision") }}:
                                <IconRevision :old="solved_revision_is_old" :rev="solved_design_revision" /> <br />
                                {{ $store.getters.t("solved_coment") }} :
                                {{
                                    issue.solved ? issue.solved_text : "not solved"
                                }}
                                <br />
                            </span>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="files_length != 0">
                <v-col class="pa-1">
                    <v-card outlined>
                        <v-card-title>
                            {{ $store.getters.t("pictures") }}
                        </v-card-title>
                        <v-card-text>
                            <v-img v-for="file in files" :key="file.uuid" :src="file.presigned_url" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

    </v-navigation-drawer>
</template>

<script>
import ButtonIssueSolve from "/src/components/issues/ButtonIssueSolve";
import ButtonIssueEdit from "/src/components/issues/ButtonIssueEdit";
import ButtonIssueDelete from "/src/components/issues/ButtonIssueDelete";
import IconBoolean from "/src/components/renderers/IconBoolean.vue";
import IconRevision from "/src/components/renderers/IconRevision.vue";
import Timestamp from "/src/components/renderers/Timestamp";
import { backend_api_v2 } from "/src/utils/apiv2";

export default {
    components: { ButtonIssueEdit, ButtonIssueSolve, ButtonIssueDelete, IconBoolean, IconRevision, Timestamp },
    data: () => ({
        loading: false,
        pck: {
            files: []
        },
    }),
    computed: {
        issue_uuid() {
            return this.$route.params.issue_uuid;
        },
        project_uuid() {
            return this.$route.params.project_uuid;
        },
        issue() {
            const issue_uuid = this.$route.params.issue_uuid;
            return this.$store.state.v2.records.issues.find(
                (issue) => issue.uuid == issue_uuid
            );
        },
        show_solve_button() {
            return this.issue && this.issue.status != "solved";
        },
        files() {
            return this.pck?.files;
        },
        files_length() {
            return this.pck?.files?.length;
        },
        design() {
            const assd_uuid = this.issue?.assembly_design
            const modd_uuid = this.issue?.module_design
            if (assd_uuid) {
                return this.$store.state.v2.constructibles.assembly_designs.find(assd => assd.uuid == assd_uuid)
            } else if (modd_uuid) {
                return this.$store.state.v2.constructibles.module_designs.find(modd => modd.uuid == modd_uuid)
            } else {
                return null
            }
        },
        design_revisions() {
            return this.$store.getters["v2/constructibles/allDesigns"].filter(d => d.name == this.design?.name)
        },
        solved_design_revision() {
            return this.design_revisions.find(ass => ass.uuid == this.issue?.assembly_design_solved || this.issue?.module_design_solved)?.revision
        },
        revision_is_old() {
            let max_rev = Math.max(
                ...this.design_revisions.map((d) => d.revision)
            );
            let this_rev = this.design?.revision || 0;
            return this_rev != max_rev
        },
        solved_revision_is_old() {
            let max_rev = Math.max(
                ...this.design_revisions.map((d) => d.revision)
            );
            let this_rev = this.solved_revision?.revision || 0;
            return this_rev != max_rev
        },
    },
    watch: {
        issue_uuid: {
            immediate: true,
            handler: function () {
                this.load_pic_pck();
            }
        }
    },
    methods: {
        load_pic_pck() {
            this.loading = true;
            if (!this.issue_uuid) {
                return
            }
            backend_api_v2
                .post("/cloudstorage/packages/get_or_create/", {
                    name: "pictures",
                    issue: this.issue_uuid,
                })
                .then(({ data }) => {
                    this.pck = data
                })
        },
        deleted() {
            this.$router.go(-1);
        },
    },
};
</script>