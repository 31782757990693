<template>
    <v-card outlined>
        <v-card-title>{{ $store.getters.t("drawing") }}</v-card-title>
        <v-card-text v-if="loading">
            <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card-text>
        <v-card-text>
            <v-img :class="$vuetify.theme.dark ? 'dark' : ''" :src="cf.presigned_url" v-for="cf in files"
                :key="cf.uuid" />
        </v-card-text>
    </v-card>
</template>

<style scoped>
.dark {
    -webkit-filter: invert(1);
    filter: invert(1);
}
</style>

<script lang="js">
import { backend_api_v2 } from "/src/utils/apiv2"
export default {
    props: ["pd_uuid"],
    data: () => ({ loading: false, pck: null }),
    computed: {
        files() {
            // Ultra ugly code, but nothing better for javascript I think
            let files = this.pck?.files || []
            let names = [...new Set(files.map(cf => cf.short_name))]
            return names.map(sn => {
                let cfs = files.filter(cf => cf.short_name == sn)
                let maxv = Math.max(...cfs.map(cf => cf.meta.version))
                return cfs.find(cf => cf.meta.version == maxv)
            })
        }
    },
    watch: {
        // pd_uuid() {
        //     this.loadPackage()
        // },
        pd_uuid: {
            immediate: true,
            handler() {
                this.loadPackage()
            }
        }
    },
    methods: {
        loadPackage() {
            this.pck = null
            if (!this.pd_uuid) return
            this.loading = true
            backend_api_v2
                .get(`/cloudstorage/packages/?part_design=${this.pd_uuid}`)
                .then(({ data }) => {
                    if (data.results.length != 0)
                        this.pck = data.results[0]
                    else this.pck = null
                })
                .catch(() => {
                    this.pck = null
                }).finally(() => this.loading = false)
        },
    },
}
</script>