<template>
    <div class="ma-3 mt-0">
        <v-btn-toggle mandatory :value="designs_mode" @change="dm($event)">
            <v-btn text color="accent" :value="false" width="50%">
                <span class="text-truncate" style="width: 170px">
                    {{ $store.getters.t("production") }}
                </span>
            </v-btn>
            <v-btn text color="accent" :value="true" width="50%">
                <span class="text-truncate" style="width: 170px">
                    {{ $store.getters.t("design") }}
                </span>
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
export default {
    computed: {
        designs_mode() {
            return this.$store.state.v2.viewer.designs_mode;
        },
    },
    methods: {
        dm(designs_mode) {
            this.$store.commit("v2/viewer/setDesignsMode", designs_mode)
        }
    }
}
</script>