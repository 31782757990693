<template>
    <v-dialog v-model="dialog" width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-code-json</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ title || "JSON Metadata" }}
                <v-spacer></v-spacer>
                <v-btn icon @click="downloadJSON">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn class="ml-3" icon @click="dialog = false"> <v-icon> mdi-close </v-icon> </v-btn>
            </v-card-title>

            <v-card-text ref="renderzone"></v-card-text>

            <v-divider></v-divider>

            <JSONRender v-if="dialog" :json="json" />

        </v-card>
    </v-dialog>
</template>

<script>
import JSONRender from "./JSONRender.vue";

export default {
    components: { JSONRender },
    props: ["title", "json"],
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        downloadJSON() {
            const jsonData = JSON.stringify(this.json, null, 2);
            const blob = new Blob([jsonData], { type: "application/json" });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = "report_meta.json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        },
    },
};
</script>