<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-title>
                        {{ $store.getters.t("orders") }}
                    </v-card-title>
                    <TableOrders />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({ loading: false }),
    computed: {
        project() {
            return this.$store.state.projects.selected_project;
        },
        orders() {
            return this.$store.state.projects.project_orders;
        },
    },
    watch: {
        project() {
            this.load_orders();
        },
    },
    methods: {
        load_orders() {
            if (this.project) {
                this.loading = true;
                this.$store
                    .dispatch("projects/loadOrders", this.project.uuid)
                    .finally(() => (this.loading = false));
            }
        },
    },
    mounted() {
        this.load_orders();
    },
};
</script>