<template>
    <div>
        <v-data-table :headers="headers" :items="filtered_constructibles" item-key="uuid" sort-by="priority"
            :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, -1],
        }" :items-per-page="50" show-select v-model="selected">
            <template v-slot:top>
                <div class="my-2" v-if="selected_uuids.length == 0"><br /></div>
                <div class="mx-5 my-2" v-else>
                    <ButtonSetScheduleDate :uuids="selected_uuids" @deleted="completed = []" />
                    <ButtonDeleteActuals :uuids="selected_uuids" @deleted="selected = []" />
                    <DialogSortingActualsByDateAndPL
                        v-if="company_works_by_date && (selected_production_dates || selected_production_lines)"
                        :production_dates="selected_production_dates" :production_lines="selected_production_lines" />
                    <ButtonClearProof :multiple="true" :actual_uuids="selected_uuids" @completed="selected = []" />
                </div>
            </template>
            <template v-slot:item.design_name="{ item }">
                <v-btn color="accent" text small :to="{
            name: `constructibles_manager_${item.level}_detail`,
            query: $route.query,
            params: {
                ...item.level == 'assembly' && { ass_uuid: item.uuid },
                ...item.level == 'module' && { mod_uuid: item.uuid },
            }
        }">
                    {{ item.design_name }}
                    <v-chip small outlined>
                        <v-icon small>mdi-sort-clock-ascending</v-icon> {{ item.priority }}
                    </v-chip>
                </v-btn>
            </template>
            <template v-slot:item.uuid="{ item }"> {{ item.uuid.substring(0, 8) }} </template>
            <template v-slot:item.design="{ item }">
                <ButtonDrawerDesignDetail :duuid="item.design" :level="item.level" :uuid="item.uuid"
                    :name="item.design_name" />
            </template>
            <template v-slot:item.scheduled_prod_date="{ item }"> {{ item.scheduled_prod_date || "---" }} </template>
            <template v-slot:item.project="{ item }">
                {{ get_project_name(item.project) }}
            </template>
            <template v-slot:item.production_line="{ item }">
                {{ get_production_line_name(item.production_line) }}
            </template>
            <template v-slot:item.status="{ item }">
                <ChipConstructibleStatus :constructible="item" />
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn text :to="'/units/' + item.uuid">
                    <v-icon left> mdi-map-legend </v-icon>
                    {{ $store.getters.t("view_meaning_action_of_view") }}
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ChipConstructibleStatus from "../renderers/ChipConstructibleStatus.vue";
import ButtonSetScheduleDate from "../constructibles/ButtonSetScheduleDate"
import ButtonDeleteActuals from "../constructibles/ButtonDeleteActuals";
import ButtonClearProof from "../constructibles/ButtonClearProof";
import DialogSortingActualsByDateAndPL from "./DialogSortingActualsByDateAndPL";
import ButtonDrawerDesignDetail from "/src/components/constructibles_manager/ButtonDrawerDesignDetail.vue";

export default {
    props: ["value", "search", "mode"],
    components: {
        ChipConstructibleStatus,
        ButtonSetScheduleDate,
        ButtonDeleteActuals,
        ButtonClearProof,
        DialogSortingActualsByDateAndPL,
        ButtonDrawerDesignDetail,
    },
    data: () => ({
        constructibles: [],
        selected: [],
        selected_assembly: "",
    }),
    computed: {
        headers() {
            return [
                // {
                //     text: this.$store.getters.t("order"),
                //     value: "priority",
                // },
                {
                    text: this.$store.getters.t("constructible"),
                    value: "design_name",
                },
                {
                    text: this.$store.getters.t("uuid"),
                    value: "uuid",
                },
                {
                    text: this.$store.getters.t("client_order_id"),
                    value: "client_order_id",
                },
                {
                    text: this.$store.getters.t("design"),
                    value: "design",
                },
                {
                    text: this.$store.getters.t("production_date"),
                    value: "scheduled_prod_date",
                },
                {
                    text: this.$store.getters.t("project_name"),
                    value: "project",
                },
                {
                    text: this.$store.getters.t("production_line"),
                    value: "production_line",
                },
                {
                    text: this.$store.getters.t("status"),
                    value: "status",
                },
                {
                    text: "Actions",
                    sortable: false,
                    align: "right",
                    value: "actions",
                },
            ];
        },
        selected_uuids() {
            return this.selected?.map(s => s.uuid)
        },
        filtered_constructibles() {
            let filtered = this.constructibles.filter(
                (ass) =>
                    this.search == "" ||
                    ass.design_name?.toLowerCase().includes(this.search) ||
                    ass.uuid?.toLowerCase().includes(this.search) ||
                    ass.scheduled_prod_date?.toLowerCase().includes(this.search) ||
                    ass.client_order_id?.toLowerCase().includes(this.search) ||
                    ass.project?.toLowerCase().includes(this.search) ||
                    ass.production_line?.toLowerCase().includes(this.search) ||
                    ass.status?.toLowerCase().includes(this.search)
            )

            if (this.mode == 'assembly') {
                return filtered.filter((ass) => (ass.level == 'assembly'))
            } else if (this.mode == 'module') {
                return filtered.filter((ass) => (ass.level == 'module'))
            } else {
                return filtered
            }
        },
        company_works_by_date() {
            return this.$store.getters["v2/session/work_by_date"]
        },
        selected_production_dates() {
            return [...new Set(this.selected.map(c => c.scheduled_prod_date).filter(d => !!d))];
        },
        selected_production_lines() {
            return [... new Set(this.selected.map(c => c.production_line).filter(d => !!d))]
        },
    },
    watch: {
        constructibles(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.constructibles = v;
            },
            immediate: true,
        },
    },
    methods: {
        get_project_name(uuid) {
            return this.$store.state.v2.company.projects.find(
                (pro) => pro.uuid === uuid
            )?.name || "-";
        },
        get_production_line_name(uuid) {
            return this.$store.state.v2.factories.production_lines.find(
                (pl) => pl.uuid === uuid
            )?.name || "-";
        },
    },
};
</script>