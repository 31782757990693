export const DEFAULT_R = [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1],
];

export const DEFAULT_T = [0, 0, 0];

export const FRUSTUMSIZE = 1000;


export function get_mod_t(t, r) {
    if (!r || !t) return [0, 0, 0];

    // Negative and meters
    let it = t.map((i) => -i / 1000);

    // Factors to multiply rotation
    let a = r[0].map((i) => i * it[0]);
    let b = r[1].map((i) => i * it[1]);
    let c = r[2].map((i) => i * it[2]);

    // Moved and rotated
    let rt = [
        a[0] + b[0] + c[0],
        a[1] + b[1] + c[1],
        a[2] + b[2] + c[2],
    ];

    // Switch Z and Y axis (IFC -> WEBGL)
    return [rt[0], rt[2], -rt[1]];
}

export function get_mod_r(r) {
    if (!r) return null;
    // return [r[0], r[2], r[1].map((i) => -i)];
    return [
        [r[0][0], r[0][2], -r[0][1]],
        [r[1][0], r[1][2], -r[1][1]],
        [r[2][0], r[2][2], -r[2][1]],
    ]
}