<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("projects") }}
            </v-btn>
            <v-spacer></v-spacer>
            <ButtonArchivedProjects v-if="expanded" />
            <v-btn color="accent" v-if="expanded" text @click="new_project">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">UUID</th>
                        <th class="text-center">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-center">Superproject</th>
                        <th class="text-center">
                            {{ $store.getters.t("pr_number") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("client") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="project in projects" :key="project.uuid">
                        <td>{{ project.uuid.substring(0, 8) }}</td>
                        <td>{{ project.name }}</td>
                        <td>{{ project.super_project }}</td>
                        <td>{{ project.project_number }}</td>
                        <td>{{ project.client_name }}</td>
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(project.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon :to="`/projects/${project.uuid}/settings`">
                                <v-icon> mdi-cog </v-icon>
                            </v-btn>
                            <ButtonArchiveProject :project="project" />
                            <v-btn icon @click="launch_delete(project.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_project") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_project_confirm") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_project" color="error" :disabled="delete_loading"
                                :loading="delete_loading">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("project") }}:
                            {{ form.name }}
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" @keyup.enter.native="post_project">
                                <h2 class="
                                        ml-1
                                        mb-3
                                        mt-5
                                        font-weight-light
                                        grey--text
                                    ">
                                    {{ $store.getters.t("project_params") }}
                                </h2>
                                <SuperProjectField v-model="form.super_project" />
                                <v-text-field class="mx-1" outlined dense label="Name" :rules="rules"
                                    v-model="form.name">
                                </v-text-field>
                                <v-text-field class="mx-1" outlined dense label="Client Name" :rules="rules"
                                    v-model="form.client_name">
                                </v-text-field>
                                <v-text-field class="mx-1" outlined dense label="Project Number" :rules="rules"
                                    v-model="form.project_number">
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_project" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { by_name } from "/src/utils/basics";
import ButtonArchivedProjects from "/src/components/company/ButtonArchivedProjects";
import ButtonArchiveProject from "/src/components/company/ButtonArchiveProject";
import SuperProjectField from "/src/components/company/SuperProjectField";
import _ from "lodash";
export default {
    components: {
        ButtonArchivedProjects,
        ButtonArchiveProject,
        SuperProjectField,
    },
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
        rules: [(v) => !!v || "Required"],
    }),
    computed: {
        projects() {
            return [...this.$store.state.v2.company.projects].sort(by_name);
        },
    },
    methods: {
        launch_edit(uuid) {
            let project = this.projects.find((s) => s.uuid == uuid);
            this.form = _.cloneDeep(project);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_project() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/company/deleteProject", this.delete_uuid)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
        new_project() {
            this.form = {
                name: "",
                super_project: "",
                client_name: "",
                project_number: "",
                delivery_date: null,
                start_date: null,
                standard_library: false,
            };
            this.dialog = true;
        },
        post_project() {
            // This is due to the bug in v-combobox for super project field
            setTimeout(() => this.actual_post_project(), 100)
        },
        actual_post_project() {
            this.saving = true;
            if (this.$refs.form.validate()) {
                if (this.form.uuid) {
                    console.log("Updating Project");
                    this.$store
                        .dispatch("v2/company/putProject", this.form)
                        .then(() => {
                            this.saving = false;
                            this.dialog = false;
                        });
                } else {
                    console.log("Creating Project");
                    this.$store
                        .dispatch("v2/company/postProject", this.form)
                        .then(() => {
                            this.saving = false;
                            this.dialog = false;
                        });
                }
            } else {
                this.saving = false;
            }
        },
    },
};
</script>