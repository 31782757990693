<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card outlined class="fill-height">
                    <v-card-title>
                        {{ $store.getters.t("company") }}:
                        {{ company.name }}
                    </v-card-title>
                    <v-card-subtitle>
                        UUID:
                        {{
                            company.uuid ? company.uuid.substring(0, 8) : "---"
                        }}
                    </v-card-subtitle>
                    <v-card-text>
                        <span>
                            {{ $store.getters.t("address") }}:
                            {{ company.address }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("admin_email") }}:
                            {{ company.administration_email }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("contact_email") }}:
                            {{ company.contact_email }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("location") }}:
                            {{ company.location }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("language") }}:
                            {{ company.meta.language }}
                        </span>
                        <br />
                    </v-card-text>
                    <v-card-actions class="mt-7" v-if="admin_permission">
                        <v-spacer></v-spacer>
                        <ButtonCompanyInfoEdit />
                        <ButtonCompanyViewerSettings />
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col>
                <v-card outlined class="fill-height">
                    <v-card-title>
                        {{ $store.getters.t("licenses") }}
                    </v-card-title>
                    <v-card-text>
                        <span>
                            {{ $store.getters.t("max_woodwall_tekla_licenses") }}: {{ company.max_tekla_licenses }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("max_tekla_bricks_licenses") }}: {{ company.max_tekla_bricks_licenses }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("max_tekla_roofs_licenses") }}: {{ company.max_tekla_roofs_licenses }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("max_tekla_sidings_licenses") }}: {{ company.max_tekla_sidings_licenses }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("max_tekla_jsonconverter_licenses") }}: {{
                                company.max_tekla_jsonconverter_licenses }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("max_android_licenses") }}
                            :
                            {{ company.max_android_licenses }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("m_core") }}
                            :
                            <IconLicense :value="company.m_core" />
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("m_bom") }} :
                            <IconLicense :value="company.m_bom" />
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("module_delivery") }} :
                            <IconLicense :value="company.module_delivery" />
                        </span>
                        <br />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <CardCompanyStyles />
            </v-col>
        </v-row>
        <v-row v-if="admin_permission">
            <v-col>
                <TableProjects />
            </v-col>
        </v-row>
        <v-row v-if="admin_permission">
            <v-col>
                <TableStandardLibraries />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableUsers />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableAPIAccounts />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableWebhooks />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableProductionLines />
            </v-col>
        </v-row>
        <!-- <v-row v-if="admin_permission">
            <v-col>
                <TableTeklaLicenses />
            </v-col>
        </v-row> -->
        <v-row v-if="admin_permission">
            <v-col>
                <TableWorkCalendar :company="company" />
            </v-col>
        </v-row>
        <v-row v-if="admin_permission">
            <v-col>
                <TableIFCPSs :company="company" />
            </v-col>
        </v-row>
        <v-row v-if="admin_permission">
            <v-col>
                <TableBTLPSs :company="company" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableMachines :company="company" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TableProductionLines from "/src/components/company/TableProductionLines";
import TableProjects from "/src/components/company/TableProjects";
import TableStandardLibraries from "/src/components/company/TableStandardLibraries";
import ButtonCompanyInfoEdit from "/src/components/company/ButtonCompanyInfoEdit";
import ButtonCompanyViewerSettings from "/src/components/company/ButtonCompanyViewerSettings";
// import TableTeklaLicenses from "/src/components/company/TableTeklaLicenses";
import TableUsers from "/src/components/company/TableUsers";
import IconLicense from "/src/components/company/IconLicense";
import TableWorkCalendar from "/src/components/company/TableWorkCalendar";
import TableIFCPSs from "/src/components/ifcps/TableIFCPSs";
import TableBTLPSs from "/src/components/ifcps/TableBTLPSs";
import TableMachines from "/src/components/company/TableMachines";
import CardCompanyStyles from "/src/components/company/CardCompanyStyles";
import TableAPIAccounts from "/src/components/company/TableAPIAccounts";
import TableWebhooks from "/src/components/company/TableWebhooks";
export default {
    components: {
        TableProductionLines,
        TableProjects,
        TableStandardLibraries,
        ButtonCompanyInfoEdit,
        ButtonCompanyViewerSettings,
        // TableTeklaLicenses,
        TableUsers,
        IconLicense,
        TableWorkCalendar,
        CardCompanyStyles,
        TableIFCPSs,
        TableBTLPSs,
        TableMachines,
        TableAPIAccounts,
        TableWebhooks,
    },
    data: () => ({}),
    computed: {
        company() {
            return this.$store.state.v2.company.company;
        },
        admin_permission() {
            return this.$store.state.v2.session.user_object.auth?.company_admin;
        },
    },
    mounted() {
        // this.$store.dispatch("v2/company/loadLicenses");
        this.$store.dispatch("v2/company/loadUsers");
        this.$store.dispatch("v2/company/loadAPIAccounts");
    },
};
</script>