<template>
    <v-form @keyup.enter.native="$emit('save')" ref="form">
        <v-row>
            <v-col>
                <PitTextField label="Name" v-model="form.name">
                </PitTextField>
            </v-col>
            <v-col>
                <SelectPlateSize v-model="form.size" />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field label="Width" v-model="form.width" outlined dense>
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field label="Length" v-model="form.length" outlined dense>
                </v-text-field>
            </v-col>
        </v-row>

    </v-form>
</template>

<script>
import PitTextField from "../fields/PitTextField.vue";
import SelectPlateSize from "../logistics/SelectPlateSize.vue";

export default {
    props: ["value"],
    components: { PitTextField, SelectPlateSize },
    data: () => ({
        form: {},
    }),
    computed: {
    },
    watch: {
        value: {
            handler: function (v) {
                this.form = v;
            },
            immediate: true,
        },
        form: {
            handler: function (f) {
                this.$emit("input", f);
            },
            deep: true,
        },
    },
    methods: {
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
    },
    mounted() {
    },
};
</script>
