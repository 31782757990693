<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-card-title>
                            {{ $store.getters.t("designs") }}
                        </v-card-title>
                        <v-card-text>
                            <v-text-field label="Search designs" outlined v-model="search" clearable></v-text-field>
                            <SelectConstructible />
                            <TableDesigns :search="search" :mode="mode" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- Drawer -->
        <DrawerDesign />
    </div>
</template>

<script>
import TableDesigns from "/src/components/designs/TableDesigns";
import SelectConstructible from "/src/components/designs/SelectConstructible.vue";
import DrawerDesign from "/src/components/designs/DrawerDesign.vue";
export default {
    components: {
        TableDesigns,
        SelectConstructible,
        DrawerDesign
    },
    data: () => ({ search: "" }),
    computed: {
        mode() {
            return this.$route.query.level
        }
    }
};
</script>
