<template>
    <v-dialog v-model="dialog" width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on">
                <v-icon color="white" left>
                    mdi-atom-variant {{ spin_icon ? "mdi-spin" : "" }}
                </v-icon>
                {{ $store.getters.t("operations") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ $store.getters.t("project_operations") }}:
                {{ project_name }}
                <v-spacer></v-spacer>
                <MenuOperations :project="project" />
            </v-card-title>
            <v-card-text>
                <div>
                    <div class="mb-4">
                        {{ $store.getters.t("operations_dialog_help_text") }}
                    </div>
                    <v-sheet outlined rounded class="pa-2 my-3">
                        <BarOperationActions :loading="loading_jobs.includes('split_ifc')" job_name="split_ifc" :disabled="spin_icon"
                            :job="split_ifc_job" @launch="launchOperation('split_ifc')" />
                        <!-- FORM -->
                        <v-form ref="split_ifc_form">
                            <v-checkbox dense class="mt-0" v-model="split_project_ifcs" :label="$store.getters.t('split_project_ifcs')
                                " />
                            <v-checkbox dense class="mt-0" v-model="split_module_ifcs" :label="$store.getters.t('split_module_ifcs')
                                " />
                        </v-form>
                        <ProgressOperation :job="split_ifc_job" />
                    </v-sheet>
                    <v-sheet outlined rounded class="pa-2 my-3">
                        <BarOperationActions :loading="loading_jobs.includes('generate_designs')" job_name="generate_designs" :disabled="spin_icon"
                            :job="generate_designs_job" @launch="launchOperation('generate_designs')" />
                        <!-- FORM -->
                        <v-form ref="generate_designs_form">
                            <v-checkbox dense class="mt-0" v-model="keep_revisions" :label="$store.getters.t('keep_current_revisions')
                                " />
                            <v-checkbox dense class="mt-0" v-model="generate_in_batch" :label="$store.getters.t('generate_in_batch_phase')
                                " />
                            <div class="d-flex flex-row" v-if="generate_in_batch">
                                <ComboPhaseName v-model="phase" />
                                <ComboBatchName v-model="batch" :phase="phase" />
                            </div>
                        </v-form>
                        <ProgressOperation :job="generate_designs_job" />
                    </v-sheet>
                    <v-sheet outlined rounded class="pa-2 my-3">
                        <BarOperationActions :loading="loading_jobs.includes('detect_details')" job_name="detect_details" :disabled="spin_icon"
                            :job="detect_details_job" @launch="launchOperation('detect_details')" />
                        <!-- FORM -->
                        <v-form ref="detect_details_form">
                            <v-checkbox dense class="mt-0" v-model="ifc" :label="$store.getters.t('scan_ifc')" />
                            <v-checkbox dense class="mt-0" v-model="pdf" :label="$store.getters.t('scan_pdf')" />
                        </v-form>
                        <ProgressOperation :job="detect_details_job" />
                    </v-sheet>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :loading="loading_jobs.includes('all_operations')" :disabled="spin_icon || loading_jobs.includes('all_operations')
                    " @click="launchOperation('all_operations')">
                    {{ $store.getters.t("launch_all") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
import ComboPhaseName from "/src/components/fields/ComboPhaseName";
import ComboBatchName from "/src/components/fields/ComboBatchName";
import MenuOperations from "./MenuOperations";
import BarOperationActions from "./BarOperationActions";
import ProgressOperation from "./ProgressOperation";
BarOperationActions
ProgressOperation

const DEFAULT_JOB = {
    perc: 100,
    last_run: "never",
    status: "never",
}

export default {
    components: {
        ComboPhaseName,
        ComboBatchName,
        MenuOperations,
        BarOperationActions,
        ProgressOperation,
    },
    props: ["project"],
    data: () => ({
        dialog: false,
        loading_jobs: [],
        timeout: null,
        // meta job options
        split_project_ifcs: true,
        split_module_ifcs: false,
        keep_revisions: false,
        generate_in_batch: false,
        batch: "",
        phase: "",
        ifc: false,
        pdf: true,
    }),
    computed: {
        project_name() {
            return this.project?.name || "---";
        },
        jobs() {
            return this.project?.meta?.jobs || {}
        },
        split_ifc_job() {
            return this.jobs["split_ifc"] || DEFAULT_JOB
        },
        generate_designs_job() {
            return this.jobs["generate_designs"] || DEFAULT_JOB
        },
        detect_details_job() {
            return this.jobs["detect_details"] || DEFAULT_JOB
        },
        spin_icon() {
            return this.loading_jobs.length != 0 || Object.keys(this.jobs).some(k => this.jobs[k]?.perc != 100)
        },
    },
    methods: {
        submit_test_form() {
        },
        launchOperation(job) {
            _.defer(this.undeferedLaunchOperation, job);
        },
        undeferedLaunchOperation(job) {
            if (["all_operations", "generate_designs"].includes(job)) {
                const valid = this.$refs.generate_designs_form.validate()
                console.log(`[i] ${job} form is ${valid ? "VALID" : "NOT VALID"}`)
                if (!valid) return
            } else {
                console.log("[i] Validation not needed")
            }

            // To mark it loading (this is for button only)
            this.loading_jobs.push(job);

            let payload = {
                project_uuid: this.project.uuid,
                job,
                split_project_ifcs: this.split_project_ifcs,
                split_module_ifcs: this.split_module_ifcs,
                keep_revisions: this.keep_revisions,
                generate_in_batch: this.generate_in_batch,
                batch: this.batch,
                phase: this.phase,
                ifc: this.ifc,
                pdf: this.pdf,
            };

            // Call to endpoint
            this.$store
                .dispatch("v2/selected/launchOperationJob", payload)
                .then(() => {
                    // This to mark not as loading (this is for button only)
                    this.loading_jobs = this.loading_jobs.filter(
                        (j) => j != job
                    );
                });
        },
        refreshProjectMeta() {
            this.$store.dispatch("v2/selected/reloadProjectMeta").then(() => {
                console.log(
                    "[i] Waiting 30 seconds to refresh project meta again."
                );
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.refreshProjectMeta, 30000);
            });
        },
    },
    mounted() {
        this.timeout = setTimeout(this.refreshProjectMeta, 30000);
    },
    beforeDestroy() {
        console.log("killing operations timeout", clearTimeout(this.timeout));
    },
};
</script>