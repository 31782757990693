<template>
    <v-select outlined dense hide-hints :clearable="clearable" v-model="selected_line" :items="production_lines"
        :disabled="disabled" :label="$store.getters.t('production_line')" @change="$emit('change')"></v-select>
</template>

<script>
export default {
    props: {
        value: String,
        disabled: Boolean,
        clearable: Boolean,
        selectables: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        selected_line: "",
    }),
    computed: {
        production_lines() {
            return this.$store.state.v2.factories.production_lines
                .filter(
                    pl => this.selectables.includes(pl.uuid) || this.selectables.length === 0
                ).map(
                    (ps) => ({
                        text: ps.name,
                        value: ps.uuid,
                    })
                );
        },
    },
    watch: {
        selected_line(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_line = v;
            },
            immediate: true,
        },
    },
};
</script>