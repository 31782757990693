// THIS MODULE DOES NOT HOLD ANY BACKEND DATA
// EVERYTHING IS VIEWER FRONTEND STATE
// MAIN SOURCE OF SELECTED DATA IS selected.py MODULE
import { zones_from_viewer_bom } from "./viewer_helpers"
import { nameSort } from "/src/utils/basics";
import _ from "lodash"

export default {
    namespaced: true,
    state: {
        // Mode
        designs_mode: false,
        pdf_mode: true,
        right_panel_mode: "details",
        level: "assembly",  // part, assembly, module, project (subassembly is also assembly)

        // Viewer state
        left_panel_dwg_number: 1,
        right_panel_cf_uuid: null,
        selected_parts_ifc_ids: [],
        selected_zones: [],

        // IFC.JS DATA
        selected_part_props: {},
        bom_loading_status: false,
        bom: [],
    },
    mutations: {
        setDesignsMode: (state, dm) => state.designs_mode = dm,
        set3DMode: state => state.pdf_mode = false,
        setPDFMode: state => state.pdf_mode = true,
        setLevel: (state, level) => state.level = level,
        setLeftPanelDWGNumber: (state, idx) => state.left_panel_dwg_number = idx,
        setRightPanelMode: (state, data) => state.right_panel_mode = data,
        setRightPanelCFUuid: (state, uuid) => state.right_panel_cf_uuid = uuid,
        setSelectedPartsUuids: (state, data) => state.selected_parts_ifc_ids = data.filter(v => !!v),
        setSelectedPartProps: (state, data) => state.selected_part_props = data,
        setSelectedZones: (state, zones) => state.selected_zones = zones,
        setBomLoadingStatus: (state, status) => state.bom_loading_status = status,
        setBom: (state, bom) => state.bom = bom
    },
    actions: {
        loadBom(context) {
            context.commit("setBomLoadingStatus", true)
            // Pick cloudfile
            let files = []
            if (context.rootGetters["v2/selected/isSubassd"]) {
                files = context.getters.parentHeavyMetaPackage?.files || []
            } else {
                files = context.getters.heavyMetaPackage?.files || []
            }
            let cf = files.reduce(function (acc, obj) {
                if (obj.name == "viewer_bom" && obj.meta?.version > (acc?.meta?.version || -1)) {
                    return obj;
                } else {
                    return acc;
                }
            }, null);

            if (!cf) return

            // Download JSON
            console.log("[i] Downloading VIEWER BOM JSON.")
            fetch(cf.presigned_url)
                .then(r => r.json())
                .then(bom => context.commit("setBom", bom))

            context.commit("setBomLoadingStatus", false)
        }
    },
    getters: {
        resPackage: (state, getters, rootState, rootGetters) => {
            // THIS IS NOT OK FOR level "project" BECAUSE YOU HAVE ALL FILES
            // AND YOU NEED TO FILTER TO HAVE ONLY "PROJECT_" FILES
            let pck = rootGetters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "resources",
                level: state.level + "_design"
            })[0]
            return pck
        },
        heavyMetaPackage: (state, getters, rootState, rootGetters) => {
            let pck = rootGetters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "heavy_meta",
                level: state.level + "_design"
            })[0]
            return pck
        },
        parentResPackage: (state, getters, rootState, rootGetters) => {
            let pck = rootGetters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "resources",
                level: "parent_assembly_design"
            })[0]
            return pck
        },
        parentHeavyMetaPackage: (state, getters, rootState, rootGetters) => {
            let pck = rootGetters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "heavy_meta",
                level: "parent_assembly_design"
            })[0]
            return pck
        },
        allPackages: (state, getters, rootState, rootGetters) => {
            return rootGetters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: null,
                level: state.level + "_design"
            })
        },
        getDrawings: (state, getters) => {
            let files = getters.resPackage?.files || []
            let drawings = files.filter(cf => cf.meta.section == state.level + "_drawing")
            let dwg_number_groups = _.groupBy(drawings, "meta.dwg_number")
            let filtered_dwgs = _.map(dwg_number_groups, group => _.maxBy(group, 'meta.version'))
            return filtered_dwgs
        },
        getIFC: (state, getters, rootState, rootGetters) => {
            let files = []
            if (rootGetters["v2/selected/isSubassd"]) files = getters.parentResPackage?.files || []
            else files = getters.resPackage?.files || []
            return files.find(cf => cf.meta.section == state.level + "_ifc")
        },
        module_layers(state, getters, rootState, rootGetters) {
            let design = rootGetters["v2/selected/annotatedRouteDesign"]
            let layers = [...design?.meta.assd_names]
            layers.sort(nameSort)
            if (!layers) layers = ["main"]
            return layers
        },
        zones(state, getters, rootState, rootGetters) {
            let layers
            let design = rootGetters["v2/selected/annotatedRouteDesign"]
            if (rootGetters["v2/selected/isSubassd"]) {
                layers = zones_from_viewer_bom(
                    state.bom,
                    design?.name
                )
                layers.unshift("PARENT")
            } else {
                layers = zones_from_viewer_bom(state.bom)
            }
            return layers
        },
    }
}