<template>
    <div>
        <h3>{{ $store.getters.t("times") }}</h3>
        <div class="d-flex">
            <v-chip class="ma-2" color="info" outlined>
                <v-icon left> mdi-timer-outline </v-icon>
                {{ $store.getters.t("real_hours") }}: {{ real_hours }}h
            </v-chip>
            <v-chip
                class="ma-2"
                :disabled="use_ct_schedule"
                color="success"
                outlined
            >
                <v-icon left> mdi-clipboard-text-clock </v-icon>
                {{ $store.getters.t("fc_hours") }}: {{ fc_hours }}h
            </v-chip>
        </div>
        <div class="d-flex mt-2">
            <v-text-field
                clearable
                class="ma-0 mt-1"
                max-width="50"
                dense
                outlined
                :label="$store.getters.t('company_calculated_hours')"
                v-model="company_calculated_hours"
                type="number"
                :loading="loading"
            />
            <v-checkbox
                class="ma-0 ml-2"
                dense
                :label="$store.getters.t('use_for_schedule')"
                v-model="use_ct_schedule"
                :loading="loading"
            />
        </div>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus";
import _ from "lodash";
import { to_hours, to_minutes } from "./timesteps";
export default {
    props: ["batch", "item"],
    data: () => ({
        loading: false,
        company_calculated_hours: "",
        use_ct_schedule: false,
    }),
    computed: {
        real_hours() {
            return to_hours(this.item.real_time);
        },
        fc_hours() {
            return to_hours(this.item.fc_time);
        },
    },
    watch: {
        batch: {
            handler: function (b) {
                if (!b) return;
                this.company_calculated_hours = to_hours(
                    b.company_calculated_time
                );
                this.use_ct_schedule = b.use_ct_schedule;
            },
            immediate: true,
        },
        company_calculated_hours() {
            this.update_batch();
        },
        use_ct_schedule() {
            this.update_batch();
        },
    },
    methods: {
        update_batch: _.debounce(function () {
            if (
                this.use_ct_schedule == this.batch?.use_ct_schedule &&
                this.company_calculated_hours ==
                    to_hours(this.batch?.company_calculated_time)
            )
                return;
            this.patch_batch();
        }, 1000),
        patch_batch() {
            let new_batch = {
                ...this.batch,
                use_ct_schedule: this.use_ct_schedule,
            };
            new_batch.company_calculated_time = to_minutes(
                this.company_calculated_hours
            );
            this.loading = true;
            this.$store
                .dispatch("v2/constructibles/putBatch", new_batch)
                .then(() =>
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: `Batch number updated.`,
                        color: "success",
                    })
                )
                .finally(() => (this.loading = false));
        },
    },
};
</script>