<template>
    <td v-on:mouseenter="onHover(_uid)" v-on:mouseleave="onHover()">
        <v-list-item-title v-if="!hover">
            <span class="title ma-2 grey--text"> {{ count }} </span>
        </v-list-item-title>
        <v-list-item-title v-else-if="oldrev">
            <v-btn icon disabled>
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <span class="title ma-2 grey--text"> {{ count }} </span>
            <v-btn icon disabled>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-list-item-title>
        <div v-else>
            <v-btn v-if="!edit_mode" color="primary" icon @click="$emit('sub_one')">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <span class="title ma-2" @keyup.enter="save" @click="edit">
                <input ref="countInput" @focus="$event.target.select()" @blur="save" v-model="internal_value"
                    v-if="edit_mode" />
                <span class="primary--text" v-else> {{ value }} </span>
            </span>
            <v-btn v-if="!edit_mode" color="primary" icon @click="$emit('sum_one')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
    </td>
</template>

<style lang="scss" scoped>
input {
    width: 60px;
    border: 1px solid rgb(143, 143, 143);
    border-radius: 3px;
    color: rgb(32, 88, 229);
}
</style>

<script>
export default {
    props: ["value", "count", "oldrev"],
    data: () => ({ edit_mode: false, internal_value: "" }),
    computed: {
        hover() {
            return this.$store.state.v2.quantities.hover_cell == this._uid
        },
    },
    watch: {
        edit_mode(em) {
            if (em) this.$nextTick(() => this.$refs.countInput.focus())
        }
    },
    methods: {
        save() {
            let count = Number(this.internal_value);
            if (count < 0) count = 0
            console.log(count)
            this.$emit("update_count", count);
            this.edit_mode = false;
        },
        edit() {
            this.internal_value = this.value;
            this.edit_mode = true;
        },
        onHover(id) {
            this.$store.commit("v2/quantities/setHoverCell", id)
        }
    },
};
</script>