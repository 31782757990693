<template>
    <div>
        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height" v-if="mod">
                    <v-card-title v-if="mod_uuid">
                        {{ $store.getters.t("module") }}:
                        {{ mod_uuid.substring(0, 8) }}
                    </v-card-title>

                    <v-card-text v-if="design">
                        {{ $store.getters.t("design") }}: {{ design.name }}
                        <br />
                        {{ $store.getters.t("priority_in_batch") }}:
                        {{ mod.priority }} <br />
                        {{ $store.getters.t("batch") }}: {{ batch_name }} <br />
                        {{ $store.getters.t("phase") }}:
                        {{ phase_name }}
                        <br />
                        {{ $store.getters.t("status") }}: {{ mod.status }}
                        <br />
                        <template v-for="(v, k) in mod.stations_status">
                            <span :key="k + '_sta'"> {{ k }}: {{ v }} </span>
                            <br :key="k + '_sta_br'" />
                        </template>
                    </v-card-text>

                    <v-card-text v-else>
                        <v-skeleton-loader type="list-item-three-line@2"></v-skeleton-loader>
                    </v-card-text>
                    
                    <v-card-actions v-if="mod">
                        <v-spacer></v-spacer>
                        <v-btn color="accent" :to="'/projects/' +
                            project_uuid +
                            '/designs/assembly/' +
                            mod.design
                            " text>
                            {{ design.name }}
                        </v-btn>
                        <v-btn text :to="'/viewer/assembly/' + mod_uuid">
                            <v-icon left> mdi-map-legend </v-icon>
                            {{
                                $store.getters.t("view_meaning_action_of_view")
                            }}
                            {{ $store.getters.t("assembly") }}
                        </v-btn>
                        <ButtonJSONRender v-if="mod_fc" title="Forecast times" :json="mod_fc.times" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ChecklistDataCard :assembly="mod" />
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height d-flex flex-column">
                    <v-card-title>
                        {{ $store.getters.t("client_meta") }}
                    </v-card-title>
                    <v-card-text v-if="Object.keys(mod.client_meta).length === 0">
                        {{ $store.getters.t("missing_metadata") }}
                    </v-card-text>
                    <v-card-text v-else>
                        <JSONRender :json="mod.client_meta" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ButtonDeleteActuals :uuids="[mod_uuid]" @deleted="$router.go(-1)" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ChecklistDataCard from "/src/components/constructibles/ChecklistDataCard";
import ButtonDeleteActuals from "/src/components/constructibles/ButtonDeleteActuals";
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender";
import JSONRender from "/src/components/renderers/JSONRender.vue";

export default {
    components: {
        ChecklistDataCard,
        ButtonDeleteActuals,
        ButtonJSONRender,
        JSONRender
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        project_uuid() {
            return this.$route.params?.project_uuid;
        },
        mod_uuid() {
            return this.$route.params?.mod_uuid;
        },
        mod() {
            return this.$store.state.v2.selected.selected_mod
        },
        mod_fc() {
            // To be updated when overview
            return {}
        },
        design() {
            return this.$store.state.v2.selected.selected_modd
        },
        batch() {
            return this.$store.state.v2.constructibles.batches.find(
                (b) => b.uuid == this.mod?.batch
            );
        },
        batch_name() {
            return this.batch?.name;
        },
        phase_name() {
            return this.batch?.phase;
        },
    },
    methods: {
        deleted() {
            this.$router.go(-1);
        },
    },
};
</script>