<template>
    <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
                :disabled="!can_select_station"
            >
                <v-list-item-icon>
                    <v-icon> mdi-toolbox </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("change_station") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card :loading="loading ? 'accent' : false">
            <v-card-title class="headline">
                {{ $store.getters.t("change_station") }}
            </v-card-title>
            <v-card-subtitle>
                {{ $store.getters.t("change_station_help_text") }}
            </v-card-subtitle>
            <v-card-text v-if="production_lines.length == 0 && loading">
                <v-skeleton-loader type="list-item-avatar-three-line@2">
                </v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <h3 v-if="i_can_see_other_lines">
                    {{ $store.getters.t("no_production_line") }}
                </h3>
                <div v-if="i_can_see_other_lines" class="d-flex ma-3">
                    <v-card
                        outlined
                        class="ma-1"
                        :color="selected_station == null ? 'accent' : ''"
                        @click="clean_station"
                    >
                        <v-card-title>
                            <v-icon left> mdi-close </v-icon>
                            {{ $store.getters.t("no_station") }}
                        </v-card-title>
                    </v-card>
                </div>
                <div v-for="pl in production_lines" :key="pl.uuid">
                    <h3>{{ pl.name }}</h3>
                    <div class="d-flex ma-3">
                        <v-card
                            v-for="sta in pl.stations"
                            :key="sta.uuid"
                            @click="change_station(sta)"
                            outlined
                            class="ma-1"
                            :color="
                                selected_station == sta.uuid ? 'accent' : ''
                            "
                            width="200"
                        >
                            <v-card-title class="text-truncate">
                                <v-icon left> mdi-toolbox </v-icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            {{ sta.name }}
                                        </span>
                                    </template>
                                    {{ sta.name }}
                                </v-tooltip>
                            </v-card-title>
                            <v-card-text>
                                <div v-if="sta.zones.length != 0">
                                    <v-icon small>
                                        mdi-layers-triple-outline
                                    </v-icon>
                                    Zones: {{ sta.zones.join(", ") }}
                                </div>
                                <div v-if="sta.machine_setup">
                                    <v-icon small>
                                        mdi-robot-industrial
                                    </v-icon>
                                    {{ getMachineName(sta.machine_setup) }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
import bus from "/src/utils/event_bus";
export default {
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {
        can_select_station() {
            return this.$store.state.v2.session.user_object?.auth
                ?.can_select_station;
        },
        production_lines() {
            let pls = this.$store.state.v2.factories.production_lines;
            // Filter visibles
            pls = pls.filter(
                (pl) => pl.uuid == this.my_line || this.i_can_see_other_lines
            );
            // Order stations
            pls = pls.map((pl) => ({
                ...pl,
                stations: _.orderBy(pl.stations, "order"),
            }));
            return _.orderBy(pls, "name");
        },
        my_line() {
            return this.$store.getters["v2/session/my_line_uuid"];
        },
        selected_station() {
            return this.$store.state.v2.session.user_object.auth
                .selected_production_station;
        },
        i_can_see_other_lines() {
            return (
                this.$store.state.v2.session.user_object?.auth
                    ?.company_admin ||
                this.$store.state.v2.session.user_object?.auth
                    ?.company_manager
            );
        },
        // station_list() {
        //     let stations = [];
        //     this.production_lines.forEach((pl) => {
        //         pl.stations.forEach((sta) =>
        //             stations.push({
        //                 ...sta,
        //                 production_line: pl.uuid,
        //                 production_line_name: pl.name,
        //             })
        //         );
        //     });
        //     if (this.i_can_see_other_lines) {
        //         return stations;
        //     } else {
        //         return stations.filter(
        //             (sta) => sta.production_line == this.my_line
        //         );
        //     }
        // },
    },
    methods: {
        getMachineName(machine_setup) {
            const mach = this.$store.state.v2.factories.machines.find(
                (m) => m.uuid == machine_setup
            );
            return mach?.name || "machine";
        },
        change_station(sta) {
            this.loading = true;
            bus.$emit("back_event", {
                event: "change_station",
                meta: { station: sta.uuid },
            });
            this.$store
                .dispatch("v2/session/postUserSelectedStation", sta.uuid)
                .then(() => {
                    this.dialog = false;
                    this.loading = false;
                });
        },
        clean_station() {
            bus.$emit("back_event", {
                event: "change_station",
                meta: { station: "" },
            });
            this.loading = true;
            this.$store
                .dispatch("v2/session/postUserSelectedStation", "")
                .then(() => {
                    this.dialog = false;
                    this.loading = false;
                });
        },
    },
    watch: {
        dialog(d) {
            if (d) {
                this.loading = true;
                this.$store
                    .dispatch("v2/factories/loadProductionLines")
                    .then(() => (this.loading = false));
            } else {
                this.$emit("close");
            }
        },
    },
};
</script>