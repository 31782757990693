<template>
    <v-select outlined dense class="mx-1" v-model="scale" :items="scales"
        :label="$store.getters.t('PDF Rendering Scale')">
    </v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        scale: 2,
    }),
    computed: {
        scales() {
            return [
                {
                    text: this.$store.getters.t("pdf_scale_025"),
                    value: 0.25,
                },
                {
                    text: this.$store.getters.t("pdf_scale_05"),
                    value: 0.5,
                },
                {
                    text: this.$store.getters.t("pdf_scale_1"),
                    value: 1,
                },
                {
                    text: this.$store.getters.t("pdf_scale_2"),
                    value: 2,
                },
                {
                    text: this.$store.getters.t("pdf_scale_4"),
                    value: 4,
                },
                {
                    text: this.$store.getters.t("pdf_scale_8"),
                    value: 8,
                },
            ];
        },
    },
    watch: {
        scale(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.scale = v;
            },
            immediate: true,
        },
    },
};
</script>