export const SECTIONS = [
    { header: "Assembly files" },
    { text: "assembly_ifcs", value: "assembly_ifc", level: "assembly" },
    { text: "assembly_drawings", value: "assembly_drawing", level: "assembly" },
    { text: "machine_files", value: "assembly_alt_model", level: "assembly" },
    { divider: true },
    { header: "Module files" },
    { text: "module_ifcs", value: "module_ifc", level: "module" },
    { text: "module_drawings", value: "module_drawing", level: "module" },
    { divider: true },
    { header: "Project wide files" },
    { text: "project_ifcs", value: "project_ifc" },
    { text: "external_ifcs", value: "external_ifc" },
    { text: "project_drawings", value: "project_drawing" },
    { divider: true },
    { header: "Details and documentation" },
    { text: "details_long", value: "detail" },
    { text: "docs_long", value: "doc" },
    { text: "spec_long", value: "spec" },
]
