<template>
    <v-select clearable outlined class="mx-1" v-model="tipo" :items="tipos" :multiple="multiple" :dense="dense"
        :rules="rules" :label="$store.getters.t('tipo')">
    </v-select>
</template>

<script>
import {
    MODULE_TIPOS,
    ASSEMBLY_TIPOS,
    PART_TIPOS
} from "./tipos.js"

//    tipo is the type of design, please use following:
//    module: module_unit
//    assembly: steel_cage, timber_panel, concrete_slab, steel_panel
//    part: (this is the wrapper part CATEGORY prop)


export default {
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        level: {
            type: String,
            default: "all"  // all means assembly and module
        },
        value: {
            type: [String, Array],
        }
    },
    data: () => ({ tipo: "timber_panel" }),
    computed: {
        tipos() {
            if (this.level == "void") return []
            let tipos = []
            if (["module", "all"].includes(this.level)) tipos.push(...MODULE_TIPOS)
            if (["assembly", "all"].includes(this.level)) tipos.push(...ASSEMBLY_TIPOS)
            if (this.level == "part") tipos.push(...PART_TIPOS)
            return tipos.map(t => ({ text: this.$store.getters.t(t), value: t }))
        },
        rules() {
            if (this.required)
                return [(v) => v && v.length != 0 || 'At least one tipo is required']
            else return []
        }
    },
    watch: {
        tipo(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.tipo = v;
            },
            immediate: true,
        },
    },
}
</script>