<template>
    <section id="branches">
        <div class="d-flex">
            <h2 class="my-2">
                {{ $store.getters.t("levels_detection") }}
            </h2>
        </div>
        <v-form ref="form">
            <template v-for="(setup, level) in level_setup">
                <v-divider class="mt-2" inset :key="level + '_divider'">
                </v-divider>
                <FormIFCPSLevelSetupRow v-model="level_setup[level]" :level="level" :key="level" />
            </template>
        </v-form>
    </section>
</template>

<script>
import {
    LEVELS_FOR_SETUP,
    LEVEL_DETECTION,
    IFC_PROP_TYPES,
    IFC_CLASS_TYPES
} from "./definitions";
import FormIFCPSLevelSetupRow from "./FormIFCPSLevelSetupRow.vue"
export default {
    components: { FormIFCPSLevelSetupRow },
    props: ["value"],
    data: () => ({
        loading: false,
        level_setup: {
        },
        LEVELS_FOR_SETUP,
        LEVEL_DETECTION,
        IFC_PROP_TYPES,
        IFC_CLASS_TYPES,
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
    },
    watch: {
        value: {
            handler: function (level_setup) {
                if (level_setup) this.level_setup = level_setup;
                LEVELS_FOR_SETUP.forEach(level => {
                    if (!(level in this.level_setup)) this.level_setup[level] = {}
                })
            },
            immediate: true,
        },
        level_setup: {
            handler: function (level_setup) {
                this.$emit("input", level_setup);
            },
            deep: true,
        },
    },
    methods: {
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
    },
};
</script>