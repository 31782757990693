<template>
    <span class="grey--text caption" v-if="value">
        <br />
        {{ value.toFixed(2) }}
        m²
    </span>
    <span v-else class="accent--text caption">
        <br />
        no size
    </span>
</template>


<script>
export default {
    props: ["value"],
};
</script>