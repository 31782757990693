<template>
    <v-navigation-drawer :value="!!ass_uuid || !!mod_uuid" absolute right width="600">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title> Constructible Detail </v-list-item-title>
                </v-list-item-content>
                <v-btn icon :to="{ name: 'constructibles_manager', query: $route.query }">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
        <v-divider></v-divider>

        <!-- Drawers -->
        <DrawerAssemblyConstructiblesManager v-if="!!ass_uuid" />
        <DrawerModuleConstructiblesManager v-if="!!mod_uuid" />

    </v-navigation-drawer>
</template>

<script>
import DrawerAssemblyConstructiblesManager from "./DrawerAssemblyConstructiblesManager.vue";
import DrawerModuleConstructiblesManager from "./DrawerModuleConstructiblesManager.vue";
import { backend_api_v2 } from "/src/utils/apiv2";

export default {
    components: {
        DrawerAssemblyConstructiblesManager,
        DrawerModuleConstructiblesManager,
    },
    data: () => ({
        batch_loading: false,
        batch: null,
    }),
    computed: {
        ass_uuid() {
            return this.$route.params.ass_uuid;
        },
        mod_uuid() {
            return this.$route.params.mod_uuid;
        },
        ass() {
            return this.$store.state.v2.selected.selected_ass
        },
        design() {
            return this.$store.state.v2.selected.selected_assd
        },
        batch_uuid() {
            return this.ass?.batch
        },
        batch_name() {
            return this.batch?.name
        },
        phase_name() {
            return this.batch?.phase
        },
        ass_fc() {
            // To be updated when overview
            return {}
        },
    },
    watch: {
        batch_uuid: {
            handler: function (buuid) {
                if (buuid) {
                    this.batch = null;
                    this.batch_loading = true;

                    backend_api_v2
                        .get(`/constructibles/batches/${buuid}/`)
                        .then(({ data }) => {
                            this.batch_loading = false;
                            this.batch = { ...data };
                        })
                        .catch((e) => {
                            this.batch_loading = false;
                            console.log(`Cant load batch: ${e}`);
                        });
                }
            },
            immediate: true,
        },
    },
};
</script>