<template>
    <div>
        <PitTextField :disabled="phase_edit_loading" :loading="phase_edit_loading" v-if="edit_phase_mode" label="Phase name"
            outlined v-model="edit_phase_name" append-icon="mdi-check" @enter="save_phase" @click_append="save_phase"
            @esc="edit_phase_mode = false" :error="phase_edit_is_duplicate">
        </PitTextField>
        <v-menu bottom right v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn x-large block class="text-transform-none" text v-bind="attrs" v-on="on">{{ phase }}</v-btn>
            </template>
            <v-list>
                <v-list-item @click="edit_phase">
                    <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("change_phase_name") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="numbers_dialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-numeric-3-box-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("set_multiple_numbers") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clone_dialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("clone_phase") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item @click="delete_dialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-delete-forever</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("delete_phase") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- MULTIPLE NUMBERS DIALOG -->
        <v-dialog v-model="numbers_dialog" max-width="600">
            <v-card @keyup.enter="set_multiple_numbers">
                <v-card-title class="headline">
                    {{ $store.getters.t("set_multiple_numbers") }}
                </v-card-title>
                <v-card-text>
                    {{ $store.getters.t("multiple_numbers_warning") }}
                    <br /><br />
                    <div class="d-flex flex-row">
                        <v-text-field type="number" outlined dense v-model="multi_count" :label="$store.getters.t('count')">
                        </v-text-field>
                        <v-select class="mx-1" outlined dense multiple v-model="selected_designs" :items="designs_list"
                            :label="$store.getters.t('designs')">
                        </v-select>
                        <v-btn icon @click="select_all_designs">
                            <v-icon> mdi-checkbox-multiple-marked </v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="set_multiple_numbers" color="error"> {{ $store.getters.t("set_numbers") }} </v-btn>
                    <v-btn @click="numbers_dialog = false" color="secondary"> {{ $store.getters.t("close") }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- CLONE DIALOG -->
        <v-dialog v-model="clone_dialog" max-width="600">
            <v-card @keyup.enter="clone_phase">
                <v-card-title class="headline">
                    {{ $store.getters.t("clone_phase") }}
                </v-card-title>
                <v-card-text v-if="clone_status_text">
                    {{ this.clone_status_text }}
                </v-card-text>
                <v-card-text v-else>
                    {{ $store.getters.t("clone_phase_warning") }}
                    <br /><br />
                    <div class="d-flex flex-row">
                        <PitTextField v-model="clone_phase_name" :label="$store.getters.t('phase_name')"></PitTextField>
                    </div>
                    {{ $store.getters.t("confirm") }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="clone_phase" color="error" :disabled="clone_status_text.length != 0"> {{
                        $store.getters.t("clone_phase") }} </v-btn>
                    <v-btn @click="clone_dialog = false" color="secondary"> {{ $store.getters.t("close") }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DELETE DIALOG -->

        <v-dialog v-model="delete_dialog" max-width="600">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("delete_phase") }}
                </v-card-title>
                <v-card-text>
                    {{ $store.getters.t("add") }} {{ phase }}
                    {{ $store.getters.t("for_project") }}:
                    {{ project ? project.name : "---" }}
                    <br />
                    {{ $store.getters.t("delete_phase_warning") }}
                    <br />
                    <span v-for="b in batches" :key="b.uuid">
                        <br />
                        {{ $store.getters.t("name") }}: {{ b.name }},
                        {{ $store.getters.t("uuid") }}: {{ b.uuid }}
                    </span>
                    <br />
                    <br />
                    {{ $store.getters.t("confirm") }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="delete_phase" :disabled="delete_disable" :loading="delete_disable" color="error">{{
                        $store.getters.t("delete")
                    }}</v-btn>
                    <v-btn @click="delete_dialog = false" color="secondary">{{
                        $store.getters.t("close")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss" scoped>
.text-transform-none {
    text-transform: none !important;
}
</style>

<script>
import _ from "lodash";
import PitTextField from "../fields/PitTextField.vue";


const STORE_COUNTS = {
    "assembly": "assd_batch_counts",
    "module": "modd_batch_counts",
}
const PUT_ON_BATCH_ACTIONS = {
    "assembly": "v2/quantities/putAssdCountOnBatch",
    "module": "v2/quantities/putModdCountOnBatch",
}
const PUT_ON_PHASE_ACTIONS = {
    "assembly": "v2/quantities/putAssdCountOnPhase",
    "module": "v2/quantities/putModdCountOnPhase",
}

export default {
    name: "DropdownPhaseOptions",
    components: { PitTextField },
    props: ["project", "phase", "designs"],
    data: () => ({
        numbers_dialog: false,
        multi_count: 0,
        selected_designs: [],

        clone_dialog: false,
        clone_phase_name: "",
        clone_status_text: "",

        delete_dialog: false,
        delete_disable: false,

        edit_phase_name: "",
        edit_phase_mode: false,
        phase_edit_loading: false,
    }),
    computed: {
        batches() {
            return this.$store.state.v2.constructibles.batches.filter(
                (b) => b.phase == this.phase
            );
        },
        phase_edit_is_duplicate() {
            return (
                this.phase != this.edit_phase_name &&
                this.project.phases.includes(this.edit_phase_name)
            );
        },
        designs_list() {
            return _.orderBy(this.designs, "name").map((design) => ({
                text: design.name,
                value: design.uuid,
            }));
        },
    },
    methods: {
        async delete_phase() {
            this.delete_disable = true;
            let phase = this.phase;
            let phases = this.project.phases.filter((p) => p != phase);

            let promises = [];
            this.batches.forEach((b) => {
                promises.push(
                    this.$store.dispatch("v2/constructibles/deleteBatch", b.uuid)
                );
            });
            Promise.all(promises).then(() => {
                this.$store
                    .dispatch("v2/selected/putProject", {
                        uuid: this.project.uuid,
                        phases,
                    })
                    .finally(() => {
                        this.delete_disable = false;
                        this.delete_dialog = false;
                    });
            });
        },
        edit_phase() {
            this.edit_phase_mode = true;
            this.edit_phase_name = this.phase;
        },
        save_phase() {
            if (this.phase_edit_is_duplicate) return;
            this.phase_edit_loading = true
            // let phases = this.project.phases.filter((p) => p != this.phase);
            // phases.push(this.edit_phase_name);
            let phases = [...this.project.phases]
            var idx = phases.indexOf(this.phase)
            phases[idx] = this.edit_phase_name
            let promises = []
            this.batches.forEach((b) => {
                let batch = { ...b, phase: this.edit_phase_name };
                promises.push(this.$store.dispatch("v2/constructibles/putBatch", batch))
            });
            Promise.all(promises)
                .then(() => {
                    this.$store.dispatch("v2/selected/putProject", {
                        uuid: this.project.uuid,
                        phases,
                    }).then(() => {
                        this.phase_edit_loading = false
                        this.edit_phase_mode = false
                    })
                })
        },
        set_multiple_numbers() {
            this.selected_designs.forEach((design) => {
                let level = this.designs.find(d => d.uuid == design)?.level
                this.$store.commit(PUT_ON_PHASE_ACTIONS[level], {
                    phase: this.phase,
                    count: Number(this.multi_count),
                    design: design,
                });
            });
            this.numbers_dialog = false;
        },
        async clone_phase() {
            // Clone phase
            this.clone_status_text = "Creating phase...";
            let pr = this.$store.state.v2.selected.selected_project;
            if (pr.phases.includes(this.clone_phase_name)) {
                this.clone_status_text = "";
                return;
            }
            let phases = [...this.project.phases, this.clone_phase_name];
            await this.$store.dispatch("v2/selected/putProject", {
                uuid: this.project.uuid,
                phases,
            });

            await new Promise((r) => setTimeout(r, 2000)); // Just for user awarenes
            // Create batches
            this.clone_status_text = "Creating batches...";
            let proms = [];
            this.batches.forEach((b) => {
                let batch = {
                    name: b.name,
                    phase: this.clone_phase_name,
                    project: b.project,
                    show_in_viewer: b.show_in_viewer,
                    priority: b.priority,
                    production_line: b.production_line,
                };
                proms.push(
                    this.$store.dispatch("v2/constructibles/postBatch", batch)
                );
            });
            await Promise.all(proms);

            await new Promise((r) => setTimeout(r, 2000)); // Just for user awarenes
            // Update batch
            this.clone_status_text = "Updating batch counts...";
            this.batches.forEach((ob) => {
                let nb = this.$store.state.v2.constructibles.batches.find(
                    (b) => b.name == ob.name && b.phase == this.clone_phase_name
                );

                // Iterate designs
                this.designs.forEach((design) => {
                    let level = design?.level
                    // Get old count obj
                    let count_obj = this.$store.state.v2.quantities[STORE_COUNTS[level]].find(
                        (co) =>
                            co.phase == this.phase &&
                            co.batch == ob.uuid &&
                            co.design == design.uuid
                    );
                    // Set new batch-wall count
                    if (count_obj) {
                        this.$store.commit(
                            PUT_ON_BATCH_ACTIONS[level],
                            {
                                phase: this.clone_phase_name,
                                batch: nb.uuid,
                                design: design.uuid,
                                count: count_obj.count,
                            }
                        );
                    }
                });
            });
            await new Promise((r) => setTimeout(r, 2000)); // Just for user awarenes

            // Update phase counts
            this.clone_status_text = "Updating phase counts...";

            // Iterate design designs
            this.designs.forEach((design) => {
                let level = design?.level
                // Get old phase-design count
                let old_count_obj = this.$store.state.v2.quantities[STORE_COUNTS[level]].find(
                    (co) => co.phase == this.phase && co.design == design.uuid
                );
                // Set new phase-design count
                if (old_count_obj) {
                    this.$store.commit(PUT_ON_PHASE_ACTIONS[level], {
                        phase: this.clone_phase_name,
                        design: design.uuid,
                        count: old_count_obj.count,
                    });
                }
            });

            // Finish
            await new Promise((r) => setTimeout(r, 2000)); // Just for user 
            this.clone_status_text = "Finished.";
            await new Promise((r) => setTimeout(r, 2000)); // Just for user 
            this.clone_dialog = false;
            this.clone_status_text = "";
        },
        select_all_designs() {
            if (this.selected_designs.length == 0)
                this.selected_designs = this.designs.map((d) => d.uuid);
            else this.selected_designs = [];
        },
    },
};
</script>