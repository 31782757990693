<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="loading" :disabled="loading" @click="dialog = true" v-bind="attrs" v-on="on"
                    v-if="icon != undefined" icon>
                    <v-icon> mdi-sort-clock-ascending-outline </v-icon>
                </v-btn>
                <v-btn :loading="loading" :disabled="loading" @click="dialog = true" v-bind="attrs" v-on="on" v-else>
                    <v-icon left> mdi-sort-clock-ascending-outline </v-icon>
                    {{ $store.getters.t("sorting") }}
                </v-btn>
            </template>
            <span>
                {{ $store.getters.t("batch_sorting") }}
            </span>
        </v-tooltip>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("batch_sorting") }}
                </v-card-title>
                <v-card-subtitle>
                    {{ $store.getters.t("panel_ordering_subtitle") }}
                </v-card-subtitle>
                <v-card-text>
                    <draggable :list="batch_list" class="list-group" ghost-class="ghost">
                        <v-sheet outlined class="title ma-1 pa-1 outlined font-weight-light"
                            v-for="batch_name in batch_list" :key="batch_name">
                            <v-icon class="mb-1" color="black"> mdi-drag </v-icon>
                            {{ batch_name }}
                        </v-sheet>
                    </draggable>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="order_by_name()" text>
                        <v-icon left v-if="ascending">
                            mdi-sort-clock-ascending-outline
                        </v-icon>
                        <v-icon left v-else>
                            mdi-sort-clock-descending-outline
                        </v-icon>
                        order by name
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="save()" color="warning">
                        {{ $store.getters.t("save") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import draggable from "vuedraggable"
import { nameSort } from "/src/utils/basics.js"
export default {
    props: ["phase", "icon"],
    components: { draggable },
    data: () => ({
        dialog: false,
        loading: false,
        batch_list: [],
        ascending: true,
    }),
    computed: {
        batches() {
            return this.$store.state.v2.constructibles.batches.filter(
                (b) => b.phase == this.phase
            )
        },
    },
    watch: {
        batches() {
            this.refresh_list()
        },
        dialog() {
            this.refresh_list()
        },
    },
    methods: {
        actual_save() {
            let promises = []
            this.batch_list.forEach((batch_name, i) => {
                let priority = i + 1
                let batch = this.batches.find((b) => b.name == batch_name)
                if (batch && batch.priority != priority) {
                    let new_b = { ...batch, priority }
                    let prom = this.$store.dispatch("v2/constructibles/putBatch", new_b)
                    promises.push(prom)
                }
                this.dialog = false
                Promise.all(promises).finally(() => (this.loading = false))
            })
        },
        save() {
            this.loading = true
            this.$nextTick(() => this.actual_save())
        },
        order_by_name() {
            if (this.ascending) {
                this.batch_list.sort(nameSort)
            } else {
                this.batch_list.sort((a, b) => nameSort(b, a))
            }
            this.ascending = !this.ascending
        },
        refresh_list() {
            this.batch_list = this.batches
                .sort((a, b) => a.priority - b.priority)
                .map((b) => b.name)
        },
    },
    mounted() {
        this.refresh_list()
    },
}
</script>