<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn class="mr-2" color="primary" :disabled="disabled" @click.stop="dialog = true" v-on="on">
                <v-icon left>mdi-upload</v-icon>
                {{ $store.getters.t("upload") }}
            </v-btn>
        </template>
        <span>{{ $store.getters.t("upload_project_files") }}</span>
        <v-dialog v-model="dialog" max-width="750">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("upload_files_for_project") }}:
                    {{ project ? project.name : "---" }}
                </v-card-title>
                <v-card-text>
                    {{ tipo }}
                    <v-row class="mt-3">
                        <v-col> <v-select v-model="section" :items="sections" :label="$store.getters.t('select_file_cat')"
                                outlined class="project-select" :disabled="uploading"></v-select>
                        </v-col>
                        <v-col>
                            <SelectTipo v-model="tipo" :level="level" />
                        </v-col>
                    </v-row>
                    <v-switch v-if="['doc', 'spec'].includes(section)" v-model="text_mode"
                        :label="$store.getters.t('add_as_text')">
                    </v-switch>
                    <DocsTextLoader ref="docstextloader" v-if="text_mode && ['doc', 'spec'].includes(section)" />
                    <div class="uppy-drop-zone"></div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="upload()" :disabled="!pck">
                        <v-icon left>mdi-upload</v-icon>
                        {{ $store.getters.t("upload") }}
                    </v-btn>
                    <v-btn color="secondary" @click="dialog = false">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-tooltip>
</template>



<script>
import bus from "/src/utils/event_bus"
import { backend_api_v2 } from "/src/utils/apiv2"
import { SECTIONS } from "./sections.js"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"
import DocsTextLoader from "./DocsTextLoader.vue"
import SelectTipo from "/src/components/fields/SelectTipo.vue"

export default {
    name: "UploadButton",
    components: { DocsTextLoader, SelectTipo },
    props: ["disabled", "project"],
    data: () => ({
        text_mode: false,
        uppy: null,
        dialog: false,
        section: "drawings", // drawings, details, docs, ifc, assembly_alt_model
        tipo: null,
        uploading: false,
    }),
    computed: {
        sections() {
            return SECTIONS.map(sec => ({ ...sec, ...(sec.text && { text: this.$store.getters.t(sec.text) }) }))
        },
        level() {
            return SECTIONS.find(s => s.value == this.section)?.level || "void"
        },
        show_uppy_box() {
            // Closed dialog
            if (!this.dialog) return false
            // Docs text mode
            if (["doc", "spec"].includes(this.section) && this.text_mode)
                return false
            // Open and not docs text mode
            return true
        },
        pck() {
            return this.$store.getters["v2/cloudstorage/resourcesPackage"]
        },
    },
    watch: {
        section(ns, os) {
            if (!["doc", "spec"].includes(os) || !this.text_mode)
                this.$nextTick(this.uppy.cancelAll())
        },
        show_uppy_box(su) {
            if (su) this.$nextTick(this.reloadUppy)
            else this.uppy?.close()
        },
    },
    methods: {
        notify_bad_file(exact_error) {
            bus.$emit("notification", {
                timeout: 2000,
                text: "Bad filename: " + exact_error,
                color: "warning",
            })
        },
        check_filename(currentFile, files) {
            let filename = currentFile.name
            let dotsplit = filename.split(".")
            let extension = dotsplit.pop()
            let filebody = dotsplit.join(".")


            // General bad format checks
            if (filebody.startsWith(" ")) {
                this.notify_bad_file(
                    "Filename has a leading space. Please remove it."
                )
                return false
            }

            // drawings, details, docs,
            if (this.section.includes("drawing")) {
                if (extension.toLowerCase() != "pdf") {
                    this.notify_bad_file("Not a pdf extension.")
                    return false
                }
                if (filebody.split(" ").length < 2) {
                    let its_arrangement = filebody
                        .toLowerCase()
                        .includes("project")
                    if (its_arrangement)
                        this.notify_bad_file(
                            "DWG filenames for general arrangement needing at least two words."
                        )
                    else
                        this.notify_bad_file(
                            "The name of the wall has to be in the drawing filename after the first space."
                        )
                    return false
                }
                // Check non duplicate dwg numbers
                let linesplit = filebody.split(" - ")
                let dwg_number = linesplit[1].trim()
                let dname = linesplit[0].trim()
                let founds = [dname + dwg_number]
                Object.keys(files).forEach(fileID => {
                    let list_file = files[fileID]
                    let lf_filename = list_file.name
                    let lf_dotsplit = lf_filename.split(".")
                    lf_dotsplit.pop()
                    let lf_filebody = lf_dotsplit.join(".")
                    let lf_linesplit = lf_filebody.split(" - ")
                    let lf_dwg_number = lf_linesplit[1].trim()
                    let lf_dname = lf_linesplit[0].trim()
                    founds.push(lf_dname + lf_dwg_number)
                })
                if (new Set(founds).size !== founds.length) {
                    this.notify_bad_file("You are adding duplicate drawing numbers. Not allowed unless revision.")
                    return false
                }
            } else if (this.section.includes("ifc")) {
                if (extension.toLowerCase() != "ifc") {
                    this.notify_bad_file("Not an .ifc extension.")
                    return false
                }
                if (
                    filebody.split(" ").length > 1 &&
                    !filebody.toLowerCase().includes("project")
                ) {
                    this.notify_bad_file(
                        "IFC filename must be the exact name of the design. Spaces are not allowed."
                    )
                    return false
                }
            } else if (this.section == "assembly_alt_model") {
                if (!["jjs", "wup", "btl"].includes(extension.toLowerCase())) {
                    this.notify_bad_file(
                        "Only JJS, WUP, and BTL files alowed."
                    )
                    return false
                }
                if (filebody.split(" ").length > 1) {
                    this.notify_bad_file(
                        "Filename must be the exact name of the design. Spaces are not allowed."
                    )
                    return false
                }
            } else {
                if (
                    !["pdf", "jpg", "jpeg", "png"].includes(
                        extension.toLowerCase()
                    )
                ) {
                    this.notify_bad_file(
                        "Allowed extensions: jpg, jpeg, png, pdf"
                    )
                    return false
                }
            }
            return true
        },
        generateTextCloudFiles() {
            this.uploading = true
            let proms = this.$refs.docstextloader.specs.map(spec => {
                const url = `/cloudstorage/packages/${this.pck.uuid}/presign_for_new_file/`
                let meta = {
                    section: this.section,
                    tipo: this.tipo,
                    text: spec.text,
                    status: ["uploaded"]
                }
                return backend_api_v2
                    .post(url, {
                        name: spec.name,
                        type: "fake_text",
                        meta,
                    })
            })
            Promise.all(proms).then(() => {
                this.uploading = false
                this.$refs.docstextloader.specs = []
            })

        },
        upload() {
            if (["doc", "spec"].includes(this.section) && this.text_mode) {
                this.generateTextCloudFiles()
            } else {
                this.uppy.upload()
            }
        },
        uploadComplete(result) {
            bus.$emit("notification", {
                timeout: 5000,
                text: `Uploaded files ${result.successful.length}. Failed files: ${result.failed.length}.`,
                color: result.failed.length == 0 ? "success" : "error",
            })

            this.$store.dispatch("v2/cloudstorage/cleanDuplicatesInPackage", this.pck.uuid)
                .then(() => bus.$emit("events/cloudstorage/reloadProjectPackages"))
            this.uploading = false
        },
        get_upload_parameters(file) {
            const url = `/cloudstorage/packages/${this.pck.uuid}/presign_for_new_file/`
            let fn = file.name
            let dotsplit = fn.split(".")
            let ext = dotsplit.pop()
            let short_name = dotsplit.join(".")
            let meta = {
                section: this.section,
                tipo: this.tipo,
                ext,
                status: ["uploaded"]
            }
            if (ext.toLowerCase() == "ifc") meta["design_name"] = short_name
            // THIS SHOULD BE CONFIGURED AS A COMPANY SETUP OR SOMETHING
            if (this.section.includes("drawing")) {
                let linesplit = short_name.split(" - ")
                meta["design_name"] = linesplit[0].replace("Merk", "").trim()
                meta["dwg_number"] = linesplit[1].trim()
                meta["dwg_name"] = linesplit[2].trim()
            }
            // END OF CONFIGURATION FOR COMPANY
            return backend_api_v2
                .post(url, {
                    name: fn,
                    short_name,
                    type: file.type,
                    meta,
                })
                .then((r) => r.data)
        },
        reloadUppy() {
            this.uppy = new Uppy({
                debug: true,
                onBeforeFileAdded: this.check_filename,
            })
                .use(Dashboard, {
                    theme: this.$vuetify.theme.dark ? "dark" : "light",
                    target: ".uppy-drop-zone",
                    inline: true,
                    hideUploadButton: true,
                    width: 750,
                    height: 400,
                    proudlyDisplayPoweredByUppy: false,
                    locale: {
                        strings: {
                            dropPasteImport:
                                "Add files. Drop, paste, %{browse} or get from",
                        },
                    },
                })
                .use(AwsS3, {
                    metaFields: ["name"],
                    getUploadParameters: this.get_upload_parameters,
                })
            this.uppy.on("upload", () => (this.uploading = true))
            this.uppy.on("complete", this.uploadComplete)
        },
    },
}
</script>
