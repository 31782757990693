<template>
    <div>
        <div class="my-2">
            <v-progress-circular
                size="24"
                class="mr-2"
                indeterminate
                v-if="loading"
            />
            <v-icon class="mr-2" v-else> mdi-warehouse </v-icon>
            UUID: {{ item.uuid.substring(0, 8) }}
        </div>
        <SheetProdLineBatches v-if="item" :item="item" />
        <SheetProdLineUsers v-if="item" :item="item" />
    </div>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
import SheetProdLineBatches from "./SheetProdLineBatches";
import SheetProdLineUsers from "./SheetProdLineUsers";
export default {
    components: { SheetProdLineBatches, SheetProdLineUsers },
    props: ["item"],
    data: () => ({ loading: false, production_line: null, batches: [] }),
    computed: {
        pl_uuid() {
            return this.item.uuid;
        },
    },
    watch: {
        pl_uuid: {
            handler: function (pl_uuid) {
                // Async load the production_line when it changes
                this.loading = true;
                this.production_line = null;
                backend_api_v2
                    .get("/factories/production_lines/" + pl_uuid + "/")
                    .then(({ data }) => {
                        this.production_line = { ...data };
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.loading = false;
                        console.log(`Cant load production_line: ${e}`);
                    });
            },
            immediate: true,
        },
    },
};
</script>