function add_hours(date, hours) {
    return new Date(date.getTime() + (hours * 60 * 60 * 1000))
}

function getPreviousMonday(date) {
    var day = date.getDay();
    var prevMonday = date;
    if (date.getDay() == 0) {
        prevMonday.setDate(date.getDate() - 7);
    }
    else {
        prevMonday.setDate(date.getDate() - (day - 1));
    }

    return prevMonday;
}



export function generate_timesteps(timeframe, time_left, time_right) {

    let tl = new Date(time_left)

    if (timeframe.includes("w")) tl = getPreviousMonday(tl)
    tl.setHours(0)
    tl.setMinutes(0)
    tl.setSeconds(0)
    const tr = new Date(time_right)
    tr.setHours(23)
    tr.setMinutes(59)
    tr.setSeconds(59)

    let last_added = new Date(tl)

    let frames = [last_added]

    while (last_added <= tr) {
        if (timeframe == "d") last_added = add_hours(last_added, 24)
        else if (timeframe == "w") last_added = add_hours(last_added, 24 * 7)
        else if (timeframe == "3w") last_added = add_hours(last_added, 24 * 7 * 3)
        else if (timeframe == "6w") last_added = add_hours(last_added, 24 * 7 * 6)
        else if (timeframe == "1h") last_added = add_hours(last_added, 1)
        else if (timeframe == "6h") last_added = add_hours(last_added, 6)
        frames.push(last_added)
    }

    return frames
}


const UNIT_EM_WIDTH = 8

export function get_position(timeframe, timestamp, time_left) {
    const tl = new Date(time_left)
    const ts = new Date(timestamp)
    const days = (ts - tl) / 1000 / 60 / 60 / 24
    let em_units = 8
    if (timeframe == "d") em_units = days * UNIT_EM_WIDTH
    else if (timeframe == "w") em_units = days / 7 * UNIT_EM_WIDTH
    else if (timeframe == "3w") em_units = days / 7 / 3 * UNIT_EM_WIDTH
    else if (timeframe == "6w") em_units = days / 7 / 6 * UNIT_EM_WIDTH
    else if (timeframe == "1h") em_units = days * 24 * UNIT_EM_WIDTH
    else if (timeframe == "6h") em_units = days * 5 * UNIT_EM_WIDTH

    return em_units  // em units
}

export function to_minutes(hours) {
    if (isNaN(hours)) return null
    return Math.round(hours * 60)
}

export function to_hours(minutes) {
    if (isNaN(minutes)) return null
    return Math.round((minutes / 60) * 100) / 100
}