<template>
    <v-text-field
        prepend-icon="mdi-swap-horizontal"
        class="mx-1"
        outlined
        dense
        label="Priority"
        type="number"
        :loading="loading ? 'accent' : false"
        v-model="internal_value"
    ></v-text-field>
</template>


<script>
import bus from "/src/utils/event_bus";
export default {
    props: ["batch"],
    data: () => ({ internal_value: 0, loading: false }),
    watch: {
        batch: {
            handler: function (b) {
                this.internal_value = b.pl_ordering;
            },
            immediate: true,
        },
        internal_value(iv) {
            if (iv != this.batch.pl_ordering) this.update_batch();
        },
    },
    methods: {
        update_batch() {
            let new_batch = { ...this.batch, pl_ordering: this.internal_value };
            this.loading = true;
            this.$store.commit("overview/setTouched");
            this.$store
                .dispatch("v2/constructibles/putBatch", new_batch)
                .then(() =>
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: `Batch number updated.`,
                        color: "success",
                    })
                )
                .finally(() => (this.loading = false));
        },
    },
};
</script>