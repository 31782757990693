<template>
    <div>
        <div class="vertline" :style="style"></div>
        <div class="tag" :style="tag_style">
            <span> now </span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.vertline {
    width: 0.1em;
    background-color: red;
    z-index: 1;
}
.tag {
    width: 1.6em;
    height: 0.8em;
    background-color: red;
    z-index: 1;
    border-radius: 0.2em;
    span {
        position: relative;
        top: -0.9em;
        margin: 0.2em;
        padding: 0.2em;
        font-size: 0.6em;
    }
}
</style>

<script>
export default {
    props: ["x", "rows_count"],
    computed: {
        // chart_rows() {
        //     return this.$store.state.overview.production_overview
        //         .production_lines;
        // },
        length() {
            return this.rows_count * 3.5 + 1;
        },
        style() {
            return {
                position: "absolute",
                left: this.x + "em",
                top: "0em",
                height: this.length + "em",
            };
        },
        tag_style() {
            return {
                position: "absolute",
                left: this.x - 0.7 + "em",
                top: this.length + "em",
            };
        },
    },
};
</script>