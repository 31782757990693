<template>
    <v-sheet>
        <v-progress-linear
            height="20"
            class="mr-2"
            color="info"
            :value="perc"
            :buffer-value="buffer_perc"
            outlined
        >
            {{ text }}
        </v-progress-linear>
    </v-sheet>
</template>

<script>
export default {
    props: ["panels"],
    computed: {
        waiting() {
            return this.panels.filter((p) => p.status == "waiting").length;
        },
        working() {
            return this.panels.filter((p) => p.status == "working").length;
        },
        ready() {
            return this.panels.filter((p) => p.status == "ready").length;
        },
        total() {
            return this.panels.length;
        },
        perc() {
            return (this.ready / this.total) * 100;
        },
        buffer_perc() {
            return ((this.ready + this.working) / this.total) * 100;
        },
        text() {
            return `waiting: ${this.waiting}, working: ${this.working}, ready: ${this.ready}`;
        },
    },
};
</script>