<template>
    <div class="d-flex flex-column central-toolbar-box">
        <div v-if="left_mode" style="height: 4rem"></div>
        <ButtonPanelChecklist v-if="is_actual" :actual="actual" />
        <ButtonIssueAdd v-if="is_actual" />
        <ButtonActualIssues v-if="is_actual" />
        <div :class="show_qr_button == show_rfid_button ? 'd-flex' : ''">
            <ButtonQRShow v-if="show_qr_button" :icon="show_rfid_button" :actual="actual" />
            <ButtonRFIDRegister v-if="show_rfid_button" :small="show_qr_button" :actual="actual" />
        </div>
        <ButtonSpecs />
        <ButtonMachineDrawings />
        <v-spacer></v-spacer>
        <div class="scrollable-details-box">
            <DialogDetailButtons v-if="modal_details" />
            <SelectRightPanelMode />
        </div>
        <div style="height: 0.3rem"></div>
    </div>
</template>


<style lang="scss" scoped>
.central-toolbar-box {
    // height: calc(100vh - 5rem);
    height: 100vh;
}

.scrollable-details-box {
    overflow: auto;
    // white-space: nowrap;
}
</style>


<script>
import ButtonPanelChecklist from "../checklist/ButtonPanelChecklist.vue";
import ButtonIssueAdd from "./ButtonIssueAdd.vue";
import ButtonMachineDrawings from "./ButtonMachineDrawings.vue";
import ButtonActualIssues from "./ButtonActualIssues.vue";
import ButtonRFIDRegister from "./ButtonRFIDRegister.vue";
import SelectRightPanelMode from "./SelectRightPanelMode.vue";
import DialogDetailButtons from "./DialogDetailButtons.vue";
import ButtonSpecs from "./ButtonSpecs.vue";

export default {
    name: "CentralToolbar",
    props: ["modal_details", "left_mode"],
    components: {
        ButtonIssueAdd,
        ButtonMachineDrawings,
        ButtonActualIssues,
        ButtonPanelChecklist,
        ButtonRFIDRegister,
        SelectRightPanelMode,
        DialogDetailButtons,
        ButtonSpecs,
    },
    computed: {
        is_actual() {
            return [
                "ass_viewer",
                "mod_viewer",
            ].includes(this.$route.name)
        },
        actual() {
            if (!this.is_actual) return false
            return this.$store.getters["v2/selected/annotatedRouteActual"]
        },
        actual_uuid() {
            return this.actual?.uuid || ""
        },
        show_qr_button() {
            return (
                this.is_actual && this.$store.getters["v2/session/viewer_show_qr"]
            );
        },
        show_rfid_button() {
            return (
                this.is_actual &&
                this.$store.getters["v2/session/viewer_show_rfid"]
            );
        },
    },
};
</script>