var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"batch",class:{
            'on-hover': hover,
            dark: _vm.dark,
            light: !_vm.dark,
            [_vm.batch.status]: true,
        },style:(_vm.batch_style),on:{"click":_vm.click_batch}},[_c('div',{staticClass:"batchname d-flex"},[_c('span',{staticClass:"text-truncate",class:_vm.dark ? 'dmain' : 'lmain'},[_vm._v(" "+_vm._s(_vm.batch.name)+" ")]),_c('v-spacer'),_c('span',{staticClass:"text-truncate",class:_vm.dark ? 'dmain' : 'lmain'},[_vm._v(" "+_vm._s(_vm.batch.status_text)+" ")])],1),(!_vm.loose)?_c('div',{staticClass:"d-flex batch-inside-box",style:(_vm.batch_inside_style)},_vm._l((_vm.batch.panels),function(panel){return _c('v-hover',{key:panel.uuid,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"panel text-truncate",class:{
                        dark: _vm.dark,
                        light: !_vm.dark,
                        [panel.status]: true,
                        'on-hover': hover,
                    },style:(_vm.panel_style(panel)),on:{"click":function($event){$event.stopPropagation();return _vm.click_panel(panel)}}},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(panel.name)+" ")])])]}}],null,true)})}),1):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }