<template>
    <div class="d-flex flex-column">
        <div v-if="rail" class="d-flex justify-space-around ml-10 pa-3 pt-4">
            <br><br>
        </div>
        <div v-else class="d-flex justify-space-around ml-10 pa-3 pt-4">
            <ButtonLanguage v-if="user.auth.multilang" icon />
            <div class="logo-container text-center">
                <v-img v-if="company_logo" transition="scale-transition" max-width="130" max-height="50" contain
                    :class="dark ? 'inverted' : ''" :src="company_logo"></v-img>
                <v-img class="mt-2 mx-auto" v-else-if="dark" transition="scale-transition" max-width="130" contain
                    src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_dark.svg"></v-img>
                <v-img class="mt-2 mx-auto" v-else transition="scale-transition" max-width="130" contain
                    src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo.svg">
                </v-img>
            </div>
            <v-btn to="/qr" icon large class="ml-2">
                <v-icon> mdi-qrcode-scan </v-icon>
            </v-btn>
            <ButtonChat />
        </div>

        <div v-if="rail">
            <v-list nav>
                <ButtonUser listitem />
                <v-list-item :disabled="!m_prod_management" to="/qr">
                    <v-list-item-icon>
                        <v-icon> mdi-qrcode-scan </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        qr
                    </v-list-item-title>
                </v-list-item>
                <ButtonLanguage listitem v-if="user.auth.multilang" />
                <ButtonChat listitem />
            </v-list>
        </div>

        <v-container v-if="!rail">
            <v-row dense>
                <v-col cols="6">
                    <ButtonUser />
                </v-col>
                <v-col cols="6">
                    <!-- This selector is shown in other place when it comes to a manager -->
                    <SelectorProductionDate v-if="show_by_date_worker" />
                    <SelectorProject v-else />
                </v-col>
            </v-row>
            <v-row v-if="manager_or_admin">
                <ButtonMode />
            </v-row>
        </v-container>
        <!-- <div class="d-flex mx-auto" v-if="selected_project_uuid == ''">
            <span class="mt-5 grey--text text--lighten-2 display-1">
                {{ $store.getters.t("select_project") }}
            </span>
            <v-icon x-large class="ma-5" color="grey lighten-2">
                mdi-arrow-up
            </v-icon>
        </div> -->
        <div v-if="company_wide && manager_or_admin && !viewer_mode && !logistics_mode">
            <v-list nav>
                <v-list-item :disabled="!m_prod_management" :to="'/company/production_overview'">
                    <v-list-item-icon>
                        <v-icon> mdi-chart-gantt </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("production_overview") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!m_bom" :to="'/company/reports'">
                    <v-list-item-icon>
                        <v-icon> mdi-file-document-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("reports") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/company/stock_products'">
                    <v-list-item-icon>
                        <v-icon> mdi-chart-bar </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("stock_products") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/company/constructibles_manager'">
                    <v-list-item-icon>
                        <v-icon> mdi-garage-variant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("constructibles_list") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/company/settings'">
                    <v-list-item-icon>
                        <v-icon> mdi-cog </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("company_settings") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
        <div v-if="viewer_mode && !rail">
            <SelectorProductionDate class="mx-auto mb-2" :wide="true" v-if="show_by_date_manager" />
            <ListViewerGroup />
        </div>
        <div v-if="logistics_mode && !rail">
            <SelectTreeLogistics />
        </div>
        <div v-if="!company_wide && manager_or_admin && !viewer_mode && !logistics_mode">
            <v-list nav>
                <v-list-item v-if="!stdlib_mode" :disabled="!m_prod_management"
                    :to="'/projects/' + selected_project_uuid + '/overview'">
                    <v-list-item-icon>
                        <v-icon> mdi-chart-gantt </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("project_overview") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/projects/' + selected_project_uuid + '/files'">
                    <v-list-item-icon>
                        <v-icon> mdi-file-cog-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("files_and_operations") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item :to="'/projects/' + selected_project_uuid + '/designs'">
                    <v-list-item-icon>
                        <v-icon> mdi-text-box-multiple-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("designs") }}
                    </v-list-item-title>
                </v-list-item>


                <v-list-item v-if="!stdlib_mode" :to="'/projects/' + selected_project_uuid + '/quantities'">
                    <v-list-item-icon>
                        <v-icon> mdi-dots-grid </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("production_counts") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!stdlib_mode" :to="'/projects/' + selected_project_uuid + '/constructibles'">
                    <v-list-item-icon>
                        <v-icon> mdi-garage-variant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("constructibles_list") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!stdlib_mode" :to="'/projects/' + selected_project_uuid + '/issues'">
                    <v-list-item-icon>
                        <v-icon> mdi-garage-alert-variant </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("production_issues") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!m_bom" :to="'/projects/' + selected_project_uuid + '/reports'">
                    <v-list-item-icon>
                        <v-icon> mdi-file-document-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("reports") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/projects/' + selected_project_uuid + '/settings'">
                    <v-list-item-icon>
                        <v-icon> mdi-folder-cog-outline </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("project_settings") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
        <br /><br /><br />
        <div v-if="company_logo && !rail" class="logo-container-bottom white-gradient-color d-flex"
            :class="dark ? 'dark' : ''">
            <v-img v-if="dark" transition="scale-transition" max-width="130" contain class="mx-auto mt-5 dark"
                src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_dark.svg"></v-img>
            <v-img v-else transition="scale-transition" max-width="130" contain class="mx-auto mt-5"
                src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo.svg"></v-img>
        </div>
    </div>
</template>

<style scoped>
.dark {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.inverted {
    filter: invert(64%) contrast(228%) brightness(80%) hue-rotate(180deg);
}

.logo-container {
    width: 130px;
}

.white-background {
    background-color: lightslategrey;
}

.logo-container-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    text-align: center;
}

.white-gradient-color {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg,
            rgba(255, 255, 255, 1) 30%,
            rgba(255, 255, 255, 0) 100%);
}
</style>

<script>
import ButtonLanguage from "/src/components/account/ButtonLanguage";
import ButtonChat from "./ButtonChat";
import ButtonUser from "./ButtonUser";
import ListViewerGroup from "./list/ListViewerGroup.vue";
import ButtonMode from "./ButtonMode";
import SelectorProject from "./SelectorProject";
import SelectorProductionDate from "./SelectorProductionDate";
import SelectTreeLogistics from "./SelectTreeLogistics.vue";

export default {
    components: {
        ButtonLanguage,
        ButtonChat,
        ButtonUser,
        ListViewerGroup,
        ButtonMode,
        SelectorProject,
        SelectorProductionDate,
        SelectTreeLogistics
    },
    props: ["rail"],
    watch: {
        user(u) {
            if (u?.auth?.hide_panel_queue)
                this.$store.commit("v2/viewer/setDesignsMode", true);
            else this.$store.commit("v2/viewer/setDesignsMode", false);
        },
        manager_or_admin(moa) {
            if (!moa) {
                this.$store.commit("setViewerMode");
            }
        },
    },
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        user() {
            return this.$store.state.v2.session.user_object;
        },
        loading() {
            return this.$store.state.v2.selected.viewer_loading;
        },
        viewer_mode() {
            return this.$store.state.app_mode == "production";
        },
        logistics_mode() {
            return this.$store.state.app_mode == "logistics";
        },
        stdlib_mode() {
            return this.$store.state.v2.selected.stdlib_mode
        },
        manager_or_admin() {
            return !this.$store.getters["v2/session/iam_prod_worker"];
        },
        show_by_date_worker() {
            if (this.manager_or_admin) return false
            return this.company?.work_by_date || false
        },
        show_by_date_manager() {
            return this.manager_or_admin && this.company?.work_by_date && this.company_wide
        },
        projects() {
            return this.$store.state.v2.company.projects;
        },
        company_wide() {
            return this.selected_project_uuid == "all";
        },
        selected_project_uuid() {
            return this.$store.state.v2.selected.selected_project?.uuid || "";
        },
        selected_project() {
            return this.$store.state.v2.selected.selected_project || "";
        },
        company() {
            return this.$store.state.v2.session.user_object?.auth?.company;
        },
        company_pck() {
            return this.$store.getters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "resources",
                level: "company",
            })?.[0]
        },
        company_logo() { return this.company_pck?.files?.find(cf => cf.meta.section == "logo")?.presigned_url },
        m_prod_management() {
            return this.company?.m_prod_management || false;
        },
        m_bom() {
            return this.company?.m_bom || false;
        },
    },
};
</script>
