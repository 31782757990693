<template>
    <div class="hscrollmenu">
        <v-btn class="mx-1" color="secondary" v-if="show_3d_button" @click="select3D()">3D</v-btn>
        <v-btn v-for="dwg in dwgs" :key="dwg.uuid" class="mx-1" color="secondary" @click="selectPDF(dwg.number)">
            {{ dwg.dwg_name || "-" }} </v-btn>
    </div>
</template>

<style lang="scss" scoped>
.hscrollmenu {
    overflow: scroll;
    white-space: nowrap;
}
</style>

<script>
import bus from "/src/utils/event_bus";
export default {
    name: "LeftPanelButtons",
    data: () => ({}),
    computed: {
        dwgs() {
            return this.$store.getters["v2/viewer/getDrawings"]
                .map(cf => ({
                    ...cf,
                    number: cf.meta?.dwg_number || 0,
                    dwg_name: cf.meta?.dwg_name || 0
                }))
                .sort((a, b) => a.number - b.number)
        },
        show_3d_button() {
            return !!this.$store.getters["v2/viewer/getIFC"];
        },
    },
    methods: {
        selectPDF(dwg_number) {
            this.$store.commit("v2/viewer/setPDFMode");
            this.$store.commit("v2/viewer/setLeftPanelDWGNumber", dwg_number);
            bus.$emit("events/viewer/redraw_left_pdf");
        },
        select3D() {
            this.$store.commit("v2/viewer/set3DMode");
            setTimeout(() => bus.$emit("events/viewer/recenter_3d"), 1000);
        },
    },
};
</script>
