<template>
    <table class="table">
        <col width="250px" />
        <DialogHiddenPhases :phases="phases_list" :batches="batches" />
        <thead class="header-item" :class="dark ? 'dark-header' : 'light-header'">
            <th class="title"> {{ $store.getters.t("design") }} </th>
            <th v-for="phase in phases_list" :key="phase">
                <DropdownPhaseOptions :phase="phase" :project="project" :designs="designs" />
            </th>
            <th align="right" class="title">
                {{ $store.getters.t("total_constructibles") }}
            </th>
        </thead>
        <tbody>
            <!-- Module rows -->
            <RowPhases v-for="modd in modds" :design="modd" :key="modd.uuid" />
            <!-- Module totals -->
            <RowTotalsPhase v-if="show_module_totals" level="module" />
            <!-- Assembly rows -->
            <RowPhases v-for="assd in assds" :design="assd" :key="assd.uuid" />
            <!-- Assembly totals -->
            <RowTotalsPhase v-if="show_assembly_totals" level="assembly" />
            <!-- Absolute totals -->
            <RowTotalsPhase v-if="show_all_totals" level="all" />
        </tbody>
    </table>
</template>

<style lang="scss">
@import "./tables.scss";
</style>


<script>
import DropdownPhaseOptions from "../DropdownPhaseOptions.vue";
import DialogHiddenPhases from "../DialogHiddenPhases.vue";
import RowPhases from "./RowPhases.vue"
import RowTotalsPhase from "./RowTotalsPhase.vue"
import { tableMixin } from "./mixins"

import _ from "lodash";

export default {
    mixins: [tableMixin],
    components: {
        DropdownPhaseOptions,
        DialogHiddenPhases,
        RowPhases,
        RowTotalsPhase,
    },
    computed: {
        assd_phase_counts() {
            return this.$store.state.v2.quantities.assd_phase_counts
        },
    },
    methods: {
        getPhaseFullTotal(phase) {
            if (!phase) {
                return _.sum(this.assd_phase_counts.map((c) => c.count));
            } else {
                return _.sum(
                    this.assd_phase_counts
                        .filter((c) => c.phase == phase)
                        .map((c) => c.count)
                );
            }
        },
        getPhaseFullTotalArea(phase) {
            // Fill area map
            let area_map = {};
            this.designs.forEach((w) => {
                let size = w.meta?.metrics?.bruto || 0;
                let wuuid = w.uuid;
                area_map[wuuid] = size;
            });
            if (!phase) {
                let mm2 = _.sum(
                    this.assd_phase_counts.map(
                        (c) => c.count * (area_map[c.design] || 0)
                    )
                );
                return mm2;
            } else {
                let mm2 = _.sum(
                    this.assd_phase_counts
                        .filter((c) => c.phase == phase && c.count != 0)
                        .map((c) => c.count * (area_map[c.design] || 0))
                );
                return mm2;
            }
        },
    },
};
</script>