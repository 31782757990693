<template>
    <v-icon :color="value ? 'blue' : 'grey'" small>
        {{ value ? "mdi-square-circle" : "mdi-square-outline" }}
    </v-icon>
</template>

<script>
export default {
    props: ["value"],
};
</script>