<template>
    <v-select outlined dense class="mx-1" v-model="selected_plate" :items="materials" :rules="rules"
        :clearable="!required" :label="$store.getters.t('material')">
    </v-select>
</template>

<script>
export default {
    props: {
        value: { default: null },
        required: { type: Boolean, default: false },
    },
    data: () => ({
        selected_plate: "",
    }),
    computed: {
        materials() {
            return this.$store.state.v2.logistics.materials.map((mat) => ({
                text: mat.name,
                value: mat.uuid,
            }));
        },
        rules() {
            if (!this.required) return [];
            return [(v) => !!v || "A material is required"];
        },
    },
    watch: {
        selected_plate(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_plate = v;
            },
            immediate: true,
        },
    },
};
</script>