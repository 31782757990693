<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <v-progress-linear
                    v-if="status.includes('working_')"
                    color="success"
                    :value="status.split('_')[1]"
                ></v-progress-linear>
                <v-chip v-else small :color="status_color">
                    {{ status }}
                </v-chip>
            </div>
        </template>
        <span>
            {{ tooltip_text }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: ["status"],
    computed: {
        tooltip_text() {
            if (this.status == "ready") return "Ready";
            else if (this.status == "failed")
                return "The server can't complete the work.";
            else if (this.status == "waiting")
                return "The server is working in other jobs. Wait.";
            else if (this.status == "deleting") return "Deleting...";
            else if (this.status == "working")
                return "The job is being processed.";
            else if (this.status.includes("working_"))
                return `Working: ${this.status.split("_")[1]}%`;
            else return this.status;
        },
        status_color() {
            if (this.status == "ready") return "success";
            else if (this.status == "failed") return "error";
            else if (this.status == "working") return "warning";
            else if (this.status == "deleting") return "grey";
            else return "info";
        },
    },
};
</script>