<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon> mdi-sort-clock-ascending-outline </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("batch_ordering") }}
                {{ base_pl_ordering_number }}
            </v-card-title>
            <v-card-subtitle>
                {{ $store.getters.t("batch_ordering_subtitle") }}
            </v-card-subtitle>
            <v-card-text v-if="async_loading">
                <v-skeleton-loader type="list-item@3"></v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <draggable
                    :list="batch_list"
                    class="list-group"
                    ghost-class="ghost"
                >
                    <v-hover
                        v-slot:default="{ hover }"
                        v-for="b in batch_list"
                        :key="b.uuid"
                    >
                        <v-sheet
                            outlined
                            class="d-flex ma-1 pa-1 outlined drag-item"
                            :class="{ hover: hover }"
                        >
                            <v-icon class="mb-1" color="black">
                                mdi-drag
                            </v-icon>
                            {{ b.name }}
                        </v-sheet>
                    </v-hover>
                </draggable>
            </v-card-text>
            <v-card-actions>
                <!-- <v-btn @click="order_by_project()" text>
                    <v-icon left> mdi-sort-clock-descending-outline </v-icon>
                    {{ $store.getters.t("order_by_project") }}
                </v-btn> -->
                <v-spacer></v-spacer>
                <v-btn
                    @click="save()"
                    color="warning"
                    :loading="loading"
                    :disabled="loading"
                >
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<style lang="scss">
.drag-item {
    cursor: pointer;
}
.drag-item.hover {
    border-color: lightgrey;
}
</style>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
import draggable from "vuedraggable";
export default {
    props: ["item"],
    components: { draggable },
    data: () => ({
        dialog: false,
        loading: false,
        batch_list: [],
        ascending: true,
        async_loading: false,
    }),
    computed: {
        base_pl_ordering_number() {
            return Math.min(...this.batch_list.map((bi) => bi.pl_ordering));
        },
        pl_uuid() {
            return this.item?.uuid;
        },
        batch_items() {
            return this.item?.batches || [];
        },
    },
    watch: {
        dialog() {
            this.refresh_list();
        },
    },
    methods: {
        save() {
            this.loading = true;
            let pl_ordering = this.base_pl_ordering_number;

            // Prepare Production Line ordering payload for APIv2
            const payload = this.batch_list.map((batch) => {
                return {
                    batch_uuid: batch.uuid,
                    pl_ordering: pl_ordering++,
                };
            });

            backend_api_v2
                .put("/overview/batches_pl_ordering/", payload);

            this.loading = false;
            this.dialog = false;
        },
        refresh_list() {
            this.batch_list = [...this.batch_items].sort(
                (a, b) => a.pl_ordering - b.pl_ordering
            );
        },
    },
    mounted() {
        this.refresh_list();
    },
};
</script>