

<template>
    <div class="d-flex">
        <v-select outlined multiple dense class="my-0" :label="$store.getters.t('licenses')" v-model="licenses"
            @change="touch()" :items="license_options">
        </v-select>
        <v-dialog v-model="dialog" width="400">
            <v-card>
                <v-card-title>
                    {{ $store.getters.t("reached_limit") }}
                </v-card-title>
                <v-card-text>
                    <div class="d-flex ma-3 mt-0 mb-5">
                        <h2 class="font-weight-light title">
                            {{ $store.getters.t("reached_limit_explain_all") }}
                        </h2>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer> </v-spacer>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
export default {
    props: ["value", "username"],
    data: () => ({ dialog: false, licenses: [] }),
    computed: {
        license_options() {
            return [
                { text: this.$store.getters.t("android_license"), value: "android" },
                { text: this.$store.getters.t("tekla_walls_license"), value: "walls" },
                { text: this.$store.getters.t("tekla_sidings_license"), value: "sidings" },
                { text: this.$store.getters.t("tekla_bricks_license"), value: "bricks" },
                { text: this.$store.getters.t("tekla_roofs_license"), value: "roofs" },
                { text: this.$store.getters.t("tekla_jsonconverter_license"), value: "jsonconverter" },
            ]
        },
        company() { return this.$store.state.v2.company.company },
        can_save_license_flags() {
            let remaining = {
                "android": this.company.max_android_licenses,
                "walls": this.company.max_tekla_licenses,
                "sidings": this.company.max_tekla_sidings_licenses,
                "bricks": this.company.max_tekla_bricks_licenses,
                "roofs": this.company.max_tekla_roofs_licenses,
                "jsonconverter": this.company.max_tekla_jsonconverter_licenses,
            }
            const users = this.$store.state.v2.company.users
                .filter(u => u.username != this.username && !u.auth.pit_staff)
            users.forEach(user => {
                let licenses = user.auth.licenses
                if (licenses.includes("android")) remaining.android -= 1
                if (licenses.includes("walls")) remaining.walls -= 1
                if (licenses.includes("sidings")) remaining.sidings -= 1
                if (licenses.includes("bricks")) remaining.bricks -= 1
                if (licenses.includes("roofs")) remaining.roofs -= 1
                if (licenses.includes("jsonconverter")) remaining.jsonconverter -= 1
            })
            let flags = {
                "android": remaining.android > 0,
                "walls": remaining.walls > 0,
                "sidings": remaining.sidings > 0,
                "bricks": remaining.bricks > 0,
                "roofs": remaining.roofs > 0,
                "jsonconverter": remaining.jsonconverter > 0,
            }
            return flags
        }
    },
    watch: {
        value: {
            handler: function (v) {
                this.licenses = v;
            },
            immediate: true,
        },
    },
    methods: {
        touch() {
            console.log(this.initial_licenses)
            console.log(this.licenses)
            let forbidden = this.licenses.some(lic => !this.can_save_license_flags[lic])
            if (forbidden) {
                this.dialog = true;
                this.$emit("input", []);
                this.$nextTick(() => (this.licenses = []));
            } else {
                this.$emit("input", this.licenses);
            }
        },
    },
    mounted() {
        this.initial_licenses = this.value
    }
};
</script>


