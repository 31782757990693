<template>
    <v-select class="mx-1" outlined dense v-model="selected_users" :items="users" label="Managed Users" item-text="username"
        item-value="id" multiple>
    </v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ selected_users: "" }),
    watch: {
        value(v) {
            this.selected_users = v;
        },
        selected_users(user) {
            this.$emit("input", user);
        },
    },
    computed: {
        users() {
            return this.$store.state.v2.company.users;
        },
    },
};
</script>