<template>
    <div>
        <v-tabs color="accent" v-if="production_line" v-model="selected_station">
            <v-tab :disabled="is_disabled(station)" v-for="station in stations_to_show" :key="station.uuid"
                @click="clickStation(station.uuid)">
                <v-badge v-if="station.ready" color="green" icon="mdi-check">
                    {{ station.name }}
                </v-badge>
                <template v-else>
                    {{ station.name }}
                </template>
                <!-- <v-badge v-else color="orange" dot>
                    {{ station.name }}
                </v-badge> -->
            </v-tab>
        </v-tabs>
    </div>
</template>

<script>
import _ from "lodash";
import bus from "/src/utils/event_bus";
export default {
    data: () => ({
        loading: false,
        selected_station: null,
    }),
    computed: {
        store_proof() {
            return this.$store.state.v2.records.rcp;
        },
        production_line() {
            return this.$store.getters["v2/session/my_selected_line_object"];
        },
        stations() {
            const stations = this.production_line?.stations || [];
            return _.orderBy(stations, "order");
        },
        stations_to_show() {
            let sta_to_show = [];
            this.stations.forEach((sta) => {
                let disabled = this.subsetNotForThisStation(sta);
                if (!disabled) {
                    sta_to_show.push({
                        uuid: sta.uuid,
                        name: sta.name,
                        ready: this.isReadyStation(sta),
                        order: sta.order,
                    });
                }
            });
            return sta_to_show;
        },
        station() {
            let station = this.stations.find(
                (sta) => sta.name == this.station_name
            );
            return station || { name: "FAILED_TO_LOAD_STATION" };
        },
        station_name() {
            return this.$store.getters["v2/session/my_selected_station"];
        },
        iam_prod_worker() {
            return this.$store.getters["v2/session/iam_prod_worker"];
        },
        batch_subset() {
            return this.$store.getters["v2/selected/routeActualBatch"]?.task_subset
        },
    },
    watch: {
        station() {
            this.setSelectionFromStore();
        },
    },
    methods: {
        is_disabled(station) {
            if (this.loading) return true
            // Disabled by subsets or whatever
            if (station.disabled) return true
            // Selected is enabled
            if (station.name == this.station_name) return false


            // If my station is not ready, all disabled but previous.
            const is_prev = station.order < this.station.order
            if (!this.isReadyStation(this.station) && this.iam_prod_worker && is_prev) return false
            if (!this.isReadyStation(this.station) && this.iam_prod_worker) return true

            // If my station is ready, only next is enabled.
            const more_than_next = station.order > (this.station.order + 1)
            if (this.isReadyStation(this.station) && this.iam_prod_worker && more_than_next) return true
            // Else... don't disable 🫡
            return false
        },
        setSelectionFromStore() {
            let idx = this.stations.findIndex(
                (sta) => sta.name == this.station_name
            );
            this.selected_station = idx;
        },
        clickStation(sta_uuid) {
            this.loading = true;
            this.$emit("loading");
            bus.$emit("back_event", {
                event: "change_station",
                meta: { station: sta_uuid },
            });
            this.$store
                .dispatch("v2/session/postUserSelectedStation", sta_uuid)
                .then(() => {
                    this.loading = false;
                    this.$emit("loaded");
                });
        },
        isReadyStation(sta) {
            if (!this.store_proof) return false;
            let tasks = this.store_proof.checklist.filter(
                (t) =>
                    t.production_line == this.production_line.name &&
                    t.production_station == sta.name
            );
            return (
                tasks.filter((t) => t.text == "STATION_JOB_READY").length == 1
            );
        },
        subsetNotForThisStation(sta) {
            if (!this.store_proof) return false;
            return sta.disabled_subsets.includes(this.batch_subset)
        }
    },
    mounted() {
        this.setSelectionFromStore();
    },
};
</script>