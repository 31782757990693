<template>
    <v-row v-if="loading" dense class="mt-1 mb-3">
        <v-skeleton-loader type="button" class="mx-2"/>
        <v-skeleton-loader type="button" class="mx-2"/>
        <v-skeleton-loader type="button" class="mx-2"/>
    </v-row>
    <v-row v-else dense class="mt-1 mb-3">
        <v-btn color="accent" v-for="(item, index) in branches" :key="index" @click="select_branch(item)" class="mx-2">
            {{ item }}
        </v-btn>
    </v-row>
</template>
  
<script>
export default {
    data: () => ({
        selected_branch: null,
    }),
    watch: {
        selected_branch(sb) {
            this.$store.commit("v2/selected_logistics/setSelectedBranch", sb);
        },
    },
    computed: {
        branches() {
            return this.$store.state.v2.selected_logistics.branches;
        },
        loading() {
            return this.$store.getters["v2/selected_logistics/isLoading"]("branches");
        },
    },
    methods: {
        select_branch(item) {
            this.selected_branch = item;
        },
    },
};
</script>