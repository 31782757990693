import Vue from 'vue'
import Vuex from 'vuex'
import v2Module from './v2'
import overviewModule from './overview'
import bus from "/src/utils/event_bus"
import initialize_watchers from "./watchers"

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: true,  // Changes outside mutations will raise exception.
    state: {
        app_mode: "production",
        lock: false,  // Locks screen with loading overlay
        active_loaders: [],  // Loading calls that are running
        bootup: true, // Only at startup is true
        route_name: "",
        connection_lost: false,
    },
    mutations: {
        setViewerMode: state => {
            state.app_mode = "production"
        },
        setManagerMode: state => {
            state.app_mode = "manage"
        },
        setLogisticsMode: state => {
            state.app_mode = "logistics"
        },
        setMode: (state, mode) => {
            state.app_mode = mode
        },
        lock: state => state.lock = true,
        unlock: state => state.lock = false,
        pushLoading: (state, load) => state.active_loaders.push(load),
        popLoading: (state, load) => state.active_loaders = state.active_loaders.filter(l => l != load),
        popLoadingIncludes: (state, load) => state.active_loaders = state.active_loaders.filter(l => !l.includes(load)),
        ready: state => state.loading = false,
        endBootup: state => state.bootup = false,

        setRouteName: (state, name) => state.route_name = name,
        setConnectionLost(state, status) {
            state.connection_lost = status;
        },
    },
    actions: {
        initialLoad(context) {
            return Promise.all([
                context.dispatch("v2/company/loadCompany"),
                context.dispatch("v2/factories/loadProductionLines"),
                context.dispatch("v2/company/loadIFCPSs"),
                context.dispatch("v2/company/loadBTLPSs"),
            ])
        },
        refreshBaseUserData(context, lock) {
            if (lock) context.commit("lock");
            return Promise.all([
                context.dispatch('v2/session/getUserObject'),
                context.dispatch("v2/company/loadProjects"),
                store.dispatch('v2/company/loadStandardLibraries'),
            ]).then(() => {
                if (lock) context.commit("unlock");
                if (context.state.bootup) {
                    // ONLY ON BOOT
                    let station = context.rootState.v2.session.user_object.auth
                        .selected_production_station;
                    bus.$emit("back_event", {
                        event: "change_station",
                        meta: { station },
                    });
                    context.commit("endBootup")
                }
            }).catch(() => { if (lock) context.commit("unlock") })
        },
    },
    getters: {
        loading: state => state.active_loaders.length != 0,
        companyWide: state => state.v2.selected.pr_selection == "all",
        m_bom: state => {
            return state.v2.session.user_object?.auth?.company?.m_bom || false
        },
        m_machines: state => {
            return state.v2.session.user_object?.auth?.company?.m_machines || false
        },
        m_prod_management: state => {
            return state.v2.session.user_object?.auth?.company?.m_prod_management || false
        },
        t: (state, getters) => key => getters["v2/translations/translate"](key)
    },
    modules: {
        v2: v2Module,
        overview: overviewModule,
    }
})


// Inits our own watchers
initialize_watchers(store)


export default store