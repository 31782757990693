<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("users") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" v-if="expanded" text @click="new_user">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">
                            {{ $store.getters.t("username") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("email") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("role") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("production_station") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("managed_users") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("licenses") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users_table" :key="user.id">
                        <td>{{ user.username }}
                            <v-chip outlined x-small class="ml-1" color="accent" v-if="user.auth.pit_staff">
                                <v-icon x-small left> mdi-lock-outline </v-icon>
                                produuz.it
                            </v-chip>
                        </td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.auth.role }}</td>
                        <td>
                            {{ user.site_name }}
                        </td>
                        <td>{{ user.managed_users_count }}</td>
                        <!-- <td v-if="show_android">
                            <IconAndroidBoolean :value="user.auth.licenses.includes('android')" />
                        </td> -->
                        <td> <v-chip :key="lic" x-small outlined v-for="lic of user.auth.licenses"> {{ lic }}</v-chip></td>
                        <td class="text-right">
                            <v-btn :disabled="user.auth.pit_staff && !my_user.auth.pit_staff" icon
                                @click="launch_edit(user.id)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn :disabled="user.auth.pit_staff" icon @click="launch_delete(user.id)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_user") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_user_confirm") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_user" color="error" :disabled="delete_loading" :loading="delete_loading">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500">
                    <v-card>
                        <v-card-title v-if="form.id">
                            {{ $store.getters.t("edit_user") }}
                        </v-card-title>
                        <v-card-title v-else>
                            {{ $store.getters.t("new_user") }}
                        </v-card-title>
                        <v-card-text>
                            <h2 class="
                                    ml-1
                                    mb-3
                                    mt-5
                                    font-weight-light
                                    grey--text
                                ">
                                {{ $store.getters.t("user_parameters") }}
                            </h2>
                            <v-form ref="userform" @keyup.enter.native="post_user">
                                <v-text-field class="mx-1" outlined dense label="Username" v-model="form.username"
                                    :rules="[rules.required]">
                                </v-text-field>
                                <v-text-field class="mx-1" outlined dense label="Email" v-model="form.email" type="email"
                                    :rules="emailRules">
                                </v-text-field>
                                <SelectProductionLineAndStation v-model="form.auth.production_station" />
                                <SelectRole v-model="form.auth.role" />
                                <SelectUsersMultiple v-model="form.auth.managed_users" />
                                <DialogSelectUserLicenses v-model="form.auth.licenses" :username="form.username" />
                                <v-checkbox class="my-0" :label="$store.getters.t('multilang')"
                                    v-model="form.auth.multilang">
                                </v-checkbox>
                                <v-checkbox class="my-0" :label="$store.getters.t('hide_panel_queue')
                                    " v-model="form.auth.hide_panel_queue">
                                </v-checkbox>
                                <v-checkbox class="my-0" :label="$store.getters.t('can_select_station')
                                    " v-model="form.auth.can_select_station">
                                </v-checkbox>
                                <DialogCheckboxHasOwnWorktime v-model="form.auth.own_calendar.week_work_periods
                                    " />
                                <DialogCheckboxHasOwnHolydays v-model="form.auth.own_calendar.holydays" />
                            </v-form>
                        </v-card-text>
                        <!-- :href="'/admin/auth/user/' + form.id + '/password/'" target="_blank" -->
                        <v-card-actions>
                            <a class="ml-3" v-if="form.id" @click="launch_password_reset">
                                {{ $store.getters.t("password_reset") }}
                            </a>
                            <span class="grey--text" v-else> Save user first to set a password. </span>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_user" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="password_dialog" max-width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("change_password") }}
                        </v-card-title>
                        <v-card-text>
                            <v-form @keyup.enter.native="post_password" ref="password_form">
                                <v-text-field class="mx-1" outlined dense label="Password" v-model="password_A"
                                    type="password" autocomplete="new-password" :rules="[rules.password]">
                                </v-text-field>
                                <v-text-field class="mx-1" outlined dense label="Type again password" v-model="password_B"
                                    type="password" autocomplete="new-password" :rules="[
                                        rules.password,
                                        rules.matchPassword,
                                    ]">
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_password" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="password_dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { by_username } from "/src/utils/basics";
import SelectProductionLineAndStation from "./SelectProductionLineAndStation"
import SelectRole from "./SelectRole";
import SelectUsersMultiple from "./SelectUsersMultiple";
import DialogCheckboxHasOwnWorktime from "./DialogCheckboxHasOwnWorktime";
import DialogCheckboxHasOwnHolydays from "./DialogCheckboxHasOwnHolydays";
// import IconAndroidBoolean from "/src/components/renderers/IconAndroidBoolean";
import _ from "lodash";
import bus from "/src/utils/event_bus";
// import DialogCheckboxAndroidLicense from "./DialogCheckboxAndroidLicense.vue";
import DialogSelectUserLicenses from "./DialogSelectUserLicenses.vue"
export default {
    props: [],
    components: {
        SelectProductionLineAndStation,
        SelectRole,
        SelectUsersMultiple,
        DialogCheckboxHasOwnWorktime,
        DialogCheckboxHasOwnHolydays,
        // DialogCheckboxAndroidLicense,
        DialogSelectUserLicenses,
        // IconAndroidBoolean,
    },
    data: () => ({
        expanded: false,
        saving: false,
        form: {
            auth: { own_calendar: {} },
        },
        password_A: "",
        password_B: "",
        dialog: false,
        delete_dialog: false,
        password_dialog: false,
        delete_id: "",
        delete_loading: false,
        emailRules: [
            // (v) => !!v || "E-mail is required",
            (v) => /.+@.+/.test(v) || v == "" || "E-mail must be valid",
        ],
    }),
    computed: {
        my_user() { return this.$store.state.v2.session.user_object },
        users() {
            return [...this.$store.state.v2.company.users].sort(by_username);
        },
        users_table() {
            return this.users.map((u) => ({
                ...u,
                managed_users_count: u.auth?.managed_users?.length || "---",
                site_name: this.get_pl_name(u.auth.production_station),
            }));
        },
        rules() {
            return {
                password: (v) =>
                    (v && v.length >= 8) ||
                    "Your password must contain at least 8 characters.",
                matchPassword: () =>
                    this.password_A == this.password_B ||
                    "Passwords not matching.",
                required: (v) => !!v || "Required.",
            };
        },
    },
    methods: {
        get_pl_name(sta_uuid) {
            let pls = this.$store.state.v2.factories.production_lines;
            let site_name = "--";
            pls.forEach((site) => {
                let sta = site.stations.find((s) => s.uuid == sta_uuid);
                if (sta) site_name = sta.name + " [" + site.name + "]";
            });
            return site_name;
        },
        launch_edit(id) {
            let user = this.users.find((u) => u.id == id);
            this.dialog = true;
            this.$nextTick(() => (this.form = _.cloneDeep(user)));
        },
        launch_delete(id) {
            this.delete_id = id;
            this.delete_dialog = true;
        },
        launch_password_reset() {
            this.dialog = false;
            this.password_dialog = true;
        },
        delete_user() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/company/deleteUser", this.delete_id)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
        new_user() {
            this.form = {
                username: "",
                email: "",
                auth: {
                    role: "",
                    production_station: "",
                    own_calendar: {
                        holydays: false,
                        week_work_periods: false,
                    },
                },
            };
            this.dialog = true;
        },
        post_user() {
            const validated = this.$refs.userform.validate();
            if (!validated) {
                bus.$emit("notification", {
                    timeout: 3000,
                    text: "Please fix form errors.",
                    color: "error",
                });
                return;
            }
            this.saving = true;
            if (this.form.id) {
                this.$store
                    .dispatch("v2/company/putUser", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                this.$store
                    .dispatch("v2/company/postUser", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
        post_password() {
            if (!this.$refs.password_form.validate()) {
                bus.$emit("notification", {
                    timeout: 3000,
                    text: "Please enter valid passwords.",
                    color: "error",
                });
                return;
            }
            this.saving = true;
            this.$store
                .dispatch("v2/company/postUserPassword", {
                    ...this.form,
                    password: this.password_A,
                })
                .then(() => {
                    this.password_dialog = false;
                })
                .finally(() => (this.saving = false));
        },
    },
};
</script>