import cloudstorage from './cloudstorage'
import company from './company'
import constructibles from './constructibles'
import factories from './factories'
import integrations from './integrations'
import logistics from './logistics'
import mail from './mail'
import quantities from './quantities'
import records from './records'
import reports from './reports'
import translations from './translations'
import selected from './selected'
import selected_logistics from './selected_logistics'
import session from './session'
import viewer from './viewer'

export default {
    namespaced: true,
    modules: {
        cloudstorage,
        company,
        constructibles,
        factories,
        integrations,
        logistics,
        mail,
        quantities,
        records,
        reports,
        translations,
        selected,
        selected_logistics,
        session,
        viewer,
    }
}
