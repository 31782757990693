// NEED A WATCHER HERE THAT WATCHES THE SELECTED ACTUAL AND LOADS THE RCP


export default function (store) {
    // Reload RCP
    store.watch(
        (state) => state.v2.selected.selected_mod?.uuid,
        (uuid) => {
            store.commit("v2/records/setRCP", null)
            if (!uuid) return
            store.dispatch('v2/records/loadRCP', { level: "module", uuid })
        },
        { immediate: true }
    );
    store.watch(
        (state) => state.v2.selected.selected_ass?.uuid,
        (uuid) => {
            store.commit("v2/records/setRCP", null)
            if (!uuid) return
            store.dispatch('v2/records/loadRCP', { level: "assembly", uuid })
        },
        { immediate: true }
    );
}