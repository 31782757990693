

// export const REPORT_PAPER_SIZES = [
//     { value: "a4", text: "A4 - 4 QRs" },
//     { value: "a4_banner", text: "A4 - 4 QRs (with banner)" },
//     { value: "a4_banner_12", text: "A4 - 12 QRs (with banner)" },
//     { value: "dymo_159_104", text: "Dymo (159*104) (with banner)" },
// ]

export const WORKTIME_FORMATS = [
    // { value: "per_worker", text: "Per worker" },
    { value: "per_const", text: "By constructible rows" },
    { value: "per_const_station", text: "By constructible-station rows" },
    // { value: "per_worker_panel", text: "Per worker-panel" },
]

export const REPORT_TYPES = [
    { header: "Misc reports" },
    { value: "production_excel", text: "Production Excel" },
    { value: "worktime_excel", text: "Worktime Excel" },
    { value: "quality_control", text: "Quality Control Report" },
    { value: "issues", text: "Issues Report" },
]

export const PROJECT_REPORT_TYPES_MOD_BOM = [
    { divider: true },
    { header: "Bills of Materials" },
    { value: "plates_bom", text: "Plates BoM" },
    { value: "wood_bom", text: "Wood BoM" },
    { value: "designs_bom", text: "Per-Design BoM" },
    { value: "accesories_bom", text: "Accesories BoM" },
    { value: "custom_woodcsv", text: "Custom Woods CSV" },
    { value: "custom_platecsv", text: "Custom Plates CSV" },
    { value: "custom_accesorycsv", text: "Custom Accesories CSV" },
]
export const PROJECT_REPORT_TYPES = [
    ...PROJECT_REPORT_TYPES_MOD_BOM,
    { divider: true },
    { header: "Panels stickers" },
    { value: "qr_stickers", text: "QR Stickers" },
    { divider: true },
    { header: "Framing machine files" },
    { value: "panel_mob1", text: "MOB v1 Machine File" },
    { value: "panel_mob2", text: "MOB v2 Machine File" },
    { value: "frame_mtf", text: "MTF File [Roofs]" },
    { value: "frame_nailer_csv", text: "Frame Nails csv file" },
    { divider: true },
    { header: "Wood Profiles machine files" },
    { value: "wood_btl", text: "BTL File [Woods]" },
    { value: "wood_bvx", text: "BVX File [Hundegger][Woods]" },
    { divider: true },
    { header: "Wood Plates machine files" },
    {
        value: "plates_xcs",
        text: "XCS Maestro File [Plates]",
    },
    { value: "plates_ardis", text: "Ardis File [Plates]" },
    { value: "plates_dxf", text: "DXF File [Plates]" },
    { value: "plates_cix", text: "CIX Biesse File [Plates]" },
    // { value: "plates_ptx", text: "PTX File [Plates]" },
    { divider: true },
    { header: "Steel Profiles" },
    { value: "steel_nc", text: "DSTV .nc File" },
    { value: "steel_wiscon", text: "WISCON Welding" },
]

export const MULTIMATERIAL_REPORTS = [
    'plates_xcs',
    'plates_ardis',
    'plates_dxf',
    'plates_ptx',
    "plates_cix",
]

export const SELECT_MATERIALS_REPORTS = [
    "plates_bom",
    "wood_bom",
    // "accesories_bom",
    // "custom_bom",
]

export const SELECT_ASSEMBLY_REPORTS = [
    "per_worker",
    "per_panel",
    "per_worker_panel",
    "quality_control",
    "issues",
    "plates_bom",
    "wood_bom",
    "designs_bom",
    "accesories_bom",
    "custom_woodcsv",
    "custom_platecsv",
    "custom_accesorycsv",
    "qr_stickers",
    "panel_mob1",
    "panel_mob2",
    "frame_mtf",
    "frame_nailer_csv",
    "wood_btl",
    'wood_bvx',
    "steel_nc",
    "steel_wiscon",
    "plates_xcs",
    "plates_ardis",
    "plates_dxf",
    "plates_cix",
]

export const SELECT_MODULE_REPORTS = [
]

export const SELECT_MAT_PART_TIPO = {
    "plates_bom": "plate",
    "wood_bom": "profile",
    // "accesories_bom":"accesories",
    // "custom_bom":"plate_and_frame",
}


// This sould be deleted in favor of the dynamic form options
export const TOTALS_PAGE_REPORTS = [
    'plates',
    'wood'
]

export const DEFAULT_JOB_REPORTS = [
    "plates_bom",
    "wood_bom",
    "designs_bom",
    "accesories_bom",
    "custom_bom",
    "qr_stickers",
    "quality_control",
    "issues",
    "custom_woodcsv",
    "custom_platecsv",
    "custom_accesorycsv",
]

export const MACHINE_REPORTS = [
    'wood_btl',
    'wood_bvx',
    'plates_xcs',
    'plates_ardis',
    'plates_dxf',
    'plates_ptx',
    'plates_cix',
    "frame_mtf",
    "frame_nailer_csv",
]

export const PRODLINE_REPORTS = [
    'panel_mob1',
    'panel_mob2'
]

export const NEW_CLEAN_REPORT = {
    name: "",
    document_status: "waiting",
    project: "",
    document_type: "",
    meta: {
        production_line_uuid: null,
        machine_uuid: null,
        dynamic_forms: {},
        full_sizes: [],
    },
    selected_assemblies: [],
    selected_assembly_designs: [],
    selected_modules: [],
    selected_module_designs: [],
    // now files are handled through packages with name being the version
}


export function new_rev_cf(rev) {
    return {
        name: rev,
        created: new Date(),
        meta: { version: rev, status: "waiting" },
        cloud_folder: "",
        presigned_url: "",
        status: "waiting",
    };
}