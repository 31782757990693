<template>
    <div>
        <v-btn
            :loading="loading"
            color="secondary"
            small
            class="ml-2"
            @click="dialog = true"
            v-if="url"
        >
            <v-icon left small> mdi-information-outline </v-icon>
            {{ $store.getters.t("info") }}
        </v-btn>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            @click="dialog = false"
        >
            <v-card>
                <v-toolbar dense dark color="secondary" class="fronted">
                    <v-toolbar-title> {{ file_name }} </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="recenterPDF">
                            <v-icon>mdi-image-filter-center-focus</v-icon>
                        </v-btn>
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div class="pa-5" v-if="text">{{ text }}</div>
                <PDFView
                    v-else-if="url && !picture_mode"
                    :url="url"
                    :name="node_id"
                />
                <PictureView v-else-if="url && picture_mode" :url="url" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus";
import PDFView from "../PDFView.vue";
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    components: { PDFView },
    props: ["package_uuid"],
    data: () => ({
        fpck: null,
        loading: false,
        dialog: false,
    }),
    computed: {
        node_id() {
            return "pck" + this._uid;
        },
        url() {
            return this.fpck?.files?.[0]?.presigned_url;
        },
        text() {
            return this.fpck?.short_name;
        },
        file_name() {
            return this.fpck?.files?.[0]?.name || "";
        },
        picture_mode() {
            let extension = this.file_name
                .substr(this.file_name.lastIndexOf(".") + 1)
                .toLowerCase();
            return ["jpg", "jpeg", "png", "bmp"].includes(extension);
        },
    },
    watch: {
        package_uuid() {
            this.loadPackage();
        },
    },
    methods: {
        loadPackage() {
            if (!this.package_uuid) return;
            else {
                this.loading = true;
                backend_api_v2
                    .get(`/cloudstorage/packages/${this.package_uuid}/`)
                    .then(({ data }) => {
                        this.fpck = data;
                        this.loading = false;
                    })
                    .catch(() => {
                        this.fpck = null;
                        this.loading = false;
                    });
            }
        },
        recenterPDF() {
            bus.$emit("events/viewer/recenter_pdf_" + this.node_id);
        },
    },
    mounted() {
        this.loadPackage();
    },
};
</script>