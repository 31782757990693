import bus from "/src/utils/event_bus";

let MODDS_TO_FETCH = []
let ASSDS_TO_FETCH = []

export default function (store) {

    // BY DATE
    store.watch(
        (state) => state.v2.selected.selected_date,
        (date) => {
            store.commit("pushLoading", "project_resources")
            Promise.all([
                store.dispatch("v2/constructibles/loadModuleDesignsByDate", date),
                store.dispatch("v2/constructibles/loadAssemblyDesignsByDate", date),
                store.dispatch("v2/constructibles/loadBatchesByDate", date),
                store.dispatch("v2/constructibles/loadModulesByDate", date),
                store.dispatch("v2/constructibles/loadAssembliesByDate", date),
            ]).finally(() => store.commit("popLoading", "project_resources"))
        }
    );

    // BY PROJECT
    store.watch(
        (state) => state.v2.selected.selected_project?.uuid,
        (uuid) => {
            if (uuid == "all" || !uuid) {
                // Clean
                store.commit("v2/constructibles/setModuleDesigns", [])
                store.commit("v2/constructibles/setAssemblyDesigns", [])
                store.commit("v2/constructibles/setBatches", [])
                store.commit("v2/constructibles/setModules", [])
                store.commit("v2/constructibles/setAssemblies", [])
            }
            else {
                store.commit("pushLoading", "project_resources")
                Promise.all([
                    store.dispatch("v2/constructibles/loadModuleDesigns", uuid),
                    store.dispatch("v2/constructibles/loadAssemblyDesigns", uuid),
                    store.dispatch("v2/constructibles/loadBatches", uuid),
                    store.dispatch("v2/constructibles/loadModules", uuid),
                    store.dispatch("v2/constructibles/loadAssemblies", uuid),
                ]).finally(() => store.commit("popLoading", "project_resources"))
            }
        }
    );

    store.watch(
        // Watch all designs
        (state, getters) => getters["v2/constructibles/annotatedDesignsFromLevelOnlyLastRevisions"]("all"),
        designs => {
            // Clear prev timeouts

            // Filter designs that are working or waiting
            let ds = designs.filter(d => ["working", "waiting"].includes(d?.meta?.analysis))

            // Fill buffers
            let assds = ds.filter(d => d.level == "assembly")
            let modds = ds.filter(d => d.level == "module")
            ASSDS_TO_FETCH = assds.map(assd => assd.uuid).filter(uuid => !!uuid)
            MODDS_TO_FETCH = modds.map(modd => modd.uuid).filter(uuid => !!uuid)

            // Launch timeouts
            if (ASSDS_TO_FETCH.length != 0)
                setTimeout(() => {
                    // Call it
                    let action = "v2/constructibles/refreshAssemblyDesigns"
                    store.dispatch(action, [...ASSDS_TO_FETCH])
                    // On execution of timeout, clean to avoid repetition
                    ASSDS_TO_FETCH = []
                }, 25000)
            if (MODDS_TO_FETCH.length != 0)
                setTimeout(() => {
                    // Call it
                    let action = "v2/constructibles/refreshModuleDesigns"
                    store.dispatch(action, [...MODDS_TO_FETCH])
                    // On execution of timeout, clean to avoid repetition
                    MODDS_TO_FETCH = []
                }, 25000)
        },
        { deep: true }
    )

    bus.$on("events/constructibles/reloadConstructibles", () => {
        let pr_uuid = store.state.v2.selected.selected_project?.uuid
        store.dispatch('v2/constructibles/loadAssemblies', pr_uuid)
        store.dispatch('v2/constructibles/loadModules', pr_uuid)
    })

    bus.$on("events/constructible/routeToMyNextActual", () => {
        let first = store.getters["v2/constructibles/myFirst"]
        console.log("push here route to:", first.uuid)
    })
}