<template>
    <!-- This is like selectorzones for the modules viewer -->
    <div>
        <v-sheet class="ma-2" v-if="loading_status" width="500">
            <v-progress-linear color="accent" height="30" :value="loading_status.count / loading_status.total * 100">
                Parsing parts: {{ loading_status.count }} of {{ loading_status.total }}. </v-progress-linear>
        </v-sheet>
        <v-btn-toggle loading color="accent" multiple dense v-model="selected_assds" class="mr-3">
            <v-btn v-for="z in assds" :key="z" :value="z">{{ z }}</v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus";
export default {
    data: () => ({
        selected_assds: [],
    }),
    computed: {
        loading_status() { return this.$store.state.v2.viewer.bom_loading_status },
        assds() { return this.$store.getters["v2/viewer/module_layers"] },
        default_zones() {
            return this.assds;
        },
    },
    watch: {
        selected_assds(sz) {
            this.$store.commit("v2/viewer/setSelectedZones", sz);
        },
    },
    methods: {
        set_defaults() {
            setTimeout(() => {
                this.selected_assds = this.default_zones;
                this.$store.commit(
                    "v2/viewer/setSelectedZones",
                    this.selected_assds
                );
            }, 2000);
        },
    },
    mounted() {
        this.set_defaults();
        bus.$on("events/viewer/default_zone_selection", this.set_defaults);
    },
    beforeDestroy() {
        bus.$off("events/viewer/default_zone_selection");
    },
};
</script>