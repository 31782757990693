import base from "./base"

export default {
  namespaced: true,
  state: {
    materials: [],
    product_plates: [],
    product_profiles: [],
    plate_sizes: [],
    profile_sizes: [],
  },
  mutations: {
    // Materials
    setMaterials: base.mutations.setter("materials"),
    createMaterial: base.mutations.creator("materials"),
    updateMaterial: base.mutations.updater("materials"),
    deleteMaterial: base.mutations.deleter("materials"),

    // Product Plates
    setProductPlates: base.mutations.setter("product_plates"),
    createProductPlate: base.mutations.creator("product_plates"),
    updateProductPlate: base.mutations.updater("product_plates"),
    deleteProductPlate: base.mutations.deleter("product_plates"),

    // Product Profiles
    setProductProfiles: base.mutations.setter("product_profiles"),
    createProductProfile: base.mutations.creator("product_profiles"),
    updateProductProfile: base.mutations.updater("product_profiles"),
    deleteProductProfile: base.mutations.deleter("product_profiles"),

    // Plate Sizes
    setPlateSizes: base.mutations.setter("plate_sizes"),
    createPlateSize: base.mutations.creator("plate_sizes"),
    updatePlateSize: base.mutations.updater("plate_sizes"),
    deletePlateSize: base.mutations.deleter("plate_sizes"),

    // Profile Sizes
    setProfileSizes: base.mutations.setter("profile_sizes"),
    createProfileSize: base.mutations.creator("profile_sizes"),
    updateProfileSize: base.mutations.updater("profile_sizes"),
    deleteProfileSize: base.mutations.deleter("profile_sizes"),
  },
  actions: {
    // Materials
    loadMaterials: base.actions.loader("logistics/materials/", "Materials"),
    postMaterial: base.actions.poster("logistics/materials/", "Material"),
    putMaterial: base.actions.putter("logistics/materials/", "Material"),
    deleteMaterial: base.actions.deleter("logistics/materials/", "Material"),

    // Product Plates
    loadProductPlates: base.actions.loader("logistics/products/plates/", "ProductPlates"),
    postProductPlate: base.actions.poster("logistics/products/plates/", "ProductPlate"),
    putProductPlate: base.actions.putter("logistics/products/plates/", "ProductPlate"),
    deleteProductPlate: base.actions.deleter("logistics/products/plates/", "ProductPlate"),

    // Product Profiles
    loadProductProfiles: base.actions.loader("logistics/products/profiles/", "ProductProfiles"),
    postProductProfile: base.actions.poster("logistics/products/profiles/", "ProductProfile"),
    putProductProfile: base.actions.putter("logistics/products/profiles/", "ProductProfile"),
    deleteProductProfile: base.actions.deleter("logistics/products/profiles/", "ProductProfile"),

    // Plate Sizes
    loadPlateSizes: base.actions.loader("logistics/sizes/plates/", "PlateSizes"),
    postPlateSize: base.actions.poster("logistics/sizes/plates/", "PlateSize"),
    putPlateSize: base.actions.putter("logistics/sizes/plates/", "PlateSize"),
    deletePlateSize: base.actions.deleter("logistics/sizes/plates/", "PlateSize"),

    // Profile Sizes
    loadProfileSizes: base.actions.loader("logistics/sizes/profiles/", "ProfileSizes"),
    postProfileSize: base.actions.poster("logistics/sizes/profiles/", "ProfileSize"),
    putProfileSize: base.actions.putter("logistics/sizes/profiles/", "ProfileSize"),
    deleteProfileSize: base.actions.deleter("logistics/sizes/profiles/", "ProfileSize"),
  },
  getters: {
  }
}
