<template>
    <div>
        <FullScreenPackageViewer :package_uuid="package_uuid" v-if="package_uuid" />
    </div>
</template>

<script>
import FullScreenPackageViewer from "../cloudfiles/FullScreenPackageViewer.vue";
export default {
    components: { FullScreenPackageViewer },
    props: ["checklist_item"],
    computed: {
        stations() {
            return this.$store.getters["v2/session/my_selected_line_object"]?.stations || []
        },
        package_uuid() {
            const sta = this.stations.find(
                (sta) => sta.name == this.checklist_item.production_station
            );
            const chi = sta.checklist_items.find(
                (chi) => chi.text == this.checklist_item.text
            );
            return chi.docs_package;
        },
    },
};
</script>