<template>
    <v-list dense>
        <v-subheader>{{ $store.getters.t("assembly_designs") }}</v-subheader>
        <v-list-item :to="{ name: 'assd_viewer', params: { assd_uuid: dgroup.last_uuid } }"
            v-for="dgroup in designs_by_name" :key="dgroup.name">
            <v-list-item-avatar>
                <v-icon>mdi-land-rows-vertical</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="dgroup.name"></v-list-item-title>
            </v-list-item-content>
            <v-btn small icon v-for="design in dgroup.revisions" :key="design.uuid"
                :to="{ name: 'assd_viewer', params: { assd_uuid: design.uuid } }">
                <IconRevision :rev="design.revision" />
            </v-btn>
        </v-list-item>
    </v-list>
</template>

<script>
import { nameSort, by_revision } from "/src/utils/basics.js"
import IconRevision from "../../renderers/IconRevision.vue"
export default {
    components: { IconRevision },
    computed: {
        assds() { return this.$store.state.v2.constructibles.assembly_designs },
        designs_by_name() {
            let designs_by_name = []
            this.assds.forEach((assd) => {
                let found_assd_i = designs_by_name
                    .findIndex((found_assd) => found_assd.name == assd.name)
                if (found_assd_i == -1) {
                    designs_by_name.push({
                        name: assd.name,
                        revisions: [assd],
                        last_uuid: assd.uuid,
                        last_rev: assd.revision,
                    })
                } else {
                    designs_by_name[found_assd_i].revisions.push(assd)
                    if (assd.revision > designs_by_name[found_assd_i].last_rev) {
                        designs_by_name[found_assd_i].last_uuid = assd.uuid
                        designs_by_name[found_assd_i].last_rev = assd.revision
                    }
                }
            })
            designs_by_name.forEach((found_assd) => {
                found_assd.revisions.sort(by_revision)
            })
            designs_by_name.sort((a, b) => nameSort(a.name, b.name))
            return designs_by_name
        }
    }
}
</script>