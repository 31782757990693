<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card outlined class="fill-height d-flex flex-column" v-if="project">
                    <v-card-title>
                        {{ $store.getters.t("project") }}:
                        {{ project.name }}
                    </v-card-title>
                    <v-card-text class="flex-grow-1">
                        <span>
                            {{ $store.getters.t("client") }}:
                            {{ project.client_name }}
                        </span>
                        <br />
                        <span>
                            {{ $store.getters.t("pr_number") }}:
                            {{ project.project_number }}
                        </span>
                        <br />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonProjectTransfer />
                    </v-card-actions>
                </v-card>
                <v-card v-else>
                    <v-card-text>
                        <v-skeleton-loader class="ma-5" type="text@3"></v-skeleton-loader>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-if="project && project.super_project">
                <v-card outlined class="fill-height d-flex flex-column">
                    <v-card-title>
                        {{ $store.getters.t("superproject") }}:
                        {{ project.super_project }}
                    </v-card-title>
                    <v-card-text class="flex-grow-1">
                        <span>
                            {{ $store.getters.t("grouped_projects") }}:
                            {{ super_project_projects.length }}
                        </span>
                        <ul>
                            <li v-for="sp in super_project_projects" :key="sp.uuid">
                                {{ sp.name }}
                            </li>
                        </ul>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-title>
                        {{ $store.getters.t("project_settings") }}
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" @keyup.enter.native="save">
                            <div class="d-flex">
                                <SelectIFCPS v-model="ifc_parsing_setup" />
                                <SelectBTLPS v-model="btl_parsing_setup" />
                            </div>
                            <div class="d-flex">
                                <SelectPDFScale v-model="pdf_render_scale" />
                                <DatePicker :label="$store.getters.t('start_date')" v-model="start_date"></DatePicker>
                                <DatePicker :label="$store.getters.t('delivery_date')" v-model="delivery_date">
                                </DatePicker>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" :disabled="loading" color="success" @click="save">
                            {{ $store.getters.t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SelectPDFScale from "/src/components/fields/SelectPDFScale";
import SelectIFCPS from "/src/components/ifcps/SelectIFCPS";
import SelectBTLPS from "/src/components/ifcps/SelectBTLPS";
import ButtonProjectTransfer from "/src/components/project/ButtonProjectTransfer";
export default {
    components: { SelectPDFScale, SelectIFCPS, SelectBTLPS, ButtonProjectTransfer },
    data: () => ({
        ifc_parsing_setup: "",
        btl_parsing_setup: "",
        pdf_render_scale: 2,
        start_date: "",
        delivery_date: "",
        detail_scanning: "ifc_dwg",
        loading: false,
    }),
    computed: {
        project() {
            return this.$store.state.v2.selected.selected_project;
        },
        super_project_projects() {
            return this.$store.state.v2.company.projects.filter(
                (p) => p.super_project == this.project.super_project
            );
        },
    },
    watch: {
        project() {
            this.refresh_form();
        },
    },
    methods: {
        save() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$store
                    .dispatch("v2/company/putProject", {
                        ...this.project,
                        ifc_parsing_setup: this.ifc_parsing_setup,
                        btl_parsing_setup: this.btl_parsing_setup,
                        detail_scanning: this.detail_scanning,
                        start_date: this.start_date,
                        delivery_date: this.delivery_date,
                        meta: { ...this.project.meta, pdf_render_scale: this.pdf_render_scale },
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        refresh_form() {
            if (!this.project) return;
            this.ifc_parsing_setup = this.project.ifc_parsing_setup;
            this.btl_parsing_setup = this.project.btl_parsing_setup;
            this.detail_scanning = this.project.detail_scanning;
            this.start_date = this.project.start_date;
            this.pdf_render_scale = this.project.meta?.pdf_render_scale;
            this.delivery_date = this.project.delivery_date;
        },
    },
    mounted() {
        this.$nextTick(() => this.refresh_form())
    },
};
</script>