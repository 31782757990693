// import { paginated_factory_v2, backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
// import bus from '/src/utils/event_bus'
// import router from '/src/router'

export default {
    namespaced: true,
    state: {
        production_lines: [],
        machines: [],
    },
    mutations: {

        // Production Lines
        setProductionLines: base.mutations.setter("production_lines"),
        createProductionLine: base.mutations.creator("production_lines"),
        updateProductionLine: base.mutations.updater("production_lines"),
        deleteProductionLine: base.mutations.deleter("production_lines"),

        // Machines
        setMachines: base.mutations.setter("machines"),
        createMachine: base.mutations.creator("machines"),
        updateMachine: base.mutations.updater("machines"),
        deleteMachine: base.mutations.deleter("machines"),

    },
    actions: {


        // Production Lines
        loadProductionLines: base.actions.loader("factories/production_lines/", "ProductionLines"),
        postProductionLine: base.actions.poster("factories/production_lines/", "ProductionLine"),
        putProductionLine: base.actions.putter("factories/production_lines/", "ProductionLine"),
        deleteProductionLine: base.actions.deleter("factories/production_lines/", "ProductionLine"),

        // Machines
        loadMachines: base.actions.loader("factories/machines/", "Machines"),
        postMachine: base.actions.poster("factories/machines/", "Machine"),
        putMachine: base.actions.putter("factories/machines/", "Machine"),
        deleteMachine: base.actions.deleter("factories/machines/", "Machine"),
    },
    getters: {
    }
}