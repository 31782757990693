<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("api_accounts") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" v-if="expanded" text @click="new_account">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">
                            {{ $store.getters.t("account_name") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("role") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="account in accounts" :key="account.id">
                        <td>{{ account.username }}
                            <v-chip outlined x-small class="ml-1" color="accent" v-if="account.auth.pit_staff">
                                <v-icon x-small left> mdi-lock-outline </v-icon>
                                produuz.it
                            </v-chip>
                        </td>
                        <td>{{ account.auth.role }}</td>
                        <td class="text-right">
                            <ButtonJWTToken :account_id="account.id" />
                            <v-btn :disabled="account.auth.pit_staff && !my_user.auth.pit_staff" icon
                                @click="launch_edit(account.id)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn :disabled="account.auth.pit_staff" icon @click="launch_delete(account.id)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_account") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_account_confirm") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_account" color="error" :disabled="delete_loading"
                                :loading="delete_loading">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500">
                    <v-card>
                        <v-card-title v-if="form.id">
                            {{ $store.getters.t("edit_api_account") }}
                        </v-card-title>
                        <v-card-title v-else>
                            {{ $store.getters.t("new_api_account") }}
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" @keyup.enter.native="post_account">
                                <PitTextField v-if="!form.id" label="Account label" v-model="form.username" maxlength="10">
                                </PitTextField>
                                <h3 v-else class="ml-1 my-5 font-weight-light grey--text">
                                    Label: {{ form.username }}
                                </h3>
                                <SelectRole v-model="form.auth.role" />
                            </v-form>
                        </v-card-text>
                        <!-- :href="'/admin/auth/user/' + form.id + '/password/'" target="_blank" -->
                        <v-card-actions>
                            <!-- <a class="ml-3" v-if="form.id" @click="launch_password_reset">
                                {{ $store.getters.t("password_reset") }}
                            </a>
                            <span class="grey--text" v-else> Save user first to set a password. </span> -->
                            <v-spacer></v-spacer>
                            <v-btn @click="post_account" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { by_username } from "/src/utils/basics";
import SelectRole from "./SelectRole";
import ButtonJWTToken from "./ButtonJWTToken";
import PitTextField from "../fields/PitTextField.vue";
import _ from "lodash";
import bus from "/src/utils/event_bus";
export default {
    props: [],
    components: {
        SelectRole,
        ButtonJWTToken,
        PitTextField,
    },
    data: () => ({
        expanded: false,
        saving: false,
        form: {
            auth: {},
        },
        dialog: false,
        delete_dialog: false,
        delete_id: "",
        delete_loading: false,
    }),
    computed: {
        my_user() { return this.$store.state.v2.session.user_object },
        accounts() {
            return [...this.$store.state.v2.company.api_accounts].sort(by_username);
        },
        rules() {
            return {
                required: (v) => !!v || "Required.",
            };
        },
    },
    methods: {
        launch_edit(id) {
            let user = this.accounts.find((u) => u.id == id);
            this.dialog = true;
            this.$nextTick(() => (this.form = _.cloneDeep(user)));
        },
        launch_delete(id) {
            this.delete_id = id;
            this.delete_dialog = true;
        },
        delete_account() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/company/deleteAPIAccount", this.delete_id)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
        new_account() {
            this.form = {
                username: "",
                email: "",
                auth: {
                    role: "",
                    production_station: "",
                    own_calendar: {
                        holydays: false,
                        week_work_periods: false,
                    },
                },
            };
            this.dialog = true;
        },
        post_account() {
            const validated = this.$refs.form.validate();
            if (!validated) {
                bus.$emit("notification", {
                    timeout: 3000,
                    text: "Please fix form errors.",
                    color: "error",
                });
                return;
            }
            this.saving = true;
            if (this.form.id) {
                this.$store
                    .dispatch("v2/company/putAPIAccount", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                this.$store
                    .dispatch("v2/company/postAPIAccount", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
    },
};
</script>