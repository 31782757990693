<template>
    <div>
        <v-data-table :headers="headers" :items="table_items" item-key="uuid" sort-by="name" :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, -1],
        }" :items-per-page="50" :search="search" show-select v-model="selected">
            <template v-slot:top>
                <div class="my-2" v-if="selected_uuids.length == 0"><br /></div>
                <div class="mx-5 my-2" v-else>
                    <ButtonSetScheduleDate :uuids="selected_uuids" @deleted="completed = []" />
                    <ButtonDeleteActuals :uuids="selected_uuids" @deleted="selected = []" />
                    <ButtonClearProof :multiple="true" :actual_uuids="selected_uuids" @completed="selected = []" />
                </div>
            </template>
            <template v-slot:item.ass_name="{ item }">
                <v-btn color="accent" text :to="item.to_route">
                    {{ item.design_name }}
                    <v-chip small outlined>
                        <v-icon small>mdi-sort-clock-ascending</v-icon> {{ item.priority }}
                    </v-chip>
                </v-btn>
            </template>
            <template v-slot:item.uuid="{ item }">
                {{ item.uuid.substring(0, 8) }}
            </template>
            <template v-slot:item.design_name="{ item }">
                <v-btn :to="`/projects/${project_uuid}/designs/${item.level}/${item.design}`" text color="accent">
                    {{ $store.getters.t("design") }}: {{ item.design_name }}
                </v-btn>
            </template>
            <template v-slot:item.status="{ item }">
                <ChipConstructibleStatus :constructible="item" />
            </template>
            <template v-slot:item.created="{ item }">
                <Timestamp :value="item.created" />
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn text :to="'/units/' + item.uuid">
                    <v-icon left> mdi-map-legend </v-icon>
                    {{ $store.getters.t("view_meaning_action_of_view") }}
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ButtonSetScheduleDate from "./ButtonSetScheduleDate"
import ButtonDeleteActuals from "./ButtonDeleteActuals";
import ButtonClearProof from "./ButtonClearProof";
import Timestamp from "/src/components/renderers/Timestamp";
import { nameSort } from "/src/utils/basics.js";
import ChipConstructibleStatus from "../renderers/ChipConstructibleStatus.vue";

export default {
    components: {
        ButtonSetScheduleDate,
        ButtonDeleteActuals,
        ButtonClearProof,
        Timestamp,
        ChipConstructibleStatus,
    },
    props: ["search", "mode"],
    data: () => ({
        selected: [],
    }),
    computed: {
        headers() {
            return [
                {
                    text: this.$store.getters.t("constructible"),
                    value: "ass_name",
                    sort: nameSort,
                },
                { text: "UUID", value: "uuid" },
                {
                    value: "client_order_id",
                    text: this.$store.getters.t("client_order_id"),
                },
                {
                    text: this.$store.getters.t("design"),
                    value: "design_name",
                    sort: nameSort,
                },
                {
                    value: "schedule_str",
                    text: this.$store.getters.t("schedule"),
                },
                {
                    text: this.$store.getters.t("production_line"),
                    value: "pl_name",
                },
                {
                    text: this.$store.getters.t("status"),
                    value: "status",
                },
                {
                    text: this.$store.getters.t("production_date"),
                    value: "production_date",
                },
                {
                    text: "Actions",
                    sortable: false,
                    align: "right",
                    value: "actions",
                },
                { text: "", align: "end", value: "data-table-expand" },
            ];
        },
        project_uuid() {
            return this.$route.params.project_uuid;
        },
        constructibles() {
            return this.$store.getters["v2/constructibles/annotatedActualsFromLevel"](this.mode)
        },
        table_items() {
            return this.constructibles.map((ass) => {
                const const_store = this.$store.state.v2.constructibles
                let design_name = this.$store.getters["v2/constructibles/designNameByUuid"](ass.design, false)
                let batch = const_store.batches.find(b => b.uuid == ass.batch)
                let batch_name = batch?.name || "-"
                let phase_name = batch?.phase || "-"
                let production_line = this.$store.state.v2.factories.production_lines
                    .find(pl => pl.uuid == batch?.production_line)
                let schedule_str =
                    phase_name + " - " + batch_name + " (" + String(ass.priority).padStart(2, 0) + ")";
                let pl_name = production_line?.name || "---"
                let production_date = ass.scheduled_prod_date || "---"
                let to_route = {
                    name: `${ass.level}_detail`,
                    query: this.$route.query,
                    params: {
                        ...ass.level == 'assembly' && { ass_uuid: ass.uuid },
                        ...ass.level == 'module' && { mod_uuid: ass.uuid },
                        project_uuid: this.project_uuid
                    }
                }
                return {
                    ...ass,
                    design_name,
                    batch_name,
                    phase_name,
                    schedule_str,
                    pl_name,
                    production_date,
                    to_route,
                };
            });
        },
        selected_uuids() {
            return this.selected.map(s => s.uuid)
        },
    },
};
</script>