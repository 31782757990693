<template>
    <v-dialog v-if="project" v-model="dialog" max-width="800" persistent>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("generate_report_for_pr") }}:
                {{ project_name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="form_valid">
                    <v-row>
                        <v-col cols="6" class="pa-0">
                            <v-select outlined dense class="ma-2" v-model="report.document_type" :items="report_types"
                                :rules="[rules.required]" :label="$store.getters.t('report_type')"
                                @change="on_rtype_change"></v-select>
                        </v-col>
                        <v-col cols="6" class="pa-0" v-if="report.document_type == 'worktime_excel'">
                            <v-select outlined dense class="ma-2" v-model="report.meta.worktime_format"
                                :items="WORKTIME_FORMATS" :rules="[rules.required]" label="Worktime Excel Format">
                            </v-select>
                        </v-col>
                        <v-col cols="6" class="pa-2" v-if="PRODLINE_REPORTS.includes(report.document_type)">
                            <SelectProductionLine v-model="report.meta.production_line_uuid" @change="on_pl_change" />
                        </v-col>
                        <v-col cols="6" class="pa-2" v-if="MACHINE_REPORTS.includes(report.document_type)">
                            <SelectMachine v-model="report.meta.machine_uuid" @change="on_machine_change"
                                :report_type="report.document_type" />
                        </v-col>
                        <v-col cols="6" class="pa-0">
                            <v-text-field outlined dense class="ma-2" :label="$store.getters.t('document_comment')"
                                counter="150" :rules="[rules.less_than_150]" v-model="report.name">
                            </v-text-field>
                        </v-col>
                        <SelectReportStatus v-model="report.document_status" />
                        <v-checkbox class="mx-2" v-model="report.meta.options" value="materials_totals_page"
                            :label="$store.getters.t('materials_totals_page')"
                            v-if="TOTALS_PAGE_REPORTS.includes(report.document_type)"></v-checkbox>
                    </v-row>
                    <!-- 
                            👉🏽 ORDEN DE RELLENO DE DYNAMIC FORM 👈🏽
                            1 Defaults settings dynamic form
                            2 Los defaults de machine / production line
                            3 Contenido real del objeto
                            4 xxxxxx Modificaciones que se hagan xxxxxxxxxx
                         -->
                    <v-row v-for="df in dynamic_forms" :key="df.key">
                        <DynamicForm :title="df.title" :form_name="df.form_name" :options_resource="df.options_resource"
                            v-model="report.meta.dynamic_forms[df.key]" />
                    </v-row>
                    <SelectMultiMaterialSize v-if="show_multi_mat_size" v-model="report.meta.full_sizes"
                        :ass_uuids="report.selected_assemblies" part_tipo="plate" />
                    <SelectMultiMaterials v-if="show_multi_mat" v-model="report.meta.filter_materials"
                        :ass_uuids="report.selected_assemblies" :part_tipo="SELECT_MAT_PART_TIPO[report.document_type]" />
                    <SelectTreeActualsProject :project="project" v-model="report.selected_assemblies" :level="show_tree"
                        v-if="show_tree && !work_by_date" />
                    <SelectTreeActualsByDate v-model="report.selected_assemblies" :level="show_tree"
                        v-if="show_tree && work_by_date" />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" color="success" @click="submitReport">{{
                    $store.getters.t("submit")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import bus from "/src/utils/event_bus"
import { backend_api_v2 } from "/src/utils/apiv2"
import _ from "lodash"
import SelectProductionLine from "../fields/SelectProductionLine"
import SelectMachine from "./SelectMachine"
import SelectReportStatus from "./SelectReportStatus"
import SelectMultiMaterialSize from "./SelectMultiMaterialSize"
import SelectMultiMaterials from "./SelectMultiMaterials"
import SelectTreeActualsProject from "./SelectTreeActualsProject"
import SelectTreeActualsByDate from "./SelectTreeActualsByDate"
import DynamicForm from "/src/components/fields/DynamicForm"

import {
    // REPORT_PAPER_SIZES,
    WORKTIME_FORMATS,
    REPORT_TYPES,
    PROJECT_REPORT_TYPES,
    PROJECT_REPORT_TYPES_MOD_BOM,
    NEW_CLEAN_REPORT,
    MULTIMATERIAL_REPORTS,
    SELECT_MATERIALS_REPORTS,
    SELECT_ASSEMBLY_REPORTS,
    SELECT_MODULE_REPORTS,
    SELECT_MAT_PART_TIPO,
    TOTALS_PAGE_REPORTS,
    DEFAULT_JOB_REPORTS,
    MACHINE_REPORTS,
    PRODLINE_REPORTS,
} from "./forms.js"


export default {
    name: "DialogReportForm",
    components: {
        SelectProductionLine,
        SelectMachine,
        DynamicForm,
        SelectReportStatus,
        SelectMultiMaterialSize,
        SelectMultiMaterials,
        SelectTreeActualsProject,
        SelectTreeActualsByDate,
    },
    props: ["project", "value"],
    data: () => ({
        loading: false,
        dialog: false,
        form_valid: false,
        report: {},
        // REPORT_PAPER_SIZES,
        WORKTIME_FORMATS,
        MULTIMATERIAL_REPORTS,
        SELECT_MATERIALS_REPORTS,
        SELECT_ASSEMBLY_REPORTS,
        SELECT_MODULE_REPORTS,
        SELECT_MAT_PART_TIPO,
        TOTALS_PAGE_REPORTS,
        DEFAULT_JOB_REPORTS,
        MACHINE_REPORTS,
        PRODLINE_REPORTS,
        machine_models: [], // Async loaded
    }),
    computed: {
        // SHOW FLAGS
        show_tree() {
            if (SELECT_ASSEMBLY_REPORTS.includes(this.report.document_type)) {
                return "assembly"
            } else if (SELECT_MODULE_REPORTS.includes(this.report.document_type)) {
                return "module"
            } else return false
        },
        show_multi_mat_size() {
            return MULTIMATERIAL_REPORTS.includes(this.report.document_type)
        },
        show_multi_mat() {
            return SELECT_MATERIALS_REPORTS.includes(this.report.document_type)
        },
        // END OF SHOW FLAGS

        work_by_date() {
            return this.$store.getters["v2/session/work_by_date"]
        },
        m_machines() {
            return this.$store.getters["m_machines"]
        },
        report_types() {
            let rtypes = [...REPORT_TYPES]
            if (this.work_by_date || this.project?.uuid != "all") {
                if (this.m_machines) {
                    rtypes.push(...PROJECT_REPORT_TYPES)
                } else {
                    rtypes.push(...PROJECT_REPORT_TYPES_MOD_BOM)
                }
            }
            return rtypes
        },
        rules() {
            return {
                required: (v) => !!v || "required",
                less_than_150: (v) => (v && v.length < 151) || "Max chars: 150",
            }
        },
        project_name() {
            return this.project?.name || "---"
        },
        dynamic_forms() {
            if (DEFAULT_JOB_REPORTS.includes(this.report.document_type)) {
                return [
                    {
                        form_name: this.report.document_type + "_job",
                        key: "job",
                        title: "Document settings",
                    },
                ]
            }
            else if (MACHINE_REPORTS.includes(this.report.document_type)) {
                let dfs = []
                const model = this.selected_machine?.model
                const machine_uuid = this.selected_machine?.uuid
                const mm = this.machine_models.find((mm) => mm.name == model)
                mm?.formats
                    .filter((f) => f.name == this.report.document_type)
                    .forEach((f) => dfs.push({
                        form_name: f.settings,
                        key: machine_uuid,
                        title: "Job options",
                    }))
                return dfs
            }
            else if (PRODLINE_REPORTS.includes(this.report.document_type)) {
                let dfs = []
                this.selected_production_line?.stations.forEach((sta) => {
                    const machine_uuid = sta.machine_setup
                    if (machine_uuid) {
                        let machine =
                            this.$store.state.v2.factories.machines.find(
                                (m) => m.uuid == machine_uuid
                            )
                        let model = machine?.model
                        const mm = this.machine_models.find(
                            (mm) => mm.name == model
                        )
                        mm?.formats
                            .filter((f) => f.name == this.report.document_type)
                            .forEach((f) =>
                                dfs.push({
                                    form_name: f.settings,
                                    key: machine_uuid,
                                    title: sta.name + ": Station Job Options",
                                    options_resource: machine.parsed_setup,
                                })
                            )
                    }
                })
                return dfs
            }
            return []
        },
        production_lines() {
            return this.$store.state.v2.factories.production_lines
        },
        selected_production_line() {
            return this.production_lines.find(
                (pl) => pl.uuid == this.report.meta?.production_line_uuid
            )
        },
        machines() {
            return this.$store.state.v2.factories.machines
        },
        selected_machine() {
            return this.machines.filter(m => !!m).find(
                (m) => m.uuid == this.report.meta?.machine_uuid
            )
        },
    },
    watch: {
        value: {
            handler: function (value) {
                this.form = _.cloneDeep(value)
            },
            deep: true,
            immediate: true,
        },
        report: {
            handler: function (report) {
                this.$emit("input", report)
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        on_rtype_change() {
            this.$nextTick(() => this.fill_defaults())
        },
        fill_defaults() {
            // Clean forms related to PLs and machines
            delete this.report.meta.dynamic_forms[this.report.meta.machine_uuid]

            this.selected_production_line?.stations?.forEach((sta) => {
                if (sta.machine_setup) {
                    let ms = this.machines.find(
                        (m) => m.uuid == sta.machine_setup
                    )

                    if (ms) delete this.report.meta.dynamic_forms[ms.uuid]
                }
            })

            // Clean machines and PL ids
            this.report.meta.production_line_uuid = null
            this.report.meta.machine_uuid = null
        },
        on_machine_change() {
            this.$nextTick(() => this.fill_defaults_from_machine())
        },
        fill_defaults_from_machine() {
            // Initialize ALWAYS on MACHINE CHANGE
            this.report.meta.dynamic_forms = {}

            // Fill dynamic forms
            let sm = this.selected_machine
            let fresh_form = _.cloneDeep(sm.default_jobs[this.report.document_type])
            this.report.meta.dynamic_forms[sm.uuid] = fresh_form
        },
        on_pl_change() {
            this.$nextTick(() => this.fill_defaults_from_pl())
        },
        fill_defaults_from_pl() {
            // Initialize ALWAYS on PL CHANGE
            this.report.meta.dynamic_forms = {}

            this.selected_production_line?.stations?.forEach((sta) => {
                if (sta.machine_setup) {
                    let ms = this.machines.find(
                        (m) => m.uuid == sta.machine_setup
                    )
                    if (ms) {
                        let fresh_form = _.cloneDeep(ms.default_jobs[this.report.document_type])
                        this.report.meta.dynamic_forms[ms.uuid] = fresh_form
                    }
                }
            })
        },
        load_machine_models() {
            backend_api_v2
                .get("/machines/models/")
                .then((r) => (this.machine_models = r.data))
        },
        submitReport() {
            // Validate form
            if (!this.$refs.form.validate()) {
                bus.$emit("notification", {
                    timeout: 3000,
                    text: "Please fix errors in forms.",
                    color: "error",
                })
                return
            }
            this.loading = true

            // Fix project uuid
            this.report.project = this.project?.uuid
            if (this.report.project == "all") this.report.project = null

            // Determine store action
            let store_method = "v2/reports/putReport"
            if (this.report == null || !this.report.uuid)
                store_method = "v2/reports/postReport"

            // Launch
            this.$store
                .dispatch(store_method, this.report)
                .finally(() => {
                    this.loading = false
                    this.dialog = false
                })
        },
        newReport() {
            this.report = _.cloneDeep(NEW_CLEAN_REPORT)
        },
    },
    mounted() {
        this.newReport()
        this.$store.dispatch("v2/factories/loadMachines")
        this.load_machine_models()
        bus.$on("report_dialog", () => (this.dialog = true))
        bus.$on("report_dialog_update", (r) => (this.report = _.cloneDeep(r)))
    },
    beforeDestroy() {
        bus.$off("report_dialog")
        bus.$off("report_dialog_update")
    },
}
</script>