<template>
    <v-dialog
        ref="dialog"
        v-model="dialog"
        :return-value.sync="dates"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                label="Date range"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker v-model="dates" range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        dates: [null, null],
        dialog: false,
    }),
    computed: {
        dateRangeText() {
            if (this.dates == undefined) return "all time";
            return this.dates.join(" to ");
        },
    },
    watch: {
        value: {
            handler: function (v) {
                console.log("updated down");
                this.dates = v;
            },
            immediate: true,
        },
        dates(d) {
            console.log("updated up");
            this.$emit("input", d);
        },
    },
    methods: {
        setNull() {
            console.log("Setting null");
            this.dates[0] = null;
            this.dates[1] = null;
        },
        setEmpty() {
            console.log("Setting empty");
            this.dates[0] = "2020-01-01";
            this.dates[1] = "2022-12-31";
        },
    },
};
</script>