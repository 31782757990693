<template>
    <div>
        <div class="text-center" v-if="loading_proof && !upload_picture_mode">
            <v-progress-circular class="ma-9" :size="50" indeterminate color="primary"></v-progress-circular>
        </div>
        <v-card-text v-else>
            <br />
            <div v-if="upload_picture_mode">
                <div class="uppy-camera"></div>
            </div>
            <div v-else-if="proof">
                <ChecklistTaskRow v-for="i in station_tasks_idxs" :key="i" :task="proof.checklist[i]" :pck="pck"
                    @clickupload="startPictureMode(i)" @clickcheckbox="checkboxClick(i)"
                    @updateForm="updateForm(i, $event)" @reload="debouncedGetPackage" />
                <v-divider></v-divider>
            </div>
        </v-card-text>
        <v-card-actions v-if="upload_picture_mode">
            <v-spacer></v-spacer>
            <v-btn :disabled="uploading" @click="upload()" color="info">
                <v-icon left>mdi-upload</v-icon>
                {{ $store.getters.t("upload") }}
            </v-btn>
            <v-btn @click="closePictureMode()" color="secondary">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $store.getters.t("return") }}
            </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
            <v-btn @click="pauseProof" color="accent">
                <v-icon left> mdi-pause </v-icon>
                {{ $store.getters.t("pause") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!all_tasks_of_station_ready || loading_proof" @click="myStationStepIsReady()"
                color="error">
                <v-icon left>mdi-toolbox-outline</v-icon>
                {{
            station.is_last
                ? $store.getters.t("panel_ready")
                : $store.getters.t("station_job_completed")
        }}
            </v-btn>
            <v-btn @click="closeDialog" color="secondary">
                {{ $store.getters.t("close") }}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import _ from "lodash"
import { backend_api_v2 } from "/src/utils/apiv2"
import bus from "/src/utils/event_bus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"
import Webcam from "@uppy/webcam"
import ChecklistTaskRow from "./ChecklistTaskRow.vue"
export default {
    components: { ChecklistTaskRow },
    props: [
        "value",
        "station",
        "station_name",
        "loading_proof",
        "production_line_name",
    ],
    data: () => ({
        uploading: false,
        uppy: null,
        selected_task_idx: 0,
        upload_picture_mode: false,
        proof: [],
        pck: null,
    }),
    computed: {
        all_tasks_of_station_ready() {
            return this.station_tasks.every((t) => t.checked)
        },
        station_tasks_idxs() {
            let indices = []
            this.proof.checklist.forEach((t, i) => {
                if (
                    t.production_station == this.station_name &&
                    !["STATION_JOB_READY", "STATION_STARTED"].includes(t.text)
                )
                    indices.push(i)
            })
            return indices
        },
        station_tasks() {
            // don't use for things with setters
            return this.proof.checklist.filter((t, i) =>
                this.station_tasks_idxs.includes(i)
            )
        },
        proof_uuid() {
            return this.proof?.uuid
        },
        selected_task() {
            return this.proof.checklist[this.selected_task_idx]
        },
        selected_task_files() {
            let cfs = this.pck.files.filter(cf => {
                if (cf.meta?.text != this.selected_task.text) return false
                if (cf.meta?.production_station != this.station_name) return false
                if (cf.meta?.production_line != this.production_line_name) return false
                return true
            })
            return cfs
        }
    },
    watch: {
        value: {
            handler: function (v) {
                this.proof = v
            },
            immediate: true,
        },
        proof: {
            handler: function (p) {
                this.$emit("input", p)
            },
            deep: true,
        },
        proof_uuid: {
            handler: function () {
                this.getPackage()
            },
            immediate: true,
        },
    },
    methods: {
        // CHECKLIST MARKING
        checkboxClick(i) {
            // Timestamps a checked task
            if (this.proof.checklist[i].checked) {
                this.proof.checklist[i].timestamp = new Date()
            } else {
                this.proof.checklist[i].timestamp = null
            }
            this.$emit("save")
        },
        updateForm(i, form) {
            this.proof.checklist[i].form = form
            this.$emit("save")
        },
        async myStationStepIsReady() {
            this.proof.checklist.push({
                text: "STATION_JOB_READY",
                production_line: this.production_line_name,
                production_station: this.station_name,
                checked: true,
                timestamp: new Date(),
            })
            bus.$emit("back_event", {
                event: "station_ready",
                meta: {
                    frontend_timestamp: new Date(),
                    station_name: this.station_name,
                    station: this.station?.uuid,
                },
            })
            if (this.station.is_last) {
                this.proof.ready_date = new Date()
                // Backend sets proof as ready.
                bus.$emit("back_event", {
                    event: "constructible_ready",
                    meta: { frontend_timestamp: new Date() },
                })
            }
            this.$emit("save")
            this.$emit("close")
        },

        // PICTURE MODE
        startPictureMode(i) {
            this.selected_task_idx = i
            this.upload_picture_mode = true
            this.$nextTick(() => this.reloadUppy())
        },
        closePictureMode() {
            this.uppy.cancelAll()
            this.uppy.close()
            this.upload_picture_mode = false
            this.uploading = false
        },
        failedFile(f) {
            // Handle the error here
            let name = f.name
            bus.$emit('notification', { timeout: 5000, text: "UPLOAD FAILED:" + name, color: "error" })
            this.$store.dispatch("v2/cloudstorage/deleteCloudFileByPckAndName", { pck: this.pck?.uuid, name })
        },
        debouncedGetPackage: _.debounce(function () {
            this.getPackage()
        }, 1000),
        getPackage() {
            if (!this.proof_uuid) {
                return
            }
            backend_api_v2
                .post("/cloudstorage/packages/get_or_create/", {
                    name: "checklist",
                    proof: this.proof_uuid,
                })
                .then(({ data }) => { this.pck = data })
        },

        // UPLOADING PICTURES
        get_upload_parameters(file) {
            let meta = {
                text: this.proof.checklist[this.selected_task_idx].text,
                production_station: this.station_name,
                production_line: this.production_line_name
            }
            return backend_api_v2
                .post(`/cloudstorage/packages/${this.pck?.uuid}/presign_for_new_file/`, {
                    name: file.name,
                    type: file.type,
                    meta
                })
                .then((r) => r.data)
        },
        upload() {
            this.uppy.upload()
        },
        reloadUppy() {
            this.uppy = new Uppy({
                debug: true,
                restrictions: {
                    maxFileSize: 30000000,
                    maxNumberOfFiles: 10,
                    minNumberOfFiles: 1,
                    allowedFileTypes: ["image/*", "video/*"],
                },
            })
                .use(Dashboard, {
                    theme: this.$vuetify.theme.dark ? "dark" : "light",
                    target: ".uppy-camera",
                    inline: true,
                    hideUploadButton: true,
                    width: 750,
                    height: 400,
                    proudlyDisplayPoweredByUppy: false,
                    locale: {
                        strings: {
                            dropPasteImport:
                                "Add, %{browse} pictures or launch camera",
                        },
                    },
                })
                .use(AwsS3, {
                    metaFields: ["name"],
                    getUploadParameters: this.get_upload_parameters,
                })
                .use(Webcam, {
                    target: Dashboard,
                    modes: ["picture"],
                    facingMode: "environment",
                    mirror: false,
                })
            this.uppy.on("upload", () => (this.uploading = true))
            this.uppy.on("complete", (result) => {
                result.failed.forEach(this.failedFile)
                this.debouncedGetPackage()
                this.closePictureMode()
            })
        },
        closeDialog() {
            this.$emit("close")
        },
        pauseProof() {
            this.proof.paused = true
            this.$emit("save")
            bus.$emit("back_event", {
                event: "forced_pause",
                meta: { frontend_timestamp: new Date() },
            });
            this.$emit("close")
        },
    },
}
</script>