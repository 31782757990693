<template>
    <div class="d-flex flex-column align-space-between mb-3">
        <span class="display-1 mb-3 ml-1 font-weight-light">
            <v-icon left>mdi-clipboard-list-outline</v-icon>
            {{ $store.getters.t("part_lists") }}
        </span>
        <div v-if="analysis_status == 'waiting'">
            <v-alert outlined type="warning"> {{ $store.getters.t("part_lists_waiting") }}
            </v-alert>
        </div>
        <div v-else-if="analysis_status == 'working'">
            <v-alert outlined type="warning"> {{ $store.getters.t("part_lists_working") }}
            </v-alert>
        </div>
        <div v-else class="d-flex flex-row">
            <span class="caption" v-if="'branches' == loading">
                {{ $store.getters.t("loading_branches") }}
            </span>
            <!-- <span class="caption" v-else-if="branches.length == 0">
                {{ $store.getters.t("no_branches_detected_helptext") }}
            </span> -->
            <v-btn v-for="branch in branches" :key="branch" :disabled="loading == branch" :loading="loading == branch"
                color="secondary"  class="ma-1" @click="openWith(branch)">
                {{ $store.getters.t(branch) }}
            </v-btn>
            <ButtomUnknownsBOM v-if="'branches' != loading" />
        </div>
        <v-dialog v-model="dialog" max-width="1000">
            <v-card>
                <v-card-title class="headline"> {{ $store.getters.t("bill_of_materials") }} - {{ branch }} ({{ $store.getters.t("tracked_parts") }})</v-card-title>
                <v-card-subtitle> {{ $store.getters.t("select_part_to_hightlight") }} </v-card-subtitle>
                <v-data-table :loading="loading" :page.sync="page" :headers="headers" :items="table_items"
                    :no-data-text="$store.getters.t('no_parts_on_this_branch')">
                    <template v-slot:item.ref="{ item }">
                        {{ item.ref }}
                        <ChipPartTipo :value="item.tipo" />
                    </template>

                    <template v-slot:item.select="{ item }">
                        <v-btn color="primary" small @click="select(item.uuids)"> {{ $store.getters.t("select") }}
                        </v-btn>
                    </template>
                </v-data-table>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" color="secondary"> {{ $store.getters.t("close") }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { paginated_factory_v2, backend_api_v2 } from '/src/utils/apiv2'
import { clean_branches, size_string_from_part_design } from "./branches"
import { nameSort } from "/src/utils/basics.js";
import ButtomUnknownsBOM from "./ButtomUnknownsBOM"
import ChipPartTipo from "/src/components/renderers/ChipPartTipo"
export default {
    name: "ButtonBOM",
    components: { ButtomUnknownsBOM, ChipPartTipo },
    data: () => ({
        // State
        dialog: false,
        loading: false,  // used with strings
        branch: "wood",
        page: 1,
        last_ass_loaded: null,

        // Async loaded
        branches: [],
        branch_parts: [],

        // Static
        headers: [
            { text: "REF", value: "ref" },
            { text: "Material", value: "material" },
            { text: "Count", value: "count" },
            { text: "Size", value: "size" },
            { text: "Length", value: "length" },
            { text: "Comment", value: "comment" },
            { text: "Finish", value: "finish" },
            { text: "Zone", value: "zone" },
            { text: "Select", value: "select" },
        ],
    }),
    computed: {
        assembly() {
            return this.$store.state.v2.selected.selected_ass
        },
        analysis_status() {
            let status = this.$store.state.v2.selected.selected_assd?.meta?.analysis
            return status
        },
        table_items() {
            let rows = []
            this.branch_parts
                .forEach(part => {
                    let size = size_string_from_part_design(part.design)
                    let row = {
                        ref: part.design?.name,
                        tipo: part.design?.tipo,
                        material: part.design?.meta?.material,
                        count: 1,
                        size, // this is width if it's a plate
                        length: part.design?.meta?.length,
                        comment: part.meta?.comment,
                        finish: part.meta?.finish,
                        zone: part.meta?.zone,
                        uuids: [part.gid],
                    }
                    let found = rows.find(
                        (f_row) =>
                            f_row.ref == row.ref &&
                            f_row.material == row.material &&
                            f_row.comment == row.comment &&
                            f_row.finish == row.finish
                    );
                    if (found) {
                        found.count += 1;
                        found.uuids.push(part.gid);
                    } else {
                        rows.push(row);
                    }
                })
            rows.sort((a, b) => nameSort(a.material, b.material))
            return rows
        },
    },
    watch: {
        dialog(d) {
            if (!d) {
                this.page = 1;
            }
        },
        assembly(ass) {
            if (ass?.uuid != this.last_ass_loaded) this.loadBranches()
        }
    },
    methods: {
        select(uuids) {
            this.$store.commit("v2/viewer/setSelectedPartsUuids", uuids);
            this.dialog = false;
        },
        fixHeader(branch) {
            // Fix header
            let header = this.headers.find((h) => h.value == "size");
            if (branch == "plate") {
                header.text = "Width";
            } else {
                header.text = "Size";
            }
        },
        openWith(branch) {
            this.branch = branch
            this.loading = branch
            this.fixHeader(branch)
            // Do request with branch, assembly, serialized design, etc.
            let assuuid = this.assembly?.uuid
            let kwrd = ["frame", "plate"].includes(branch) ? "cat" : "branch"
            let url = `/constructibles/parts/?assembly=${assuuid}&${kwrd}=${branch}&serialize_design=true`
            paginated_factory_v2(url).then(({data}) => {
                this.branch_parts = data
                this.loading = false
                this.dialog = true
            });
        },
        loadBranches() {
            let assuuid = this.assembly?.uuid
            this.last_ass_loaded = assuuid
            this.branches = []
            if (!assuuid) return
            this.loading = "branches"
            let url = `/constructibles/assemblies/${assuuid}/pd_branches/`
            backend_api_v2.get(url).then(({ data }) => {
                this.branches = clean_branches(data)
                this.loading = false
            });
        }
    },
    mounted() {
        this.loadBranches()
    }
}

</script>