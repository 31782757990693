<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("standard_libraries") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="accent"
                v-if="expanded"
                text
                @click="new_standard_library"
            >
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">UUID</th>
                        <th class="text-center">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("std_number") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sl in standard_libraries" :key="sl.uuid">
                        <td>{{ sl.uuid.substring(0, 8) }}</td>
                        <td>{{ sl.name }}</td>
                        <td>{{ sl.project_number }}</td>
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(sl.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(sl.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_standard_library") }}
                        </v-card-title>
                        <v-card-text>
                            {{
                                $store.getters.t(
                                    "delete_standard_library_confirm"
                                )
                            }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn
                                @click="delete_standard_library"
                                color="error"
                                :disabled="delete_loading"
                                :loading="delete_loading"
                            >
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn
                                @click="delete_dialog = false"
                                color="secondary"
                            >
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("standard_library") }}:
                            {{ form.name }}
                        </v-card-title>
                        <v-card-text>
                            <v-form
                                ref="form"
                                @keyup.enter.native="post_standard_library"
                            >
                                <h2
                                    class="
                                        ml-1
                                        mb-3
                                        mt-5
                                        font-weight-light
                                        grey--text
                                    "
                                >
                                    {{
                                        $store.getters.t(
                                            "standard_library_params"
                                        )
                                    }}
                                </h2>
                                <v-text-field
                                    class="mx-1"
                                    outlined
                                    dense
                                    label="Name"
                                    :rules="rules"
                                    v-model="form.name"
                                >
                                </v-text-field>
                                <v-text-field
                                    class="mx-1"
                                    outlined
                                    dense
                                    label="STDLib Number"
                                    :rules="rules"
                                    v-model="form.project_number"
                                >
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="post_standard_library"
                                color="primary"
                                :loading="saving"
                                :disabled="saving"
                            >
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { by_name } from "/src/utils/basics";
import _ from "lodash";
export default {
    components: {},
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
        rules: [(v) => !!v || "Required"],
    }),
    computed: {
        standard_libraries() {
            return [...this.$store.state.v2.company.standard_libraries].sort(
                by_name
            );
        },
    },
    methods: {
        launch_edit(uuid) {
            let sl = this.standard_libraries.find((s) => s.uuid == uuid);
            this.form = _.cloneDeep(sl);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_standard_library() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/company/deleteStandardLibrary", this.delete_uuid)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
        new_standard_library() {
            this.form = {
                name: "",
                super_project: "std",
                project_number: "",
                standard_library: true,
            };
            this.dialog = true;
        },
        post_standard_library() {
            this.saving = true;
            if (this.$refs.form.validate()) {
                if (this.form.uuid) {
                    console.log("[^] Updating Standard Library");
                    this.$store
                        .dispatch("v2/company/putStandardLibrary", this.form)
                        .then(() => {
                            this.saving = false;
                            this.dialog = false;
                        });
                } else {
                    console.log("Creating Standard Library");
                    this.$store
                        .dispatch("v2/company/postStandardLibrary", this.form)
                        .then(() => {
                            this.saving = false;
                            this.dialog = false;
                        });
                }
            } else {
                this.saving = false;
            }
        },
    },
    mounted() {
        this.$store.dispatch("v2/company/loadStandardLibraries");
    },
};
</script>