<template>

    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="save_counts" :loading="loading" :disabled="loading"
                class="mr-2">
                <v-icon left>mdi-content-save-all-outline</v-icon>
            </v-btn>
        </template>
        <span>
            {{ $store.getters.t("save_counts_without_generating") }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    data: () => ({
        loading: false,
    }),
    computed: {
        project() {
            return this.$store.state.v2.selected.selected_project
        },
        project_meta() {
            return this.project?.meta
        },
    },
    methods: {
        save_counts() {
            this.loading = true
            let quantities = {
                "assd_batch_counts": this.$store.state.v2.quantities.assd_batch_counts,
                "assd_phase_counts": this.$store.state.v2.quantities.assd_phase_counts,
                "modd_batch_counts": this.$store.state.v2.quantities.modd_batch_counts,
                "modd_phase_counts": this.$store.state.v2.quantities.modd_phase_counts,
            }
            let meta = { ...this.project_meta, quantities }
            this.$store
                .dispatch("v2/company/putProject", {
                    uuid: this.project.uuid,
                    meta,
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>