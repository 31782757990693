<template>
    <v-icon :color="value ? 'green' : 'grey'" small>
        {{ value ? "mdi-check-decagram" : "mdi-close-circle" }}
    </v-icon>
</template>

<script>
export default {
    props: ["value"],
};
</script>