// NEED A WATCHER HERE THAT WATCHES THE SELECTED ACTUAL AND LOADS THE RCP

let REPORT_TIMEOUTS_QUEUE = []

export default function (store) {
    // Load reports
    store.watch(
        (state) => state.v2.selected.pr_selection,
        (uuid) => {
            store.dispatch('v2/reports/loadReports', uuid).then(() => store.commit("v2/reports/setReady"))
            store.commit("v2/reports/setLoading")
        }
    )

    store.watch(
        // Watch al reports
        state => state.v2.reports.reports,
        reports => {
            reports
                // Only reports that are working or waiting
                .filter(r => ["working", "waiting"]
                    .some((s) => r.last_file?.meta?.status.includes(s))
                )
                .forEach(r => {
                    // If it's not in the queue, launch timeout
                    if (!REPORT_TIMEOUTS_QUEUE.includes(r.uuid)) {
                        // Add to the queue
                        REPORT_TIMEOUTS_QUEUE.push(r.uuid)
                        setTimeout(() => {
                            // On execution of timeout, remove from queue
                            REPORT_TIMEOUTS_QUEUE = REPORT_TIMEOUTS_QUEUE.filter(q => q != r.uuid)
                            store.dispatch("v2/reports/refreshReport", r.uuid)
                        },
                            3000
                        )
                    }
                }

                )
        },
        { deep: true }
    )
}