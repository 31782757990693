<template>
    <v-select outlined dense class="mx-1" v-model="selected_status" :items="status_values"
        :label="$store.getters.t('status')" multiple></v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ 
        selected_status: [],
        status_values: ['ready', 'working', 'waiting', 'kitted_parts'],
    }),
    computed: {},
    watch: {
        selected_status(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_status = v;
            },
            immediate: true,
        },
    }
};
</script>

  