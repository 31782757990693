<template>
    <v-navigation-drawer v-model="drawer" absolute right width="400">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-btn icon @click.stop="drawer = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
        <v-divider></v-divider>
        <!-- Fancy icons: mdi-robot-industrial -->
        <v-card-text v-if="cat == 'batch'">
            <ContextualInfoBatch :item="item" />
        </v-card-text>
        <v-card-text v-else-if="cat == 'panel'">
            <ContextualInfoPanel :item="item" />
        </v-card-text>
        <v-card-text v-else-if="cat == 'production_line'">
            <ContextualInfoProductionLine :item="item" />
        </v-card-text>
    </v-navigation-drawer>
</template>

<script>
import bus from "/src/utils/event_bus";
import ContextualInfoBatch from "./ContextualInfoBatch";
import ContextualInfoPanel from "./ContextualInfoPanel";
import ContextualInfoProductionLine from "./ContextualInfoProductionLine";

export default {
    components: {
        ContextualInfoBatch,
        ContextualInfoPanel,
        ContextualInfoProductionLine,
    },
    data: () => ({
        drawer: false,
        item: null,
        cat: "phase",
    }),
    watch: {
        item() {
            this.drawer = true;
        },
    },
    computed: {
        title() {
            return this.item?.name || "---";
        },
        subtitle() {
            if (this.cat == "batch") return this.item?.status_text;
            if (this.cat == "panel") return this.item?.status;
            return "";
        },
    },
    methods: {
        update_item(payload) {
            this.drawer = true;
            this.item = payload.item;
            this.cat = payload.cat;
        },
    },
    mounted() {
        bus.$on("po_detail", this.update_item);
    },
    beforeDestroy() {
        bus.$off("po_detail");
    },
};
</script>