<template>
    <div>
        <v-text-field :label="$store.getters.t('report_search')" outlined v-model="search" clearable />
        <v-data-table :headers="headers" :items="reports_table_items" item-key="uuid" show-expand single-expand
            :expanded.sync="expanded" :loading="loading ? 'accent' : false"
            loading-text="Loading reports... please wait." :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
            }" :items-per-page="50" :item-class="item_class">
            <template v-slot:item.status="{ item }">
                <ChipReportStatus :status="item.last_status" />
            </template>
            <template v-slot:item.modified="{ item }">
                <Timestamp :value="item.modified" />
            </template>
            <template v-slot:item.actions="{ item }">
                <RowReportActions :report="item" />
            </template>
            <template v-slot:no-data>
                <span>{{ $store.getters.t("create_first_report") }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td class="pa-0" :colspan="headers.length">
                    <TableReportVersions :versions="item.files" @refresh="refresh_as_is(item)" />
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<style>
.report-status-final {
    background-color: #fba7533d;
}
</style>

<script>
import Timestamp from "/src/components/renderers/Timestamp"
import TableReportVersions from "./TableReportVersions.vue"
import ChipReportStatus from "./ChipReportStatus.vue"
import RowReportActions from "./RowReportActions.vue"
export default {
    components: {
        Timestamp,
        TableReportVersions,
        ChipReportStatus,
        RowReportActions
    },
    props: ["reports", "loading"],
    data: () => ({
        expanded: [],
        selected: [],
        search: "",
    }),
    computed: {
        headers() {
            return [
                { text: this.$store.getters.t("title"), value: "name" },
                {
                    text: this.$store.getters.t("document_type"),
                    value: "document_type",
                },
                {
                    text: this.$store.getters.t("last_report_status"),
                    value: "status",
                },
                {
                    text: this.$store.getters.t("document_status"),
                    value: "document_status_text",
                },
                {
                    text: this.$store.getters.t("last_modified"),
                    value: "modified",
                },
                {
                    text: this.$store.getters.t("user"),
                    value: "username",
                },
                {
                    text: this.$store.getters.t("actions"),
                    value: "actions",
                    sortable: false,
                    align: "right",
                },
                { text: "", value: "data-table-expand" },
            ]
        },
        reports_table_items() {
            const low_search = this.search.toLowerCase()
            return this.reports
                .map((report) => {
                    let document_status_text = this.$store.getters.t(
                        "rep_status_" + report.document_status
                    )
                    if (document_status_text.includes("$vuetify"))
                        document_status_text = report.document_status

                    return {
                        ...report,
                        last_status: report.last_file?.meta.status || "missing",
                        document_status_text,
                    }
                })
                .filter(
                    (report) =>
                        report.document_status
                            ?.toLowerCase()
                            .replace("_", " ")
                            .includes(low_search) ||
                        report.document_type
                            ?.toLowerCase()
                            .includes(low_search) ||
                        report.document_status_text
                            ?.toLowerCase()
                            .includes(low_search) ||
                        this.last_file(report)
                            .status?.toLowerCase()
                            .replace("_", " ")
                            .includes(low_search) ||
                        report.name?.toLowerCase().includes(low_search) ||
                        report.username?.toLowerCase().includes(low_search)
                )
        },
    },
    methods: {
        refresh_as_is(report) {
            this.$store.dispatch("v2/reports/refreshReport", report.uuid)
        },
        report_as_final(report) {
            this.$store.dispatch("v2/reports/refreshReport", report.uuid)
        },
        last_file(report) {
            if (report.last_file) return report.last_file

            // Somehow this list can be empty and breaks the render
            return {
                presigned_url: "",
                meta: { version: 0, status: "waiting", },
                created: "",
            }
        },
        item_class(item) {
            if (item.document_status === 'final') {
                return 'report-status-final';
            }
            return '';
        },
    },
}
</script>