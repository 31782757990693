<template>
    <v-container>
        <v-card outlined>
            <v-card-title>
                <v-btn text @click="expanded = !expanded">
                    <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                    <v-icon v-else> mdi-chevron-down </v-icon>
                    {{ title }}
                </v-btn>
            </v-card-title>
            <v-card-text v-show="expanded">
                <v-row v-for="section in sections" :key="section">
                    <v-col cols="12">
                        <h4>{{ section }}</h4>
                    </v-col>
                    <v-col cols="6" v-for="line in get_cs_of_section(section)" :key="line.name">
                        <DynamicField v-model="form[line.name]" :schema="line" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>


<script>
import { backend_api_v2 } from "/src/utils/apiv2"
import DynamicField from "/src/components/fields/DynamicField"

export default {
    components: { DynamicField },
    props: ["value", "form_name", "options_resource", "title"],
    data: () => ({
        form_settings: {},
        form: {},
        expanded: false,
    }),
    watch: {
        form_name: {
            handler: function (fn) {
                this.form = {}
                this.load_form(fn)
            },
            immediate: true,
        },
        value: {
            handler: function (v) {
                console.log("HANDLING VALUE", JSON.stringify(v))
                if (v) this.form = v
                if (v && Object.keys(v).length == 0)
                    // When fresh, better to reload to fill defaults
                    this.load_form(this.form_name)
            },
            deep: true,
            immediate: true,
        },
        form: {
            handler: function (form) {
                console.log("EMMITING UPDATE", JSON.stringify(form))
                this.$emit("input", form)
            },
            immediate: true,
            deep: true,
        },
        crafted_settings(cf) {
            this.fill_form_with_defaults(cf)
        },
    },
    computed: {
        crafted_settings() {
            let settings = this.form_settings
            if (!settings) return settings
            if (!this.options_resource) return settings
            if (!Array.isArray(settings)) {
                return settings
            }
            return settings.map((line) => {
                if (!line.options) return line
                let options = line.options
                    .map(this.map_option_from_resource)
                    .filter((op) => op != "PLEASE_HIDE")
                let new_line = { ...line, options }
                return new_line
            })
        },
        sections() {
            // This returns a list of sections readed from the crafted_settings
            if (Array.isArray(this.crafted_settings)) {
                let sections = this.crafted_settings.map(
                    (cs) => cs.section || "General"
                )
                sections = [...new Set(sections)].filter((s) => s != "General")
                // General first
                return ["General", ...sections]
            }
            return ["General"]
        },
    },
    methods: {
        fill_form_with_defaults(crafted_settings) {
            crafted_settings.forEach((line) => {
                if (!(line.name in this.form)) {
                    this.form[line.name] = line.default_value
                    // this.$set(this.form, line.name, line.default_value)
                }
            })
        },
        get_cs_of_section(section) {
            if (!this.crafted_settings) {
                console.log("empty CASE")
                return []
            }
            else if (!Array.isArray(this.crafted_settings)) {
                console.log("NOT ARRAY CASE")
                return this.crafted_settings
            }
            else {
                console.log("ARRAY CASE")
                return this.crafted_settings.filter(
                    (s) =>
                        s.section == section ||
                        (!s.section && section == "General")
                )
            }
        },
        load_form(form_name) {
            backend_api_v2
                .get("/dynamic_form_settings/" + form_name + "/")
                .then((r) => this.build_form(r))
        },
        map_option_from_resource(op) {
            try {
                if (typeof op == "string" && op.startsWith("XML__")) {
                    let spl = op.split("__")
                    let section = spl[1]
                    let key = spl[2]
                    let value = spl[3]
                    if (this.options_resource[section]?.[key]) return value
                    else return "PLEASE_HIDE"
                } else return op
            } catch {
                return op
            }
        },
        build_form(response) {
            if (response.status == 200) {
                this.form_settings = response.data.settings
            }
        },
    },
}
</script>