<template>
    <div class="flex-container">
        <v-row v-if="loading" dense class="mt-1 mb-3">
            <v-skeleton-loader :loading="loading" type="table-thead, table-tbody"></v-skeleton-loader>
        </v-row>
        <v-row v-else :disabled="true">
            <table>
                <thead>
                    <tr>
                        <th class="text-center align-center">Zone</th>
                        <th class="text-center align-center">Reference</th>
                        <th class="text-center align-center">Count</th>
                        <th class="text-center align-center">Counts by Constructible</th>
                        <th class="text-center align-center" v-if="selected_branch === 'plate'">Width</th>
                        <th class="text-center align-center" v-if="selected_branch === 'plate'">Length</th>
                        <th class="text-center align-center" v-else>Length</th>
                        <th class="text-center align-center">Ready Parts</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(group, index) in gr" :key="index">
                        <td v-if="is_new_zone(index)" :rowspan="get_zone_row_count(index)"
                            class="text-center align-center">
                            <strong>
                                <h2>
                                    {{ group.zone }}
                                </h2>
                            </strong>
                        </td>
                        <td class="text-center align-center">
                            <v-btn text @click="select(group.pd_uuid)"> {{ group.ref }} </v-btn>
                        </td>
                        <td class="text-center align-center">{{ group.count }}</td>
                        <td class="text-center align-center">
                            <v-chip v-for="(count_design, chip_index) in group.counts_per_constructible" class="ma-1"
                                :key="chip_index" :color="get_chip_color(final_status(count_design))" outlined small>
                                {{ count_design.count }}/{{ count_design.design }}[{{ count_design.uuid }}]
                            </v-chip>
                        </td>
                        <td class="text-center align-center" v-if="selected_branch === 'plate'">{{ group.width }}</td>
                        <td class="text-center align-center" v-if="selected_branch === 'plate'">{{ group.length }}</td>
                        <td class="text-center align-center" v-else>{{ group.length }}</td>
                        <EditableLogisticsCell :part="group" class="text-center align-center editable-cell" />
                    </tr>
                </tbody>
            </table>
            <ButtonAllPartsReady v-if="gr.length > 0" :groups="gr" class="flex-item" />
        </v-row>
    </div>
</template>

<style scoped>
table {
    width: 100%;
}

.flex-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.flex-item {
    width: 100%;
}

td {
    border: 1px solid #dddddd;
    border-radius: 3px;
    text-align: left;
    padding: 8px;
}


td.editable-cell {
    width: 160px;
    height: 55px;
}
</style>


<script>
import { part_grouping } from "./logistics_table_utils"
import EditableLogisticsCell from "./EditableLogisticsCell.vue";
import ButtonAllPartsReady from "./ButtonAllPartsReady.vue";

export default {
    components: {
        EditableLogisticsCell,
        ButtonAllPartsReady
    },
    data: () => ({
    }),
    computed: {
        gr() {
            return this.groups.flatMap(zone => zone.parts);
        },
        branchAlike() {
            return this.$store.getters["v2/selected_logistics/branchAlike"];
        },
        parts() {
            return this.$store.state.v2.selected_logistics.parts;
        },
        loading() {
            return this.$store.getters["v2/selected_logistics/isLoading"]("parts");
        },
        selected_size() {
            return this.$store.state.v2.selected_logistics.selected_size;
        },
        groups() {
            return part_grouping(this.parts);
        },
        selected_branch() {
            return this.$store.state.v2.selected_logistics.selected_branch;
        },
    },
    methods: {
        select(pd_uuid) {
            this.$store.commit("v2/selected_logistics/setSelectedPart", pd_uuid);
        },
        is_new_zone(index) {
            return index === 0 || this.gr[index].zone !== this.gr[index - 1].zone;
        },
        get_zone_row_count(index) {
            let zone = this.gr[index].zone;
            let count = 1;
            for (let i = index + 1; i < this.gr.length; i++) {
                if (this.gr[i].zone === zone) {
                    count++;
                }
                else {
                    break;
                }
            }
            return count;
        },
        final_status(counts_per_constructible) {
            let statuses = counts_per_constructible.statuses
            if (statuses.every(status => status === 'waiting')) {
                return 'waiting';
            } else if (statuses.every(status => status === 'ready')) {
                return 'ready';
            } else {
                return 'working';
            }
        },
        get_chip_color(status) {
            if (status === 'waiting') {
                return 'grey';
            } else if (status === 'ready') {
                return 'success';
            } else {
                return 'warning';
            }
        },
    },
}
</script>