<template>
    <v-select class="mx-1" outlined dense v-model="selected_station" :items="production_stations"
        label="Production Station">
    </v-select>
</template>

<script>
import { nameSort } from "/src/utils/basics.js";
export default {
    props: ["value"],
    data: () => ({ selected_station: "" }),
    watch: {
        value(v) {
            this.selected_station = v;
        },
        selected_station(sta) {
            this.$emit("input", sta);
        },
    },
    computed: {
        production_lines() {
            return this.$store.state.v2.factories.production_lines;
        },
        production_stations() {
            let stations = [{ text: "No defined location", value: null }];
            this.production_lines.forEach((line) => {
                line.stations.forEach((station) => {
                    stations.push({
                        value: station.uuid,
                        text: `[PL: ${line.name}] [Order: ${station.order}] ${station.name} `,
                    });
                });
            });
            return stations.sort((a, b) => nameSort(a.text, b.text));
        },
    },
};
</script>