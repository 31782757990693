<template>
    <v-dialog width="1200" v-model="dialog" hide-overlay transition="dialog-top-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-if="listitem != undefined" v-bind="attrs" v-on="on">
                <v-list-item-icon>
                    <v-badge color="green" :value="unread_count" :content="unread_count">
                        <v-icon> mdi-message-text </v-icon>
                    </v-badge>
                </v-list-item-icon>
                <v-list-item-title>
                    chat
                </v-list-item-title>
            </v-list-item>
            <v-btn v-else icon large v-bind="attrs" v-on="on">
                <v-badge color="green" :value="unread_count" :content="unread_count">
                    <v-icon> mdi-message-text </v-icon>
                </v-badge>
            </v-btn>
        </template>

        <template v-slot:default="dialog">
            <v-card v-if="dialog.value">
                <v-toolbar dark>
                    <v-toolbar-title> Chat </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dialog.value = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <ChatBox />
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import ChatBox from "/src/components/chat/ChatBox";
export default {
    components: { ChatBox },
    props: ["listitem"],
    data: () => ({
        interval_count: null,
        interval_LastMessages: null,
        dialog: false,
    }),
    computed: {
        unread_count() {
            return this.$store.state.v2.mail.unread_count;
        },
    },
    watch: {
        dialog(newVal) {
            if (newVal) {
                this.$store.dispatch("v2/mail/loadLastMessages");
                this.interval_LastMessages = setInterval(() => this.$store.dispatch("v2/mail/loadLastMessages"), 15000);
            } else {
                console.log("killing loadmessages timeout", clearInterval(this.interval_LastMessages));
            }
        },
    },
    methods: {
        loadUnreadCount() {
            this.$store
                .dispatch("v2/mail/loadUnreadCount");
        },
    },
    mounted() {
        this.loadUnreadCount();
        this.interval_count = setInterval(this.loadUnreadCount, 15000);
    },
    beforeDestroy() {
        console.log("killing count timeout", clearInterval(this.interval_count));
    },
};
</script>