<template>
    <div>
        <div class="my-2">
            <v-progress-circular
                size="24"
                class="mr-2"
                indeterminate
                v-if="loading"
            />
            <v-icon class="mr-2" v-else> mdi-garage-variant </v-icon>
            UUID: {{ item.uuid.substring(0, 8) }}
        </div>
        <div class="my-2">
            <v-icon class="mr-2"> mdi-home-modern </v-icon>
            {{ project_name }}
        </div>
        <div class="my-2">Production Line: {{ pl_name }}</div>
        <div class="mb-5">
            <h3>Status</h3>
            <PanelsProgressBar :panels="item.panels" />
        </div>
        <BatchTimesBox :batch="batch" :item="item" />
        <div v-if="batch">
            <div class="d-flex">
                <SelectBatchProductionLine :batch="batch" :asyncmode="true" />
                <FieldBatchPriority :batch="batch" />
            </div>
            <div class="my-4"></div>
            <FieldBatchStartDate :batch="batch" />
            <FieldBatchDeliveryDate :batch="batch" />
            <v-alert
                v-if="item.warning_date"
                class="my-0 mx-2"
                dense
                outlined
                type="error"
            >
                {{ $store.getters.t("warning_date_alert") }}
            </v-alert>
        </div>
        <v-sheet class="pa-2 my-2" outlined rounded>
            <h4>{{ $store.getters.t("timestamps") }}</h4>
            <ForecastTimesTable :item="item" />
        </v-sheet>
        <div class="d-flex">
            <h3>{{ $store.getters.t("panels") }}</h3>
            <v-spacer></v-spacer>
            <ButtonConstructiblesSortingDialog
                :batch="batch"
                v-if="batch"
                :asyncmode="true"
            />
        </div>
        <v-hover
            v-slot="{ hover }"
            v-for="panel in item.panels"
            :key="panel.uuid"
        >
            <v-sheet
                outlined
                class="pa-2 my-2 panel-sheet"
                :class="{ hover: hover }"
                rounded
                @click="click_panel(panel)"
            >
                <h4>{{ panel.name }} ({{ panel.status }})</h4>
                <ForecastTimesTable :item="panel" />
            </v-sheet>
        </v-hover>
    </div>
</template>
<style scoped>
.panel-sheet {
    cursor: pointer;
}
.panel-sheet.hover {
    border-color: lightgrey;
}
</style>
<script>
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "/src/utils/apiv2";
import SelectBatchProductionLine from "/src/components/quantities/SelectBatchProductionLine";
import ButtonConstructiblesSortingDialog from "/src/components/sorting/ButtonConstructiblesSortingDialog";
import FieldBatchPriority from "./FieldBatchPriority.vue";
import FieldBatchStartDate from "./FieldBatchStartDate.vue";
import FieldBatchDeliveryDate from "./FieldBatchDeliveryDate.vue";
import PanelsProgressBar from "./PanelsProgressBar";
import ForecastTimesTable from "./ForecastTimesTable";
import BatchTimesBox from "./BatchTimesBox";
export default {
    components: {
        SelectBatchProductionLine,
        FieldBatchPriority,
        FieldBatchStartDate,
        FieldBatchDeliveryDate,
        ButtonConstructiblesSortingDialog,
        PanelsProgressBar,
        ForecastTimesTable,
        BatchTimesBox,
    },
    props: ["item"],
    data: () => ({
        batch: null,
        loading: false,
    }),
    computed: {
        pl_name() {
            const uuid = this.batch?.production_line;
            let ps = this.$store.state.company.production_sites.find(
                (ps) => ps.uuid == uuid
            );
            return ps?.name || "No production line";
        },
        batch_uuid() {
            return this.item.uuid;
        },
        project_name() {
            const pr = this.$store.state.v2.company.projects.find(
                (p) => p.uuid == this.batch?.project
            );
            if (pr?.super_project)
                return pr ? `${pr?.name} [${pr?.super_project}]` : "...";
            else return pr?.name;
        },
    },
    watch: {
        batch_uuid: {
            handler: function (buuid) {
                // Async load the batch when it changes
                this.loading = true;
                this.batch = null;
                backend_api_v2
                    .get(`/constructibles/batches/${buuid}/`)
                    .then(({ data }) => {
                        this.loading = false;
                        this.batch = { ...data };
                        // delivery_date
                        this.delivery_date = this.batch.delivery_date;
                    })
                    .catch((e) => {
                        this.loading = false;
                        console.log(`Cant load batch: ${e}`);
                    });
            },
            immediate: true,
        },
    },
    methods: {
        click_panel(panel) {
            bus.$emit("po_detail", { cat: "panel", item: panel });
        },
    },
};
</script>