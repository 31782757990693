<template>
    <v-dialog overlay-color="black" overlay-opacity="0.85" v-model="dialog" width="600">
        <v-card>
            <v-card-title class="headline" v-if="level == 'module'">
                {{ $store.getters.t("generate_modules") }}
            </v-card-title>
            <v-card-title class="headline" v-if="level == 'assembly'">
                {{ $store.getters.t("generate_assemblies") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("generate_constructibles_explanation") }}
                <v-radio-group v-model="level">
                    <v-radio label="Modules" value="module"></v-radio>
                    <v-radio label="Assemblies" value="assembly"></v-radio>
                </v-radio-group>
                <v-container>

                    <v-alert v-if="last_test === false" type="error"> Numbers are wrong. Please review your setup </v-alert>
                    <v-alert v-else-if="last_test.length == 0" type="warning"> No changes in counts from existent
                        constructibles. </v-alert>
                    <v-row v-else>
                        <v-col>
                            <v-card-subtitle>
                                {{ $store.getters.t("generate_constructibles_create") }}
                            </v-card-subtitle>
                            <ul>
                                <li v-for="row in generation_rows" :key="row.design">
                                    [Phase: {{ row.phase }}] [Batch: {{ row.batch_name }}]
                                    {{ row.design_name }} ({{ row.count }} units)
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="lock_button" :loading="generating" @click="generateConstructibles">
                    <v-icon> </v-icon> {{ $store.getters.t("generate") }}
                </v-btn>
                <v-btn @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import bus from "/src/utils/event_bus";

export default {
    props: ["value"],
    data: () => ({
        dialog: null,
        loading_test: false,
        last_test: [],
        level: "assembly",
    }),
    computed: {
        generating() {
            return this.$store.state.v2.quantities.generating
        },
        lock_button() {
            return this.generating || !this.last_test
        },
        generation_rows() {
            return this.last_test.map(row => {
                const b = this.$store.state.v2.constructibles.batches.find(b => b.uuid == row.batch)
                return {
                    count: row.count,
                    design_name: this.$store.getters["v2/constructibles/designNameByUuid"](row.design),
                    batch_name: b?.name,
                    phase: b?.phase,
                }
            })
        },
        generator_action_name() {
            if (this.level == "module") {
                return "v2/quantities/generateModulesFromCounts"
            }
            else if (this.level == "assembly") {
                return "v2/quantities/generateAssembliesFromCounts"
            }
            else return "v2/quantities/generateAssembliesFromCounts"
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function (v) { this.dialog = v }
        },
        dialog(d) {
            this.$emit("input", d)
            if (d) {
                let lev = this.$route.query.level
                if (lev != "all") this.level = lev
                this.load_test()
            }
        },
        level() {
            this.load_test()
        }
    },
    methods: {
        load_test() {
            this.loading_test = true
            this.$store
                .dispatch(this.generator_action_name, true)
                .then((tests) => this.last_test = tests)
                .catch(() => this.last_test = false)
                .finally(() => this.loading_test = false)
        },
        generateConstructibles() {
            this.$store.commit("v2/quantities/setGenerating", true)
            bus.$emit("quantities_reset_button_clean_meta")
            this.$store
                .dispatch(this.generator_action_name, false)
                .then(() => {
                    bus.$emit("events/constructibles/reloadConstructibles")
                })
                .finally(() => {
                    this.$store.commit("v2/quantities/setGenerating", false)
                    this.dialog = false
                });
        },
    }
}
</script>
