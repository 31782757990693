<template>
    <div class="d-flex flex-column align-space-between mb-1 ml-1 mr-1" v-if="show">
        <v-btn large icon v-if="icon" @click="dialog = true">
            <v-icon>mdi-qrcode</v-icon>
        </v-btn>
        <v-btn small color="secondary" @click="dialog = true" v-else>
            <v-icon left>mdi-qrcode</v-icon>QR
        </v-btn>
        <v-dialog v-model="dialog" width="400">
            <v-card>
                <v-card-title class="headline">{{
                        $store.getters.t("qr_code")
                }}</v-card-title>
                <v-card-subtitle v-if="actual_mode">{{ $store.getters.t("wall_unit") }}:
                    {{ actual_uuid.substring(0, 8) }}</v-card-subtitle>
                <v-card-subtitle v-if="phase_mode">{{ $store.getters.t("phase") }}:
                    {{ phase }}</v-card-subtitle>
                <v-card-subtitle v-if="batch_mode">{{ $store.getters.t("batch") }}:
                    {{ batch.name }}</v-card-subtitle>
                <v-card-text>
                    <qrcode :value="url" :options="{ width: 350 }" tag="img" id="qr-image"></qrcode>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="printQR" color="accent">
                        <v-icon left>mdi-printer</v-icon>
                        {{ $store.getters.t("print") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">{{
                            $store.getters.t("close")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "ButtonQRShow",
    props: ["actual", "project", "phase", "batch", "icon"],
    data: () => ({
        dialog: false,
    }),
    computed: {
        url() {
            // if (this.phase_mode) {
            //     let href = window.location.href;
            //     let url = href.replace(this.$route.path, "");
            //     return url + "/HERE_ADD_BATCH_ROUTE!!!!!";
            // } else {
            return window.location;
            // }
        },
        show() {
            return this.phase == undefined || this.phase != "0";
        },
        actual_mode() {
            return this.actual != undefined;
        },
        actual_uuid() {
            return this.actual?.uuid || ""
        },
        phase_mode() {
            return (
                this.project != undefined &&
                this.phase != undefined &&
                this.phase != "0"
            );
        },
        batch_mode() {
            return this.batch != undefined;
        },
    },
    methods: {
        printQR() {
            console.log(document.getElementById("qr-image").outerHTML);
            let popup = window.open("", "_blank");
            popup.document.body.innerHTML =
                document.getElementById("qr-image").outerHTML;
            popup.focus(); //required for IE
            popup.print();
        },
    },
};
</script>