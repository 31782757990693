<template>
    <v-form ref="form" @keyup.enter.native="$emit('save')">
        <h2 class="ml-1 mb-3 mt-5 font-weight-light grey--text">
            {{ $store.getters.t("site_parameters") }}
        </h2>
        <div class="d-flex">
            <PitTextField class="mx-1" outlined label="Name" v-model="form.name" maxlength="60"></PitTextField>
            <SelectTipo multiple dense required v-model="form.tipos" />
        </div>
        <v-alert outlined type="error" v-if="show_same_machine_alert"> {{ $store.getters.t("pl_same_machine_alert") }}
        </v-alert>
        <div>
            <div class="ml-2 mb-10">
                <span v-if="checklist_subsets.length != 0" class="caption"> {{ $store.getters.t("pl_subsets") }}: {{
                    checklist_subsets.join(", ")
                }}</span>
                <span v-else class="caption"> {{ $store.getters.t("pl_no_subsets") }} </span>
            </div>
        </div>
        <div class="d-flex my-3">
            <h2 class="mb-3 font-weight-light grey--text">
                {{ $store.getters.t("stations") }}
            </h2>
            <v-spacer> </v-spacer>
            <v-btn text @click="add_station">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("add_station") }}
            </v-btn>
        </div>
        <template v-for="(station, i) in form.stations">
            <v-sheet rounded outlined class="my-3 pa-3" :key="i + '_sheet'">
                <div class="d-flex mb-3" :key="i">
                    <div style="width: 250px">
                        <h4 class="mb-2 font-weight-light grey--text">
                            <span v-if="station.is_last">[{{ $store.getters.t("last") }}]</span>
                            {{ $store.getters.t("station_parameters") }}
                        </h4>
                        <PitTextField class="mx-1" dense outlined label="Name" v-model="station.name" maxlength="60">
                        </PitTextField>
                        <v-text-field class="mx-1" dense outlined label="Client ID" v-model="station.client_station_id"
                            maxlength="40" />
                        <!-- <SelectStationKeywords v-model="station.keywords" /> -->
                        <v-combobox class="mx-1" dense outlined multiple :label="$store.getters.t('disabled_subsets')"
                            clearable :items="checklist_subsets" v-model="station.disabled_subsets">
                        </v-combobox>
                        <h4 class="mb-2 font-weight-light grey--text"> Zones </h4>
                        <InputZones v-model="station.zones" />

                    </div>
                    <div class="ml-5" style="width: 600px">
                        <div class="d-flex">
                            <h4 class="mb-2 font-weight-light grey--text">
                                {{ $store.getters.t("checklist") }}
                            </h4>
                            <v-spacer> </v-spacer>
                            <v-btn small text @click="add_chi(i)">
                                <v-icon left> mdi-plus </v-icon>
                                {{ $store.getters.t("add_item") }}
                            </v-btn>
                        </div>
                        <template v-for="(chi, j) in station.checklist_items">
                            <div :key="`${i}_${j}_iconrow`" class="d-flex mx-2">
                                <v-icon small>
                                    mdi-numeric-{{ j + 1 }}-box
                                </v-icon>
                                <v-spacer></v-spacer>
                                <v-btn small class="ml-3 mt-1" text color="accent" @click="delete_chi(i, j)">
                                    <v-icon small left> mdi-delete </v-icon> delete task
                                </v-btn>
                            </div>
                            <v-sheet outlined rounded class="d-flex mx-2 pt-3 px-1" :key="`${i}_${j}_sheet`">
                                <div class="flex-shrink-1">
                                    <v-text-field :rules="[v => !!v || 'Set a task text']" class="mx-1" dense outlined
                                        :label="$store.getters.t('task_text')" v-model="chi.text">
                                    </v-text-field>
                                    <SelectBranches v-model="chi.branches"
                                        :hint="$store.getters.t('auto_by_branch_explain')" />
                                    <v-combobox class="mx-1" dense outlined multiple :label="$store.getters.t('subsets')"
                                        clearable :items="checklist_subsets" v-model="chi.subsets" persistent-hint
                                        :hint="$store.getters.t('subset_field_hint')">
                                    </v-combobox>
                                    <div class="mx-1 d-flex">
                                        <v-select :items="proof_inclusion_items" outlined dense
                                            :rules="[v => !!v || 'Set a criteria']"
                                            :label="$store.getters.t('inclusion_criteria')"
                                            v-model="chi.inclusion_criteria" />
                                        <v-text-field
                                            v-if="['fraction', 'fraction_random'].includes(chi.inclusion_criteria)"
                                            v-model="chi.inclusion_criteria_n" label="n" class="ml-2" dense outlined
                                            type="number" :hint="$store.getters.t('task_n_explain')"
                                            :rules="[v => !!v]"></v-text-field>
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <div class="d-flex justify-end">
                                        <v-spacer></v-spacer>
                                        <UploadFilePackageField class="ma-2" v-model="chi.docs_package" small label="docs"
                                            v-if="station.uuid" :package_params="{
                                                production_line: form.uuid,
                                            }" />
                                        <span v-else class="ma-3 grey--text">Save to upload docs.</span>
                                    </div>
                                    <div class="d-flex justify-end">
                                        <v-checkbox class="mx-3 mb-3 mt-1" outlined
                                            :label="$store.getters.t('require_form')" v-model="chi.require_form">
                                        </v-checkbox>
                                        <v-spacer></v-spacer>
                                        <DialogCustomForm v-if="chi.require_form" v-model="chi.form_schema" />
                                        <div v-else class="mx-10"></div>
                                    </div>
                                    <div class="d-flex">
                                        <v-checkbox class="mx-3 mb-3 mt-1" outlined
                                            :label="$store.getters.t('require_picture')" v-model="chi.picture">
                                        </v-checkbox>
                                    </div>
                                </div>
                                <div>
                                    <!-- AUTO DETECTION: when branch present, when zone present -->
                                </div>
                            </v-sheet>
                        </template>
                    </div>
                    <v-spacer></v-spacer>
                    <div style="width: 250px">
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn color="accent" class="ma-2" text @click="delete_station(i)">
                                <v-icon left> mdi-delete </v-icon>
                                {{ $store.getters.t("delete_station") }}
                            </v-btn>
                        </v-row>
                        <SelectMachineSetup v-model="station.machine_setup" />
                    </div>
                </div>
            </v-sheet>
        </template>
    </v-form>
</template>


<script>
import { get_subsets_from_pl } from "/src/utils/company";

// import SelectStationKeywords from "./SelectStationKeywords.vue"
import SelectMachineSetup from "./SelectMachineSetup.vue"
import InputZones from "./InputZones.vue"
import UploadFilePackageField from "../fields/uploads/UploadFilePackageField.vue"
import SelectTipo from "../fields/SelectTipo.vue"
import PitTextField from "../fields/PitTextField.vue"
import DialogCustomForm from "./DialogCustomForm.vue"
import SelectBranches from "/src/components/ifcps/SelectBranches"

export default {
    components: {
        // SelectStationKeywords,
        SelectMachineSetup,
        InputZones,
        UploadFilePackageField,
        SelectTipo,
        PitTextField,
        DialogCustomForm,
        SelectBranches,
    },
    props: ["value"],
    data: () => ({
        form: {
            name: "",
            tipos: ["timber_panel"],
            stations: [],
        },
        proof_inclusion_items: [
            { text: "All proofs", value: 'all' },
            { text: "1/n", value: "fraction" },
            { text: "1/n randomized", value: "fraction_random" },
        ],
    }),
    computed: {
        show_same_machine_alert() {
            const setups = this.form.stations
                .filter(sta => sta.machine_setup)
                .map(sta => sta.machine_setup)
            return new Set(setups).size != setups.length
        },
        checklist_subsets() {
            return get_subsets_from_pl(this.form)
        },
    },
    watch: {
        value: {
            handler: function (v) {
                console.log("watcher value")
                this.form = v
            },
            immediate: true,
        },
        form: {
            handler: function (f) {
                this.$emit("input", f)
            },
            deep: true,
        },
    },
    methods: {
        add_chi(i) {
            let sta = this.form.stations[i]
            sta.checklist_items.push({
                text: "",
                picture: false,
                inclusion_criteria: 'all',
            })
        },
        delete_chi(i, j) {
            let sta = this.form.stations[i]
            sta.checklist_items.splice(j, 1)
        },
        add_station() {
            this.form.stations.push({
                name: "",
                zones: [],
                disabled_subsets: [],
                checklist_items: [],
                keywords: [],
                order: 0,
                is_last: false,
            })
            this.$emit("touched_stations")
        },
        delete_station(i) {
            this.form.stations.splice(i, 1)
            this.$emit("touched_stations")
        },
        validate() {
            return this.$refs.form.validate() && !this.show_same_machine_alert
        },
    },
}
</script>