import { backend_api_v2 } from '/src/utils/apiv2'


export const COMPANY_PR_ALL = {
    uuid: "all",
    name: "Company Wide",
    phases: [],
    meta: {},
}



export function loadDesignFactory(obj_name, url_key) {
    function loadDesign(context, d_uuid) {
        context.commit("pushLoading", "viewer", { root: true })
        return backend_api_v2.get(`/${url_key}/${d_uuid}/`)
            .then(({ data }) => {
                context.commit('set' + obj_name, data)
                context.commit("popLoading", "viewer", { root: true })
            })
            .catch(e => {
                context.commit("popLoading", "viewer", { root: true })
                console.log(`Cant load design: ${e}`)
            })
    }
    return loadDesign
}

export function loadActualAndItsDesignFactory(obj_name, obj_name_d, url_key) {
    function loadDesignFromActual(context, a_uuid) {
        context.commit("pushLoading", "viewer", { root: true })
        return backend_api_v2.get(`/${url_key}/${a_uuid}/`)
            .then(({ data }) => {
                context.commit('set' + obj_name, data)
                context.commit("popLoading", "viewer", { root: true })
                return context.dispatch("load" + obj_name_d, data.design)
            })
            .catch(e => {
                context.commit("popLoading", "viewer", { root: true })
                console.log(`Cant load assembly: ${e}`)
            })
    }
    return loadDesignFromActual
}
