<template>
    <v-data-table :items="table_items" :headers="headers" item-key="uuid" sort-by="ass_name" :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1],
    }" :items-per-page="50">
        <template v-slot:item.ass_name="{ item }">
            <v-btn color="accent" text :to="'/projects/' + project_uuid + '/constructibles/assembly/' + item.uuid">
                {{ item.design_name }}
                <v-chip small outlined>
                    <v-icon small>mdi-sort-clock-ascending</v-icon> {{ item.priority }}
                </v-chip>
            </v-btn>
        </template>
        <template v-slot:item.uuid="{ item }">
            {{ item.uuid.substring(0, 8) }}
        </template>
        <template v-slot:item.design_name="{ item }">
            <v-btn :to="'/projects/' + project_uuid + '/designs/assembly/' + item.design" text>
                {{ $store.getters.t("design") }}: {{ item.design_name }}
            </v-btn>
        </template>
        <template v-slot:item.status="{ item }">
            <ChipConstructibleStatus :constructible="item" />
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn text :to="'/units/' + item.uuid">
                <v-icon left> mdi-map-legend </v-icon>
                {{ $store.getters.t("view_meaning_action_of_view") }}
            </v-btn>
        </template>
    </v-data-table>
</template>


<script>
import ChipConstructibleStatus from "../renderers/ChipConstructibleStatus.vue";
export default {
    components: {
        ChipConstructibleStatus,
    },
    props: ["assemblies", "hide_columns"],
    data: () => ({}),
    computed: {
        headers() {
            const headers = [
                { text: "Assembly", value: "ass_name" },
                { text: "UUID", value: "uuid" },
                {
                    text: this.$store.getters.t("design"),
                    value: "design_name",
                },
                {
                    text: this.$store.getters.t("schedule"),
                    value: "schedule_str",
                },
                {
                    text: this.$store.getters.t("production_line"),
                    value: "pl_name",
                },
                {
                    text: this.$store.getters.t("status"),
                    value: "status",
                },
                {
                    text: this.$store.getters.t("production_date"),
                    value: "production_date",
                },
                {
                    text: "Actions",
                    sortable: false,
                    align: "right",
                    value: "actions",
                },
                // { text: "", align: "end", value: "data-table-expand" },
            ];
            if (this.hide_columns)
                return headers.filter(h => !this.hide_columns.includes(h.value))
            else return headers
        },
        project_uuid() {
            return this.$route.params.project_uuid;
        },
        table_items() {
            if (!this.assemblies) return []
            return this.assemblies.map((ass) => {
                const const_store = this.$store.state.v2.constructibles
                let design = const_store.assembly_designs.find(d => d.uuid == ass.design)
                let design_name = design?.name || "-"
                let batch = const_store.batches.find(b => b.uuid == ass.batch)
                let batch_name = batch?.name || "-"
                let phase_name = batch?.phase || "-"
                let production_line = this.$store.state.v2.factories.production_lines
                    .find(pl => pl.uuid == batch?.production_line)
                let schedule_str =
                    phase_name + " - " + batch_name + " (" + String(ass.priority).padStart(2, 0) + ")";
                let pl_name = production_line?.name;
                let production_date = "unknown"
                return {
                    ...ass,
                    design_name,
                    batch_name,
                    phase_name,
                    schedule_str,
                    pl_name,
                    production_date,
                };
            });
        },
    },
};
</script>
