<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">
                        <span class="ml-5">
                            {{ $store.getters.t("name") }}
                        </span>
                    </th>
                    <th class="text-left">
                        <span class="ml-5">
                            {{ $store.getters.t("version") }}
                        </span>
                    </th>
                    <th class="text-right">
                        <span class="mr-5">
                            {{ $store.getters.t("actions") }}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <v-skeleton-loader v-if="!pck" type="list-item@3"></v-skeleton-loader>
                <tr v-for="file in files" :key="file.uuid">
                    <td class="text-left"> {{ file.name }} </td>
                    <td class="text-center"> {{ file.meta.version }} </td>
                    <td class="text-right">
                        <ButtonJSONRender :title="file.name" :json="file.meta" />
                        <v-btn icon target="_blank" :disabled="!file.presigned_url" :href="file.presigned_url">
                            <v-icon> mdi-download </v-icon>
                        </v-btn>
                        <ButtonDeleteFile :file="file" :delete_from_package="pck.uuid" />
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>


<script>
import { nameSort } from "/src/utils/basics.js";
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender";
import ButtonDeleteFile from "./ButtonDeleteFile.vue";
export default {
    components: {
        ButtonJSONRender,
        ButtonDeleteFile,
    },
    props: ["pck"],
    computed: {
        files() {
            if (!this.pck) return [];
            return this.pck?.files
                .filter((f) => !!f)
                .sort((a, b) => nameSort(a.name, b.name));
        },
    },
};
</script>