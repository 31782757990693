export const MATCH_TYPES = [
    { text: "Match full word", value: "all" },
    { text: "Match prefix", value: "prefix" },
    { text: "Contains word", value: "contains" },
]

export const IFC_PROP_TYPES = [
    "name",
    "ifc_type",
    "ifc_material",
    "description",
    "object_type",
    "reference",
    "zone",
    "profile",
    "material_type",
    "material",
    "finish",
    "class",
]

export const IFC_CLASS_TYPES = [
    "IfcPresentationLayerAssignment",
    "IfcWall",
    "IfcSlab",
    "IfcElementAssembly",
]



export const TYPICAL_BRANCHES = [
    "windows",
    "connectors",
    "aluminium",
    "airtightness",
    "electrical",
    "air_ducts",
    "plumbing",
]

export const BRANCH_ALIKE_OPTIONS = [
    { text: "Generic random geometries", value: "generic" },
    { text: "Geom similar to plate (2D, planar)", value: "plate" },
    { text: "Geom similar to profile (1D, linear)", value: "wood" },
]

export const LEVELS_FOR_SETUP = [
    "module",
    "assembly",
    "subassembly",
]


export const LEVEL_DETECTION = [
    { text: "By IFC class", value: "ifc_class" },
    { text: "By part property", value: "part_prop" },
    { text: "By assembly property", value: "ass_prop" },
]