<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("ifc_parsing_setups") }}
            </v-btn>
            <v-spacer></v-spacer>
            <ButtonJSONUpload @uploaded="uploaded" v-if="expanded" />
            <v-btn v-if="expanded" :loading="loading_button" color="accent" text @click="new_ifcps">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-left">UUID</th>
                        <th class="text-left">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("Used_by_projects") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ips in sorted_ifcps_list" :key="ips.uuid">
                        <td class="text-left">{{ ips.uuid.substring(0, 8) }}</td>
                        <td class="text-left">
                            {{ ips.name }}
                            <v-chip outlined x-small class="ml-1" color="accent" v-if="ips.read_only">
                                <v-icon x-small left> mdi-lock-outline </v-icon>
                                produuz.it
                            </v-chip>
                            <v-chip outlined x-small class="ml-1" color="accent" v-else-if="ips.company_lock">
                                <v-icon x-small left> mdi-lock-outline </v-icon>
                                admin
                            </v-chip>
                        </td>
                        <td class="text-left">
                            <span x-small outlined class="mx-1" v-for="project in getProjects(ips)" :key="project.uuid">{{
                                project.name }} <br> </span>
                        </td>
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(ips.uuid)" :disabled="ips.read_only || ips.company_lock">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <ButtonJSONDownload :json="ips" prefix="ifcps" />
                            <v-btn icon @click="launch_clone(ips.uuid)">
                                <v-icon> mdi-content-copy </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_block(ips.uuid, my_auth.role)"
                                :disabled="(ips.read_only === true && my_auth.role !== 'pit_staff') || (my_auth.role !== 'company_admin')">
                                <v-icon> mdi-lock </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(ips.uuid)" :disabled="ips.read_only || ips.company_lock">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_ifc_parsing_setup") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("this_ips_will_be_deleted") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_ifcps" color="error" :disabled="loading_delete" :loading="loading_delete">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="1000" persistent>
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("ifc_parsing_setup") }}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="py-3">
                            <FormIFCPSMain ref="ips_form" v-model="form" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="(form.uuid === undefined) || (!getProjects(form).length)" @click="post_ips()"
                                color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn v-else @click="save_dialog = true" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="save_dialog" max-width="500px" persistent>
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("project_analysis_alert") }}
                        </v-card-title>
                        <v-card-text class="py-3">
                            <p>{{ $store.getters.t("projects_affected_by_analysis") }}</p>
                            <ul>
                                <li v-for="project in getProjects(form)" :key="project.uuid">{{ project.name }}</li>
                            </ul>
                            &nbsp;
                            <p>{{ $store.getters.t("save_ifcps_as_cloned") }}</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="save_as_copy">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save_as_clone") }}
                            </v-btn>
                            <v-btn color="warning" @click="post_ips">
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="save_dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import FormIFCPSMain from "/src/components/ifcps/FormIFCPSMain";
import ButtonJSONUpload from "/src/components/renderers/ButtonJSONUpload";
import ButtonJSONDownload from "/src/components/renderers/ButtonJSONDownload";
import _ from "lodash";
import { backend_api_v2 } from "/src/utils/apiv2";
import { nameSort } from "/src/utils/basics.js";

function add_copy_tag(name) {
    let copy_name = "[COPY] " + name
    if (copy_name.length > 22) {
        copy_name = copy_name.substring(0, 22)
    }
    return copy_name
}

export default {
    components: { FormIFCPSMain, ButtonJSONUpload, ButtonJSONDownload },
    props: [],
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        save_dialog: false,
        delete_uuid: "",
        loading_delete: false,
        loading_button: false,
    }),
    computed: {
        my_auth() {
            return this.$store.state.v2.session.user_object.auth;
        },
        ifc_parsing_setups() {
            return this.$store.state.v2.company.ifc_ps;
        },
        sorted_ifcps_list() {
            return [...this.ifc_parsing_setups].sort((a, b) => nameSort(a.name, b.name))
        }
    },
    methods: {
        getProjects(ifcps) {
            return this.$store.state.v2.company.projects.filter(
                (p) => p.ifc_parsing_setup === ifcps.uuid
            ).sort((a, b) => nameSort(a.name, b.name));
        },
        launch_edit(uuid) {
            let ips = this.ifc_parsing_setups.find((ips) => ips.uuid == uuid);
            this.form = _.cloneDeep(ips);
            this.dialog = true;
        },
        uploaded(ifcps) {
            this.form = _.cloneDeep(ifcps);
            this.dialog = true;
        },
        launch_clone(uuid) {
            let ipsData = this.ifc_parsing_setups.find((ips) => ips.uuid == uuid);
            this.form = _.cloneDeep(ipsData);
            delete this.form.uuid;
            delete this.form.id;
            this.form.name = this.form.name + " [copy]";
            this.form.read_only = false;
            this.dialog = true;
        },
        launch_block(uuid, role) {
            let ips = _.cloneDeep(this.ifc_parsing_setups.find((ips) => ips.uuid == uuid));
            let myUrl = "company/ifc_ps/" + uuid + "/";

            if (role === 'pit_staff') {
                backend_api_v2.put(myUrl, { read_only: !ips.read_only })
                    .then(() => {
                        this.$store.dispatch("v2/company/putIFCPS", { uuid: uuid, read_only: !ips.read_only });
                    })
                    .catch(() => {
                        ips.read_only = !ips.read_only;
                    });
            } else if (role === 'company_admin') {
                backend_api_v2.put(myUrl, { company_lock: !ips.company_lock })
                    .then(() => {
                        this.$store.dispatch("v2/company/putIFCPS", { uuid: uuid, company_lock: !ips.company_lock });
                    })
                    .catch(() => {
                        ips.company_lock = !ips.company_lock;
                    });
            }
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_ifcps() {
            this.loading_delete = true;
            this.$store
                .dispatch("v2/company/deleteIFCPS", this.delete_uuid)
                .then(() => {
                    this.loading_delete = false;
                    this.delete_dialog = false;
                });
        },
        new_ifcps() {
            this.loading_button = true;
            backend_api_v2.get("/company/ifc_ps_definitions/default/").then((data) => {
                this.form = { ...data };
                this.dialog = true;
                this.loading_button = false;
            });
        },
        post_ips() {
            if (!this.$refs.ips_form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("Updating IFC Parsing Setup");
                this.$store
                    .dispatch("v2/company/putIFCPS", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                        this.save_dialog = false;
                    });
            } else {
                console.log("Creating IFC Parsing Setup");
                this.$store
                    .dispatch("v2/company/postIFCPS", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                        this.save_dialog = false;
                    });
            }
        },
        save_as_copy() {
            delete this.form.uuid;
            delete this.form.id;
            this.form.name = add_copy_tag(this.form.name)
            this.form.read_only = false;
            this.post_ips()
        },
    },
};
</script>