<template>
    <v-combobox ref="combo" v-model="batch_name" outlined dense :items="batch_names" lazy
        :rules="[rules.required, rules.illegalName]" no-data-text="Write batch name" class="mx-1"
        :label="$store.getters.t('batch')"></v-combobox>
</template>

<script>
import { pit_rules } from "./utils";
export default {
    props: ["phase", "value"],
    data: () => ({
        batch_name: "",
        rules: pit_rules,
    }),
    computed: {
        batch_names() {
            return this.$store.state.v2.constructibles.batches
                .filter((b) => b.phase == this.phase)
                .map((b) => b.name);
        },
    },
    methods: {
        validate() {
            return this.$refs.combo.validate()
        }
    },
    watch: {
        batch_name(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.batch_name = v;
            },
            immediate: true,
        },
    },
};
</script>