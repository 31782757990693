<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" outlined color="error">
                <v-icon left>mdi-file-document-remove</v-icon>
                {{ $store.getters.t("clear_proof") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("clear_proof") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("clear_proof_help") }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="clear()" outlined color="error">
                    <v-icon left> mdi-file-document-remove </v-icon>
                    {{ $store.getters.t("clear") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["rcp", "multiple", "actual_uuids"],
    data: () => ({
        dialog: false,
        loading: false,
    }),
    methods: {
        async clear() {
            if (this.multiple) await this.multiClear()
            else await this.singleClear()
        },
        async multiClear() {
            this.loading = true
            this.$store
                .dispatch("v2/records/multiRCPFlushByActualUuids", this.actual_uuids)
                .then(() => {
                    this.$store.dispatch("v2/constructibles/refreshModules", this.actual_uuids)
                    this.$store.dispatch("v2/constructibles/refreshAssemblies", this.actual_uuids)
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("completed")
                });
            this.dialog = false;
        },
        async singleClear() {
            this.loading = true;
            this.$store
                .dispatch("v2/records/deleteRCP", this.rcp.uuid)
                .finally(() => {
                    this.loading = false;
                    this.dialog = false;
                    this.$emit("completed")
                });
        },
    },
}
</script>