<template>
    <div :class="inline != undefined ? 'd-flex' : ''">
        <v-chip :class="inline != undefined ? 'mt-1 mr-1' : ''" :color="chip_color" outlined x-small>
            {{ status }}
        </v-chip>
        <div v-if="station_chips" class="d-flex">
            <v-chip :class="i == 0 ? 'mt-1' : 'ml-1 mt-1'" v-for="(sc, i) in station_chips" :key="i" x-small
                :outlined="sc.outlined" :color="sc.color">
                {{ i + 1 }}
            </v-chip>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
export default {
    props: ["constructible", "inline"],
    computed: {
        chip_color() {
            if (this.status == "ready") return "success";
            if (this.status == "working") return "warning";
            return "";
        },
        status() {
            return this.constructible.status;
        },
        stations_status() {
            return this.constructible.stations_status;
        },
        batch() {
            return this.$store.state.v2.constructibles.batches
                .find(b => b.uuid == this.constructible.batch)
        },
        production_line() {
            return this.$store.state.v2.factories.production_lines
                .find(pl => pl.uuid == this.batch?.production_line)
        },
        stations() {
            let stations = this.production_line?.stations;
            if (stations) return _.orderBy(stations, "order");
            else return undefined;
        },
        station_chips() {
            if (!this.stations) {
                return [];
            }
            let station_chips = this.stations.map((s) => {
                let sta_name = s.name ? s.name : "unknown";
                let status = this.stations_status[sta_name];
                let color = "info";
                let outlined = true;
                if (status == "working") {
                    outlined = false;
                    color = "warning";
                } else if (status == "ready") {
                    outlined = false;
                    color = "success";
                }
                return { number: s.order, color, outlined };
            });
            station_chips.sort((a, b) => a.number - b.number);
            return station_chips
        },
    },
};
</script>