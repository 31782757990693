<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                    <v-icon> mdi-theme-light-dark </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("theme") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title>
                {{ $store.getters.t("theme_title") }}
            </v-card-title>

            <v-card-text>  {{ $store.getters.t("theme_text") }} </v-card-text
            > <v-card-text>
                <v-select
                    outlined
                    dense
                    v-model="theme"
                    :items="options"
                    :label="$store.getters.t('theme')"
                >
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        dialog: false,
        theme: localStorage.getItem("theme"),
    }),
    computed: {
        options() {
            return [
                { value: "auto", text: this.$store.getters.t("auto_dark") },
                { value: "light", text: this.$store.getters.t("light_mode") },
                { value: "dark", text: this.$store.getters.t("dark_mode") },
            ];
        },
    },
    watch: {
        theme(theme) {
            localStorage.setItem("theme", theme);
            if (theme == "dark") {
                this.$vuetify.theme.dark = true;
            } else if (theme == "light") {
                this.$vuetify.theme.dark = false;
            } else if (theme == "auto") {
                const web_dark = window.matchMedia(
                    "(prefers-color-scheme: dark)"
                ).matches;
                this.$vuetify.theme.dark = web_dark;
            }
        },
    },
};
</script>