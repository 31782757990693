<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("profile_size") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="expanded" :loading="loading_button" color="accent" text @click="new_profile_size">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-left">
                            {{ $store.getters.t("uuid") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("width") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("height") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("material") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("created") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("modified") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("erp_code") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ps in profile_sizes_rows" :key="ps.uuid">
                        <td class="text-left">{{ ps.uuid.substring(0, 8) }}</td>
                        <td class="text-left">{{ ps.width }}</td>
                        <td class="text-left">{{ ps.height }}</td>
                        <td class="text-left">{{ ps.material_name }}</td>
                        <td class="text-left">
                            <Timestamp :value="ps.created" />
                        </td>
                        <td class="text-left">
                            <Timestamp :value="ps.modified" />
                        </td>
                        <td class="text-left">{{ ps.erp_code }}</td>

                        <!-- ACTIONS -->
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(ps.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(ps.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>

                <!-- EDIT DIALOG -->
                <v-dialog v-model="dialog" max-width="1000" persistent>
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("profile_size_setup") }}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="py-3">
                            <FormProfileSizeSetup ref="profile_size_form" v-model="form" @save="post_profile_size" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_profile_size" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- DELETE DIALOG -->
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_profile_size") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_profile_size_text") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_profile_size" color="error" :disabled="loading_delete"
                                :loading="loading_delete">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import _ from "lodash";
import Timestamp from "/src/components/renderers/Timestamp.vue";
import FormProfileSizeSetup from "/src/components/logistics/FormProfileSizeSetup";
import { nameSort } from "/src/utils/basics.js";

export default {
    props: [],
    components: {
        Timestamp, FormProfileSizeSetup
    },
    data: () => ({
        expanded: false,
        loading: false,
        loading_button: false,
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        loading_delete: false,
        saving: false,
        form: {},
    }),
    computed: {
        profile_sizes() {
            return [...this.$store.state.v2.logistics.profile_sizes]
        },
        profile_sizes_rows() {
            return this.profile_sizes.map(
                (ps) => ({
                    ...ps,
                    material_name: this.get_material_name(ps.material)
                })
            ).sort(
                (a, b) => {
                    if (a.width != b.width) return a.width - b.width
                    if (a.height != b.height) return a.height - b.height
                    else return nameSort(a.material_name, b.material_name)
                }
            )
        },
    },
    methods: {
        get_material_name(uuid) {
            return this.$store.state.v2.logistics.materials.find(
                (mat) => mat.uuid === uuid
            )?.name || "-";
        },
        new_profile_size() {
            this.form = {
                erp_code: "",
                width: "",
                height: "",
                material: "",
            };
            this.dialog = true;
        },
        launch_edit(uuid) {
            let ps = this.profile_sizes.find((ps) => ps.uuid == uuid);
            this.form = _.cloneDeep(ps);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_profile_size() {
            this.loading_delete = true;
            this.$store
                .dispatch("v2/logistics/deleteProfileSize", this.delete_uuid)
                .then(() => {
                    this.loading_delete = false;
                    this.delete_dialog = false;
                    console.log("[i] Profile Size deleted")
                });
        },
        post_profile_size() {
            if (!this.$refs.profile_size_form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("[i] Updating Profile Size");
                this.$store
                    .dispatch("v2/logistics/putProfileSize", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("[i] Creating profile Size");
                this.$store
                    .dispatch("v2/logistics/postProfileSize", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
    },
    mounted() {
        this.$store.dispatch("v2/logistics/loadProfileSizes");
    }
};

</script>