<template>
    <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item @click="clean_stucked">
                <v-list-item-icon>
                    <v-icon>mdi-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("clean_stucked_jobs") }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                target="_blank"
                href="https://docs.produuz.it/operations.html"
            >
                <v-list-item-icon>
                    <v-icon>mdi-help-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("help") }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: ["project"],
    methods: {
        clean_stucked() {
            this.$store.dispatch("v2/selected/cleanOperationJobs");
        },
    },
};
</script>