<template>
    <v-badge :content="issue_count" color="warning" :value="issue_count" overlap class="ma-1 mt-0">
        <v-dialog v-model="dialog" max-width="900">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("issues") }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col md="6">
                                <v-list>
                                    <v-list-item two-line @click="selected_issue = issue" v-for="issue in issues"
                                        :key="issue.uuid">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ issue.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ issue.text }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col md="6" v-if="selected_issue">
                                <v-card-title>
                                    {{ $store.getters.t("issue") }}:
                                    {{ selected_issue.title }}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{ selected_issue.uuid.substring(0, 8) }}
                                </v-card-subtitle>
                                <v-card-text>
                                    text: {{ selected_issue.text }}
                                </v-card-text>
                                <v-card-text>
                                    Category: {{ selected_issue.category }}
                                    <br />
                                    Status: {{ selected_issue.status }} <br />
                                    user: {{ selected_issue.username }} <br />
                                    created:
                                    <Timestamp :value="selected_issue.created" /><br />
                                    Solved:
                                    <IconBoolean :value="selected_issue.solved" /><br />
                                    <span v-if="selected_issue.solved">Solved in revision:
                                        {{ solved_revision }}
                                        <br />
                                        Solving comment:
                                        {{
                                            selected_issue.solved
                                            ? selected_issue.solved_text
                                            : "not solved"
                                        }}
                                        <br />
                                    </span>
                                </v-card-text>
                                <v-img v-for="pic in selected_issue.resources
                                    .pictures" :key="pic.short_name" :src="pic.presigned_url" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn small color="secondary text-truncate" @click="dialog = true" width="100%">
            <v-icon left>mdi-garage-alert</v-icon>
            <span class="text-truncate">
                {{ $store.getters.t("issues") }}
            </span>
        </v-btn>
    </v-badge>
</template>

<script>
import IconBoolean from "/src/components/renderers/IconBoolean.vue";
import Timestamp from "/src/components/renderers/Timestamp";
export default {
    name: "ButtonPanelIssues",
    components: { IconBoolean, Timestamp },
    data: () => ({ dialog: false, selected_issue: null, interval: null }),
    computed: {
        issues() {
            return this.$store.state.v2.selected.selected_hot_issues.filter(
                (issue) => issue.category == "prod_issue"
            );
        },
        issue_count() {
            return this.issues.length;
        },
        actual() {
            return this.$store.getters["v2/selected/annotatedRouteActual"]
        },
        project_uuid() {
            return this.$store.state.v2.selected.pr_selection
        },
        design() {
            return this.$store.getters["v2/selected/annotatedRouteDesign"]
        },
    },
    watch: {
        issues(v) {
            if (v.length === 0) {
                this.selected_issue = null;
            }
        },
    },
};
</script>