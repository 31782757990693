
const MODULE_TIPOS = ["module_unit"]
const ASSEMBLY_TIPOS = ["steel_cage", "timber_panel", "concrete_slab", "steel_panel"]
const PART_TIPOS = [
    "top_rail",
    "bottom_rail",
    "extra_top_rail",
    "extra_bottom_rail",
    "frame_nail",
    "frame_nail_single",
    "frame_saw",
    "plate_nail",
    "milling",
    "wood",
    "plate",
    "core_plate",
    "plate_screw",
    "sawing",
    "glue",
    "brick",
    "unknown",
]


export { MODULE_TIPOS, ASSEMBLY_TIPOS, PART_TIPOS }