<template>
    <div>
        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height" v-if="ass">
                    <v-card-text v-if="design">
                        {{ $store.getters.t("assembly") }}:
                        {{ ass.uuid }} <br />
                        {{ $store.getters.t("design") }}:
                        {{ design.name }} <br />
                        {{ $store.getters.t("priority_in_batch") }}:
                        {{ ass.priority }} <br />
                        {{ $store.getters.t("status") }}:
                        {{ ass.status }}
                        <br />
                        <template v-for="( v, k ) in  ass.stations_status ">
                            <span :key="k + '_sta'"> {{ k }}: {{ v }} </span>
                            <br :key="k + '_sta_br'" />
                        </template>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-skeleton-loader type="list-item-three-line@2"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-actions v-if="ass">
                        <v-btn text :to="'/viewer/assembly/' + ass_uuid">
                            <v-icon left> mdi-map-legend </v-icon>
                            {{
                                $store.getters.t("view_meaning_action_of_view")
                            }}
                            {{ $store.getters.t("assembly") }}
                        </v-btn>
                        <ButtonJSONRender v-if="ass_fc" title="Forecast times" :json="ass_fc.times" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height">
                    <v-card-title>
                        {{ $store.getters.t("Batch") }}
                    </v-card-title>
                    <v-card-text v-if="batch_loading">
                        <v-skeleton-loader type="card"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else>
                        {{ $store.getters.t("name") }}: {{ batch_name }} <br />
                        {{ $store.getters.t("phase") }}: {{ phase_name }} <br />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ChecklistDataCard :assembly="ass" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ChecklistDataCard from "/src/components/constructibles/ChecklistDataCard";
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender";
import { backend_api_v2 } from "/src/utils/apiv2";

export default {
    components: { 
        ChecklistDataCard, 
        ButtonJSONRender 
    },
    data: () => ({
        batch_loading: false,
        batch: null,
    }),
    computed: {
        ass_uuid() {
            return this.$route.params.ass_uuid;
        },
        ass() {
            return this.$store.state.v2.selected.selected_ass
        },
        design() {
            return this.$store.state.v2.selected.selected_assd
        },
        batch_uuid() {
            return this.ass?.batch
        },
        batch_name() {
            return this.batch?.name
        },
        phase_name() {
            return this.batch?.phase
        },
        ass_fc() {
            // To be updated when overview
            return {}
        },
    },
    watch: {
        batch_uuid: {
            handler: function (buuid) {
                if (buuid) {
                    this.batch = null;
                    this.batch_loading = true;

                    backend_api_v2
                        .get(`/constructibles/batches/${buuid}/`)
                        .then(({ data }) => {
                            this.batch_loading = false;
                            this.batch = { ...data };
                        })
                        .catch((e) => {
                            this.batch_loading = false;
                            console.log(`Cant load batch: ${e}`);
                        });
                }
            },
            immediate: true,
        },
    },
};
</script>