<template>
    <!-- <v-col class="flex-grow-1" id="pdf-container" v-resize="refresh"> -->
    <v-col class="flex-grow-1" id="pdf-container" ref="pdfContainer">
        <canvas
            v-if="canvas_id"
            :id="canvas_id"
            :ref="canvas_id"
            :class="dark ? 'inverted' : ''"
        ></canvas>
        <v-overlay :value="loading" :absolute="true" :opacity="0.4">
            <div class="d-flex flex-column align-center">
                <v-progress-circular
                    indeterminate
                    color="white"
                ></v-progress-circular>
                <span class="display-1 font-weight-light mt-4">{{
                    $store.getters.t("loading_pdf")
                }}</span>
            </div>
        </v-overlay>
        <div v-if="num_pages > 1">
            <v-btn class="mb-2 mx-2" @click="subPage" color="secondary">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn class="mb-2" @click="addPage" color="secondary">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </v-col>
</template>

<style lang="scss" scoped>
canvas {
    width: 100%;
}
.inverted {
    filter: invert(64%) contrast(228%) brightness(80%) hue-rotate(180deg);
}
#pdf-container {
    // overflow-y: auto;
    position: relative;
    // max-height: calc(100vh - 65px);
    // max-height: 100%;
}
</style>

<script>
import bus from "/src/utils/event_bus";
import Hammer from "hammerjs";
// import PDFJS from "pdfjs-dist";
// import PDFJS from 'pdfjs-dist/webpack';
import pdfjs_loader from "/src/utils/pdfjs_loader";
import { getRelativePosition, scaleFrom } from "/src/utils/pdfgeom";

let PDFJS = pdfjs_loader.PDFJS;

export default {
    name: "PDFView",
    props: {
        url: String,
        name: String,
    },
    data: () => ({
        canvas_id: null,
        pdf: undefined,
        page: undefined,
        selected_page: 1,
        num_pages: 1,
        viewport: undefined,
        render_task: undefined,
        loading: true,
        hammertime: null,
        currentPos: null,
        pinchStart: { x: undefined, y: undefined },
        last: {
            x: 0,
            y: 0,
            z: 1,
        },
        lastEvent: null,
        fixHammerjsDeltaIssue: { x: 0, y: 0 },
    }),
    computed: {
        pdf_render_scale() {
            return this.$store.state.v2.selected.selected_project?.metadata
                ?.pdf_render_scale;
        },
        dark() {
            return this.$vuetify.theme.dark;
        },
        canvasRef() {
            return this.$refs[this.canvas_id];
        },
        containerRef() {
            return this.$refs.pdfContainer;
        },
        originalSize() {
            return {
                width: this.containerRef.clientWidth,
                height: this.containerRef.clientHeight,
            };
        },
        containerRatio() {
            return (
                this.containerRef.clientWidth / this.containerRef.clientHeight
            );
        },
    },
    methods: {
        getPDF() {
            let getdoc = PDFJS.getDocument(this.url);
            let prom = getdoc.promise;
            prom.then((pdf) => {
                console.log("Success load pdf");
                this.pdf = pdf;
            }).catch(() => {
                console.log("Error loading pdf");
                this.loading = false;
            });
        },
        updateCanvasTransform() {
            this.currentPos.height =
                this.originalSize.height * this.currentPos.z;
            this.currentPos.width = this.originalSize.width * this.currentPos.z;
            this.canvasRef.style.transform =
                "translate3d(" +
                this.currentPos.x +
                "px, " +
                this.currentPos.y +
                "px, 0) scale(" +
                this.currentPos.z +
                ")";
        },
        drawPDF() {
            this.clean_hammer_vars();
            this.destroy_hammer();

            // const pixelRatio = window.devicePixelRatio || 1;

            // Scale 2 for A2, 1 for A3, 4 for A4, and 0.5 for A1.

            let scale = this.pdf_render_scale || 2;
            let params = {
                scale,
                // rotation: 0,
                // dontFlip: true
            };

            // Hammer new
            this.hammertime = new Hammer(this.canvasRef, {});
            this.hammertime.get("pinch").set({ enable: true });
            this.hammertime.get("pan").set({ threshold: 0 });

            this.currentPos = {
                x: 0,
                y: 0,
                z: 1,
                zooming: false,
                width: this.originalSize.width * 1,
                height: this.originalSize.height * 1,
            };

            this.hammertime.on("pan", (e) => {
                if (this.lastEvent !== "pan") {
                    this.fixHammerjsDeltaIssue = {
                        x: e.deltaX,
                        y: e.deltaY,
                    };
                }
                this.currentPos.x =
                    this.last.x + e.deltaX - this.fixHammerjsDeltaIssue.x;
                this.currentPos.y =
                    this.last.y + e.deltaY - this.fixHammerjsDeltaIssue.y;
                this.lastEvent = "pan";
                this.updateCanvasTransform();
            });

            this.hammertime.on("pinch", (e) => {
                var d = scaleFrom(
                    this.originalSize,
                    pinchZoomOrigin,
                    this.last.z,
                    this.last.z * e.scale
                );
                this.currentPos.x = d.x + this.last.x + e.deltaX;
                this.currentPos.y = d.y + this.last.y + e.deltaY;
                this.currentPos.z = d.z + this.last.z;
                this.lastEvent = "pinch";
                this.updateCanvasTransform();
            });

            var pinchZoomOrigin = undefined;
            this.hammertime.on("pinchstart", (e) => {
                this.pinchStart.x = e.center.x;
                this.pinchStart.y = e.center.y;
                pinchZoomOrigin = getRelativePosition(
                    this.canvasRef,
                    { x: this.pinchStart.x, y: this.pinchStart.y },
                    this.originalSize,
                    this.currentPos.z
                );
                this.lastEvent = "pinchstart";
            });

            this.hammertime.on("panend", () => {
                this.last.x = this.currentPos.x;
                this.last.y = this.currentPos.y;
                this.lastEvent = "panend";
            });

            this.hammertime.on("pinchend", () => {
                this.last.x = this.currentPos.x;
                this.last.y = this.currentPos.y;
                this.last.z = this.currentPos.z;
                this.lastEvent = "pinchend";
            });

            Hammer.on(this.canvasRef, "mousewheel", (e) => {
                // console.log("MOUSE WHEEL", e);
                var scrollStart = getRelativePosition(
                    this.canvasRef,
                    { x: e.x, y: e.y },
                    this.originalSize,
                    this.last.z
                );
                var d = scaleFrom(
                    this.originalSize,
                    scrollStart,
                    this.last.z,
                    this.last.z * (1 - e.deltaY / 5000)
                );
                this.currentPos.x += d.x;
                this.currentPos.y += d.y;
                // this.currentPos.z = d.z;
                this.currentPos.z *= 1 - e.deltaY / 5000;
                this.lastEvent = "wheel";
                this.updateCanvasTransform();

                this.last.x = this.currentPos.x;
                this.last.y = this.currentPos.y;
                this.last.z = this.currentPos.z;
            });

            //  END HAMMER

            // Prepare canvas using PDF page dimensions
            let viewport = this.page.getViewport(params);
            // console.log("VIEWPORT ", viewport.width, viewport.height);

            let viewport_ratio = viewport.width / viewport.height;

            if (viewport_ratio < this.containerRatio) {
                viewport.width =
                    viewport.width * (this.containerRatio / viewport_ratio) +
                    100;
                // viewport = viewport.clone({
                //     rotation: 90,
                //     width:
                //         viewport.width * (container_ratio / viewport_ratio) +
                //         100
                // });
            }

            this.canvasRef.height = viewport.height;
            this.canvasRef.width = viewport.width;
            const canvasContext = this.canvasRef.getContext("2d");
            const renderParams = {
                canvasContext,
                viewport,
            };

            // May be too fast and a render task may be already running
            this.destroy_render_task();

            // Render task
            this.render_task = this.page.render(renderParams);
            this.render_task.promise
                .then(() => (this.loading = false))
                .catch((e) => console.log(e));

            // Reset things
            this.recenter();
        },
        destroy_render_task() {
            if (!this.render_task) return;

            // RenderTask#cancel
            // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
            this.render_task.cancel();
            delete this.render_task;
            this.loading = false;
        },
        subPage() {
            if (this.selected_page > 1) {
                this.selected_page -= 1;
            }
        },
        addPage() {
            if (this.selected_page < this.num_pages) {
                this.selected_page += 1;
            }
        },
        destroyPage(page) {
            if (!page) return;
            page._destroy();
            if (this.renderTask) this.renderTask.cancel();
        },
        refresh() {
            console.log("[i] Refresh PDF renderer");
            this.loading = true;
            this.selected_page = 1;
            this.getPDF();
        },
        render_current_page() {
            console.log("[i] Rendering selected page");
            this.$nextTick(() =>
                this.pdf.getPage(this.selected_page).then((p) => {
                    // .getPage(1).then(p => {
                    this.page = p;
                    this.drawPDF();
                })
            );
        },
        clean_hammer_vars() {
            this.currentPos = {
                x: 0,
                y: 0,
                z: 1,
            };
            this.last = {
                x: 0,
                y: 0,
                z: 1,
            };
            this.fixHammerjsDeltaIssue = {
                x: 0,
                y: 0,
            };
            this.pinchStart = {
                x: 0,
                y: 0,
            };
        },
        destroy_hammer() {
            this.hammertime?.destroy();
        },
        recenter() {
            console.log("[>] Recentering PDF page.");
            // const canvas = this.$refs[this.canvas_id];
            // canvas.style.transform = "translate3d(0px, 0px, 0) scale(1)";
            this.clean_hammer_vars();
            this.updateCanvasTransform();
        },
    },
    watch: {
        url() {
            this.refresh();
        },
        pdf(pdf) {
            this.num_pages = pdf.numPages;
            this.render_current_page();
        },
        // THIS WATCHER MADE A PREVIOUSLY SEEN PAGE GET BROKEN!
        // page(new_page, old_page) {
        //     this.destroyPage(old_page);
        // },
        selected_page() {
            this.render_current_page();
        },
    },
    mounted() {
        this.canvas_id = this._uid;
        this.refresh();
        bus.$on("events/viewer/recenter_pdf_" + this.name, this.recenter);
    },
    beforeDestroy() {
        try {
            bus.$off("events/viewer/recenter_pdf_" + this.name);
            this.destroy_render_task();
            this.destroyPage(this.page);
            this.pdf.destroy();
        } catch {
            console.log("[i] Cant destroy PDF");
        }
    },
};
</script>