import Vue from 'vue';
import Vuetify from 'vuetify';
import { en, es, nl, pl } from 'vuetify/es5/locale'
// import en from '/src/translations/en'
// import es from '/src/translations/es'
// import nl from '/src/translations/nl'
// import pl from '/src/translations/pl'
import 'vuetify/dist/vuetify.min.css'


Vue.use(Vuetify);

function determine_theme() {
    const web_dark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const theme = localStorage.getItem("theme");
    if (theme == "dark") {
        return true;
    } else if (theme == "light") {
        return false;
    } else {
        return web_dark;
    }
}


export default new Vuetify({
    lang: {
        locales: { en, es, nl, pl },
        current: 'en',
    },
    theme: {
        dark: determine_theme(),
        themes: {
            light: {
                primary: '#1976D2',
                secondary: '#242021',
                accent: '#ea590c',
                error: '#c90202', // default was '#FF5252'
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#ea590c',
                worange: "#ea590c",  // accent, warning produuz.it
                wblack: "#242021",  // secondary produuz.it
            },
            dark: {
                primary: '#1976D2',
                secondary: "#707070",
                accent: '#ea590c',
                error: '#c90202', // default was '#FF5252'
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#ea590c',
                worange: "#ea590c",  // accent, warning produuz.it
                wblack: "#242021",  // secondary produuz.it
            }
        },
    },
});





