<template>
    <div class="width-100" :class="dark ? 'dmain' : 'lmain'">
        <div class="d-flex width-100">
            <div class="d-flex flex-column" :class="dark ? 'dhead' : 'lhead'">
                <!-- <div class="rowlab">
                    {{ $store.getters.t("project") }}
                </div> -->
                <div class="rowlab" v-for="label in row_labels" :key="label">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <p v-on="on">{{ label }}</p>
                        </template>

                        <span>{{ label }}</span>
                    </v-tooltip>
                </div>
            </div>

            <div class="d-flex flex-column h-scroll">
                <!-- <div class="timescale" :class="dark ? 'dhead' : 'lhead'">
                    <div v-for="step in timesteps" :key="step">
                        {{ step }}
                    </div>
                </div> -->
                <div
                    class="prodline-row d-flex"
                    :class="dark ? 'dmain' : 'lmain'"
                    v-for="p in projects"
                    :key="p.uuid"
                >
                    <BatchItem
                        v-for="(batch, i) in p.loose_batches"
                        :key="batch.uuid"
                        :batch="batch"
                        :loose_idx="i"
                        loose
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<script>
import BatchItem from "./BatchItem.vue";
export default {
    components: {
        BatchItem,
    },
    props: ["loose_batches", "timeframe"],
    data: () => ({}),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        projects() {
            return this.loose_batches.projects;
        },
        row_labels() {
            return this.projects.map((p) => p.name);
        },
        tl() {
            return this.$store.state.overview.production_overview.tl;
        },
        tr() {
            return this.$store.state.overview.production_overview.tr;
        },
        timesteps() {
            const w = Math.max(
                ...this.projects.map((pr) => pr.loose_batches.length)
            );
            return Array.from({ length: w + 1 }, (_, i) => i + 1);
        },
    },
};
</script>