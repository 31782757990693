import { paginated_factory_v2, backend_api_v2 } from '/src/utils/apiv2'
import { today } from '/src/utils/time'

let LASTS = {}

export default {
    mutations: {
        // MULTIPLE
        setter(store_keyword) {
            function setter_mutation(state, data) { state[store_keyword] = data }
            return setter_mutation
        },
        // SINGLE
        creator(store_keyword) {
            function creator_mutation(state, data) {
                state[store_keyword].push(data)
            }
            return creator_mutation
        },
        updater(store_keyword) {
            function updater_mutation(state, updated) {
                let i = state[store_keyword].findIndex(obj => (obj?.uuid || obj.id) == (updated?.uuid || updated.id))
                if (i != -1) {
                    state[store_keyword][i] = updated
                    state[store_keyword] = [...state[store_keyword]]
                }
            }
            return updater_mutation
        },
        deleter(store_keyword) {
            function deleter_mutation(state, uuid) {
                state[store_keyword] = [...state[store_keyword].filter(obj => (obj?.uuid || obj.id) != uuid)]
            }
            return deleter_mutation
        }
    },
    actions: {
        // MULTIPLE
        loader(url, objs_name) {
            function loader_action(context) {
                return paginated_factory_v2(url)
                    .then(({ data }) => {
                        context.commit('set' + objs_name, data)
                        return data
                    })
                    .catch(e => {
                        console.log(`Cant get ${objs_name}: ${e}`)
                    })
            }
            return loader_action
        },
        loader_query(url, objs_name) {
            const keyword = `${url}__${objs_name}`
            function loader_action(context, payload) {

                let query = Object.keys(payload).map(key => key + '=' + payload[key]).join('&')
                // Register last call (ensure last called fills)
                LASTS[keyword] = query

                return paginated_factory_v2(`${url}/?${query}`)
                    .then(({ data }) => {
                        // Accept last
                        if (LASTS[keyword] == query)
                            context.commit('set' + objs_name, data)
                        // Reject prebounce
                        else console.log("[i] Rejecting loader commit. Not last call.")
                        return data
                    })
                // .catch(e => {
                // console.log(`Cant get ${objs_name}: ${e}`)
                // })
            }
            return loader_action
        },
        loader_pr(url, objs_name) {
            const keyword = `${url}__${objs_name}`
            function loader_action(context, project_uuid) {

                // Register last call (ensure last called fills)
                LASTS[keyword] = project_uuid

                // Clean previous
                context.commit('set' + objs_name, [])

                // If project is "all", query as null
                let query_pr = project_uuid == "all" ? null : project_uuid
                return paginated_factory_v2(`${url}?project=${query_pr}`)
                    .then(({ data }) => {
                        // Accept last
                        if (LASTS[keyword] == project_uuid)
                            context.commit('set' + objs_name, data)
                        // Reject prebounce
                        else console.log("[i] Rejecting loader commit. Not last call.")
                        return data
                    })
                    .catch(e => {
                        console.log(`Cant get ${objs_name}: ${e}`)
                    })
            }
            return loader_action
        },
        loader_date(url, objs_name) {
            const keyword = `${url}__${objs_name}`
            function loader_action(context, prod_date) {

                // Register last call (ensure last called fills)
                LASTS[keyword] = prod_date

                // Clean previous
                context.commit('set' + objs_name, [])

                // If today, previous panels are also needed
                let api_promise
                if (prod_date == today()) {
                    api_promise = backend_api_v2(`${url}today_and_delayed/`)
                } else {
                    api_promise = paginated_factory_v2(`${url}?scheduled_prod_date=${prod_date}`)
                }

                return api_promise.then(({ data }) => {
                    // Accept last
                    if (LASTS[keyword] == prod_date)
                        context.commit('set' + objs_name, data)
                    // Reject prebounce
                    else console.log("[i] Rejecting loader commit. Not last call.")
                    return data
                })
                    .catch(e => {
                        console.log(`Cant get ${objs_name}: ${e}`)
                    })
            }
            return loader_action
        },

        // SINGLE
        poster(url, obj_name) {
            function poster_action(context, payload) {
                return backend_api_v2.post(url, payload)
                    .then(({ data }) => {
                        context.commit('create' + obj_name, data)
                        return { data }
                    })
                    .catch(e => console.log(`Cant create ${obj_name}: ${e}`))
            }
            return poster_action
        },
        putter(url, obj_name) {
            function putter_action(context, payload) {
                context.commit('update' + obj_name, payload)
                return backend_api_v2.put(url + (payload?.uuid || payload.id) + "/", payload)
                    .then(({ data }) => {
                        context.commit('update' + obj_name, data)
                        return { data }
                    })
                    .catch(e => console.log(`Cant update ${obj_name}: ${e}`))
            }
            return putter_action
        },
        deleter(url, obj_name) {
            function deleter_action(context, uuid) {
                return backend_api_v2.delete(url + uuid + '/')
                    .then(() => {
                        context.commit('delete' + obj_name, uuid)
                    })
                    .catch(e => console.log(`Cant delete ${obj_name}: ${e}`))
            }
            return deleter_action
        },
        refresher(url, obj_name) {
            function refresher_action(context, uuid) {
                return backend_api_v2.get(url + uuid + "/")
                    .then(({ data }) => {
                        context.commit('update' + obj_name, data)
                        return { data }
                    })
                    .catch(e => console.log(`Cant update ${obj_name}: ${e}`))
            }
            return refresher_action
        },
        multi_refresher(url, obj_name) {
            function refresher_action(context, uuids) {
                if (uuids.length == 0) {
                    // Return a fulfilled promise with an empty object if the uuids came empty
                    return Promise.resolve({ data: { results: [] } });
                    // Otherwise, the backend gets an empty filter so it returns a non filtered query
                }
                return backend_api_v2.get(`${url}?uuid__in=${uuids.join(",")}`)
                    .then(({ data }) => {
                        data.results.forEach(obj => context.commit('update' + obj_name, obj))
                        return { data }
                    })
                    .catch(e => console.log(`Cant update ${obj_name}: ${e}`))
            }
            return refresher_action
        },
    }
}