<template>
    <div>
        <div class="d-flex flex-row">
            {{ $store.getters.t("custom_headers") }}
            <v-btn x-small text class="ml-3 mt-0 mb-4" color="accent" @click="add"> <v-icon x-small left>mdi-plus</v-icon>
                ADD
            </v-btn>
        </div>
        <div class="d-flex flex-row" v-for="header, i in headers" :key="i">
            <v-text-field class="mx-1" outlined dense v-model="header.name"
                :label="$store.getters.t('name')"></v-text-field>
            <v-text-field class="mx-1" outlined dense v-model="header.value"
                :label="$store.getters.t('value')"></v-text-field>
            <v-btn small icon class="ml-3 mt-1" color="accent" @click="remove(i)"> <v-icon small>mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        headers: [
            // {"name": "HEADER_NAME": "value": "HEADER_VALUE"}
        ]
    }),
    watch: {
        value: {
            handler: function (v) {
                if (!v) return
                this.headers = v;
            },
            immediate: true,
        },
        headers: {
            deep: true,
            handler: function (h) {
                this.$emit("input", h)
            }
        }
    },
    methods: {
        add() {
            this.headers.push({ "name": "", "value": "" })
        },
        remove(i) {
            this.headers.splice(i, 1)
        }
    }
}
</script>