<template>
    <v-navigation-drawer :value="!!ass_uuid || !!mod_uuid" absolute stateless disable-route-watcher right width="700">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <v-icon>mdi-information-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Constructible Detail</v-list-item-title>
                </v-list-item-content>
                <v-btn icon :to="{ name: 'constructibles', query: $route.query }" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
        <v-divider></v-divider>

        <!-- Detail -->
        <DrawerAssemblyConstructible v-if="!!ass_uuid" />
        <DrawerModuleConstructible v-else-if="!!mod_uuid" />

    </v-navigation-drawer>
</template>

<script>
import DrawerAssemblyConstructible from "./DrawerAssemblyConstructible";
import DrawerModuleConstructible from "./DrawerModuleConstructible";

export default {
    components: {
        DrawerAssemblyConstructible,
        DrawerModuleConstructible,
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        parametros() {
            return this.$route.params;
        },
        project_uuid() {
            return this.$route.params?.project_uuid;
        },
        ass_uuid() {
            return this.$route.params?.ass_uuid;
        },
        mod_uuid() {
            return this.$route.params?.mod_uuid;
        },
    },
};
</script>