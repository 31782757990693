<template>
    <div class="scroll-left">
        <span>
            <v-icon x-small> mdi-toolbox </v-icon> {{ station_name }}
            <v-icon x-small> mdi-pen </v-icon> Task: {{ next_task_name }}
        </span>
    </div>
</template>


<style lang="scss" scoped>
@import "src/scss/_marquee.scss";
</style>

<script>
export default {
    props: ["station_name", "tasks", "my_job_is_ready", "not_started"],
    computed: {
        next_task() {
            return this.tasks.filter((t) => !t.checked)?.[0];
        },
        next_task_name() {
            if (this.not_started) return "Start production"
            if (this.my_job_is_ready) return "Station Ready";
            return this.next_task?.text || "Mark as ready!";
        },
    },
};
</script>