<template>
    <v-combobox
        :value="value"
        @input="input"
        outlined
        dense
        :items="known_superprojects"
        no-data-text="No superproject"
        clearable
        class="mx-1"
        label="Super project"
        hint="Only if belongs to a group of projects"
    ></v-combobox>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        known_superprojects() {
            return this.$store.state.v2.company.projects
                .map((p) => p.super_project)
                .filter((sp) => sp != "");
        },
    },
    methods: {
        input(v) {
            if (v) this.$emit("input", v);
            else this.$emit("input", "");
        },
    },
};
</script>