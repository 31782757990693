<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <TableMaterials />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TablePlateSize />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableProfileSize />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableProductPlate />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableProductProfile />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TableMaterials from "/src/components/logistics/TableMaterials";
import TableProductPlate from "/src/components/logistics/TableProductPlate";
import TableProductProfile from "/src/components/logistics/TableProductProfile";
import TablePlateSize from "/src/components/logistics/TablePlateSize";
import TableProfileSize from "/src/components/logistics/TableProfileSize";

export default {
    components: {
        TableMaterials,
        TableProductPlate,
        TableProductProfile,
        TablePlateSize,
        TableProfileSize,
    },
    data: () => ({}),
    computed: {
    },
    methods: {

    },
    mounted() {
    },
};
</script>