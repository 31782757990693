<template>
    <v-list-item
        :class="{
            pointer: hover,
        }"
        class="rowlab"
        @click="click_pl(pl)"
        :style="pl_style"
    >
        <v-list-item-content>
            <v-list-item-title>
                {{ pl.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
                <v-chip outlined x-small>
                    {{ pl.workers.length }} workers
                </v-chip>
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import bus from "/src/utils/event_bus";
export default {
    props: ["pl", "hover"],
    computed: {
        rows_count() {
            return this.pl.batches_rows.length || 1;
        },
        pl_style() {
            return {
                height: this.rows_count * 3.5 + "em",
            };
        },
    },
    methods: {
        click_pl(pl) {
            bus.$emit("po_detail", { cat: "production_line", item: pl });
        },
    },
};
</script>