import { render, staticRenderFns } from "./TableProductPlate.vue?vue&type=template&id=939d9d74&"
import script from "./TableProductPlate.vue?vue&type=script&lang=js&"
export * from "./TableProductPlate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports