import router from '/src/router'

export default function (store) {
    // ROUTING
    store.watch(
        (state) => state.app_mode,
        (app_mode) => {
            if (app_mode == "logistics" && router.currentRoute?.name != "logistics") {
                router.push({ ...router.currentRoute, name: "logistics" })
            }
        }
    );


    // LOAD BRANCHES
    store.watch(
        (state) => state.v2.selected_logistics.selected_actuals,
        (uuids) => {
            store.commit("v2/selected_logistics/setSelectedBranch", null)
            store.commit("v2/selected_logistics/setSelectedSize", null)
            store.commit("v2/selected_logistics/setSelectedPart", null)
            store.commit("v2/selected_logistics/setBranches", [])
            store.commit("v2/selected_logistics/setSizes", [])
            store.commit("v2/selected_logistics/setParts", [])
            if (uuids.length != 0) {
                store.dispatch("v2/selected_logistics/loadBranchesByActualUuids", uuids);
            }
        }
    );

    // LOAD SIZES OR PARTS (NOT WOOD OR NOT PLATES)
    store.watch(
        (state) => ({
            actuals: state.v2.selected_logistics.selected_actuals,
            branch: state.v2.selected_logistics.selected_branch,
        }),
        (payload) => {
            store.commit("v2/selected_logistics/setSelectedSize", null)
            store.commit("v2/selected_logistics/setSelectedPart", null)
            store.commit("v2/selected_logistics/setSizes", [])
            store.commit("v2/selected_logistics/setParts", [])
            if (payload.branch === null) {
                return
            } else if (!["timber", "plate"].includes(payload.branch)) {
                store.dispatch("v2/selected_logistics/loadParts", payload)
            } else if (payload.actuals.length != 0) {
                store.dispatch("v2/selected_logistics/loadSizesByActualUuidsAndBranch", payload)
            }
        }
    );

    // LOAD PARTS
    store.watch(
        (state) => ({
            branch: state.v2.selected_logistics.selected_branch,
            actuals: state.v2.selected_logistics.selected_actuals,
            size: state.v2.selected_logistics.selected_size,
        }),
        (payload) => {
            store.commit("v2/selected_logistics/setSelectedPart", null)
            store.commit("v2/selected_logistics/setParts", [])
            if (payload.actuals.length != 0 && payload.branch != null && payload.size != null) {
                store.dispatch("v2/selected_logistics/loadParts", payload)
            }

        }
    );
}
