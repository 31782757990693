<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("materials") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="expanded" :loading="loading_button" color="accent" text @click="new_material">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-left">
                            {{ $store.getters.t("uuid") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("created") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("modified") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("material_family") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("erp_code") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="mat in materials" :key="mat.uuid">
                        <td class="text-left">{{ mat.uuid.substring(0, 8) }}</td>
                        <td class="text-left">
                            <Timestamp :value="mat.created" />
                        </td>
                        <td class="text-left">
                            <Timestamp :value="mat.modified" />
                        </td>
                        <td class="text-left">{{ mat.name }}</td>
                        <td class="text-left">{{ mat.material_family }}</td>
                        <td v-if="mat.erp_code === ''" class="text-left"> - </td>
                        <td v-else class="text-left">{{ mat.erp_code }}</td>

                        <!-- ACTIONS -->
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(mat.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(mat.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>

                <!-- EDIT DIALOG -->
                <v-dialog v-model="dialog" max-width="1000" persistent>
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("Material Setup") }}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="py-3">
                            <FormMaterialSetup ref="material_form" v-model="form" @save="post_material" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_material" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- DELETE DIALOG -->
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_material") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_material_text") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_material" color="error" :disabled="loading_delete"
                                :loading="loading_delete">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import _ from "lodash";
import Timestamp from "/src/components/renderers/Timestamp.vue";
import FormMaterialSetup from "/src/components/logistics/FormMaterialSetup";
import { nameSort } from "/src/utils/basics.js";

export default {
    props: [],
    components: {
        Timestamp, FormMaterialSetup
    },
    data: () => ({
        expanded: false,
        loading: false,
        loading_button: false,
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        loading_delete: false,
        saving: false,
        form: {},
    }),
    computed: {
        materials() {
            return [...this.$store.state.v2.logistics.materials].sort(
                (a, b) => nameSort(a.name, b.name))
        }
    },
    methods: {
        new_material() {
            this.form = {
                name: "",
                material_family: "",
                erp_code: "",
            };
            this.dialog = true;
        },
        launch_edit(uuid) {
            let mat = this.materials.find((mat) => mat.uuid == uuid);
            this.form = _.cloneDeep(mat);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_material() {
            this.loading_delete = true;
            this.$store
                .dispatch("v2/logistics/deleteMaterial", this.delete_uuid)
                .then(() => {
                    this.loading_delete = false;
                    this.delete_dialog = false;
                    console.log("[i] Material deleted")
                });
        },
        post_material() {
            if (!this.$refs.material_form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("[i] Updating material");
                this.$store
                    .dispatch("v2/logistics/putMaterial", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("[i] Creating Material");
                this.$store
                    .dispatch("v2/logistics/postMaterial", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
    },
    mounted() {
        this.$store.dispatch("v2/logistics/loadMaterials");
    }
};

</script>