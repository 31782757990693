<template>
    <td>
        <v-list-item-title>
            {{ dname }}
            <v-icon :class="oldrev ? 'red--text' : ''" small>
                mdi-numeric-{{ design.revision }}-box
            </v-icon>
            <span class="mx-1"></span>
            <ChipSubassemblyOf v-if="show_sub_chip" :value="design.parent_designs" />

            <AreaIndicator :value="bruto_area" />
        </v-list-item-title>
    </td>
</template>

<script>
import ChipSubassemblyOf from '../../renderers/ChipSubassemblyOf.vue';

export default {
    props: ["design", "oldrev"],
    computed: {
        dname() {
            return this.design?.name || "---";
        },
        bruto_area() {
            return this.design.meta?.metrics?.bruto || 0;
        },
        show_sub_chip() {
            return [
                "assembly",
                //  "subassembly"
            ].includes(this.design.level) && this.design.parent_designs.length != 0
        }
    },
    components: { ChipSubassemblyOf }
}
</script>