
import { size_string_from_part_design } from "/src/components/viewer/right/branches"

function update_counts_per_constructible(counts_per_constructible, part) {
    const wname = part.assembly_design_name || part.module_design_name;
    const uuid = (part.assembly || part.module)?.substring(0, 8)
    const status = part.status || "waiting";


    const pname = wname + "__" + uuid

    if (!counts_per_constructible[pname]) {
        counts_per_constructible[pname] = {
            design: wname,
            uuid: uuid,
            count: 1,
            statuses: [status]
        };
    } else {
        counts_per_constructible[pname].count++;
        counts_per_constructible[pname].statuses.push(status);
    }


    return counts_per_constructible;
}

function find_part_in_table_from_dimensions(parts, width, height, length, thickness, ref) {
    return parts.find(part =>
        Math.round(part["width"]) == Math.round(width) &&
        Math.round(part["height"]) == Math.round(height) &&
        Math.round(part["length"]) == Math.round(length) &&
        Math.round(part["thickness"]) == Math.round(thickness) &&
        part["ref"] == ref
    );
}

export function part_grouping(parts) {
    let part_groups = [];

    parts.forEach(part => {
        const ref = part.design?.name;
        const zone = part.meta?.zone;
        const width = part.design?.meta?.width;
        const height = part.design?.meta?.height;
        const length = part.design?.meta?.length;
        const thickness = part.design?.meta?.thickness;
        const part_is_ready = part.status == 'ready';
        let size = size_string_from_part_design(part.design);
        let pd_uuid = part.design?.uuid

        let pg = part_groups.find(pg => pg.zone == zone);

        if (!pg) {
            pg = {
                "zone": zone,
                "parts": []
            };
            part_groups.push(pg);
        }

        let parts = pg["parts"];

        let part_in_table = find_part_in_table_from_dimensions(
            parts, width, height, length, thickness, ref
        );

        if (!part_in_table) {
            part_in_table = {
                "ref": ref,
                "width": width,
                "height": height,
                "length": length,
                "thickness": thickness,
                "size": size,
                "zone": zone,
                "count": 0,
                "ready_count": 0,
                "counts_per_constructible": {},
                "uuids": [],
                "pd_uuid": pd_uuid,
            };
            parts.push(part_in_table);
        }

        if (part_is_ready) part_in_table["ready_count"] += 1;

        part_in_table["count"] += 1;
        part_in_table["counts_per_constructible"] = update_counts_per_constructible(
            part_in_table["counts_per_constructible"], part
        );

        part_in_table["uuids"].push(part.uuid);
    });

    return part_groups;
}
