<template>
    <div class="d-flex flex-column">
        <v-dialog v-model="dialog" width="900">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" outlined small v-bind="attrs" v-on="on">
                    <v-icon left small> mdi-format-list-checkbox </v-icon>
                    {{ $store.getters.t("form") }}
                </v-btn>
            </template>

            <v-card>
                <v-card-title> {{ $store.getters.t("custom_form") }} </v-card-title>
                <v-card-subtitle class="d-flex"> {{ $store.getters.t("custom_form_subtitle") }}
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="accent" @click="add"> <v-icon left small> mdi-plus </v-icon>
                        {{ $store.getters.t("add") }}
                    </v-btn>
                </v-card-subtitle>
                <!-- Actual field -->
                <v-sheet outlined class="ma-5 pa-3 d-flex" rounded v-for="(field, i) in form_schema" :key="i">
                    <v-select v-model="field.data_type" dense outlined class="mx-1" hide-hints :items="field_types"
                        :rules="[v => !!v]" label="Field type"></v-select>
                    <v-text-field v-model="field.name" dense outlined class="mx-1" hide-hints :rules="[v => !!v]"
                        label="Field label"></v-text-field>
                    <v-textarea v-if="['select', 'multi_select'].includes(field.data_type)" outlined dense
                        :label="$store.getters.t('select_options')" :hint="$store.getters.t('select_options_hint')"
                        @change="touch_select_options(i, $event)"></v-textarea>
                    <v-text-field v-model="field.suffix" dense outlined class="mx-1" hide-hints
                        :label="$store.getters.t('suffix')" v-if="['text', 'int', 'number'].includes(field.data_type)">
                    </v-text-field>
                    <v-checkbox v-model="field.required" dense :label="$store.getters.t('required')"></v-checkbox>
                    <v-btn small class="ml-3 mt-1" icon color="accent" @click="delete_field(i)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </v-sheet>
                <!-- End of field -->
                <v-card-text v-if="form_schema.length == 0"> {{ $store.getters.t("custom_form_void") }} </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="clear">
                        {{ $store.getters.t("clear") }}
                    </v-btn>
                    <v-btn color="secondary" @click="close">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: { default: () => [] },
    },
    data: () => ({
        dialog: false,
        form_schema: [],
        field_types: [
            { value: "bool", text: "Boolean (A Checkbox)" },
            { value: "select", text: "Select (Will show options to pick 1)" },
            { value: "multi_select", text: "Select Multiple" },
            { value: "text", text: "Text" },
            { value: "int", text: "Int (Integer number)" },
            { value: "number", text: "Number" },
            { value: "text_area", text: "Text Area (Bigger area for longer text)" },
        ],
    }),
    computed: {
    },
    watch: {
        value(v) {
            this.form_schema = [...v]
        },
        dialog() {
            this.form_schema = [...this.value]
        }
    },
    methods: {
        add() {
            this.form_schema.push({ "data_type": "checkbox", "name": "No label" })
        },
        clear() {
            this.form_schema = []
        },
        close() {
            this.$emit("input", [...this.form_schema])
            this.dialog = false
        },
        delete_field(i) {
            this.form_schema.splice(i, 1)
        },
        touch_select_options(i, ev) {
            this.form_schema[i].options = ev.split("\n")
        },
    },
    mounted() {
    },
}
</script>