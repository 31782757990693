<template>
    <v-list>
        <!-- INJECT FIRST PANEL HERE -->
        <v-subheader v-if="show_first"> {{ $store.getters.t("first_constructible") }} </v-subheader>
        <ListItemConstructible :level="first_level" v-if="show_first" :constructible="first" working />
        <v-skeleton-loader class="ml-1 mt-5" type="text" width="50"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-three-line@3"></v-skeleton-loader>
    </v-list>
</template>

<script>
import ListItemConstructible from "./ListItemConstructible.vue"

export default {
    components: {
        ListItemConstructible
    },
    props: ["loading"],
    computed: {
        designs_mode() {
            return this.$store.state.v2.viewer.designs_mode;
        },
        first() {
            return this.$store.getters["v2/constructibles/myFirst"]()
        },
        first_level() {
            return this.first?.level
        },
        show_first() {
            return !this.designs_mode && this.first
        }
    }
}

</script>