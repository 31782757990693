<template>
    <div class="d-flex flex-column roundbox pa-2 mb-3 ma-1">
        <span class="ml-3 mb-2">
            {{
                period.is_a_break
                    ? $store.getters.t("break_text")
                    : $store.getters.t("worktime_text")
            }}
            [{{ period.start }}-{{ period.end }}]
        </span>
        <div class="d-flex">
            <v-select
                style="width: 200px"
                class="mr-2"
                :label="'Weekdays ' + (i + 1)"
                :items="weekdays"
                multiple
                v-model="period.weekdays"
                outlined
                dense
                :rules="[(v) => v.length != 0 || 'Select at least one']"
            >
            </v-select>
            <v-menu
                :close-on-content-click="false"
                v-model="clocks[i + 's']"
                max-width="300"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="mr-2"
                        :label="'Start ' + (i + 1)"
                        v-model="period.start"
                        :rules="[(v) => !!v || 'Set time']"
                        outlined
                        dense
                        hide-hints
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-text-field>
                </template>
                <v-time-picker
                    v-if="clocks[i + 's']"
                    format="24hr"
                    color="accent"
                    v-model="period.start"
                    full-width
                    @click:minute="clocks[i + 's'] = false"
                ></v-time-picker>
            </v-menu>
            <v-menu
                :close-on-content-click="false"
                v-model="clocks[i + 'e']"
                max-width="300"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="'End ' + (i + 1)"
                        v-model="period.end"
                        :rules="[(v) => !!v || 'Set time']"
                        outlined
                        dense
                        hide-hints
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-text-field>
                </template>
                <v-time-picker
                    v-if="clocks[i + 'e']"
                    v-model="period.end"
                    full-width
                    format="24hr"
                    color="accent"
                    @click:minute="clocks[i + 'e'] = false"
                ></v-time-picker>
            </v-menu>
        </div>
        <div class="d-flex">
            <v-checkbox
                class="ma-3 mt-1 mb-0"
                :label="$store.getters.t('its_a_break')"
                v-model="period.is_a_break"
            >
            </v-checkbox>
            <v-checkbox
                class="ma-3 mt-1 mb-0"
                :label="$store.getters.t('calendar_range')"
                :input-value="show_range_picker"
                @change="update_ranges($event)"
            />
            <DatePickerRange
                v-if="show_range_picker"
                v-model="period.range_dates"
            />
            <v-spacer></v-spacer>
            <v-btn small class="ml-3 mt-1" icon @click="$emit('delete_period')">
                <v-icon> mdi-delete-forever </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<style scoped>
.roundbox {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}
</style>

<script>
import DatePickerRange from "/src/components/DatePickerRange";
export default {
    props: ["value", "i"],
    components: { DatePickerRange },
    data: () => ({ period: false, clocks: {} }),
    computed: {
        weekdays() {
            return [
                {
                    value: "monday",
                    text: this.$store.getters.t("monday"),
                },
                {
                    value: "tuesday",
                    text: this.$store.getters.t("tuesday"),
                },
                {
                    value: "wednesday",
                    text: this.$store.getters.t("wednesday"),
                },
                {
                    value: "thursday",
                    text: this.$store.getters.t("thursday"),
                },
                {
                    value: "friday",
                    text: this.$store.getters.t("friday"),
                },
                {
                    value: "saturday",
                    text: this.$store.getters.t("saturday"),
                },
                {
                    value: "sunday",
                    text: this.$store.getters.t("sunday"),
                },
            ];
        },
        show_range_picker() {
            return this.period.range_dates?.length > 0;
        },
    },
    watch: {
        value: {
            handler: function (v) {
                console.log("watcher value");
                this.period = v;
            },
            immediate: true,
            deep: true,
        },
        period: {
            handler: function (p) {
                this.$emit("input", p);
            },
            deep: true,
        },
    },
    methods: {
        update_ranges(checkbox) {
            if (checkbox) {
                this.period.range_dates = ["2020-01-01", "2022-12-31"];
            } else {
                this.period.range_dates = false;
            }
        },
    },
    mounted() {
        // This, to fill legacy periods
        if (!("range_dates" in this.period)) {
            console.log("[i] This period has no range dates. Adding.");
            this.$set(this.period, "range_dates", false);
        }
    },
};
</script>