<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-card outlined dense class="mx-1">
                        <v-card-text>
                            <v-row>
                                <!-- <v-col :cols="selected_part !== '---' ? 6 : 12"> -->
                                <v-col cols="6">
                                    <v-card-title>
                                        {{ $store.getters.t("logistics") }}
                                    </v-card-title>
                                    <LogisticsBranchesBar v-if="selected_actuals" />
                                    <!-- LogisticsViewer.vue -->
                                    <LogisticsSizesBar v-if="selected_branch" @input="handleSelectedSize" />
                                    <TableLogistics />
                                </v-col>
                                <!-- <v-col v-if="selected_part !== '---'" cols="6"> -->
                                <v-col cols="6">
                                    <RightPanelLogistics />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
  
  
<script>

import LogisticsBranchesBar from "/src/components/logistics_viewer/LogisticsBranchesBar.vue"
import LogisticsSizesBar from "/src/components/logistics_viewer/LogisticsSizesBar.vue"
import RightPanelLogistics from "/src/components/logistics_viewer/RightPanelLogistics.vue"
// import TableLogistics from '/src/components/logistics_viewer/TableLogisticsPROVISIONAL.vue';
import TableLogistics from '/src/components/logistics_viewer/TableLogistics.vue';

export default {
    components: {
        TableLogistics,
        LogisticsBranchesBar,
        LogisticsSizesBar,
        RightPanelLogistics,
    },
    data: () => ({}),
    computed: {
        selected_actuals() {
            return this.$store.state.v2.selected_logistics.selected_actuals;
        },
        selected_branch() {
            return this.$store.state.v2.selected_logistics.selected_branch || "---";
        },
        selected_size() {
            return this.$store.state.v2.selected_logistics.selected_size || "---";
        },
        selected_part() {
            return this.$store.state.v2.selected_logistics.selected_part || "---";
        },
        branches() {
            return this.$store.state.v2.selected_logistics.branches || "---";
        },
        sizes() {
            return this.$store.state.v2.selected_logistics.sizes || "---";
        },
    },
    methods: {
        handleSelectedSize(uuid) {
            this.$store.commit('v2/selected_logistics/setSelectedSize', uuid);
        },
    },
};
</script>