<template>
    <v-list dense two-line>
        <v-subheader v-if="first">
            {{ $store.getters.t("first_constructible") }}
        </v-subheader>
        <ListItemConstructible :level="level" v-if="first" :constructible="first" />
        <v-subheader v-if="futures.length != 0">
            {{ $store.getters.t("following_constructibles") }}
        </v-subheader>
        <v-virtual-scroll bench="10" :items="futures" class="flex-grow" item-height="80">
            <template v-slot:default="{ index, item }">
                <ListItemConstructible :level="level" :constructible="item" :key="index" />
            </template>
        </v-virtual-scroll>
        <v-subheader v-if="futures.length == 0 && !first">
            {{ $store.getters.t(`no_${level}_for_this_station`) }}
        </v-subheader>
    </v-list>
</template>


<script>
import ListItemConstructible from './ListItemConstructible.vue';
export default {
    components: {
        ListItemConstructible,
    },
    props: ["level"],
    computed: {
        first() {
            return this.$store.getters["v2/constructibles/myFirst"](this.level)
        },
        futures() {
            return this.$store.getters["v2/constructibles/myListNoFirst"](this.level)
        }
    },
}
</script>


