<template>
    <v-dialog v-model="dialog" max-width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="icon == undefined" color="success" :loading="loading"
                :disabled="loading">
                <v-icon left>mdi-comment-check-outline</v-icon>
                {{ $store.getters.t("solve_annotation") }}
            </v-btn>
            <v-btn v-bind="attrs" v-on="on" icon v-else :loading="loading" :disabled="loading">
                <v-icon>mdi-comment-check-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline"> {{ $store.getters.t("solve_annotation") }} </v-card-title>
            <v-card-text class="body-1"> {{ $store.getters.t("select_revision_annotation") }} </v-card-text>
            <v-card-text>
                <v-form>
                    <v-select outlined dense v-model="revision" :items="revisions" label="Revision"></v-select>
                    <v-textarea outlined dense label="Comment" v-model="solved_text"></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="solveIssue()" color="success">
                    <v-icon left>mdi-check</v-icon>
                    {{ $store.getters.t("submit") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ButtonIssueSolve",
    props: ["issue", "icon"],
    data: () => ({
        dialog: false,
        revision: null,
        solved_text: "",
        loading: false,
    }),
    computed: {
        level() {
            if (this.issue && this.issue.assembly_design) return "assembly"
            else if (this.issue && this.issue.module_design) return "module"
            else return "all"
        },
        revisions() {
            let designs = []
            let d_uuid
            if (this.level == "assembly") {
                designs = this.$store.state.v2.constructibles.assembly_designs
                d_uuid = this.issue.assembly_design
            } else if (this.level == "module") {
                designs = this.$store.state.v2.constructibles.module_designs
                d_uuid = this.issue.module_design
            } else return []
            let design = designs.find((d) => d.uuid == d_uuid)
            if (!design) return []
            return designs
                .filter((d) => d.name == design.name && d.project == design.project)
                .sort((a, b) => a.revision - b.revision)
                .map((d) => ({
                    text: d.name + " rev " + d.revision,
                    value: d.uuid,
                }))
        },
    },
    methods: {
        solveIssue() {
            this.loading = true
            let issue = {
                ...this.issue,
                solved: new Date(),
                wall_solved: this.revision,
                solved_text: this.solved_text,
                status: "solved",
            }
            if (this.level == "assembly") {
                issue.assembly_design_solved = this.revision
            } else if (this.level == "module") {
                issue.module_design_solved = this.revision
            }
            this.$store
                .dispatch("v2/records/putIssue", issue)
                .finally(() => (this.loading = false))
            this.dialog = false
        },
    },
}
</script>