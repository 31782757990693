<template>
    <v-btn color="secondary" class="ml-1 mr-1" @click="$emit('click')" large
        :disabled="station_name == 'FAILED_TO_LOAD_STATION'" :loading="loading_proof">
        <div class="d-flex flex-column">
            <div class="d-flex">
                <v-icon small>mdi-order-bool-ascending-variant</v-icon>
                {{ $store.getters.t("checklist_short_name") }}
            </div>
            <span v-if="my_job_is_ready" class="small-text mb-1">
                <v-icon x-small>mdi-checkbox-marked-outline</v-icon> Ready
            </span>
            <span v-else-if="not_started" class="small-text mb-1">
                <v-icon x-small>mdi-checkbox-blank-outline</v-icon> Not started
            </span>
            <span v-else-if="paused" class="small-text mb-1">
                <v-icon x-small>mdi-pause</v-icon> Paused
            </span>
            <span v-else class="small-text mb-1">
                <v-icon x-small> mdi-checkbox-marked-outline </v-icon>{{ done_tasks_count }} of
                <v-icon x-small> mdi-checkbox-blank-outline </v-icon>{{ total_tasks_count }}
            </span>
            <v-progress-linear color="success" :value="not_started ? 0 : (done_tasks_count / total_tasks_count) * 100"
                :background-color="not_started ? 'error' : 'warning'">
            </v-progress-linear>
        </div>
    </v-btn>
</template>

<style lang="scss" scoped>
.small-text {
    font-size: 0.5em;
}
</style>

<script>
export default {
    name: "ActualChecklistButton",
    props: [
        "station_name",
        "loading_proof",
        "tasks",
        "my_job_is_ready",
        "not_started",
        "paused",
    ],
    computed: {
        filtered_tasks() {
            return this.tasks.filter((t) => !["STATION_JOB_READY", "STATION_STARTED"].includes(t.text));
        },
        done_tasks_count() {
            return this.filtered_tasks.filter((t) => t.checked).length;
        },
        total_tasks_count() {
            return this.filtered_tasks.length;
        },
    },
};
</script>