<template>
    <div>
        <v-data-table :headers="headers" :items="designs_for_table" item-key="uuid" show-expand single-expand
            :expanded.sync="expanded" sort-by="name" :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, -1],
        }" :items-per-page="50" :search="search" show-select v-model="selected">
            <template v-slot:top>
                <div class="my-2" v-if="selected.length == 0"><br /></div>
                <div class="mx-5 my-2" v-else>
                    <ButtonDeleteDesign :multiple="true" :uuids="selected_uuids" @completed="selected = []" />
                    <ButtonDialogDetails :multiple="true" :uuids="selected_uuids" @completed="selected = []" />
                    <ButtonForceAnalysis :uuids="selected_uuids" @completed="selected = []" />
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <v-btn :to="item.to_route" text color="accent">
                    {{ item.name }}
                </v-btn>
                <ChipSubassemblyOf v-if="item.level == 'assembly' && item.tipo == 'subassembly'"
                    :value="item.parent_designs" />
            </template>
            <template v-slot:item.uuid="{ item }">
                {{ item.uuid.substring(0, 8) }}
            </template>
            <template v-slot:item.revision="{ item }">
                <IconRevision :rev="item.revision" />
            </template>
            <template v-slot:item.meta.has_ifc="{ item }">
                <IconHasIFC :value="item.meta.has_ifc" />
            </template>
            <template v-slot:item.meta.analyzed="{ item }">
                <IconAnalysisStatus :value="item.meta.analysis" />
            </template>
            <template v-slot:item.created="{ item }">
                <Timestamp :value="item.created" />
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn text :to="item.viewer_route">
                    <v-icon left> mdi-map-legend </v-icon>
                    {{ $store.getters.t("view_meaning_action_of_view") }}
                </v-btn>
                <ButtonDeleteDesign icon :uuid="item.uuid" />
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td class="pa-0" :colspan="headers.length">
                    <v-sheet outlined color="grey" class="pl-1">
                        <v-simple-table v-if="item.old_versions.length != 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">UUID</th>
                                        <th class="text-right">
                                            {{ $store.getters.t("revision") }}
                                        </th>
                                        <th class="text-right">
                                            {{ $store.getters.t("created") }}
                                        </th>
                                        <th class="text-right">
                                            {{ $store.getters.t("panels") }}
                                        </th>
                                        <th class="text-right">
                                            {{ $store.getters.t("dwgs") }}
                                        </th>
                                        <th class="text-right">IFC</th>
                                        <th class="text-right">
                                            {{ $store.getters.t("actions") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="version in item.old_versions" :key="version.uuid">
                                        <td class="text-left">
                                            <v-btn :to="'/projects/' +
            version.project +
            '/designs/' +
            version.uuid
            " text color="accent">
                                                {{
            version.uuid.substring(0, 8)
        }}
                                            </v-btn>
                                        </td>
                                        <td class="text-right">
                                            <IconRevision :rev="version.revision" :old="true" />
                                        </td>
                                        <td class="text-right">
                                            <Timestamp :value="version.created" />
                                        </td>
                                        <td class="text-right">
                                            {{ version.meta.panel_count }}
                                        </td>
                                        <td class="text-right">
                                            {{ version.meta.dwg_count }}
                                        </td>
                                        <td class="text-right">
                                            <IconHasIFC :value="version.meta.has_ifc" />
                                        </td>
                                        <td class="text-right">
                                            <v-btn text :to="'/designs/' + version.uuid">
                                                <v-icon left>
                                                    mdi-map-legend
                                                </v-icon>
                                                {{
            $store.getters.t(
                "view_meaning_action_of_view"
                                                )
                                                }}
                                            </v-btn>
                                            <ButtonDeleteDesign icon :uuid="version.uuid" />
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-sheet v-else class="text-center pa-5">
                            <span class="display-1 text-center font-weight-thin">
                                No old revisions
                            </span>
                        </v-sheet>
                    </v-sheet>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ButtonDeleteDesign from "./ButtonDeleteDesign";
import ButtonDialogDetails from "./ButtonDialogDetails";
import ButtonForceAnalysis from "./ButtonForceAnalysis";
import IconHasIFC from "/src/components/renderers/IconHasIFC";
import IconRevision from "/src/components/renderers/IconRevision.vue";
import IconAnalysisStatus from "/src/components/renderers/IconAnalysisStatus";
import Timestamp from "/src/components/renderers/Timestamp";
import { nameSort } from "/src/utils/basics.js";
import ChipSubassemblyOf from "../renderers/ChipSubassemblyOf.vue";

export default {
    components: {
        ButtonDeleteDesign,
        ButtonDialogDetails,
        ButtonForceAnalysis,
        IconHasIFC,
        IconRevision,
        IconAnalysisStatus,
        Timestamp,
        ChipSubassemblyOf,
    },
    props: ["search", "mode"],
    data: () => ({
        expanded: [],
        selected: [],
    }),
    computed: {
        headers() {
            return [
                {
                    text: this.$store.getters.t("name"),
                    value: "name",
                    sort: nameSort,
                },
                { text: "UUID", value: "uuid" },
                {
                    text: this.$store.getters.t("revision"),
                    value: "revision",
                },
                {
                    value: "created",
                    text: this.$store.getters.t("created"),
                },
                {
                    text: this.$store.getters.t("units"),
                    value: "meta.actual_count",
                },
                {
                    text: this.$store.getters.t("dwgs"),
                    value: "meta.dwg_count",
                },
                {
                    text: "IFC",
                    value: "meta.has_ifc",
                },
                {
                    text: this.$store.getters.t("analyzed"),
                    value: "meta.analyzed",
                },
                {
                    text: "Actions",
                    sortable: false,
                    align: "right",
                    value: "actions",
                },
                { text: "", align: "end", value: "data-table-expand" },
            ];
        },
        designs() {
            return this.$store.getters["v2/constructibles/annotatedDesignsFromLevel"](this.mode)
        },
        designs_for_table() {
            let designs_for_table = [];
            // Loop that fills last revisions
            this.designs.forEach((w) => {
                let i = designs_for_table.findIndex((r) => r.name == w.name);
                if (i == -1) {
                    designs_for_table.push({ ...w, old_versions: [] });
                } else if (designs_for_table[i].revision < w.revision) {
                    designs_for_table[i] = { ...w, old_versions: [] };
                }
            });

            // Loop that fils children
            this.designs.forEach((w) => {
                let i = designs_for_table.findIndex((r) => r.name == w.name);
                if (designs_for_table[i].revision != w.revision) {
                    designs_for_table[i].old_versions.push(w);
                }
            });

            designs_for_table = designs_for_table.map(d => ({
                ...d,
                to_route: {
                    name: `${d.level}_design_detail`,
                    query: this.$route.query,
                    params: {
                        ...d.level == 'assembly' && { assd_uuid: d.uuid },
                        ...d.level == 'module' && { modd_uuid: d.uuid },
                        project_uuid: d.project
                    }
                },
                viewer_route: `/viewer/${d.level}_design/${d.uuid}`,

            }))

            return designs_for_table;
        },
        selected_uuids() {
            let uuids = [];
            this.selected.forEach((s) => {
                uuids.push(s.uuid);
                uuids.push(...s.old_versions.map((ov) => ov.uuid));
            });
            return uuids;
        },
    },
};
</script>