export function clean_branches(branches) {
    let clean = [...branches]
    clean = clean.filter(b => !["unknown", "frame", "plate", "timber"].includes(b.toLowerCase()))
    clean.unshift("timber", "plate")
    return clean
}

export function clean_non_logistics_branches(branches) {
    let clean = [...new Set(branches)]
    clean = clean.filter(b => !["unknown"].includes(b.toLowerCase()))
    clean.sort()
    clean.push("unknown")
    return clean
}

export function size_string_from_part_design(part_design) {
    if (!part_design) return "---"
    if (part_design.tipo == "plate") return part_design.meta?.width
    else return `${part_design.meta?.width}*${part_design.meta?.height}`
}
