<template>
    <v-sheet class="pa-2 my-2" outlined rounded>
        <div class="d-flex">
            <h4>
                {{ $store.getters.t("workers") }}
            </h4>
            <span class="ml-2"> ({{ workers_list.length }}) </span>
            <v-spacer></v-spacer>
            <ButtonProdLineAddUser
                :item="item"
                @added="added"
                ref="button_add"
            />
        </div>
        <v-chip
            class="ma-2"
            v-for="worker in workers_list"
            :key="worker.id"
            close
            :disabled="loading_users.includes(worker.id)"
            @click:close="remove(worker.id)"
        >
            {{ worker.name }}
        </v-chip>
    </v-sheet>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
import ButtonProdLineAddUser from "./ButtonProdLineAddUser.vue";
export default {
    components: { ButtonProdLineAddUser },
    props: ["item"],
    data: () => ({
        workers_list: [],
        loading_users: [],
    }),
    watch: {
        item() {
            this.refresh_list();
        },
    },
    methods: {
        remove(id) {
            this.loading_users.push(id);
            backend_api_v2
                .patch("/company/users/" + id + "/", {
                    auth: { production_station: null },
                })
                .then(() => {
                    const workers = this.workers_list.filter((w) => w.id != id);
                    const payload = { uuid: this.item.uuid, workers };
                    this.$store.commit("overview/localPatchPL", payload);
                    this.workers_list = workers;
                    this.$refs.button_add.refreshNAW();
                })
                .finally(() => {
                    this.loading_users = this.loading_users.filter(
                        (i) => i != id
                    );
                });
            console.log("removing user from pl:", id);
        },
        added(user) {
            this.workers_list.push({ name: user.username, id: user.id });
            const payload = {
                uuid: this.item.uuid,
                workers: this.workers_list,
            };
            this.$store.commit("overview/localPatchPL", payload);
        },
        refresh_list() {
            this.workers_list = this.item.workers;
        },
    },
    mounted() {
        this.refresh_list();
    },
};
</script>