<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent" class="ml-2">
                <v-icon left>mdi-cog</v-icon> SETTINGS
            </v-btn>
        </template>

        <v-card>
            <v-card-title> Company Settings </v-card-title>

            <v-card-text>
                <v-form ref="form" @keyup.enter.native="save">
                    <v-card-subtitle> Interface/Viewer Settings </v-card-subtitle>
                    <v-checkbox class="mx-1 mt-0" dense v-model="viewer_show_qr"
                        :label="$store.getters.t('show_qr_button')">
                    </v-checkbox>
                    <v-checkbox class="mx-1 mt-0" dense v-model="viewer_show_rfid"
                        :label="$store.getters.t('show_rfid_button')">
                    </v-checkbox>
                    <v-checkbox class="mx-1 mt-0" dense v-model="viewer_hide_hidden_materials"
                        :label="$store.getters.t('hide_hidden_mats')">
                    </v-checkbox>
                    <SelectLanguage v-model="language" />

                    <v-card-subtitle> Company Workflow Settings </v-card-subtitle>
                    <v-checkbox class="mx-1 mt-0" dense v-model="work_by_date"
                        :label="$store.getters.t('production_based_on_dates')">
                    </v-checkbox>
                    <v-checkbox class="mx-1 mt-0" dense v-model="force_checklist_start"
                        :label="$store.getters.t('force_checklist_start_in_viewer')">
                    </v-checkbox>
                    <v-checkbox class="mx-1 mt-0" dense v-model="use_geom_fixer"
                        :label="$store.getters.t('use_geom_fixer')">
                    </v-checkbox>
                    <v-select outlined dense :label="$store.getters.t('kitted_parts_select_label')"
                        v-model="kitted_parts_behavior" :items="kitted_parts_options" />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" :disabled="loading" color="success" @click="save">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import SelectLanguage from "../account/SelectLanguage.vue"
export default {
    components: { SelectLanguage },
    data: () => ({
        loading: false,
        dialog: false,
        language: "",
        viewer_show_qr: false,
        viewer_show_rfid: false,
        viewer_hide_hidden_materials: false,
        work_by_date: false,
        force_checklist_start: false,
        use_geom_fixer: false,
        kitted_parts_behavior: "always_kitted",  // or "manual", "auto"
    }),
    watch: {
        company() {
            this.fill_form()
        },
        dialog(d) {
            if (d) this.fill_form()
        },
    },
    computed: {
        company() { return this.$store.state.v2.company.company },
        kitted_parts_options() {
            return [
                { text: this.$store.getters.t("kitted_parts_always"), value: "always_kitted" },
                { text: this.$store.getters.t("kitted_parts_manual"), value: "manual" },
                { text: this.$store.getters.t("kitted_parts_auto"), value: "auto" },
            ]
        },
    },
    methods: {
        fill_form() {
            this.language = this.company.meta.language
            this.viewer_show_qr = this.company.meta.viewer_show_qr
            this.viewer_show_rfid = this.company.meta.viewer_show_rfid
            this.viewer_hide_hidden_materials = this.company.meta.viewer_hide_hidden_materials

            this.work_by_date = this.company.work_by_date
            this.force_checklist_start = this.company.force_checklist_start
            this.use_geom_fixer = this.company.use_geom_fixer
            this.kitted_parts_behavior = this.company.kitted_parts_behavior
        },
        save() {
            this.loading = true
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch("v2/company/patchCompany", {
                        meta: {
                            ...this.company.meta,
                            language: this.language,
                            viewer_show_qr: this.viewer_show_qr,
                            viewer_show_rfid: this.viewer_show_rfid,
                            viewer_hide_hidden_materials: this
                                .viewer_hide_hidden_materials,
                        },
                        work_by_date: this.work_by_date,
                        force_checklist_start: this.force_checklist_start,
                        use_geom_fixer: this.use_geom_fixer,
                        kitted_parts_behavior: this.kitted_parts_behavior,
                    })
                    .then(() => {
                        this.loading = false
                        this.dialog = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            } else {
                this.dialog = false
            }
        },
    },
    mounted() {
        this.fill_form()
    },
}
</script>