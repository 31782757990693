<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("product_profile") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="expanded" :loading="loading_button" color="accent" text @click="new_product_profile">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">
                            {{ $store.getters.t("uuid") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("created") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("modified") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("size") }}
                        </th>
                        <th class="text-center">
                            {{ $store.getters.t("length") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr v-for="pp in product_profile" :key="pp.uuid"> -->
                    <tr v-for="pp in product_profile_rows" :key="pp.uuid">
                        <td class="text-left">{{ pp.uuid.substring(0, 8) }}</td>
                        <td>
                            <Timestamp :value="pp.created" />
                        </td>
                        <td>
                            <Timestamp :value="pp.modified" />
                        </td>
                        <td class="text-left">{{ pp.name }}</td>
                        <td class="text-left">{{ pp.size_name }}</td>
                        <td class="text-left">{{ pp.length }}</td>

                        <!-- ACTIONS -->
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(pp.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(pp.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>

                <!-- EDIT DIALOG -->
                <v-dialog v-model="dialog" max-width="1000" persistent>
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("product_profile_setup") }}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="py-3">
                            <FormProductProfileSetup ref="product_profile_form" v-model="form" @save="post_product_profile" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_product_profile" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- DELETE DIALOG -->
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_product_profile") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_product_profile_text") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_product_profile" color="error" :disabled="loading_delete"
                                :loading="loading_delete">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import _ from "lodash";
import Timestamp from "/src/components/renderers/Timestamp.vue";
import FormProductProfileSetup from "/src/components/logistics/FormProductProfileSetup";
import { nameSort } from "/src/utils/basics.js";

export default {
    props: [],
    components: {
        Timestamp, FormProductProfileSetup,
    },
    data: () => ({
        expanded: false,
        loading: false,
        loading_button: false,
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        loading_delete: false,
        saving: false,
        form: {},
    }),
    computed: {
        product_profile() {
            return [...this.$store.state.v2.logistics.product_profiles].sort(
                (a, b) => nameSort(a.name, b.name))
        },
        product_profile_rows() {
            return this.product_profile.map(
                (pp) => ({
                    ...pp,
                    size_name: this.get_size_name(pp.size)
                })
            )
        }
    },
    methods: {
        new_product_profile() {
            this.form = {
                name: "",
                size: "",
                length: "",
            };
            this.dialog = true;
        },
        launch_edit(uuid) {
            let ps = this.product_profile.find((ps) => ps.uuid == uuid);
            this.form = _.cloneDeep(ps);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_product_profile() {
            this.loading_delete = true;
            this.$store
                .dispatch("v2/logistics/deleteProductProfile", this.delete_uuid)
                .then(() => {
                    this.loading_delete = false;
                    this.delete_dialog = false;
                    console.log("[i] Product Profile deleted")
                });
        },
        post_product_profile() {
            if (!this.$refs.product_profile_form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("[i] Updating Product Profile");
                this.$store
                    .dispatch("v2/logistics/putProductProfile", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("[i] Creating new Product Profile");
                this.$store
                    .dispatch("v2/logistics/postProductProfile", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
        get_size_name(uuid) {
            const size_data = this.$store.state.v2.logistics.profile_sizes.find(
                (ps) => ps.uuid === uuid
            );

            if (size_data) {
                const material = this.get_material_name(size_data.material);
                const width = size_data.width;
                const height = size_data.height;
                return `${material} ${width}*${height}`;
            } else {
                return "";
            }
        },
        get_material_name(uuid) {
            return this.$store.state.v2.logistics.materials.find(
                (mat) => mat.uuid === uuid
            )?.name || "-";
        },
    },
    mounted() {
        this.$store.dispatch("v2/logistics/loadProductProfiles");
    }
};

</script>