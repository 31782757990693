<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("work_calendar") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-card-text v-if="expanded">
                <div class="d-flex ma-3 mt-0 mb-5">
                    <h2 class="font-weight-light title">
                        {{ $store.getters.t("work_calendar_subtitle") }}
                    </h2>
                    <v-spacer> </v-spacer>
                    <v-btn small text @click="add_working_hour_period">
                        <v-icon left> mdi-plus </v-icon>
                        {{ $store.getters.t("add_entry") }}
                    </v-btn>
                </div>
                <RowWeekHourEntry v-for="(an, i) in week_work_periods" :key="i + '_an'" :i="i"
                    v-model="week_work_periods[i]" @delete_period="delete_working_hour_period(i)" />
                <br /><br />

                <div class="d-flex ma-3 mt-0 mb-5">
                    <h2 class="font-weight-light title">
                        {{ $store.getters.t("holyday_calendar") }}
                    </h2>
                </div>
                <div class="d-flex ma-3">
                    <v-date-picker landscape label="Holydays" v-model="holydays" multiple color="accent">
                    </v-date-picker>
                    <div class="ml-2">
                        <h2 class="ml-2 font-weight-light title">
                            {{ $store.getters.t("non_working_days") }}
                        </h2>
                        <v-chip-group column>
                            <v-chip v-for="(hd, i) in holydays" :key="hd" close small outlined
                                @click:close="delete_holyday(i)">
                                {{ hd }}
                            </v-chip>
                        </v-chip-group>
                    </div>
                </div>
            </v-card-text>
        </v-expand-transition>
        <v-card-actions v-if="expanded">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="save" :loading="loading" :disabled="loading">
                <v-icon left> mdi-check </v-icon>
                {{ $store.getters.t("save") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>


<style scoped>
.roundbox {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}
</style>

<script>
import RowWeekHourEntry from "./RowWeekHourEntry";
export default {
    props: ["company"],
    components: { RowWeekHourEntry },
    data: () => ({
        expanded: false,
        loading: false,
        holydays: [],
        clocks: {},
        week_work_periods: [
            // {"weekdays": ["monday", "tuesday", "wednesday", "thursday"], "start": "xxxxxxx", "end": "xxxxx"}
        ],
    }),
    watch: {
        company() {
            this.fill_form();
        },
    },
    methods: {
        fill_form() {
            this.holydays = this.company.work_calendar?.default?.holydays;
            this.week_work_periods =
                this.company.work_calendar?.default?.week_work_periods;
        },
        delete_holyday(i) {
            this.holydays.splice(i, 1);
        },
        add_working_hour_period() {
            this.week_work_periods.push({
                weekdays: [],
                range_dates: false,
                start: null,
                end: null,
                is_a_break: false,
            });
        },
        delete_working_hour_period(i) {
            this.week_work_periods.splice(i, 1);
        },
        save() {
            this.loading = true;
            this.$store
                .dispatch("v2/company/patchCompany", {
                    work_calendar: {
                        default: {
                            holydays: this.holydays,
                            week_work_periods: this.week_work_periods,
                        },
                    },
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.fill_form();
    },
};
</script>