<template>
    <v-row dense class="mt-1 mb-3">
        <v-col sm="6" md="2">
            <SelectDate v-model="selected_prod_date_gte" :label="$store.getters.t('from')" />
        </v-col>

        <v-col sm="6" md="2">
            <SelectDate v-model="selected_prod_date_lte" :label="$store.getters.t('to')" />
        </v-col>

        <v-col sm="6" md="2">
            <ButtonProjectNameFilter v-model="selected_projects_names" />
        </v-col>

        <v-col sm="6" md="2">
            <ButtonProductionLineFilter v-model="selected_production_lines" />
        </v-col>

        <v-col sm="6" md="2">
            <SelectStatusFilter v-model="selected_status" />
        </v-col>

        <v-col sm="6" md="2">
            <v-btn :loading="loading" :disabled="loading" color="warning" @click="emit_payload">Search</v-btn>
        </v-col>
    </v-row>
</template>
  
<script>
import ButtonProjectNameFilter from './ButtonProjectNameFilter.vue';
import ButtonProductionLineFilter from './ButtonProductionLineFilter.vue';
import SelectStatusFilter from './SelectStatusFilter.vue';
import SelectDate from '../fields/SelectDate.vue';

export default {
    props: ["loading"],
    components: {
        SelectDate,
        ButtonProjectNameFilter,
        ButtonProductionLineFilter,
        SelectStatusFilter,
    },
    data: () => ({
        selected_prod_date_gte: "",
        selected_prod_date_lte: "",
        selected_projects_names: [],
        selected_production_lines: [],
        selected_status: [],
    }),
    methods: {
        emit_payload() {
            if (
                !this.selected_prod_date_gte &&
                !this.selected_prod_date_lte &&
                this.selected_projects_names.length === 0 &&
                this.selected_production_lines.length === 0 &&
                this.selected_status.length === 0
            ) {
                return;
            }

            const payload = {
                scheduled_prod_date__gte: this.selected_prod_date_gte,
                scheduled_prod_date__lte: this.selected_prod_date_lte,
                project__in: this.selected_projects_names,
                production_line__in: this.selected_production_lines,
                status__in: this.selected_status,
                extended_serializer: true,
            };

            this.$emit("change", payload);
        },
    },
};
</script>
  