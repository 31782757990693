import {
    // EdgesGeometry, LineSegments,LineBasicMaterial,
    AmbientLight,
    AxesHelper,
    Line,
    DirectionalLight,
    GridHelper,
    Vector3,
    ArrowHelper,
    OrthographicCamera,
    Scene,
    WebGLRenderer,
    LoadingManager,
    Raycaster,
    Vector2,
    MeshLambertMaterial,
    BufferGeometry,
} from "three"


import _ from "lodash"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { IFCLoader } from "web-ifc-three/IFCLoader"
import { buildGUIDMap } from "./utils"

import { DEFAULT_R, DEFAULT_T, FRUSTUMSIZE, get_mod_t, get_mod_r } from "./utils_geom"
import { timeout } from "./utils"
import bus from "/src/utils/event_bus"


// Materials are actually like "GROUPS", when you create or remove
// a subset, the subset is identified by material.
export const SELMAT = new MeshLambertMaterial({
    transparent: true,
    opacity: 0.8,
    color: 0xeb9534,
    depthTest: false,
})


export const MODEL_KEY = 0

export var ifcViewerMixin = {
    props: ["urls"],
    data: () => ({
        loading: true,
        scene: null,
        camera: null,
        ifcLoader: null,
        renderer: null,
        controls: null,
        raycaster: null,
        mouse: null,
        aspect: 1,
        ifc_models: [],
        guid_maps: [],
        layer_subsets: {},
        resize_observer: null,
        clipping: false,
    }),
    computed: {
        selection_uuids() { return this.$store.state.v2.viewer.selected_parts_ifc_ids },
        project() { return this.$store.state.v2.selected.selected_project },
        // FINISH NOT USED PROPS
        always_hidden_ids() {
            return []
        },
        r() { return this.design?.meta?.r || DEFAULT_R },
        mod_r() { return get_mod_r(this.r) },
        t() { return this.design?.meta?.t || DEFAULT_T },
        mod_t() { return get_mod_t(this.t, this.r) },
        cog_t() { return this.mod_t },
        bbox() {
            if (this.ifc_models.length == 0) return null
            this.ifc_models[0].mesh.geometry.computeBoundingBox()
            let box = this.ifc_models[0].mesh.geometry.boundingBox
            return box
        },
        sphere() {
            let void_sphere = { radius: 0 }
            if (this.ifc_models.length == 0) return void_sphere
            let sphere = this.ifc_models[0].mesh.geometry.boundingSphere
            return sphere || void_sphere
        },
        cog_bbox_t() {
            if (!this.bbox) return "no ifc mesh"
            return [
                (this.bbox["max"]["x"] + this.bbox["min"]["x"]) / 2,
                (this.bbox["max"]["y"] + this.bbox["min"]["y"]) / 2,
                (this.bbox["max"]["z"] + this.bbox["min"]["z"]) / 2,
            ]
        },
        worldDir() {
            if (!this.camera) return ""
            let v = new Vector3()
            this.camera.getWorldDirection(v)
            return v
        },
        zoom() {
            if (!this.camera) return ""
            return this.camera.zoom
        },
    },
    watch: {
        urls() {
            this.load_urls()
            this.build_layer_subsets()
        },
        async selection_uuids(sids) {
            console.log("[i][produuz.it IFC Viewer] Selection uuids: ", sids)
            await this.parse_ifc_part_data(sids)
            await this.highlight_items(sids, SELMAT)

        },
        layer_names() {
            this.build_layer_subsets()
        },
        ifc_models() {
            this.build_layer_subsets()
        },
    },
    methods: {
        load_urls: _.debounce(
            function () {
                this.loading = true
                const ifc = this.ifcLoader.ifcManager

                // Unrender all subsets
                this.render_layers([])
                this.highlight_items([], SELMAT)

                // Remove models
                this.ifc_models.forEach((old_model) => {
                    let mid = old_model.mesh.modelID

                    // Removes previous picking subsets
                    ifc.removeSubset(mid, SELMAT)

                    // Remove model from scene
                    this.scene.remove(old_model)

                })

                // Flush and garbage collect old models
                this.ifc_models.splice(0)



                // Add fresh models
                this.urls.forEach((url) => {
                    this.ifcLoader.load(url, (ifcModel) => {
                        this.ifc_models.push(ifcModel)
                        this.scene.add(ifcModel)


                        // const edgesGeometry = new EdgesGeometry(ifcModel.mesh.geometry);
                        // const edgesMaterial = new LineBasicMaterial({ color: 0x000000, linewidth: 14 });
                        // const edges = new LineSegments(edgesGeometry, edgesMaterial);
                        // this.scene.add(edges);
                    })
                })
            },
            1000
        ),
        async on_model_load() {
            await timeout(2000);
            this.resetView()
            this.loading = false
            this.build_data_structures()
        },
        async resetView() {
            if (!this.camera) return
            console.log("[i][produuz.it IFC Viewer] Rescaling ThreeJS.")
            this.resizeRendererCamera()
            this.recenterCamera()
            this.set_camera("front")
        },
        recenterCamera() {
            const container = this.$refs.canvasContainer
            this.camera.zoom = 1
            // aspect equals window.innerWidth / window.innerHeight
            if (this.aspect > 1.0) {
                // if view is wider than it is tall, zoom to fit height
                this.camera.zoom =
                    container.offsetHeight / (this.sphere.radius * 2)
            } else {
                // if view is taller than it is wide, zoom to fit width
                this.camera.zoom =
                    container.offsetWidth / (this.sphere.radius * 2)
            }
            this.camera.updateProjectionMatrix()
        },
        set_camera(side) {
            if (!this.camera || !this.controls || !this.mod_r) return
            let dir = new Vector3()
            if (side == "front") {
                dir.set(...this.mod_r[2])
            } else if (side == "back") {
                dir.set(...this.mod_r[2].map((i) => -i))
            } else if (side == "top") {
                dir.set(...this.mod_r[1])
            } else if (side == "bottom") {
                dir.set(...this.mod_r[1].map((i) => -i))
            } else if (side == "right") {
                dir.set(...this.mod_r[0].map((i) => -i))
            } else if (side == "left") {
                dir.set(...this.mod_r[0])
            }
            this.controls.target.set(...this.cog_bbox_t)
            this.camera.position.set(...this.cog_bbox_t)
            this.camera.lookAt(...this.cog_bbox_t)
            this.camera.translateOnAxis(dir, 100)
        },
        vector_draw() {
            const dir = new Vector3(...this.mod_t)
            const length = dir.length()

            //normalize the direction vector (convert to vector of length 1)
            dir.normalize()

            const origin = new Vector3(0, 0, 0)
            const hex = 0xffff00

            const arrowHelper = new ArrowHelper(dir, origin, length, hex)
            this.scene.add(arrowHelper)
        },
        resizeRendererCamera() {
            const container = this.$refs.canvasContainer

            if (!this.camera || !this.renderer || !container) return

            this.aspect = container.offsetWidth / container.offsetHeight

            this.renderer.setSize(
                container.offsetWidth,
                container.offsetHeight
            )
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

            this.camera.left = (FRUSTUMSIZE * this.aspect) / -2
            this.camera.right = (FRUSTUMSIZE * this.aspect) / 2
            this.camera.top = FRUSTUMSIZE / 2
            this.camera.bottom = -FRUSTUMSIZE / 2

            this.camera.updateProjectionMatrix()
        },
        animate() {
            //Animation loop
            this.controls.update()
            requestAnimationFrame(this.animate)
            this.renderer.render(this.scene, this.camera)
        },
        async init_ifcjs() {
            //Creates the Three.js scene
            this.scene = new Scene()

            // Canvas obj and viewport sizing
            const threeCanvas = this.$refs.threeCanvas

            //Sets up the renderer
            this.renderer = new WebGLRenderer({
                canvas: threeCanvas,
                alpha: true,
                antialias: true,
            })

            // Camera
            this.camera = new OrthographicCamera(
                -FRUSTUMSIZE / 2,
                FRUSTUMSIZE / 2,
                FRUSTUMSIZE / 2,
                -FRUSTUMSIZE / 2
                // 0.1,
                // 1000
            )

            //Creates the orbit controls (to navigate the scene)
            this.controls = new OrbitControls(this.camera, threeCanvas)
            // controls.enableDamping = true
            // this.controls.target.set(0, 0, 0)

            this.animate()

            // Loading manager
            const loadingManager = new LoadingManager()
            loadingManager.onLoad = this.on_model_load


            // Sets up the IFC loading
            this.ifcLoader = new IFCLoader(loadingManager)

            // Web worker setup
            // await this.ifcLoader.ifcManager.useWebWorkers(true, "/static/js/IFCWorker.js")
            // this.ifcLoader.ifcManager.setOnProgress((event) => {
            //     const percent = (event.loaded / event.total) * 100;
            //     const result = Math.trunc(percent);
            //     console.log("LOADING IFC", result)
            // });
        },
        init_development_tools() {
            //Creates grids and axes in the scene
            const grid = new GridHelper(10, 10)
            this.scene.add(grid)

            const axes = new AxesHelper()
            axes.material.depthTest = false
            axes.renderOrder = 1
            this.scene.add(axes)


            // This is a lines example for a future own axis development
            const points = [];
            points.push(new Vector3(- 10, 0, 0));
            points.push(new Vector3(0, 10, 0));
            points.push(new Vector3(10, 0, 0));

            const geometry = new BufferGeometry().setFromPoints(points);

            const line = new Line(geometry);
            this.scene.add(line);
        },
        init_lights() {
            //Creates the lights of the scene
            const lightColor = 0xffffff

            const ambientLight = new AmbientLight(lightColor, 0.7)
            this.scene.add(ambientLight)

            const directionalLight = new DirectionalLight(lightColor, 0.4)
            directionalLight.position.set(0, 10, 0)
            directionalLight.target.position.set(-5, 0, 0)
            this.scene.add(directionalLight)
            this.scene.add(directionalLight.target)
        },
        init_raycaster() {
            this.raycaster = new Raycaster()
            this.raycaster.firstHitOnly = true
            this.mouse = new Vector2()
        },
        async pick(event) {
            // Computes the position of the mouse on the screen
            const threeCanvas = this.$refs.threeCanvas
            const bounds = threeCanvas.getBoundingClientRect()

            const x1 = event.clientX - bounds.left
            const x2 = bounds.right - bounds.left
            this.mouse.x = (x1 / x2) * 2 - 1

            const y1 = event.clientY - bounds.top
            const y2 = bounds.bottom - bounds.top
            this.mouse.y = -(y1 / y2) * 2 + 1

            // Places it on the camera pointing to the mouse
            this.raycaster.setFromCamera(this.mouse, this.camera)

            // Casts a ray
            let found = this.raycaster.intersectObjects(this.scene.children)[0]


            // Clean selection
            this.$store.commit("v2/viewer/setSelectedPartsUuids", [])

            if (found) {
                const index = found.faceIndex
                const geometry = found.object.geometry
                const model_id = found.object.modelID
                // found.object.geometry.visible = false
                const ifc = this.ifcLoader.ifcManager
                const eid = await ifc.getExpressId(geometry, index)

                const props = await ifc.getItemProperties(model_id, eid, true)
                let propsets
                let typeprops
                let matprops
                try { propsets = await ifc.getPropertySets(model_id, eid, true) } catch { console.log("⛔[produuz.it IFC Viewer] Can't load PropertySets") }
                try { typeprops = await ifc.getTypeProperties(model_id, eid, true) } catch { console.log("⛔[produuz.it IFC Viewer] Can't load TypeProperties") }
                try { matprops = await ifc.getMaterialProperties(model_id, eid) } catch { console.log("⛔[produuz.it IFC Viewer] Can't load MaterialProperties") }
                const ifcid = props.GlobalId?.value
                this.$store.commit("v2/viewer/setSelectedPartsUuids", [ifcid])
                this.$store.commit("v2/viewer/setSelectedPartProps", {
                    props,
                    propsets,
                    typeprops,
                    matprops,
                })
            }

        },
        async destroy() {
            bus.$off("events/viewer/recenter_3d")
            bus.$off("events/viewer/3d_set_camera")
            bus.$off("events/viewer/3d_clip_plane")
            await this.resize_observer.disconnect()
            await this.ifcLoader.ifcManager.dispose()
            this.ifcLoader = null


            // If IFC models are an array or object,
            // you must release them there as well
            // Otherwise, they won't be garbage collected
            this.ifc_models.length = 0
        },
        start_resize_observer() {
            const container = this.$refs.canvasContainer
            this.resize_observer = new ResizeObserver(
                _.debounce(this.resizeRendererCamera, 100)
            )
            this.resize_observer.observe(container)
        },
        async highlight_items(uuids, material) {
            let model = this.ifc_models[MODEL_KEY]

            // Sometimes there is no model loaded
            if (!model) return

            let mid = model.mesh.modelID
            let eids = uuids.map((uuid) => this.guid_maps[MODEL_KEY]?.[uuid])

            // Creates subset
            try {
                this.ifcLoader.ifcManager.createSubset({
                    modelID: mid,
                    ids: eids,
                    material: material,
                    removePrevious: true,
                    customID: 'highlighted-items',
                    scene: this.scene,
                })
            } catch {
                console.log("[!][produuz.it IFC Viewer] Can't create highlight")
            }
        },
        async parse_ifc_part_data(uuids) {
            let model = this.ifc_models[MODEL_KEY]

            // Sometimes there is no model loaded
            if (!model) return

            let mid = model.mesh.modelID
            const ifc = this.ifcLoader.ifcManager
            const eid = this.guid_maps[MODEL_KEY]?.[uuids[0]]
            const props = await ifc.getItemProperties(mid, eid, true)
            let propsets
            let typeprops
            let matprops
            try { propsets = await ifc.getPropertySets(mid, eid, true) } catch { console.log("⛔[produuz.it IFC Viewer] Can't load PropertySets") }
            try { typeprops = await ifc.getTypeProperties(mid, eid, true) } catch { console.log("⛔[produuz.it IFC Viewer] Can't load TypeProperties") }
            try { matprops = await ifc.getMaterialProperties(mid, eid) } catch { console.log("⛔[produuz.it IFC Viewer] Can't load MaterialProperties") }
            this.$store.commit("v2/viewer/setSelectedPartProps", {
                props,
                propsets,
                typeprops,
                matprops,
            })
        },
        async build_data_structures() {
            this.$set(this.guid_maps, MODEL_KEY, await buildGUIDMap(this.ifc_models[MODEL_KEY], this.ifcLoader.ifcManager))
        },
    },
    async mounted() {
        await this.init_ifcjs()
        // this.init_development_tools()
        this.init_lights()
        this.resizeRendererCamera()
        this.init_raycaster()
        this.load_urls()
        this.build_layer_subsets()
        this.start_resize_observer()
        bus.$on("events/viewer/recenter_3d", this.resetView)
        bus.$on("events/viewer/3d_set_camera", this.set_camera)
        bus.$on("events/viewer/3d_clip_plane", (c) => (this.clipping = c))
    },
    async beforeDestroy() {
        console.log("[i][produuz.it IFC Viewer] Cleaning memory")
        await this.destroy()
        console.log("[i][produuz.it IFC Viewer] Memory cleaned")
    },
}