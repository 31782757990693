<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon> mdi-sort-clock-ascending-outline </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("constructible_sorting") }}
            </v-card-title>
            <v-card-subtitle>
                {{ $store.getters.t("constructible_sorting_subtitle") }}
            </v-card-subtitle>
            <v-card-text v-if="async_loading">
                <v-skeleton-loader type="list-item@3"></v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <draggable :list="actuals_list" class="list-group" ghost-class="ghost">
                    <v-hover v-slot:default="{ hover }" v-for="panel in actuals_list" :key="panel.uuid">
                        <v-sheet outlined class="d-flex ma-1 pa-1 outlined drag-item" :class="{ hover: hover }">
                            <v-icon class="" color="black"> mdi-drag </v-icon>
                            <span class="title font-weight-light">
                                {{ panel.dname }}
                            </span>
                            <span class="font-weight-light ml-3 ma-1">
                                {{ panel.uuid.substring(0, 8) }}
                            </span>
                            <v-spacer></v-spacer>
                            <ChipConstructibleStatus class="ma-1" inline :constructible="panel" />
                        </v-sheet>
                    </v-hover>
                </draggable>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="order_by_design_name()" text>
                    <v-icon left v-if="ascending">
                        mdi-sort-clock-ascending-outline
                    </v-icon>
                    <v-icon left v-else>
                        mdi-sort-clock-descending-outline
                    </v-icon>
                    {{ $store.getters.t("order_by_design") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="save()" color="warning" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<style lang="scss">
.drag-item {
    cursor: pointer;
}

.drag-item.hover {
    border-color: lightgrey;
}
</style>


<script>
import { backend_api_v2 } from "/src/utils/apiv2";
import draggable from "vuedraggable";
import ChipConstructibleStatus from "/src/components/renderers/ChipConstructibleStatus.vue";
import { nameSort } from "/src/utils/basics.js";

export default {
    props: ["batch", "icon", "asyncmode"],
    components: { draggable, ChipConstructibleStatus },
    data: () => ({
        dialog: false,
        loading: false,
        async_loading: false,
        actuals_list: [],
        ascending: true,
    }),
    computed: {
        actuals() {
            return this.$store.getters["v2/constructibles/annotatedActualsFromLevel"]("all")
                .filter((actual) => actual.batch == this.batch.uuid)
                .map((actual) => {
                    let dname = this.$store.getters["v2/constructibles/designNameByUuid"](actual.design)
                    return { ...actual, dname };
                });
        },
    },
    watch: {
        actuals() {
            if (!this.asyncmode) this.refresh_list();
        },
        dialog() {
            if (this.asyncmode) {
                this.load_async();
            } else {
                this.refresh_list();
            }
        },
    },
    methods: {
        save() {
            // TO BE UPDATED WITH ASYNC FOR OVERVIEW
            // this.$store.commit("overview/setTouched");
            this.loading = true;
            let promises = [];
            this.actuals_list.forEach((actual, i) => {
                let priority = i + 1;
                let store_actual = this.actuals.find((p) => p.uuid == actual.uuid);
                if (store_actual && store_actual.priority != priority) {
                    let new_actual = { ...store_actual, priority };
                    let method
                    if (actual.level == "assembly") method = "v2/constructibles/putAssembly"
                    else method = "v2/constructibles/putModule"
                    let prom = this.$store.dispatch(method, new_actual);
                    promises.push(prom);
                }

                Promise.all(promises).finally(() => {
                    this.loading = false;
                    this.dialog = false;
                });
            });
        },
        order_by_design_name() {
            if (this.ascending) {
                this.actuals_list.sort((a, b) =>
                    nameSort(a.dname, b.dname)
                );
            } else {
                this.actuals_list.sort((a, b) =>
                    nameSort(b.dname, a.dname)
                );
            }
            this.ascending = !this.ascending;
        },
        refresh_list() {
            this.actuals_list = this.actuals.sort((a, b) =>
                a.priority > b.priority ? 1 : -1
            );
        },
        load_async() {
            console.log("ASYNC MODE TO BE DONE. NOT REFACTORED. PROBABLY NO ENDPOINT ALSO")
            this.async_loading = true;
            backend_api_v2
                .get(`/constructibles/assemblies/?batch=${this.batch.uuid}/sorting_list`)
                .then((r) => {
                    if (r.status == 200) this.actuals_list = r.data.results;
                    this.async_loading = false;
                })
                .catch(() => (this.async_loading = false));
        },
    },
    mounted() {
        if (this.asyncmode) {
            this.load_async();
        } else {
            this.refresh_list();
        }
    },
};
</script>