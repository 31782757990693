<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-if="icon != undefined" icon>
                <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-else color="accent" text class="mr-2">
                <v-icon left>mdi-delete-forever</v-icon>
                {{ $store.getters.t("delete") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("delete_constructible") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("delete_constructible_warning") }}:
                <ul>
                    <li v-for="c in constructibles" :key="c.uuid">
                        {{ c.uuid }} [{{ c.status }}]
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="delete_actuals()" color="error" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("delete") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>






<script>
export default {
    props: ["uuids", "icon"],
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {
        constructibles() {
            return this.$store.getters["v2/constructibles/allActuals"].filter(
                (c) => this.uuids.includes(c.uuid)
            );
        },
    },
    methods: {
        delete_actuals() {
            this.loading = true
            this.$store
                .dispatch("v2/constructibles/multiDeleteActuals", this.uuids)
                .finally(() => {
                    this.loading = false;
                    this.$emit("deleted")
                });
            this.dialog = false;
        },
    }
}
</script>