import _ from "lodash";

var tableMixin = {
    props: ["project", "designs", "selected_phase"],
    computed: {
        assds() {
            return this.designs.filter(d => d.level == "assembly")
        },
        modds() {
            return this.designs.filter(d => d.level == "module")
        },
        show_assembly_totals() {
            return this.assds.length != 0
        },
        show_module_totals() {
            return this.modds.length != 0
        },
        show_all_totals() {
            return this.assds.length != 0 && this.modds.length != 0
        },
        dark() {
            return this.$vuetify.theme.dark;
        },
        phases_list() {
            return this.project ? [...this.project.phases] : [];
        },
        batches() {
            return _.orderBy(this.$store.state.v2.constructibles.batches, "priority");
        },
    },

}

var rowMixin = {
    props: ["design"],
    computed: {
        hover() {
            return this.$store.state.v2.quantities.hover_row == this._uid
        },
        dark() {
            return this.$vuetify.theme.dark;
        },
        project() {
            return this.$store.state.v2.selected.selected_project
        },
        phases() {
            return this.project?.phases || [];
        },
        designs() {
            return this.$store.getters["v2/constructibles/annotatedDesignsFromLevel"](this.design.level)
        },
        not_latest() {
            if (!this.design) return false;
            let d_revs = this.designs
                .filter((d) => d?.name == this.design?.name)
                .map((d) => d.revision);
            return this.design.revision < Math.max(...d_revs);
        },
    },
    methods: {
        onHover(id) {
            this.$store.commit("v2/quantities/setHoverRow", id)
        }
    }
}


export {
    rowMixin,
    tableMixin,
}