<template>
    <v-card outlined>
        <v-card-title>
            <v-chip v-if="zone != undefined" class="ma-2" color="info" outlined small>
                {{ $store.getters.t("zone") }} {{ zone }} 
            </v-chip>
            <v-chip v-if="part_ref != undefined" class="ma-2" color="warning" outlined small>
                {{ $store.getters.t("Ref") }}: {{ part_ref }}
            </v-chip>
            <v-chip v-if="material != undefined" class="ma-2" color="red" outlined small>
                {{ $store.getters.t("material") }}: {{ material }}
            </v-chip>
            <span class="grey--text caption" v-if="!part">Part not in database.</span>
            <v-spacer></v-spacer>
            <v-btn small color="secondary" @click="show_metadata = !show_metadata">
                {{ $store.getters.t("part_data") }}
                <v-icon right>
                    {{ show_metadata ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
            </v-btn>
        </v-card-title>


        <!-- Part database info  -->
        <v-card-text v-if="show_metadata && selected_part">
            <v-list-item-title><b>IFC GID:</b> {{ selected_part.gid || "---" }}</v-list-item-title>
            <v-list-item-title><b>DB UUID:</b> {{ pd.uuid || "---" }}</v-list-item-title>
            <v-list-item-title> <b>{{ $store.getters.t("Created") }}:</b>
                <Timestamp :value="design_created" />
            </v-list-item-title>
            <v-list-item-title><b>Finish:</b> {{ p_meta.tipo || "---" }}</v-list-item-title>
            <v-list-item-title><b>Comment:</b> {{ p_meta.tipo || "---" }}</v-list-item-title>
            <v-list-item-title><b>Revision:</b> {{ pd.revision }}</v-list-item-title>
            <v-list-item-title><b>Tipo:</b> {{ pd.tipo || "---" }}</v-list-item-title>
            <v-list-item-title><b>branch:</b> {{ pd_meta.branch || "---" }}</v-list-item-title>
            <v-list-item-title><b>Width:</b> {{ pd_meta.width || "---" }}</v-list-item-title>
            <v-list-item-title><b>Heigth:</b> {{ pd_meta.height || "---" }}</v-list-item-title>
            <v-list-item-title><b>Length:</b> {{ pd_meta.length || "---" }}</v-list-item-title>
            <v-list-item-title><b>Thickness:</b> {{ pd_meta.thickness || "---" }}</v-list-item-title>
        </v-card-text>
    </v-card>
</template>

<script>
import Timestamp from "/src/components/renderers/Timestamp";

export default {
    components: { Timestamp },
    data: () => ({show_metadata: false}),
    computed: {
        selected_part() {
            return this.$store.state.v2.selected_logistics.parts.filter((p) => p.design.uuid == this.part)[0];
        },
        part() {
            return this.$store.state.v2.selected_logistics.selected_part || "";
        },
        zone() {
            return this.selected_part?.meta?.zone;
        },
        p_meta() { return this.selected_part?.meta },
        pd() { return this.selected_part?.design },
        pd_meta() { return this.pd?.meta },
        part_ref() {
            return this.selected_part?.design?.name
        },
        material() {
            return this.selected_part?.design?.meta?.material;
        },
        design_created() {
            return this.selected_part?.design?.created
        },
    },
    methods: {},
};
</script>