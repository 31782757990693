<template>
    <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="loading" v-bind="attrs" v-on="on" small icon>
                <v-icon> mdi-plus </v-icon>
            </v-btn>
        </template>
        <v-list dense v-if="non_assigned_workers.length == 0" width="200">
            <v-list-item disabled> No users to assign </v-list-item>
        </v-list>
        <v-list dense v-else width="200">
            <v-list-item
                :disabled="loading_users.includes(worker.id)"
                v-for="worker in non_assigned_workers"
                @click="set_worker(worker)"
                :key="worker.id"
            >
                <v-list-item-icon>
                    <v-icon small>mdi-account-hard-hat</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ worker.username }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    props: ["item"],
    data: () => ({
        loading: false,
        loading_users: [],
        non_assigned_workers: [],
    }),
    computed: {
        pl_uuid() {
            return this.item.uuid;
        },
    },
    watch: {
        pl_uuid() {
            this.refreshNAW();
        },
    },
    methods: {
        set_worker(user) {
            const id = user.id;
            this.loading_users.push(id);
            backend_api_v2
                .post("/company/users/" + id + "/set_prod_line/", {
                    production_line: this.pl_uuid,
                })
                .then(() => {
                    this.$emit("added", user);
                    this.non_assigned_workers =
                        this.non_assigned_workers.filter(
                            (w) => w.id != user.id
                        );
                })
                .finally(() => {
                    this.loading_users = this.loading_users.filter(
                        (i) => i != id
                    );
                });
            console.log("removing user from pl:", id);
        },
        refreshNAW() {
            this.loading = true;
            backend_api_v2
                .get("/company/users/non_assigned_to_line/")
                .then(({ data }) => {
                    this.non_assigned_workers = data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(`Cant load panel: ${e}`);
                });
        },
    },
    mounted() {
        this.refreshNAW();
    },
};
</script>