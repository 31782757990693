<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon :color="icon_color" small v-bind="attrs" v-on="on">
                {{ icon_name }}
            </v-icon>
        </template>
        {{ $store.getters.t(value) }}
    </v-tooltip>


</template>

<script>
import {
    MODULE_TIPOS,
    ASSEMBLY_TIPOS,
    PART_TIPOS
} from "/src/components/fields/tipos.js"
export default {
    props: ["value"],
    computed: {
        icon_name() {
            if (MODULE_TIPOS.includes(this.value)) return "mdi-cube";
            if (ASSEMBLY_TIPOS.includes(this.value)) return "mdi-land-rows-vertical";
            if (PART_TIPOS.includes(this.value)) return "mdi-lightbulb-fluorescent-tube-outline";
            return "mdi-help";
        },
        icon_color() {
            return ""
        },
    },
};
</script>