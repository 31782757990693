export function getUrlQueryFromPayload(payload) {

    const filteredKeys = Object.keys(payload).filter(key => {
        if (Array.isArray(payload[key])) {
            return payload[key].length > 0;
        } else {
            return payload[key] !== '';
        }
    });

    const url_query = filteredKeys
        .map(key => {
            if (Array.isArray(payload[key])) {
                let chained_value = payload[key].join(",");
                return `${key}=${chained_value}`;
            } else {
                return `${key}=${payload[key]}`;
            }
        })
        .join("&");

    return url_query
}