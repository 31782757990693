<template>
    <v-card outlined>
        <v-card-title>
            {{ $store.getters.t("uploads") }}
            <v-spacer></v-spacer>
            <ButtonUploadFilePackaged :disabled="lock_upload" :project="project" />
            <ButtonOperations :project="project" />
        </v-card-title>
        <v-card-subtitle>
            {{ upload_total_count }} {{ $store.getters.t("files") }}
            <span v-if="there_are_ifcs">
                <br /> IFC PS: {{ ifcps_name }}
                <router-link :to="{ name: 'project_settings' }">change</router-link>
            </span>
            <span v-if="there_are_btls">
                <br /> IFC PS: {{ btlps_name }}
                <router-link :to="{ name: 'project_settings' }">change</router-link>
            </span>
        </v-card-subtitle>

        <v-expand-transition>
            <v-card-text>
                <TableProjectResourcePackages />
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>

<script>
import TableProjectResourcePackages from "./TableProjectResourcePackages";
import ButtonOperations from "./operations/ButtonOperations";
import ButtonUploadFilePackaged from "./ButtonUploadFilePackaged";
export default {
    components: {
        TableProjectResourcePackages,
        ButtonOperations,
        ButtonUploadFilePackaged,
    },
    props: ["project"],
    computed: {
        there_are_ifcs() {
            return this.pck?.files?.find(cf => cf.meta?.ext == "ifc")
        },
        ifcps_name() {
            return this.$store.getters["v2/selected/projectIFCPS"]?.name || "---"
        },
        there_are_btls() {
            return this.pck?.files?.find(cf => cf.meta?.ext == "btl")
        },
        btlps_name() {
            return this.$store.getters["v2/selected/projectBTLPS"]?.name || "---"
        },
        pck() {
            return this.$store.getters["v2/cloudstorage/resourcesPackage"];
        },
        lock_upload() {
            return !this.pck;
        },
        upload_total_count() {
            return this.pck?.files?.length || 0;
        },
    },
};
</script>