// import bus from "/src/utils/event_bus";
// import router from '/src/router'
import _ from "lodash"

let BATCH_FIRST_RUN = true
let MODD_FR = true
let ASSD_FR = true

export default function (store) {

    const assemblyCountersFromSaveOrClean = _.debounce(() => {
        store.dispatch("v2/quantities/assemblyCountersFromSaveOrClean")
    }, 3000)

    const moduleCountersFromSaveOrClean = _.debounce(() => {
        store.dispatch("v2/quantities/moduleCountersFromSaveOrClean")
    }, 3000)

    const allCountersFromSaveOrClean = _.debounce(() => {
        store.dispatch("v2/quantities/assemblyCountersFromSaveOrClean")
        store.dispatch("v2/quantities/moduleCountersFromSaveOrClean")
    }, 3000)



    // Recalculate counts each change
    store.watch(
        (state) => state.v2.selected.selected_project,
        allCountersFromSaveOrClean
    );
    store.watch(
        (state) => state.v2.constructibles.batches,
        (newBatches, oldBatches) => {
            // Only refresh if they removed batches.
            const nuuids = newBatches.map(b => b.uuid)
            const ouuids = oldBatches.map(b => b.uuid)
            const removedBatches = ouuids.filter(ouuid => !nuuids.includes(ouuid));
            if (removedBatches.length > 0 || BATCH_FIRST_RUN) {
                allCountersFromSaveOrClean()
                BATCH_FIRST_RUN = false
            }
        }
    );
    store.watch(
        (state) => state.v2.constructibles.module_designs,
        (new_modds, old_modds) => {
            // Only refresh if they removed designs.
            const nuuids = new_modds.map(mod => mod.uuid)
            const ouuids = old_modds.map(mod => mod.uuid)
            const removed_modds = ouuids.filter(ouuid => !nuuids.includes(ouuid))
            if (removed_modds.length > 0 || MODD_FR) {
                moduleCountersFromSaveOrClean()
                MODD_FR = false
            }
        }
    );
    store.watch(
        (state) => state.v2.constructibles.assembly_designs,
        (new_assds, old_assds) => {
            // Only refresh if they removed designs.
            const nuuids = new_assds.map(ass => ass.uuid)
            const ouuids = old_assds.map(ass => ass.uuid)
            const removed_assds = ouuids.filter(ouuid => !nuuids.includes(ouuid))
            if (removed_assds.length > 0 || ASSD_FR) {
                assemblyCountersFromSaveOrClean()
                ASSD_FR = false
            }
        }
    );
    store.watch(
        (state) => state.v2.constructibles.modules,
        moduleCountersFromSaveOrClean
    );
    store.watch(
        (state) => state.v2.constructibles.assemblies,
        assemblyCountersFromSaveOrClean
    );
}