import { backend_api_v2 } from '/src/utils/apiv2'
import bus from '/src/utils/event_bus'
import vue from '/src/main'


function determineUserLanguage(auth) {
    if (auth.multilang && auth.language) {
        return auth.language
    } else
        return auth.company.meta.language
}

export default {
    namespaced: true,
    state: {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        authenticated: !!localStorage.getItem('access_token'),
        user_object: {
            _flag: "not-initialized",
            username: "",
            auth: {}
        }
    },
    mutations: {
        setTokens(state, data) {
            state.access_token = data.access
            state.refresh_token = data.refresh
            state.authenticated = true
            localStorage.setItem('access_token', state.access_token)
            localStorage.setItem('refresh_token', state.refresh_token)
        },
        refreshToken(state, at) {
            state.access_token = at
            localStorage.setItem('access_token', state.access_token)
        },
        cleanSession(state) {
            state.access_token = ""
            state.refresh_token = ""
            state.authenticated = false
            localStorage.setItem('access_token', "")
            localStorage.setItem('refresh_token', "")
        },
        setUserObject(state, data) {
            state.user_object = data
        }
    },
    actions: {
        login(context, data) {
            return backend_api_v2.post('/my_user/token/', data)
                .then(({ data }) => {
                    context.commit('setTokens', data)
                    context.dispatch('refreshBaseUserData', true, { root: true })
                })
        },
        refresh(context) {
            console.log("[i] Refreshing access token")
            if (context.state.refresh_token == "") bus.$emit("events/session/logout")
            return backend_api_v2.post('/my_user/token/refresh/', { refresh: context.state.refresh_token })
                .then(({ data }) => {
                    context.commit('refreshToken', data.access)
                    context.dispatch('refreshBaseUserData', true, { root: true })
                })
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Failed to refresh session. You have to login.",
                        color: "error"
                    });
                })
        },
        getUserObject(context) {
            return backend_api_v2.get('/my_user/')
                .then(({ data }) => {
                    context.commit('setUserObject', data)
                    const lang = determineUserLanguage(data.auth)
                    vue.$vuetify.lang.current = lang
                    context.dispatch("v2/translations/loadLang", lang, { root: true })
                })
                .catch(e => console.log(`Cant get User Object: ${e}`))
        },
        postUserSelectedStation(context, uuid) {
            return backend_api_v2.post('/my_user/select_station/', { selected_station_uuid: uuid })
                .then(({ data }) => {
                    context.commit('setUserObject', data)
                })
                .catch(e => console.log(`Cant post user station: ${e}`))
        }
    },
    getters: {
        viewer_show_qr: state => state.user_object?.auth?.company?.viewer_show_qr || true,
        viewer_show_rfid: state => state.user_object?.auth?.company?.viewer_show_rfid || true,
        viewer_hide_hidden_materials: state => state.user_object?.auth?.company?.viewer_hide_hidden_materials || true,
        work_by_date: state => state.user_object?.auth?.company?.work_by_date || false,
        force_checklist_start: state => state.user_object?.auth?.company?.force_checklist_start || false,
        kitted_parts_behavior: state => state.user_object?.auth?.company?.kitted_parts_behavior || "always_kitted",
        my_station: state => state.user_object.auth?.production_station_name,
        my_line_object: state => state.user_object.auth?.production_line_obj,
        my_selected_station: state => state.user_object.auth?.selected_production_station_name,
        my_selected_line_object: state => state.user_object.auth?.selected_production_line_obj,
        my_line_uuid: state => state.user_object.auth?.production_line,
        iam_prod_worker: state => state.user_object.auth?.production_user,
        iam_admin: state => state.user_object.auth?.company_admin,
        my_company: state => state.user_object.auth?.company,
        androidLicensed: state => state.user_object.auth?.licenses?.includes('android'),
    }
}