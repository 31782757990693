<template>
    <div>
        <ListSelector v-if="show_list_selector" />
        <v-divider v-if="show_list_selector" />
        <ListConstructibles level="module" v-if="show_modules" />
        <ListConstructibles level="assembly" v-if="show_assemblies" />
        <ListFullProject v-if="show_proj_view" />
        <ListModuleDesigns v-if="show_modds" />
        <ListAssemblyDesigns v-if="show_assds" />
        <ListSkeleton v-if="loading" />
        <v-subheader v-if="no_constructibles_flag"> {{ $store.getters.t('no_const_to_show') }} </v-subheader>
        <v-subheader v-if="no_designs_flag"> {{ $store.getters.t('no_design_to_show') }} </v-subheader>
    </div>
</template>


<script>
import ListSelector from './ListSelector.vue'
import ListSkeleton from './ListSkeleton.vue'
import ListConstructibles from './ListConstructibles.vue'
import ListFullProject from './ListFullProject.vue'
import ListModuleDesigns from './ListModuleDesigns.vue'
import ListAssemblyDesigns from './ListAssemblyDesigns.vue'
export default {
    components: {
        ListSelector,
        ListSkeleton,
        ListConstructibles,
        ListFullProject,
        ListModuleDesigns,
        ListAssemblyDesigns,
    },
    computed: {
        prod_worker() {
            return this.$store.getters["v2/session/iam_prod_worker"]
        },
        designs_mode() {
            return this.$store.state.v2.viewer.designs_mode && !this.prod_worker
        },
        show_list_selector() {
            return !this.prod_worker
        },
        loading() {
            return this.$store.state.active_loaders.includes("project_resources")
        },
        show_modules() {
            let have = this.$store.getters["v2/constructibles/haveModules"]
            return have && !this.designs_mode && !this.loading
        },
        show_assemblies() {
            let have = this.$store.getters["v2/constructibles/haveAssemblies"]
            return have && !this.designs_mode && !this.loading
        },
        show_proj_view() {
            let have = this.$store.getters["v2/cloudstorage/hasFullProjectResources"]
            return have && this.designs_mode && !this.loading
        },
        show_modds() {
            let have = this.$store.getters["v2/constructibles/haveModuleDesigns"]
            return have && this.designs_mode && !this.loading
        },
        show_assds() {
            let have = this.$store.getters["v2/constructibles/haveAssemblyDesigns"]
            return have && this.designs_mode && !this.loading
        },
        no_constructibles_flag() {
            return !this.loading && !this.designs_mode && !this.show_modules && !this.show_assemblies
        },
        no_designs_flag() {
            return !this.loading && this.designs_mode && !this.show_assds && !this.show_modds
        }
    }
}
</script>