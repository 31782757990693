<template>
    <v-dialog overlay-color="black" overlay-opacity="0.85" v-model="dialog" max-width="550"
        @click:outside="dialog = false">
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("hidden_batches") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("hidden_batches_explanation") }}
                <br /><br />
                <ul>
                    <li v-for="phase in missing_phases" :key="phase">
                        {{ phase }}
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
export default {
    props: ["phases", "batches"],
    data: () => ({ dialog: false }),
    computed: {
        missing_phases() {
            let mp = this.batches
                .map((b) => b.phase)
                .filter((p) => !this.phases.includes(p));
            return [...new Set(mp)];
        },
    },
    watch: {
        missing_phases() {
            this.show_dialog_if_needed();
        },
    },
    methods: {
        close() {
            this.dialog = false;
        },
        show_dialog_if_needed: _.debounce(function () {
            if (this.missing_phases.length != 0) {
                this.dialog = true;
            } else {
                this.dialog = false;
            }
        }, 2000),
    },
    mounted() {
        this.show_dialog_if_needed()
    }
};
</script>