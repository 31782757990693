<template>
    <v-card outlined>
        <v-card-title>
            <v-chip v-if="zone != undefined" class="ma-2" color="info" outlined small>
                {{ $store.getters.t("zone") }} {{ zone }} </v-chip>
            <v-chip v-if="part_ref != undefined" class="ma-2" color="warning" outlined small>
                {{ $store.getters.t("Ref") }}: {{ part_ref }}
            </v-chip>
            <v-chip v-if="material != undefined" class="ma-2" color="red" outlined small>
                {{ $store.getters.t("material") }}: {{ material }}
            </v-chip>
            <span class="grey--text caption" v-if="!part">Part not in database.</span>
            <v-spacer></v-spacer>
            <v-btn small color="secondary" @click="show_metadata = !show_metadata">
                {{ $store.getters.t("part_data") }}
                <v-icon right>
                    {{ show_metadata ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
            </v-btn>
        </v-card-title>


        <!-- Part database info  -->
        <v-card-text v-if="show_metadata && part">
            <v-list-item-title><b>IFC GID:</b> {{ part.gid || "---" }}</v-list-item-title>
            <v-list-item-title><b>DB UUID:</b> {{ pd.uuid || "---" }}</v-list-item-title>
            <v-list-item-title> <b>{{ $store.getters.t("Created") }}:</b>
                <Timestamp :value="design_created" />
            </v-list-item-title>
            <v-list-item-title><b>Finish:</b> {{ p_meta.tipo || "---" }}</v-list-item-title>
            <v-list-item-title><b>Comment:</b> {{ p_meta.tipo || "---" }}</v-list-item-title>
            <v-list-item-title><b>Revision:</b> {{ pd.revision }}</v-list-item-title>
            <v-list-item-title><b>Tipo:</b> {{ pd.tipo || "---" }}</v-list-item-title>
            <v-list-item-title><b>branch:</b> {{ pd_meta.branch || "---" }}</v-list-item-title>
            <v-list-item-title><b>Width:</b> {{ pd_meta.width || "---" }}</v-list-item-title>
            <v-list-item-title><b>Heigth:</b> {{ pd_meta.height || "---" }}</v-list-item-title>
            <v-list-item-title><b>Length:</b> {{ pd_meta.length || "---" }}</v-list-item-title>
            <v-list-item-title><b>Thickness:</b> {{ pd_meta.thickness || "---" }}</v-list-item-title>
        </v-card-text>

        <v-expansion-panels color="info" outlined dense v-if="show_metadata">

            <!-- IFC Native Props expansion panel -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>IFC Native Props</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list-item-title><b>{{ $store.getters.t("Name") }}:</b> {{ props_name || "---" }}
                    </v-list-item-title>
                    <v-list-item-title><b>{{ $store.getters.t("Desciption") }}:</b> {{ props_description || "---" }}
                    </v-list-item-title>
                    <v-list-item-title><b>{{ $store.getters.t("Object Type") }}:</b> {{ props_objectType || "---" }}
                    </v-list-item-title>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- IFC Property Set panel -->
            <v-expansion-panel v-for="(item, index) in part_ifc_props.propsets" :key="index">
                <v-expansion-panel-header><b>IFC Property Set: {{ item.Name.value }}</b></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list-item-title v-for="(property, i) in item.HasProperties" :key="i">
                        <b>{{ property.Name.value }}: </b>
                        <span v-if="property.NominalValue">{{ property.NominalValue.value }}</span>
                        <span v-else>---</span>
                    </v-list-item-title>
                    <v-list-item-title v-for="(quant, ind) in item.Quantities" :key="ind">
                        <b>{{ quant.Name.value }}: </b>
                        <span v-if="quant.LengthValue">{{ quant.LengthValue.value }}</span>
                        <span v-else>---</span>
                    </v-list-item-title>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- IFC Type Props expansion panel -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>IFC Type Props</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list-item-title><b>{{ $store.getters.t("Name") }}:</b> {{ tpName
                    }}</v-list-item-title>
                    <v-list-item-title><b>{{ $store.getters.t("Description") }}:</b> {{ tpDescription || "---"
                    }}</v-list-item-title>
                    <v-list-item-title><b>Predefined Type:</b> {{ tpPredefinedType }}</v-list-item-title>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
import Timestamp from "/src/components/renderers/Timestamp";
export default {
    components: { Timestamp },
    data: () => ({ show_metadata: false }),
    computed: {
        part_ifc_props() {
            return this.$store.state.v2.viewer.selected_part_props
        },
        part() {
            let parts = this.$store.state.v2.selected.selected_parts
            if (parts.length == 0) {
                return null
            } else {
                return parts[0]
            }
        },
        p_meta() { return this.part?.meta },
        pd() { return this.part?.design },
        pd_meta() { return this.pd?.meta },
        zone() {
            return this.part?.meta?.zone
        },
        part_ref() {
            return this.part?.design?.name
        },
        // selection_uuids() {
        //     return this.$store.state.viewer.selected_parts_ifc_ids;
        // },
        material() {
            return this.part?.design?.meta?.material;
        },
        design_created() {
            return this.part?.design?.created
        },
        props_name() {
            return this.part_ifc_props?.props?.Name?.value
        },
        props_description() {
            return this.part_ifc_props?.props?.Description?.value
        },
        props_objectType() {
            return this.part_ifc_props?.props?.ObjectType?.value
        },
        tpName() {
            return this.part_ifc_props?.typeprops?.[0]?.Name?.value
        },
        tpDescription() {
            return this.part_ifc_props?.typeprops?.[0]?.Description?.value
        },
        tpPredefinedType() {
            return this.part_ifc_props?.typeprops?.[0]?.PredefinedType?.value
        },
    },
    methods: {
        meta_value_parse(v) {
            try {
                return isNaN(v) || Number.isInteger(v) ? v : v.toFixed(2);
            } catch {
                return "";
            }
        },
    },
};
</script>