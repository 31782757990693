<template>
    <v-list dense>
        <v-subheader>
            {{ $store.getters.t("full_project") }}
        </v-subheader>
        <v-list-item :to="('/viewer/project/' + project_uuid)">
            <v-list-item-avatar>
                <v-icon>mdi-home-modern</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $store.getters.t("full_project") }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    computed: {
        project_uuid() { return this.$store.state.v2.selected.pr_selection },
    }
}
</script>