
export default function (store) {
    // RELOAD HOT ISSUES
    store.watch(
        (state) => state.v2.selected.selected_ass?.uuid,
        (assembly) => {
            // Hot Issues
            store.commit("v2/selected/setHotIssues", [])
            if (!assembly) return
            store.dispatch("v2/selected/loadHotIssues", { assembly })
        }
    );
    store.watch(
        (state) => state.v2.selected.selected_mod?.uuid,
        (module) => {
            // Issues
            store.commit("v2/selected/setHotIssues", [])
            if (!module) return
            store.dispatch("v2/selected/loadHotIssues", { module })
        }
    );

    // LOAD SELECTED PARTS
    store.watch(
        (state) => state.v2.viewer.selected_parts_ifc_ids,
        (gids) => {
            store.commit("v2/selected/setSelectedParts", [])
            if (gids.length != 0) store.dispatch("v2/selected/loadSelectedParts", gids)
        }
    );
}
