<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("machine_setups") }}
            </v-btn>
            <v-spacer></v-spacer>
            <ButtonJSONUpload @uploaded="uploaded" v-if="expanded" />
            <v-btn v-if="expanded" color="accent" text @click="new_machine">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-center">UUID</th>
                        <th class="text-center">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="machine in machines" :key="machine.uuid">
                        <td>{{ machine.uuid.substring(0, 8) }}</td>
                        <td>{{ machine.name }}</td>
                        <!-- <td> {{}} </td> -->
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(machine.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <ButtonJSONDownload :json="machine" prefix="machine" />
                            <v-btn icon @click="launch_delete(machine.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_machine") }}
                        </v-card-title>
                        <v-card-text>
                            {{
                                $store.getters.t("this_machine_will_be_deleted")
                            }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn
                                @click="delete_machine"
                                color="error"
                                :disabled="delete_loading"
                                :loading="delete_loading"
                            >
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn
                                @click="delete_dialog = false"
                                color="secondary"
                            >
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="800">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("machine") }}
                        </v-card-title>
                        <v-card-text class="py-3">
                            <FormMachineSetup
                                ref="machine_form"
                                v-model="form"
                                @save="post_machine"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="post_machine"
                                color="primary"
                                :loading="saving"
                                :disabled="saving"
                            >
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { by_name } from "/src/utils/basics";
import FormMachineSetup from "/src/components/company/FormMachineSetup";
import ButtonJSONUpload from "/src/components/renderers/ButtonJSONUpload";
import ButtonJSONDownload from "/src/components/renderers/ButtonJSONDownload";
import _ from "lodash";
export default {
    components: { FormMachineSetup, ButtonJSONUpload, ButtonJSONDownload },
    props: [],
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
    }),
    computed: {
        machines() {
            return [...this.$store.state.v2.factories.machines].sort(by_name);
        },
    },
    methods: {
        launch_edit(uuid) {
            let machine = this.machines.find((machine) => machine.uuid == uuid);
            this.form = _.cloneDeep(machine);
            this.dialog = true;
        },
        uploaded(machine) {
            this.form = _.cloneDeep(machine);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_machine() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/factories/deleteMachine", this.delete_uuid)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },

        new_machine() {
            this.form = {
                model: "mobi_one_v2",
                name: "Default Machine",
                setup: {},
                parsed_setup: {},
                default_jobs: {},
            };
            this.dialog = true;
        },
        post_machine() {
            if (!this.$refs.machine_form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("Updating Machine");
                this.$store
                    .dispatch("v2/factories/putMachine", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("Creating Machine");
                this.$store
                    .dispatch("v2/factories/postMachine", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
    },
    mounted() {
        this.$store.dispatch("v2/factories/loadMachines");
    },
};
</script>