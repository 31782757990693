import { geometryTypes } from "./ifc-geom-types";
import store from '/src/store'
import { getAllIds } from "./utils_ifcjs"

function saveProperties(api, modelID, lines, allItems, excludeGeometry, index) {
    const itemID = lines.get(index);
    const props = api.GetLine(modelID, itemID);
    props.type = props.__proto__.constructor.name;
    if (!excludeGeometry || !geometryTypes.has(props.type)) {
        allItems[itemID] = props;
    }
}

function getAllItemsFromLines(api, modelID, lines, allItems, excludeGeometry) {
    for (let i = 1; i <= lines.size(); i++) {
        try {
            saveProperties(api, modelID, lines, allItems, excludeGeometry, i);
        } catch (e) {
            void (0)
        }
    }
}

export async function GetAllItems(api, modelID, excludeGeometry = true) {
    const allItems = {};
    console.log("GetAllItems MODEL ID", modelID)
    const lines = await api.GetAllLines(modelID);
    getAllItemsFromLines(api, modelID, lines, allItems, excludeGeometry);
    return allItems;
}

// export GetAllGeomItems(){
//    ESTO SON LAS GEOMETRIAS O K ?->>>  model.geometry.attributes.expressID.array
// }



export function flatten(arr) {
    return arr ? arr.reduce((result, item) => [
        ...result,
        { expressID: item.expressID },
        ...flatten(item.children)
    ], []) : [];
}

export async function buildGUIDMap(model, manager) {

    let guid_map = {}

    let items = await GetAllItems(manager.state.api, model.mesh.modelID)

    Object.entries(items).forEach(it => {
        let eid = it[0]
        let guid = it[1].GlobalId?.value
        if (guid && eid) guid_map[guid] = Number(eid)
    })

    return guid_map
}




function parse_from_ifcjs(props, propsets, typeprops, ifcps_props) {
    // Gets ifcps entries of a prop, also ifcjs objects.
    // Returns VALUE.

    // PARSE METADATA TO A FLAT OBJECT
    let gid = props?.GlobalId?.value
    if (!gid) return
    let parsed_meta = {
        gid,
        description: props.Description?.value,
        name: props.Name?.value,
        object_type: props.ObjectType?.value,
        objecttype: props.ObjectType?.value,
    }


    let raw_part_props = {}

    // psets
    let psets = [
        // Property sets
        ...propsets,
        // Exctract psets from typeprop objects
        ...typeprops
            .filter(tp => tp.HasPropertySets)
            .flatMap(tp => tp.HasPropertySets.map(ps => ps))
    ]
    psets.forEach(pset => {
        if (!pset.HasProperties) return  // continue
        pset.HasProperties.forEach(psetprop => {
            let name = psetprop.Name?.value?.toLowerCase()
            let value = psetprop.NominalValue?.value
            // discard empties
            if (name == undefined || value == undefined) return
            // Add to meta with lowercase key
            if (!(name in raw_part_props)) raw_part_props[name] = value
        })
    })


    Object.keys(ifcps_props).forEach((prop_name) => {
        let prop_entries = ifcps_props[prop_name]
        prop_entries.forEach(lookup_key => {
            Object.entries(raw_part_props).forEach(item => {
                let meta_key = item[0]
                let meta_value = item[1]
                if (lookup_key.toLowerCase() == meta_key) {
                    parsed_meta[prop_name] = meta_value
                }

            })
        })
    })

    if (!("zone" in parsed_meta)) parsed_meta["zone"] = 0
    // HARDCODED FOR MODULES
    parsed_meta["assd"] = "main"

    return parsed_meta // not found
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


// FOLLOWING ASYNC FUNCTION IS NOT USED ANYMORE SINCE THIS COMES FROM THE 
// BACKEND AGAIN AS A META PROP. IT WAS SO FUCKING SLOW THAT WAS 
// FREEZING THE INTERFACE AND THERE IS NO GOOD WAY TO SOLVE IT.


// This is to prevent duplicate calls keep running. Just kill if not last.
let LAST_EXECUTED_FLAG

export async function buildViewerBom(model, manager) {
    const my_random = Math.random()
    LAST_EXECUTED_FLAG = my_random
    const ids = getAllIds(model)
    // let items = GetAllItems(manager.state.api, model.mesh.modelID)
    const ids_len = Object.keys(ids).length
    let i = 0
    let bom = []
    let ifcps_props = store.getters["v2/selected/projectIFCPS"]?.props || {}
    for (const eid of ids) {
        i += 1
        if (LAST_EXECUTED_FLAG != my_random) {
            console.log("Killing a buildViewerBom call here")
            return []
        }
        else if (i % 20 == 0) {
            await timeout(10)
            console.log(`Parsed ${i} of ${ids_len}`)
            store.commit("v2/viewer/setBomLoadingStatus", { count: i, total: ids_len })
        }
        const props = await manager.getItemProperties(model.mesh.modelID, eid, true)
        const propsets = await manager.getPropertySets(model.mesh.modelID, eid, true)
        const typeprops = await manager.getTypeProperties(model.mesh.modelID, eid, true)
        // const typeprops = []
        let parsed = parse_from_ifcjs(props, propsets, typeprops, ifcps_props)
        if (parsed) bom.push(parsed)
    }
    console.log("LENGTH OF THE ACTUAL BOM PARSED", bom.length)
    store.commit("v2/viewer/setBomLoadingStatus", false)
    return bom
}