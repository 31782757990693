<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-chip small :color="color" outlined v-bind="attrs" v-on="on">
                <v-icon left small>
                    mdi-file-tree
                </v-icon> {{ count }}
            </v-chip>
        </template>
        <span>
            {{ tooltip_text }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        color() {
            return this.count == 0 ? 'orange' : 'blue'
        },
        count() {
            return this.value.length
        },
        tooltip_text() {
            if (this.count != 0)
            return `Subassembly of ${this.count} parent designs.`
            else return "Subassembly without parent designs."
        }
    },
};
</script>