import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VueQrcode from '@chenfengyuan/vue-qrcode';

import INITIAL_STATE from '/src/utils/defaults';
import ButtonQRShow from "/src/components/renderers/ButtonQRShow.vue";
import PictureView from "/src/components/renderers/PictureView.vue";
import DatePicker from "/src/components/DatePicker.vue";
import AreaIndicator from "/src/components/renderers/AreaIndicator.vue";


import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";



Vue.component(VueQrcode.name, VueQrcode);

Vue.component("ButtonQRShow", ButtonQRShow)
Vue.component("PictureView", PictureView)
Vue.component("DatePicker", DatePicker)
Vue.component("AreaIndicator", AreaIndicator)



Vue.config.productionTip = false

console.log("[i] Launching app on environment: ", INITIAL_STATE.PROD_ENV)

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        Vue,
        dsn: "https://0fc906c906a9440599544a6c7e68d70f@o357522.ingest.sentry.io/5169833",
        integrations: [new Integrations.BrowserTracing()],
        release: "woodwall-front@" + process.env.npm_package_version,
        tracesSampleRate: 0.2,
        environment: INITIAL_STATE.PROD_ENV,
    });
}


export default new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
