<template>
    <v-select outlined dense hide-hints v-model="selected_machine" :items="machines" :label="$store.getters.t('machine')"
        @change="$emit('change')"></v-select>
</template>

<script>
export default {
    props: {
        value: {
            default: "",
            type: String,
        },
        report_type: {
            default: "",
            type: String,
        },
    },
    data: () => ({
        selected_machine: "",
    }),
    computed: {
        filtered_machines_from_store() {
            let machines = this.$store.state.v2.factories.machines;
            if (this.report_type == "") return machines;
            else
                return machines.filter((m) =>
                    m.report_types.includes(this.report_type)
                );
        },
        machines() {
            return this.filtered_machines_from_store.map((m) => ({
                text: m.name,
                value: m.uuid,
            }));
        },
    },
    watch: {
        selected_machine(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_machine = v;
            },
            immediate: true,
        },
    },
    mounted() {
        this.$store.dispatch("v2/factories/loadMachines");
    },
};
</script>