<template>
    <v-dialog v-model="dialog" max-width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :loading="loading"
                :disabled="loading"
                v-bind="attrs"
                v-on="on"
                icon
            >
                <v-icon> mdi-archive-arrow-down </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("archive") }}
            </v-card-title>
            <v-card-text>
                You are going to archive this project. It will not be visible in
                production nor in management.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="archive()" color="warning">
                    {{ $store.getters.t("archive") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import bus from "/src/utils/event_bus";
export default {
    props: ["project"],
    data: () => ({
        dialog: false,
        loading: false,
    }),
    computed: {},
    methods: {
        archive() {
            this.dialog = false;
            this.loading = true;
            let project = { ...this.project, archived: true };
            this.$store
                .dispatch("v2/company/putProject", project)
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Project archived",
                        color: "success",
                    });
                    this.$store.dispatch("v2/company/loadProjects");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>