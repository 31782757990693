<template>
    <div class="d-flex">
        <p> {{ $store.getters.t(job_name) }} ({{ $store.getters.t("last_run") }}:
            <Timestamp v-if="job" :value="job.last_run" />,
            {{ $store.getters.t("status") }}: {{ job.status }})
        </p>
        <v-spacer></v-spacer>
        <v-btn small color="error" :loading="loading" :disabled="loading || disabled" @click="click">
            {{ $store.getters.t("launch") }}
        </v-btn>
    </div>
</template>

<script>
import Timestamp from "/src/components/renderers/Timestamp";


const ENABLE_STATUSES = ["never", "completed", "failed"]
export default {
    props: ["job", "job_name", "loading", "disabled"],
    data: () => ({ ENABLE_STATUSES }),
    components: {
        Timestamp,
    },
    methods: {
        click() {
            this.$emit("launch")
        }
    }
}
</script>