<template>
    <div class="ma-3">
        <div class="mb-6">
            <ButtonBOM />
        </div>
        <div class="mb-3">
            <CardMaterialPropsWrapper :ass_uuid="ass_uuid" :wall_uuid="wall_uuid" />
        </div>
        <div class="mb-3">
            <PartDrawing :pd_uuid="pd_uuid" />
        </div>
    </div>
</template>

<script>
import ButtonBOM from "./ButtonBOM.vue";
import CardMaterialPropsWrapper from "./CardMaterialPropsWrapper.vue";
import PartDrawing from "./PartDrawing.vue";

export default {
    name: "IFCViewContextMenu",
    components: {
        ButtonBOM,
        CardMaterialPropsWrapper,
        PartDrawing,
    },
    data: () => ({}),
    computed: {
        pd_uuid() {
            let parts = this.$store.state.v2.selected.selected_parts
            if (parts.length == 0) {
                return null
            } else {
                return parts[0].design.uuid
            }
        },
        unit_mode() {
            return Object.prototype.hasOwnProperty.call(
                this.$route.params,
                "unit_uuid"
            );
        },
        ass_uuid() {
            return this.$route.params.unit_uuid;
        },
        wall_uuid() {
            return this.$route.params.wall_uuid;
        },
    },
};
</script>
