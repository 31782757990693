<template>
    <v-menu ref="menu" v-model="project_menu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-card outlined width="190" v-bind="attrs" v-on="on" :disabled="loading" loader-height="2"
                :loading="loading ? 'accent' : false">
                <div class="d-flex grey--text">
                    <v-icon v-if="stdlib_mode" outlined class="ml-2 mr-1 my-3">
                        mdi-database
                    </v-icon>
                    <v-icon outlined class="ml-2 mr-1 my-3" v-else>
                        mdi-home-modern
                    </v-icon>
                    <span class="my-3 d-inline-block text-truncate">
                        <strong v-if="stdlib_mode">stdlib:</strong>
                        {{ selected_project_name }}
                    </span>
                </div>
            </v-card>
        </template>
        <v-list dense class="overflow-y-auto" style="max-height: 80vh">
            <v-list-item @click="selectCompanyWideMenu" v-if="manager_or_admin">
                <v-list-item-icon>
                    <v-icon>mdi-factory</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Company Wide </v-list-item-title>
            </v-list-item>
            <v-divider v-if="manager_or_admin"></v-divider>
            <v-list-item v-if="menu_projects.length == 0">
                <v-list-item-icon>
                    <v-icon>mdi-home-modern</v-icon>
                </v-list-item-icon>
                <v-list-item-title> No projects </v-list-item-title>
            </v-list-item>
            <template v-for="(item, index) in menu_projects">
                <v-list-group v-if="item.is_super" :key="index" :value="false" prepend-icon="mdi-city-variant-outline">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="selectProject(pr.uuid)" v-for="pr in item.projects" :key="pr.uuid">
                        <v-list-item-icon>
                            <v-icon>mdi-home-modern</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ pr.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item @click="selectProject(item.uuid)" v-else :key="index + '_else'">
                    <v-list-item-icon>
                        <v-icon>mdi-home-modern</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item>
            </template>
            <v-list-group v-if="menu_stdlibs.length != 0" :value="false" prepend-icon="mdi-database">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $store.getters.t("standard_libraries") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item @click="selectSTDLIB(stdlib.uuid)" v-for="stdlib in menu_stdlibs" :key="stdlib.uuid">
                    <v-list-item-icon>
                        <v-icon>mdi-home-modern</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ stdlib.name }}
                    </v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-menu>
</template>

<script>
import {
    COMPANY_TO_PR_ROUTE,
    PR_TO_COMPANY_ROUTE,
    MISC_ROUTES,
    VIEWER_ROUTES,
} from "/src/router/redirections";

export default {
    data: () => ({ project_menu: false, selected_project: "all" }),
    computed: {
        projects() {
            return this.$store.state.v2.company.projects;
        },
        standard_libraries() {
            return this.$store.state.v2.company.standard_libraries;
        },
        projects_and_stdlibs() {
            return this.projects.concat(this.standard_libraries)
        },
        stdlib_mode() {
            return this.$store.state.v2.selected.stdlib_mode
        },
        loading() {
            return this.$store.getters.loading
        },
        manager_or_admin() {
            return !this.$store.getters["v2/session/iam_prod_worker"];
        },
        company_wide() {
            return this.selected_project == "all";
        },
        selected_project_name() {
            if (this.company_wide) return this.$store.getters.t("company_wide");
            return (
                this.projects_and_stdlibs.find((p) => p.uuid == this.selected_project)
                    ?.name || this.$store.getters.t("project")
            );
        },
        menu_projects() {
            let items = [];
            this.projects.forEach((pr) => {
                if (pr.super_project != "") {
                    let idx = items.findIndex(
                        (it) => it.name == pr.super_project
                    );
                    if (idx != -1) {
                        items[idx].projects.push({
                            uuid: pr.uuid,
                            name: pr.name,
                        });
                    } else {
                        items.push({
                            name: pr.super_project,
                            is_super: true,
                            projects: [
                                {
                                    uuid: pr.uuid,
                                    name: pr.name,
                                },
                            ],
                        });
                    }
                } else {
                    items.push({
                        uuid: pr.uuid,
                        name: pr.name,
                        is_super: false,
                    });
                }
            });
            // Sort list
            items.sort((a, b) =>
                a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
            );
            // Sort nested
            items.forEach((item) => {
                if (item.is_super)
                    item.projects.sort((a, b) =>
                        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                    );
            });
            return items;
        },
        menu_stdlibs() {

            let items = this.standard_libraries.map((stdlib) => ({
                uuid: stdlib.uuid,
                name: stdlib.name,
            }))
            // Sort list
            items.sort((a, b) =>
                a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
            );
            return items;
        },
        store_pr_selection() {
            return this.$store.state.v2.selected.pr_selection
        }
    },
    watch: {
        store_pr_selection: {
            immediate: true,
            handler: function (pr_uuid) {
                this.selected_project = pr_uuid
            }
        },
        selected_project(sp, sp_old) {
            // No change (all to all, or pr to same pr)
            console.log(sp, sp_old);
            if (sp == sp_old) return;

            // PUSH ROUTES

            // From project to company
            if (sp == "all") {
                this.$store.commit("v2/selected/setCompanyWide");
                const rn =
                    PR_TO_COMPANY_ROUTE?.[this.$route.name] ||
                    "company_settings";
                this.$router.push({ name: rn }).catch((e) => console.log(e));
                return;
            }
            // From company to project
            if (sp_old == "all") {
                const rn = COMPANY_TO_PR_ROUTE?.[this.$route.name] || "designs";
                const params = {
                    ...this.$route.params,
                    project_uuid: sp,
                };
                this.$router
                    .push({ name: rn, params })
                    .catch((e) => console.log(e));
                return;
            }

            // From project to project
            const params = {
                ...this.$route.params,
                project_uuid: sp,
            };

            if (VIEWER_ROUTES.includes(this.$route.name) || MISC_ROUTES.includes(this.$route.name))
                this.$router
                    .push({ name: "project_viewer", params })
                    .catch((e) => console.log(e));
            else
                this.$router
                    .push({ name: this.$route.name, params })
                    .catch((e) => console.log(e));
        },
    },
    methods: {
        selectProject(pr_uuid) {
            this.selected_project = pr_uuid;
            this.project_menu = false;
            this.$store.commit("v2/selected/setProjectMode");
        },
        selectSTDLIB(stdlib_uuid) {
            this.selected_project = stdlib_uuid;
            this.project_menu = false;
            this.$store.commit("v2/selected/setSTDLIBMode");
        },
        selectCompanyWideMenu() {
            this.selected_project = "all";
            this.project_menu = false;
            this.$store.commit("v2/selected/setProjectMode");
            this.$store.commit("setManagerMode");
        },
    },
};
</script>