<template>
    <v-list subheader>
        <v-subheader>CHATS</v-subheader>
        <v-list-item-group v-model="selected_chat" v-if="chats.length != 0">
            <template v-for="(chat, index) in chats">
                <v-list-item
                    :key="chat.contact"
                    :value="chat.contact"
                    @click="setSelected(chat.contact)"
                >
                    <v-list-item-avatar size="40">
                        <v-icon> mdi-message </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-text="chat.contact_name" />
                        <v-list-item-subtitle
                            :class="
                                !chat.last_message.readed &&
                                !chat.last_message.contact.is_receiver
                                    ? 'font-weight-black'
                                    : ''
                            "
                            v-text="chat.last_message.text"
                        />
                    </v-list-item-content>
                </v-list-item>
                <v-divider :key="`chatDivider${index}`" class="my-0" />
            </template>
        </v-list-item-group>
        <v-list-item-group v-else>
            <v-list-item>
                <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ selected_chat: null }),
    computed: {
        chats() {
            return this.$store.state.v2.mail.chats
                .map((chat) => ({
                    ...chat,
                    last_message: chat.messages.slice(-1)[0],
                }))
                .sort(
                    (a, b) =>
                        new Date(b.last_message.created) -
                        new Date(a.last_message.created)
                );
        },
    },
    methods: {
        setSelected(contact_id) {
            this.$emit("input", contact_id);
        },
    },
};
</script>