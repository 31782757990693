<template>
    <v-app class="app">
        <div class="nav-icon-collapsed">
            <v-btn v-if="show_drawer" icon large @click.stop="switch_drawer">
                <v-icon v-if="drawer"> mdi-chevron-left </v-icon>
                <v-icon v-else> mdi-chevron-right </v-icon>
            </v-btn>
        </div>
        <v-navigation-drawer permanent v-if="show_drawer" :mini-variant="!drawer" stateless app width="400">
            <LeftMenu :rail="!drawer" />
        </v-navigation-drawer>
        <v-main>
            <router-view></router-view>
            <Notifications />
            <v-overlay :value="loading_overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-main>
    </v-app>
</template>

<style lang="scss">
.app {
    height: 100hv;
    touch-action: none;
}

.nav-icon-collapsed {
    position: fixed;
    top: 15px;
    left: 5px;
    z-index: 10;
}

::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #ff770054;
    /* color of the scroll thumb */
    border-radius: 8px;
    /* roundness of the scroll thumb */
}
</style>

<script>
import LeftMenu from "./components/left_menu/LeftMenu.vue";
import Notifications from "/src/components/Notifications";

export default {
    components: {
        LeftMenu,
        Notifications,
    },
    data: () => ({
        drawer: true,
    }),
    computed: {
        loading_overlay() {
            return this.$store.state.lock;
        },
        show_drawer() {
            return !["login"].includes(this.$route.name);
        },
        company_color() {
            return this.$store.state.v2.session.user_object?.auth?.company
                ?.meta?.company_color;
        },
        iam_prod_worker() {
            return this.$store.getters["v2/session/iam_prod_worker"];
        },
    },
    watch: {
        company_color: {
            handler: function (c, oc) {
                try {
                    if (c && c != oc) {
                        this.$vuetify.theme.themes.light.accent = c;
                        this.$vuetify.theme.themes.dark.accent = c;
                        document.body.style["::-webkit-scrollbar-thumb"][
                            "background-color"
                        ] = c;
                    }
                } catch {
                    console.log("[!] Skipping setup of accent color.");
                }
            },
            immediate: true,
        },
        iam_prod_worker: {
            handler(iam) {
                this.drawer = !iam
            },
            immediate: true
        },
    },
    methods: {
        switch_drawer() {
            this.drawer = !this.drawer;
        },
    },
    mounted() {
        this.$store.dispatch("v2/session/refresh");

        // First load
        this.$store.dispatch("initialLoad")  // only initial
        this.$store.dispatch("refreshBaseUserData", false)

        // 2 minutes loads
        setInterval(
            () => this.$store.dispatch("refreshBaseUserData", false),
            120000
        );
    }
};
</script>
