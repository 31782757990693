<template>
    <div>
        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height" v-if="ass">
                    <v-card-title v-if="ass_uuid">
                        {{ $store.getters.t("assembly") }}:
                        {{ ass_uuid.substring(0, 8) }}
                    </v-card-title>
                    <v-card-text v-if="design">
                        {{ $store.getters.t("design") }}: {{ design.name }}
                        <br />
                        {{ $store.getters.t("priority_in_batch") }}:
                        {{ ass.priority }} <br />
                        {{ $store.getters.t("batch") }}: {{ batch_name }} <br />
                        {{ $store.getters.t("phase") }}:
                        {{ phase_name }}
                        <br />
                        {{ $store.getters.t("status") }}: {{ ass.status }}
                        <br />
                        <template v-for="(v, k) in ass.stations_status">
                            <span :key="k + '_sta'"> {{ k }}: {{ v }} </span>
                            <br :key="k + '_sta_br'" />
                        </template>
                    </v-card-text>

                    <v-card-text v-else>
                        <v-skeleton-loader type="list-item-three-line@2"></v-skeleton-loader>
                    </v-card-text>
                    
                    <v-card-actions v-if="ass">
                        <v-spacer></v-spacer>
                        <v-btn color="accent" :to="'/projects/' +
                            project_uuid +
                            '/designs/assembly/' +
                            ass.design
                            " text>
                            {{ design.name }}
                        </v-btn>
                        <v-btn text :to="'/viewer/assembly/' + ass_uuid">
                            <v-icon left> mdi-map-legend </v-icon>
                            {{
                                $store.getters.t("view_meaning_action_of_view")
                            }}
                            {{ $store.getters.t("assembly") }}
                        </v-btn>
                        <ButtonJSONRender v-if="ass_fc" title="Forecast times" :json="ass_fc.times" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ChecklistDataCard :assembly="ass" />
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height d-flex flex-column">
                    <v-card-title>
                        {{ $store.getters.t("client_meta") }}
                    </v-card-title>
                    <v-card-text v-if="Object.keys(ass.client_meta).length === 0">
                        {{ $store.getters.t("missing_metadata") }}
                    </v-card-text>
                    <v-card-text v-else>
                        <JSONRender :json="ass.client_meta" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ButtonDeleteActuals :uuids="[ass_uuid]" @deleted="$router.go(-1)" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ChecklistDataCard from "/src/components/constructibles/ChecklistDataCard";
import ButtonDeleteActuals from "/src/components/constructibles/ButtonDeleteActuals";
import ButtonJSONRender from "/src/components/renderers/ButtonJSONRender";
import JSONRender from "/src/components/renderers/JSONRender.vue";

export default {
    components: {
        ChecklistDataCard,
        ButtonDeleteActuals,
        ButtonJSONRender,
        JSONRender
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        project_uuid() {
            return this.$route.params?.project_uuid;
        },
        ass_uuid() {
            return this.$route.params?.ass_uuid;
        },
        ass() {
            return this.$store.state.v2.selected.selected_ass
        },
        ass_fc() {
            // To be updated when overview
            return {}
        },
        design() {
            return this.$store.state.v2.selected.selected_assd
        },
        batch() {
            return this.$store.state.v2.constructibles.batches.find(
                (b) => b.uuid == this.ass?.batch
            );
        },
        batch_name() {
            return this.batch?.name;
        },
        phase_name() {
            return this.batch?.phase;
        },
    },
    methods: {
        deleted() {
            this.$router.go(-1);
        },
    },
};
</script>