<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon :color="icon_color" small v-bind="attrs" v-on="on">
                {{ icon_name }}
            </v-icon>
        </template>
        <span>
            {{ tooltip_text }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        icon_name() {
            if (this.value == true) return "mdi-check-circle-outline";
            if (this.value == "subassembly") return "mdi-check-circle-outline";
            if (this.value == "subassembly_no_parent_ifc") return "mdi-close-circle-outline";
            if (this.value == "subassembly_no_parent") return "mdi-close-circle-outline";
            return "mdi-close-circle-outline";
        },
        icon_color() {
            if (this.value == true) return "green";
            if (this.value == "subassembly") return "blue";
            if (this.value == "subassembly_no_parent_ifc") return "orange";
            if (this.value == "subassembly_no_parent") return "orange";
            return "red";
        },
        tooltip_text() {
            if (this.value == true) return "This design has IFC.";
            if (this.value == "subassembly") return "Subassembly design takes its parent IFC.";
            if (this.value == "subassembly_no_parent_ifc") return "Subassembly design parent has no IFC.";
            if (this.value == "subassembly_no_parent") return "Subassembly design has no parent design.";
            return "No IFC.";
        }
    },
};
</script>