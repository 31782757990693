<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon> mdi-shield-key </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title> Get Tokens for API Account </v-card-title>
            <v-card-text>
                <p> Our platform usually provides access tokens with 1 hour lifetime, and refresh tokens with 1 day
                    lifetime. For a M2M connection to the API, you can generate your tokens with custom expiracy. </p>

                <p> The REST API accepts HTTP requests with an Authorization header including the token. <br>
                    <code> Authorization: Bearer YOUR_FULL_ACCESS_TOKEN </code>
                </p>

                <p>
                    Refresh tokens can be used to get updated short term access tokens live. Just POST the refresh token:
                    <br>
                    <span> URL: <code> https://produuz.it/api/v2/my_user/token/refresh/ </code> </span> <br>
                    <span> Payload: <code> {"refresh": "YOUR_FULL_REFRESH_TOKEN"} </code> </span> <br>

                    Else, you can simply create a longer live access token and ignore the refresh token. Max lifespan is one
                    year.
                </p>

                <p> Our tokens are live signed and never saved in our side. You can decode them at <a href="https://jwt.io"
                        target="_blank"> jwt.io</a></p>

                <p> OpenAPI: <a href="/api/v2/auth/login/?next=/api/v2/openapi/" target="_blank">
                        <code>https://produuz.it/api/v2/openapi/</code> </a>
                </p>
                <p> SWAGGER: <a href="/api/v2/auth/login/?next=/api/v2/swagger/" target="_blank">
                        <code> https://produuz.it/api/v2/swagger/ </code> </a>
                </p>
            </v-card-text>
            <v-card-text>
                <v-form ref="form" @keyup.enter.native="load_token">
                    <v-text-field outlined dense class="mx-1" v-model="days" label="Days to expiracy">
                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-text v-if="token">
                <v-alert color="#2A3B4D" dark icon="mdi-shield-key" dense>
                    <span> Refresh Token </span> </v-alert>
                <v-textarea :value="token.refresh_token" outlined dense disabled></v-textarea>
                <v-alert color="#2A3B4D" dark icon="mdi-shield-key" dense>
                    <span> Access Token </span> </v-alert>
                <v-textarea :value="token.access_token" outlined dense disabled></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" :disabled="loading" color="success" @click="load_token">
                    {{ $store.getters.t("load") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    props: ["account_id"],
    data: () => ({
        loading: false,
        dialog: false,
        days: 7,
        token: null
    }),
    watch: {
        account_id() {
            this.token = null
        }
    },
    methods: {
        load_token() {
            this.loading = true;
            return backend_api_v2
                .post(`/company/api_accounts/${this.account_id}/get_token/`, { days: this.days })
                .then(({ data }) => {
                    this.token = data
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        },
    },
    // mounted() {
    //     this.load_token();
    // },
};
</script>