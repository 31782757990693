<template>
    <v-select outlined dense class="mx-1" v-model="selected_projects" :items="projects"
        :label="$store.getters.t('project_name')" multiple></v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ selected_projects: [] }),
    computed: {
        projects() {
            return this.$store.state.v2.company.projects?.map((pro) => ({
                text: pro.name,
                value: pro.uuid,
            }));
        },
    },
    watch: {
        selected_projects(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_projects = v;
            },
            immediate: true,
        },
    }
};
</script>

  