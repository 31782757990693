<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="loading" :disabled="loading" @click="dialog = true" v-bind="attrs" v-on="on"
                    v-if="icon != undefined" icon>
                    <v-icon> mdi-sort-clock-ascending-outline </v-icon>
                </v-btn>
                <v-btn :loading="loading" :disabled="loading" @click="dialog = true" v-bind="attrs" v-on="on" v-else>
                    <v-icon left> mdi-sort-clock-ascending-outline </v-icon>
                    {{ $store.getters.t("sorting") }}
                </v-btn>
            </template>
            <span>
                {{ $store.getters.t("phase_sorting") }}
            </span>
        </v-tooltip>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("phase_sorting") }}
                </v-card-title>
                <v-card-subtitle>
                    {{ $store.getters.t("panel_ordering_subtitle") }}
                </v-card-subtitle>
                <v-card-text>
                    <draggable :list="phase_list" class="list-group" ghost-class="ghost">
                        <v-sheet outlined class="title ma-1 pa-1 outlined font-weight-light"
                            v-for="phase_name in phase_list" :key="phase_name">
                            <v-icon class="mb-1" color="black"> mdi-drag </v-icon>
                            {{ phase_name }}
                        </v-sheet>
                    </draggable>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="order_by_name()" text>
                        <v-icon left v-if="ascending">
                            mdi-sort-clock-ascending-outline
                        </v-icon>
                        <v-icon left v-else>
                            mdi-sort-clock-descending-outline
                        </v-icon>
                        order by name
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="save()" color="warning" :loading="loading" :disabled="loading">
                        {{ $store.getters.t("save") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>
import draggable from "vuedraggable"
import { nameSort } from "/src/utils/basics.js"
export default {
    props: ["project", "icon"],
    components: { draggable },
    data: () => ({
        dialog: false,
        loading: false,
        phase_list: [],
        ascending: true,
    }),
    computed: {
        phases() {
            return this.$store.state.v2.selected.selected_project?.phases || []
        },
    },
    watch: {
        phases() {
            this.refresh_list()
        },
        dialog() {
            this.refresh_list()
        },
    },
    methods: {
        save() {
            this.loading = true
            this.$nextTick(
                () => this.$store
                    .dispatch("v2/selected/putProject", { uuid: this.project.uuid, phases: this.phase_list })
                    .then(() => (this.dialog = false))
                    .finally(() => (this.loading = false))
            )

        },
        order_by_name() {
            if (this.ascending) {
                this.phase_list.sort(nameSort)
            } else {
                this.phase_list.sort((a, b) => nameSort(b, a))
            }
            this.ascending = !this.ascending
        },
        refresh_list() {
            this.phase_list = [...this.phases]
        },
    },
    mounted() {
        this.refresh_list()
    },
}
</script>