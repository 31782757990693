<template>
    <v-dialog v-model="dialog" max-width="500" @click:outside="dialog = false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn x-large block outlined color="accent" class="ma-3" v-bind="attrs" v-on="on">
                {{ $store.getters.t("force_start_station") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">{{ $store.getters.t('start_station') }}</v-card-title>
            <v-card-text> Previous station tasks may be not filled!
                ¿Do you want to <strong>start this station and set ready the previous station</strong>? </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="start" color="success">
                    <v-icon left>mdi-play</v-icon>
                    {{ $store.getters.t('start') }}
                </v-btn>
                <v-btn color="secondary" @click="$emit('close')">{{ $store.getters.t('close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogForceStartStation",
    data: () => ({ dialog: false }),
    methods: {
        start() {
            this.$emit("start");
            this.dialog = false
        }
    }
};
</script>