<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-center align-center">
                <v-btn outlined :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" color="accent" text
                    class="mr-2">
                    <v-icon left>mdi-sticker-check-outline</v-icon>
                    {{ $store.getters.t("kitted_parts") }}
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("set_constructible_as_kitted_parts") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("set_constructible_as_kitted_parts_text") }}:
                <ul>
                    <li v-for="(act, index) in actuals" :key="index">
                        {{ act }}
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="set_as_ready()" color="accent" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("kitted_parts") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { backend_api_v2 } from "/src/utils/apiv2";

export default {
    props: ["actuals"],
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {},
    methods: {
        set_as_ready() {
            console.log("[i] Marking parts as ready.");
            this.loading = true;
            let url = `/constructibles/assemblies/mark_kitted/`;

            backend_api_v2
                .post(url, this.actuals)
                .then((r) => {
                    if (r.status == 200) {
                        console.log(r.data);
                        r.data.forEach(ass=>this.$store.commit("v2/constructibles/updateAssembly", ass))
                        console.log("[i] Parts marked as ready.");
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.dialog = false;
                });
        },
    }
}
</script>