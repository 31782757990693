<template>
    <div>
        <v-btn class="mt-2" color="accent" outlined :disabled="loading" @click="updateRC">
            <v-icon>mdi-check-all</v-icon>
            {{ $store.getters.t("set_all_parts_ready") }}
        </v-btn>
    </div>
</template>

<script>
export default {
    props: ["groups"],
    data: () => ({ loading: false }),
    methods: {
        async updateRC() {
            await this.groups.reduce((promise, part) => {
                return promise.then(() => this.updateReadyCount(part));
            }, Promise.resolve());
        },
        async updateReadyCount(part) {
            this.loading = true;
            const payload = {
                part_uuids: part.uuids,
                ready_count: part.count,
            };
            try {
                await this.$store.dispatch("v2/selected_logistics/setReadyPartCount", payload);
            } catch (error) {
                console.error('ERROR updating Ready Count');
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<style scoped>
.v-btn {
    width: 100%;
}
</style>