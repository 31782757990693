import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
import bus from '/src/utils/event_bus'
// import router from '/src/router'

export default {
    namespaced: true,
    state: {
        company: { meta: {} },
        standard_libraries: [],
        projects: [],
        archived_projects: [],
        users: [],
        api_accounts: [],
        licenses: [],
        ifc_ps: [],
        btl_ps: [],
    },
    mutations: {

        // My Company
        setCompany: (state, data) => state.company = data,

        // Standard Libraries
        setStandardLibraries: base.mutations.setter("standard_libraries"),
        createStandardLibrary: base.mutations.creator("standard_libraries"),
        updateStandardLibrary: base.mutations.updater("standard_libraries"),
        deleteStandardLibrary: base.mutations.deleter("standard_libraries"),


        // Projects
        setProjects: base.mutations.setter("projects"),
        setArchivedProjects: base.mutations.setter("archived_projects"),
        createProject: base.mutations.creator("projects"),
        updateProject: base.mutations.updater("projects"),
        deleteProject: base.mutations.deleter("projects"),

        // Users
        setUsers: base.mutations.setter("users"),
        createUser: base.mutations.creator("users"),
        updateUser: base.mutations.updater("users"),
        deleteUser: base.mutations.deleter("users"),

        // API Accounts
        setAPIAccounts: base.mutations.setter("api_accounts"),
        createAPIAccount: base.mutations.creator("api_accounts"),
        updateAPIAccount: base.mutations.updater("api_accounts"),
        deleteAPIAccount: base.mutations.deleter("api_accounts"),

        // Licenses
        setLicenses: base.mutations.setter("licenses"),
        createLicense: base.mutations.creator("licenses"),
        updateLicense: base.mutations.updater("licenses"),
        deleteLicense: base.mutations.deleter("licenses"),

        // IFCPS
        setIFCPSs: base.mutations.setter("ifc_ps"),
        createIFCPS: base.mutations.creator("ifc_ps"),
        updateIFCPS: base.mutations.updater("ifc_ps"),
        deleteIFCPS: base.mutations.deleter("ifc_ps"),

        // BTLPS
        setBTLPSs: base.mutations.setter("btl_ps"),
        createBTLPS: base.mutations.creator("btl_ps"),
        updateBTLPS: base.mutations.updater("btl_ps"),
        deleteBTLPS: base.mutations.deleter("btl_ps"),
    },
    actions: {
        // My Company
        loadCompany(context) {
            return backend_api_v2.get('/my_company/')
                .then(({ data }) => {
                    context.commit('setCompany', data)
                })
                .catch(e => console.log(`Cant load Compnay: ${e}`))
        },
        patchCompany(context, payload) {
            return backend_api_v2.put('/my_company/', payload)
                .then(({ data }) => {
                    context.commit('setCompany', data)
                })
                .catch(e => console.log(`Cant patch company: ${e}`))
        },

        // Standard Libraries
        loadStandardLibraries: base.actions.loader("company/standard_libraries/", "StandardLibraries"),
        postStandardLibrary: base.actions.poster("company/standard_libraries/", "StandardLibrary"),
        putStandardLibrary: base.actions.putter("company/standard_libraries/", "StandardLibrary"),
        deleteStandardLibrary: base.actions.deleter("company/standard_libraries/", "StandardLibrary"),

        // Projects
        loadProjects: base.actions.loader("company/projects/", "Projects"),
        loadArchivedProjects: base.actions.loader("company/projects/?archived=true", "ArchivedProjects"),
        // loadProjects(context) {
        //     return paginated_factory_v2('/company/projects/')
        //         .then(({data}) => {
        //             context.commit('setProjects', data.filter(p => p.archived == false))
        //             context.commit('setArchivedProjects', data.filter(p => p.archived == true))
        //             // context.commit("ready")
        //         })
        //         .catch(e => {
        //             console.log(`Cant get projects: ${e}`)
        //             // context.commit("failed")
        //         })
        // },
        postProject: base.actions.poster("company/projects/", "Project"),
        putProject: base.actions.putter("company/projects/", "Project"),
        deleteProject: base.actions.deleter("company/projects/", "Project"),
        updateProjectResources(context, payload) {
            let project_uuid = payload.project.uuid
            return backend_api_v2.put('company/projects/' + project_uuid + '/patch_resources/', payload.data)
                .then(({ data }) => {
                    context.commit('refreshProject', data)
                })
                .catch(e => console.log(`Cant update project resources: ${e}`))
        },

        // Users
        loadUsers: base.actions.loader("company/users/", "Users"),
        postUser: base.actions.poster("company/users/", "User"),
        putUser: base.actions.putter("company/users/", "User"),
        deleteUser: base.actions.deleter("company/users/", "User"),
        postUserPassword(context, payload) {
            return backend_api_v2.put('company/users/' + payload.id + "/change_password/", payload)
                .then(({ data }) =>
                    bus.$emit("notification", {
                        timeout: 3000,
                        text: data.detail,
                        color: "success",
                    })
                )
                .catch(e => bus.$emit("notification", {
                    timeout: 3000,
                    text: e,
                    color: "error",
                }))
        },

        // API Accounts
        loadAPIAccounts: base.actions.loader("company/api_accounts/", "APIAccounts"),
        postAPIAccount: base.actions.poster("company/api_accounts/", "APIAccount"),
        putAPIAccount: base.actions.putter("company/api_accounts/", "APIAccount"),
        deleteAPIAccount: base.actions.deleter("company/api_accounts/", "APIAccount"),

        // Licenses
        loadLicenses: base.actions.loader("company/licenses/", "Licenses"),
        postLicense: base.actions.poster("company/licenses/", "License"),
        putLicense: base.actions.putter("company/licenses/", "License"),
        deleteLicense: base.actions.deleter("company/licenses/", "License"),

        // IFCPS
        loadIFCPSs: base.actions.loader("company/ifc_ps/", "IFCPSs"),
        postIFCPS: base.actions.poster("company/ifc_ps/", "IFCPS"),
        putIFCPS: base.actions.putter("company/ifc_ps/", "IFCPS"),
        deleteIFCPS: base.actions.deleter("company/ifc_ps/", "IFCPS"),

        // BTLPS
        loadBTLPSs: base.actions.loader("company/btl_ps/", "BTLPSs"),
        postBTLPS: base.actions.poster("company/btl_ps/", "BTLPS"),
        putBTLPS: base.actions.putter("company/btl_ps/", "BTLPS"),
        deleteBTLPS: base.actions.deleter("company/btl_ps/", "BTLPS"),
    },
    getters: {
    }
}