<template>
    <div class="d-flex flex-column align-space-between mb-1">
        <ActualChecklistButton @click="dialog = true" :station_name="station_name" :loading_proof="loading_proof"
            :tasks="tasks" :my_job_is_ready="my_job_is_ready" :not_started="not_started" :paused="paused" />
        <ScrollingNextTask v-if="!no_station" :station_name="station_name" :tasks="tasks"
            :my_job_is_ready="my_job_is_ready" :not_started="not_started" />
        <v-dialog transition="scroll-x-transition" v-model="dialog" max-width="800">
            <v-card :loading="loading">
                <v-card-title class="headline">
                    {{ $store.getters.t("checklist") }}
                    <v-spacer></v-spacer>
                    <ChangingStationButtons @loading="sta_loading = true" @loaded="sta_loading = false" />
                </v-card-title>

                <!-- PANEL NOT EVEN STARTED -->
                <v-card-text v-if="not_started">
                    <v-btn @click="startChecklist" x-large block outlined color="accent" class="ma-3">
                        {{ $store.getters.t("start_production") }}
                    </v-btn>
                </v-card-text>

                <!-- PANEL PAUSED -->
                <v-card-text v-else-if="paused">
                    <v-btn @click="resumeChecklist" x-large block outlined color="accent" class="ma-3">
                        {{ $store.getters.t("resume") }}
                    </v-btn>
                </v-card-text>


                <!-- STATION NOT STARTED -->
                <v-card-text v-else-if="station_not_started && prev_station_not_ready && prev_station">
                    <DialogForceStartStation @start="setPrevReadyAndStartStation" />
                </v-card-text>
                <v-card-text v-else-if="station_not_started">
                    <v-btn @click="startStation" x-large block outlined color="accent" class="ma-3">
                        {{ $store.getters.t("start_station") }}
                    </v-btn>
                </v-card-text>

                <!-- STATION READY -->
                <v-card-text v-else-if="my_job_is_ready">
                    <v-alert class="ma-3" type="success">
                        {{ $store.getters.t("checklist_station_complete") }}
                    </v-alert>
                </v-card-text>

                <!-- CLOSE BUTTON FOR ALERTS -->
                <v-card-actions v-if="my_job_is_ready || not_started || paused || station_not_started">
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>

                <!-- NORMAL TASKS LIST -->
                <template v-else>
                    <v-card-text>
                        {{ $store.getters.t("checklist_confirm_1") }}
                        <strong> {{ design_name }} </strong> [{{ ass_uuid.substring(0, 8) }}]
                        {{ $store.getters.t("checklist_confirm_2") }}
                        <strong>{{ station_name }}</strong>
                        {{ $store.getters.t("checklist_confirm_3") }}
                        <strong>{{ production_line_name }}</strong> .
                        {{ $store.getters.t("checklist_confirm_4") }}.
                    </v-card-text>
                    <StationTasksForm v-model="proof" :station="station" :station_name="station_name"
                        :loading_proof="loading_proof" :production_line_name="production_line_name" @close="closeForm"
                        @save="saveProof((close_form = false))" />
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus"
import ActualChecklistButton from "./ActualChecklistButton.vue"
import ScrollingNextTask from "./ScrollingNextTask.vue"
import StationTasksForm from "./StationTasksForm.vue"
import ChangingStationButtons from "./ChangingStationButtons.vue"
import DialogForceStartStation from "./DialogForceStartStation.vue"
import _ from "lodash"



export default {
    name: "ButtonPanelChecklist",
    components: {
        ActualChecklistButton,
        ScrollingNextTask,
        StationTasksForm,
        ChangingStationButtons,
        DialogForceStartStation,
    },
    props: ["actual"],
    data: () => ({
        dialog: false,
        default_tasks: [],
        proof: null,
        loading: false,
        sta_loading: false,
    }),
    computed: {
        store_proof() {
            return this.$store.state.v2.records.rcp
        },
        store_loading() {
            return this.$store.state.v2.records.rcp_loading
        },
        loading_proof() {
            return this.loading || this.sta_loading || this.store_loading
        },
        not_started() {
            return !this.proof
        },
        paused() {
            return this.proof?.paused
        },
        station() {
            let station_name =
                this.$store.getters["v2/session/my_selected_station"]
            let stations = this.production_line?.stations || []
            let station = stations.find((sta) => sta.name == station_name)
            return station || { name: "FAILED_TO_LOAD_STATION" }
        },
        prev_station() {
            let station_name =
                this.$store.getters["v2/session/my_selected_station"]
            let stations = this.production_line?.stations || []
            let station_idx = stations.findIndex((sta) => sta.name == station_name)
            return stations[station_idx - 1]
        },
        no_station() {
            return this.station.name == "FAILED_TO_LOAD_STATION"
        },
        production_line() {
            return this.$store.getters["v2/session/my_selected_line_object"]
        },
        station_name() {
            return this.station ? this.station.name : ""
        },
        prev_station_name() {
            return this.prev_station ? this.prev_station.name : ""
        },
        production_line_name() {
            return this.production_line ? this.production_line.name : ""
        },
        design() {
            if (this.actual.level == "module")
                return this.$store.state.v2.selected.selected_modd
            if (this.actual.level == "assembly")
                return this.$store.state.v2.selected.selected_assd
            else return false
        },
        ass_uuid() {
            if (this.actual.level == "module")
                return this.$store.state.v2.selected.selected_modd?.uuid
            if (this.actual.level == "assembly")
                return this.$store.state.v2.selected.selected_assd?.uuid
            else return "false"
        },
        design_name() {
            return this.design?.name || "---"
        },
        station_or_default_tasks() {
            try {
                if (this.station.checklist_items.lenght != 0) {
                    return this.station.checklist_items
                } else {
                    return this.default_tasks
                }
            } catch {
                return this.default_tasks
            }
        },
        tasks() {
            return this.proof
                ? this.proof.checklist.filter(
                    (ch_item) =>
                        ch_item.production_line ==
                        this.production_line_name &&
                        ch_item.production_station == this.station_name
                )
                : []
        },
        my_job_is_ready() {
            return (
                -1 != this.tasks.findIndex((t) => t.text == "STATION_JOB_READY")
            )
        },
        iam_prod_worker() {
            return this.$store.getters["v2/session/iam_prod_worker"]
        },
        station_not_started() {
            return !this.proof?.checklist.some(t => {
                if (t.text != "STATION_STARTED") return false
                if (t.production_line != this.production_line_name) return false
                if (t.production_station != this.station_name) return false
                return true
            })
        },
        prev_station_not_started() {
            return !this.proof?.checklist.some(t => {
                if (t.text != "STATION_STARTED") return false
                if (t.production_line != this.production_line_name) return false
                if (t.production_station != this.prev_station_name) return false
                return true
            })
        },
        prev_station_not_ready() {
            return !this.proof?.checklist.some(t => {
                if (t.text != "STATION_JOB_READY") return false
                if (t.production_line != this.production_line_name) return false
                if (t.production_station != this.prev_station_name) return false
                return true
            })
        }

    },
    watch: {
        store_proof: {
            handler(p) {
                this.proof = _.cloneDeep(p)
            },
            immediate: true,
        },
        store_loading(sl) { if (!sl && this.iam_prod_worker) { this.dialog = true } },
    },
    methods: {
        closeForm() {
            this.dialog = false
        },
        refreshActual() {
            // Refresh this panel from the backend
            let action_name
            if (this.actual.level == "module")
                action_name = "v2/constructibles/refreshModule"
            if (this.actual.level == "assembly")
                action_name = "v2/constructibles/refreshAssembly"
            this.$store.dispatch(action_name, this.actual.uuid)
        },
        startChecklist() {
            // Starts constructible
            this.loading = true
            bus.$emit("back_event", {
                event: "constructible_start",
                meta: { frontend_timestamp: new Date() },
            })
            return this.$store
                .dispatch("v2/records/createRCP", { level: this.actual.level, uuid: this.actual.uuid })
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Panel started!",
                        color: "success",
                    })
                    this.refreshActual()
                    // this.dialog = false
                    this.loading = false
                })
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Can't start constructible.",
                        color: "error",
                    })
                    this.loading = false
                })
        },
        async startStation() {
            // Starts station
            this.loading = true
            this.proof.checklist.push({
                text: "STATION_STARTED",
                production_line: this.production_line_name,
                production_station: this.station_name,
                checked: true,
                timestamp: new Date(),
            })
            this.saveProof()
            bus.$emit("back_event", {
                event: "station_start",
                meta: {
                    frontend_timestamp: new Date(),
                    station_name: this.station_name,
                    station: this.station?.uuid,
                },
            })
        },
        async setPrevReadyAndStartStation() {
            // Starts station
            this.loading = true
            if (this.prev_station_not_started) {
                this.proof.checklist.push({
                    text: "STATION_STARTED",
                    production_line: this.production_line_name,
                    production_station: this.prev_station_name,
                    checked: true,
                    timestamp: new Date(),
                })
            }
            this.proof.checklist.push({
                text: "STATION_JOB_READY",
                production_line: this.production_line_name,
                production_station: this.prev_station_name,
                checked: true,
                timestamp: new Date(),
            })
            this.proof.checklist.push({
                text: "STATION_STARTED",
                production_line: this.production_line_name,
                production_station: this.station_name,
                checked: true,
                timestamp: new Date(),
            })
            this.saveProof()

            if (this.prev_station_not_started) {
                bus.$emit("back_event", {
                    event: "station_start",
                    meta: {
                        frontend_timestamp: new Date(),
                        station_name: this.prev_station_name,
                        station: this.prev_station?.uuid,
                    },
                })
            }
            bus.$emit("back_event", {
                event: "station_ready",
                meta: {
                    frontend_timestamp: new Date(),
                    station_name: this.prev_station_name,
                    station: this.prev_station?.uuid,
                },
            })
            bus.$emit("back_event", {
                event: "station_start",
                meta: {
                    frontend_timestamp: new Date(),
                    station_name: this.station_name,
                    station: this.station?.uuid,
                },
            })
        },
        resumeChecklist() {
            this.proof.paused = false
            this.saveProof()
            bus.$emit("back_event", {
                event: "forced_resume",
                meta: { frontend_timestamp: new Date() },
            })
        },
        saveProof: _.debounce(function (close_form = true) {
            // Saves proof to backend
            // If success refreshes panel (for status)
            this.loading = true
            return this.$store
                .dispatch("v2/records/putRCP", this.proof)
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Checklist updated.",
                        color: "success",
                    })
                    if (close_form) this.dialog = false
                    this.refreshActual()
                    this.loading = false
                    if (this.proof.ready_date) {
                        bus.$emit("events/constructible/routeToMyNextActual")
                    }
                })
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Can't save checklist!",
                        color: "error",
                    })
                    this.loading = false
                })
        }, 1000),
    },
}
</script>