import bus from "/src/utils/event_bus";
import send_backend_event from "/src/utils/events";

import init_cloudstorage_watchers from './cloudstorage'
import init_constructibles_watchers from './constructibles'
// import init_factories_watchers from './factories'
import init_records_watchers from './records'
import init_reports_watchers from './reports'
import init_selected_watchers from './selected'
import init_selected_logistics_watchers from './selected_logistics'
import init_session_watchers from './session'
import init_quantities_watchers from './quantities'
import init_viewer_watchers from './viewer'


export default function (store) {
    // MODULES
    init_cloudstorage_watchers(store)
    init_constructibles_watchers(store)
    // init_factories_watchers(store)
    init_records_watchers(store)
    init_reports_watchers(store)
    init_selected_watchers(store)
    init_selected_logistics_watchers(store)
    init_session_watchers(store)
    init_quantities_watchers(store)
    init_viewer_watchers(store)


    // ROOT WATCHERS
    bus.$on("back_event", send_backend_event);

}

// CAUTION!!!
// ALL THIS WATCHERS WILL PROBABLY NEED A PROPER CANCELATION SYSTEM
// LAST FINISHED CALL WILL FILL THE STORE, INSTEAD OF THE LAST CALL
// (SEE v2/cloudstorage LOADFACTORY)


// CAUTION !!!
// IF YOU ARE LOOKING TO AUTOMATIC DISPATCHERS (ON URL/ROUTE CHANGE)
// TAKE A LOOK AT "asyncStoreDispatcher" IN "router/guards.js"