<template>
    <v-container fluid class="px-5">
        <v-row class="pa-1">
            <v-btn @click="$router.go(-1)" color="secondary">
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $store.getters.t("return") }}
            </v-btn>
            <v-spacer> </v-spacer>
            <!-- <v-btn @click="delete_wall" color="error">
                <v-icon left> mdi-delete </v-icon>
                {{ $store.getters.t("delete") }}
            </v-btn> -->
        </v-row>
        <v-row v-if="wall">
            <v-col class="pa-1">
                <v-card outlined class="fill-height">
                    <v-card-title> Design Data </v-card-title>
                    <v-card-text v-if="loading">
                        <v-skeleton-loader type="text@3"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else-if="!wall.extended_bom">
                        {{ $store.getters.t("missing_wall_parts") }}
                    </v-card-text>
                    <v-card-text v-else>
                        {{ $store.getters.t("design") }}:
                        {{ wall.name }} <br />
                        {{ $store.getters.t("revision") }}:
                        {{ wall.revision }} <br />
                        {{ $store.getters.t("parts") }}:
                        {{ parts_count }} <br />
                        <!-- {{ $store.getters.t("wood") }}:
                        {{ wall.extended_bom.metadata.wood_count }}
                        <br />
                        {{ $store.getters.t("plates") }}:
                        {{ wall.extended_bom.metadata.plates_count }}
                        <br />
                        {{ $store.getters.t("accesories") }}:
                        {{ wall.extended_bom.metadata.accesories_count }} -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="pa-1">
                <v-card outlined class="fill-height">
                    <v-card-title>
                        {{ $store.getters.t("wall_metadata") }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ $store.getters.t("wall_metadata_subtitle") }}
                    </v-card-subtitle>
                    <v-card-text v-if="loading">
                        <v-skeleton-loader type="text@3"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else-if="!wall.extended_bom">
                        {{ $store.getters.t("missing_wall_metadata") }}
                    </v-card-text>
                    <v-card-text v-else>
                        <span
                            v-for="(v, k) in wall.extended_bom.metadata"
                            :key="k"
                        >
                            {{ k }}: {{ v }}
                            <br />
                        </span>
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <ButtonDesignMetadataEdit
                            @saved="load_wall"
                            :extended_bom="wall.extended_bom"
                        />
                    </v-card-actions> -->
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="wall">
            <v-col>
                <v-data-table
                    :items="wall.extended_bom.parts"
                    :headers="parts_headers"
                >
                    <template v-slot:item.ifc_id="{ item }">
                        <!-- <v-btn @click="part_detail(item)" text color="accent"> -->
                        {{ item.ifc_id }}
                        <!-- </v-btn> -->
                    </template>
                    <template v-slot:item.has_cuts="{ item }">
                        <IconCutsBoolean :value="item.has_cuts" />
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <ButtonPartDetail :part="item" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-card-text>
                <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </v-card-text>
        </v-row>
    </v-container>
</template>

<script>
import IconCutsBoolean from "/src/components/renderers/IconCutsBoolean";
import ButtonPartDetail from "/src/components/designs/ButtonPartDetail";
// import ButtonDesignMetadataEdit from "/src/components/designs/ButtonDesignMetadataEdit";
export default {
    components: {
        IconCutsBoolean,
        ButtonPartDetail,
        // ButtonDesignMetadataEdit,
    },
    data: () => ({
        loading: true,
        parts_headers: [
            { text: "Part", align: "start", value: "ifc_id" },
            { text: "IFC Class", value: "ifc_type" },
            { text: "Zone", value: "zone" },
            { text: "Has Cuts", value: "has_cuts" },
            { text: "Material", value: "material" },
            { text: "Actions", value: "actions", align: "end" },
        ],
        part_detail_modal: false,
    }),
    computed: {
        wall() {
            return this.$store.state.viewer.selected_full_wall;
        },
        other_revisions() {
            return this.$store.state.projects.project_walls.filter(
                (w) => w.name == this.wall.name
            );
        },
        is_newest() {
            let revs = this.other_revisions.map((rev) => rev.revision);
            return this.wall.revision >= Math.max(...revs);
        },
        panels() {
            return this.$store.state.projects.project_wall_units.filter(
                (wu) => wu.wall == this.wall.uuid
            );
        },
        parts_count() {
            return this.wall.extended_bom.parts.length;
        },
    },
    methods: {
        load_wall() {
            this.loading = true;
            this.$store
                .dispatch("viewer/loadFullWall", this.$route.params.design_uuid)
                .then(() => (this.loading = false));
        },
        part_detail(part) {
            console.log(part);
        },
    },
    mounted() {
        this.$nextTick(this.load_wall);
    },
};
</script>