<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-card outlined dense class="mx-1">
                        <v-card-title>
                            {{ $store.getters.t("constructibles") }}
                        </v-card-title>
                        <v-card-text>
                            <ConstructiblesFilterBar @change="handle_payload" :loading="loading" />
                            <v-text-field label="Designs Live Search" outlined v-model="search"
                                clearable></v-text-field>
                            <SelectConstructible />
                            <TableConstructiblesManager v-model="constructibles" :search="search" :mode="mode" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- Drawer -->
        <DrawerConstructiblesManager />
        <DrawerConstructiblesManagerDesignDetail />
    </div>
</template>

<script>
import TableConstructiblesManager from "../../components/constructibles_manager/TableConstructiblesManager.vue";
import ConstructiblesFilterBar from "../../components/constructibles_manager/ConstructiblesFilterBar.vue";
import DrawerConstructiblesManager from "../../components/constructibles_manager/DrawerConstructiblesManager.vue";

import DrawerConstructiblesManagerDesignDetail from "../../components/constructibles_manager/DrawerConstructiblesManagerDesignDetail.vue";
import SelectConstructible from "/src/components/designs/SelectConstructible";
import { paginated_factory_v2 } from "/src/utils/apiv2";
import { getUrlQueryFromPayload } from "/src/utils/urls"

export default {
    components: {
        ConstructiblesFilterBar,
        TableConstructiblesManager,
        DrawerConstructiblesManager,
        DrawerConstructiblesManagerDesignDetail,
        SelectConstructible,
    },
    data: () => ({
        loading: false,
        search: "",
        constructibles: [],
        received_payload: [],
    }),
    computed: {
        mode() {
            return this.$route.query.level
        },
    },
    methods: {
        handle_payload(payload) {
            this.received_payload = payload;
            this.load_constructibles();
        },
        load_constructibles() {
            this.loading = true;

            const url_query = getUrlQueryFromPayload(this.received_payload)

            const assemblyPromise = paginated_factory_v2(`constructibles/assemblies/?${url_query}`);
            const modulePromise = paginated_factory_v2(`constructibles/modules/?${url_query}`);

            return Promise.all([assemblyPromise, modulePromise])
                .then(([assemblyData, moduleData]) => {
                    this.constructibles = [
                        ...assemblyData.data.map(ass => ({ ...ass, level: "assembly" })),
                        ...moduleData.data.map(mod => ({ ...mod, level: "module" })),
                    ];
                    this.loading = false;
                })
                .catch(error => {
                    console.log(`Can't load both constructibles: ${error}`);
                    this.loading = false;
                });

        },
    },
};
</script>
