<template>
    <v-chip class="ml-2" x-small outlined color="accent" v-if="value == 'top_rail'"> TR </v-chip>
    <v-chip class="ml-2" x-small outlined color="accent" v-else-if="value == 'bottom_rail'"> BR
    </v-chip>
    <v-chip class="ml-2" x-small outlined color="accent" v-else-if="value == 'extra'"> Extra
    </v-chip>
    <v-chip class="ml-2" x-small outlined color="accent" v-else-if="value == 'extra_wood'"> Extra
    </v-chip>
    <v-chip class="ml-2" x-small outlined color="accent" v-else-if="value == 'extra_bottom_rail'">
        Extra
        BR </v-chip>
    <v-chip class="ml-2" x-small outlined color="accent" v-else-if="value == 'extra_top_rail'">
        Extra TR
    </v-chip>
</template>

<script>
export default {
    props: ["value"]
}
</script>