<template>
    <v-select outlined dense class="mx-1" v-model="category" :items="cat_items"
        :rules="[(v) => !!v || 'A Category is required']" :label="$store.getters.t('category')">
    </v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        category: "",
    }),
    computed: {
        cat_items() {
            return [
                {
                    text: this.$store.getters.t("anno_cat_prod_issue"),
                    value: "prod_issue",
                },
                {
                    text: this.$store.getters.t("anno_cat_quality_control"),
                    value: "quality_control",
                },
            ];
        },
    },
    watch: {
        category(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.category = v;
            },
            immediate: true,
        },
    },
};
</script>