import bus from "/src/utils/event_bus";

export default function (store) {

    // Reload project and company packages
    store.watch(
        (state) => state.v2.company.company?.uuid,
        (uuid) => {
            if (uuid != undefined)
                store.dispatch('v2/cloudstorage/loadCompanyPackages', uuid)
            store.commit("v2/cloudstorage/set_company_packages", [])
        }
    );
    store.watch(
        (state) => state.v2.selected.selected_project?.uuid,
        (uuid) => {
            if (uuid != "all" && uuid != undefined)
                store.dispatch('v2/cloudstorage/loadProjectPackages', uuid)
            store.commit("v2/cloudstorage/set_project_packages", [])
        }
    );

    // Reload constructible packages
    store.watch(
        (state) => state.v2.selected.selected_mod?.uuid,
        (uuid) => {
            if (uuid != undefined)
                store.dispatch('v2/cloudstorage/loadModulePackages', uuid)
            store.commit("v2/cloudstorage/set_module_packages", [])
        }
    );
    store.watch(
        (state) => state.v2.selected.selected_ass?.uuid,
        (uuid) => {
            if (uuid != undefined)
                store.dispatch('v2/cloudstorage/loadAssemblyPackages', uuid)
            store.commit("v2/cloudstorage/set_assembly_packages", [])
        }
    );
    store.watch(
        (state) => state.v2.selected.selected_modd?.uuid,
        (uuid) => {
            if (uuid != undefined)
                store.dispatch('v2/cloudstorage/loadModuleDesignPackages', uuid)
            store.commit("v2/cloudstorage/set_module_design_packages", [])
        }
    );
    store.watch(
        (state) => state.v2.selected.selected_assd?.uuid,
        (uuid) => {
            if (uuid != undefined)
                store.dispatch('v2/cloudstorage/loadAssemblyDesignPackages', uuid)
            store.commit("v2/cloudstorage/set_assembly_design_packages", [])
        }
    );

    // store.watch(
    //     (state) => state.v2.selected.selected_ass?.parent_assembly,
    //     (pass) => console.log("PARENT ASS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", pass)
    // )
    store.watch(
        (state) => state.v2.selected.selected_assd?.parent_designs,
        (pds) => {
            if (pds.length > 0) {
                const uuid = pds[0]  // We simply pick first parent. Otherwise should be determined by assembly in prev watcher.
                store.dispatch('v2/cloudstorage/loadParentAssemblyDesignPackages', uuid)
                store.commit("v2/cloudstorage/set_parent_assembly_design_packages", [])
            }
        }
    )

    bus.$on(
        "events/cloudstorage/reloadCompanyPackages",
        () => store.dispatch('v2/cloudstorage/loadCompanyPackages', store.state.v2.company.company?.uuid)
    )
    bus.$on(
        "events/cloudstorage/reloadProjectPackages",
        () => store.dispatch('v2/cloudstorage/loadProjectPackages', store.state.v2.selected.selected_project?.uuid)
    )
    bus.$on(
        "events/cloudstorage/reloadDesignPackages",
        () => {
            store.commit("v2/cloudstorage/set_assembly_design_packages", [])
            store.commit("v2/cloudstorage/set_module_design_packages", [])
            let assd_uuid = store.state.v2.selected.selected_assd?.uuid
            let modd_uuid = store.state.v2.selected.selected_modd?.uuid
            if (assd_uuid) store.dispatch('v2/cloudstorage/loadAssemblyDesignPackages', assd_uuid)
            if (modd_uuid) store.dispatch('v2/cloudstorage/loadModuleDesignPackages', modd_uuid)
        }
    )
}