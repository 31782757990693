<template>
    <div class="d-flex flex-row view-mode-selector">
        <v-btn
            @click="changeMode('drawing')"
            class="mb-2 mr-1"
            fab
            raise
            small
            color="secondary"
        >
            <v-icon>mdi-arrow-left-box</v-icon>
        </v-btn>
        <v-btn
            @click="changeMode('full_panel')"
            class="mb-2 mr-1"
            fab
            raise
            small
            color="secondary"
        >
            <v-icon>mdi-grid-large</v-icon>
        </v-btn>
        <v-btn
            @click="changeMode('detail')"
            class="mb-2 mr-1"
            fab
            raise
            small
            color="secondary"
        >
            <v-icon>mdi-arrow-right-box</v-icon>
        </v-btn>
    </div>
</template>

<script>
// import bus from "/src/utils/event_bus";
export default {
    name: "ViewModeSelector",
    methods: {
        changeMode(mode) {
            if (["drawing", "detail"].includes(mode)) {
                this.$emit("input", "full_panel");
                setTimeout(() => this.$emit("input", mode), 200);
            } else {
                this.$emit("input", mode);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.view-mode-selector {
    // width: 100px;
    position: fixed;
    // top: auto;
    top: 5px;
    // right: 10px;
    right: 2px;
}
</style>
