import { backend_api_v2, paginated_factory_v2 } from '/src/utils/apiv2'
import bus from '/src/utils/event_bus'
import base from "./base"

let LASTS = {}  // prevent old request fill data over new request.

export default {
    namespaced: true,
    state: {
        selected_actuals: [],
        selected_branch: null,
        selected_size: null,
        selected_part: null,
        branches: [],
        sizes: [],
        parts: [],
        loading: []
    },
    mutations: {
        setSelectedActuals: (state, actuals) => state.selected_actuals = actuals,

        setSelectedBranch: (state, branch) => state.selected_branch = branch,

        setSelectedSize: (state, size) => state.selected_size = size,

        setSelectedPart: (state, part) => state.selected_part = part,

        setBranches: (state, branches) => state.branches = branches,

        setSizes: (state, sizes) => state.sizes = sizes,

        setParts: (state, parts) => state.parts = parts,

        replacePart: base.mutations.updater("parts"),

        pushLoading: (state, tag) => state.loading.push(tag),

        popLoading: (state, tag) => state.loading = state.loading.filter(t => t != tag),
    },
    actions: {

        // LOAD BRANCHES
        loadBranchesByActualUuids(context, payload) {
            context.commit("pushLoading", "branches");
            let url = `/constructibles/actuals/bulk_get_branches/`

            LASTS[url] = payload;

            return backend_api_v2.post(url, payload)
                .then(({ data }) => {
                    if (LASTS[url] == payload) {
                        context.commit("setBranches", data.branches);
                        context.commit("popLoading", "branches");
                    } else {
                        console.log("[i] Rejecting loader commit. Not last call.")
                    }
                })
                .catch(() => bus.$emit("notification", { text: `Can't load branches`, color: "error" }))
        },
        // LOAD SIZES
        loadSizesByActualUuidsAndBranch(context, payload) {
            context.commit("pushLoading", "sizes");
            let url;
            if (payload.branch === "timber") {
                url = `logistics/sizes/profiles/?branch=timber&actuals__in=${payload.actuals}&extended_serializer=true`;
            } else if (payload.branch === "plate") {
                url = `logistics/sizes/plates/?branch=plate&actuals__in=${payload.actuals}&extended_serializer=true`;
            }

            const keyword = `last_logistics_sizes`
            LASTS[keyword] = payload;

            return backend_api_v2.get(url, payload)
                .then(({ data }) => {
                    if (LASTS[keyword] == payload) {
                        context.commit("setSizes", data.results);
                        context.commit("popLoading", "sizes");
                    } else {
                        console.log("[i] Rejecting loader commit. Not last call.")
                    }
                })
                .catch(() => bus.$emit("notification", { text: `Can't load sizes`, color: "error" }))
        },
        // LOAD PARTS
        loadParts(context, payload) {
            context.commit("pushLoading", "parts");
            let url
            url = `constructibles/parts/?branch=${payload.branch}&size=${payload.size}&actual__in=${payload.actuals}&serialize_design=true`;

            const keyword = `last_logistics_parts`
            LASTS[keyword] = payload;

            return paginated_factory_v2(url)
                .then(({data}) => {
                    if (LASTS[keyword] == payload) {
                        context.commit("setParts", data);
                        context.commit("popLoading", "parts");
                    } else {
                        console.log("[i] Rejecting loader commit. Not last call.")
                    }
                })
                .catch(() => bus.$emit("notification", { text: `Can't load parts`, color: "error" }))
        },
        // SET READY PARTS

        setReadyPartCount(context, payload) {
            context.commit("pushLoading", "ready_count");
            let url = `constructibles/parts/set_ready_count/?serialize_design=true`;

            return backend_api_v2.post(url, payload)
                .then(({data}) => {
                    data.forEach(part => context.commit("replacePart", part))
                    context.commit("popLoading", "ready_count");
                })
        },
    },
    getters: {
        isLoading: state => tag => state.loading.includes(tag),
        branchAlike: state => {
            // TO BE UPDATED WITH IFCPS CUSTOM BRANCHES
            if (state.selected_branch == "timber") return "timber"
            else if (state.selected_branch == "plates") return "plates"
            else return "accesories"
        },
    }
}
