
<template>
    <v-dialog v-model="dialog" width="900">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :disabled="disabled" :loading="loading" text>
                <v-icon left> mdi-archive </v-icon>
                {{ $store.getters.t("archived_projects") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ $store.getters.t("archived_projects") }}
            </v-card-title>

            <v-card-text>
                <v-text-field label="Projects filter" outlined v-model="search"></v-text-field>
                <v-skeleton-loader type="table" v-if="loading" />
                <v-simple-table v-else>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Name</th>
                                <th class="text-left">Project number</th>
                                <th class="text-left">Client</th>
                                <th class="text-left">Superproject</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in archived_projects" :key="item.uuid">
                                <td>{{ item.name }}</td>
                                <td>{{ item.project_number }}</td>
                                <td>{{ item.client_name }}</td>
                                <td>{{ item.super_project }}</td>
                                <td class="text-right">
                                    <v-btn icon @click="unarchive(item)">
                                        <v-icon> mdi-archive-arrow-up </v-icon>
                                    </v-btn>
                                    <v-btn icon @click="launch_delete(item.uuid)">
                                        <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <v-dialog v-model="delete_dialog" width="500">
                            <v-card>
                                <v-card-title>
                                    {{ $store.getters.t("delete_project") }}
                                </v-card-title>
                                <v-card-text>
                                    {{ $store.getters.t("delete_project_confirm") }}
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer> </v-spacer>
                                    <v-btn @click="delete_project" color="error" :disabled="delete_loading"
                                        :loading="delete_loading">
                                        {{ $store.getters.t("delete") }}
                                    </v-btn>
                                    <v-btn @click="delete_dialog = false" color="secondary">
                                        {{ $store.getters.t("close") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import bus from "/src/utils/event_bus";
import { nameSort } from "/src/utils/basics.js";
export default {
    props: ["company"],
    data: () => ({
        loading: false,
        dialog: false,
        search: "",
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
    }),
    computed: {
        archived_projects() {
            const s = this.search.toLowerCase();
            return this.$store.state.v2.company.archived_projects
                .filter((ap) => !!ap)
                .filter(
                    (ap) =>
                        ap.name?.toLowerCase().includes(s) ||
                        ap.project_number?.toLowerCase().includes(s) ||
                        ap.client_name?.toLowerCase().includes(s) ||
                        ap.super_project?.toLowerCase().includes(s)
                )
                .sort((a, b) => nameSort(a.name, b.name));
        },
        disabled() {
            return this.loading;
        },
    },
    watch: {
        dialog(d) {
            if (d) this.loadArchived()
        }
    },
    methods: {
        loadArchived() {
            this.loading = true;
            this.$store.dispatch("v2/company/loadArchivedProjects")
                .finally(() => {
                    this.loading = false;
                });
        },
        unarchive(pr) {
            this.loading = true;
            this.dialog = false;
            this.$store
                .dispatch("v2/company/putProject", { ...pr, archived: false })
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: this.$store.getters.t("project_unarchived"),
                        color: "success",
                    });
                    this.$store.dispatch("v2/company/loadProjects");
                })
                .finally(() => {
                    bus.$emit("notification", {
                        timeout: 5000,
                        text: this.$store.getters.t("unarchive_warning"),
                        color: "warning",
                    });

                    this.loading = false;
                });
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_project() {
            this.delete_loading = true;
            this.$store
                .dispatch("v2/company/deleteProject", this.delete_uuid)
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Project deleted",
                        color: "success",
                    });
                    this.$store.dispatch("v2/company/loadProjects");
                })
                .finally(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
    },
};
</script>