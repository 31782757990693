<template>
    <div class="d-flex flex-column align-space-between mb-1 ml-1 mr-1">
        <v-btn large v-if="small" icon @click="dialog = true">
            <v-icon>mdi-nfc-variant</v-icon>
        </v-btn>
        <v-btn v-else small color="secondary" class="ml-1 mr-1" @click="dialog = true">
            <v-icon left>mdi-nfc-variant</v-icon>
            {{ $store.getters.t("rfid") }}
        </v-btn>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title class="headline"> {{
                        $store.getters.t("register_panel_rfid_tag")
                }} </v-card-title>
                <v-card-text>
                    {{ $store.getters.t("rfid_info_text") }}
                    <v-text-field label="RFID" v-model="rfid"></v-text-field>
                </v-card-text>
                <v-card-text v-if="show_scanner">
                    <qrcode-stream @decode="onDecode"></qrcode-stream>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="scanQR()" color="accent">
                        <v-icon left>mdi-qrcode</v-icon>
                        {{ $store.getters.t("scan_qr") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveRFID()" color="success" :disabled="loading" :loading="loading">
                        <v-icon left>mdi-check</v-icon>
                        {{ $store.getters.t("save") }}
                    </v-btn>
                    <v-btn @click="dialog = false" color="secondary">{{
                            $store.getters.t("close")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import bus from "/src/utils/event_bus";
import { QrcodeStream } from "vue-qrcode-reader";
export default {
    name: "ButtonRFIDRegister",
    components: {
        "qrcode-stream": QrcodeStream,
    },
    props: ["actual", "small"],
    data: () => ({
        dialog: false,
        rfid: "",
        show_scanner: false,
        loading: false,
    }),
    computed: {
        level() {
            return this.actual.level
        }
    },
    methods: {
        saveRFID() {
            this.loading = true;
            let meta = { ...this.actual.meta, rfid: this.rfid };
            let prom
            if (this.level == "assembly") {
                this.$store.dispatch("v2/constructibles/putAssembly", { meta })

            } else if (this.level == "module") {
                this.$store.dispatch("v2/constructibles/putModule", { meta })
            } else {
                throw "[!] Unanotated level in actual ButtonRFIDRegister!"
            }
            prom.then(() =>
                bus.$emit("notification", {
                    timeout: 1000,
                    text: "RFID tag code updated.",
                    color: "success",
                })
            ).catch((e) =>
                bus.$emit("notification", {
                    timeout: 1000,
                    text: "RFID tag code can't be updated." + e,
                    color: "error",
                })
            ).finally(() => {
                console.log("ended");
                this.loading = false;
                this.dialog = false;
            });
        },
        scanQR() {
            this.show_scanner = true;
        },
        onDecode(text) {
            this.rfid = text;
            this.show_scanner = false;
        },
        set_tag() {
            this.rfid = this.actual?.meta?.rfid || "";
        }
    },
    watch: {
        actual() {
            this.set_tag()
        },
    },
    mounted() {
        this.set_tag()
    },
};
</script>