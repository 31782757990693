var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filtered_constructibles,"item-key":"uuid","sort-by":"priority","footer-props":{
        'items-per-page-options': [10, 25, 50, 100, -1],
    },"items-per-page":50,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.selected_uuids.length == 0)?_c('div',{staticClass:"my-2"},[_c('br')]):_c('div',{staticClass:"mx-5 my-2"},[_c('ButtonSetScheduleDate',{attrs:{"uuids":_vm.selected_uuids},on:{"deleted":function($event){_vm.completed = []}}}),_c('ButtonDeleteActuals',{attrs:{"uuids":_vm.selected_uuids},on:{"deleted":function($event){_vm.selected = []}}}),(_vm.company_works_by_date && (_vm.selected_production_dates || _vm.selected_production_lines))?_c('DialogSortingActualsByDateAndPL',{attrs:{"production_dates":_vm.selected_production_dates,"production_lines":_vm.selected_production_lines}}):_vm._e(),_c('ButtonClearProof',{attrs:{"multiple":true,"actual_uuids":_vm.selected_uuids},on:{"completed":function($event){_vm.selected = []}}})],1)]},proxy:true},{key:"item.design_name",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"accent","text":"","small":"","to":{
        name: `constructibles_manager_${item.level}_detail`,
        query: _vm.$route.query,
        params: {
            ...item.level == 'assembly' && { ass_uuid: item.uuid },
            ...item.level == 'module' && { mod_uuid: item.uuid },
        }
    }}},[_vm._v(" "+_vm._s(item.design_name)+" "),_c('v-chip',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-clock-ascending")]),_vm._v(" "+_vm._s(item.priority)+" ")],1)],1)]}},{key:"item.uuid",fn:function({ item }){return [_vm._v(" "+_vm._s(item.uuid.substring(0, 8))+" ")]}},{key:"item.design",fn:function({ item }){return [_c('ButtonDrawerDesignDetail',{attrs:{"duuid":item.design,"level":item.level,"uuid":item.uuid,"name":item.design_name}})]}},{key:"item.scheduled_prod_date",fn:function({ item }){return [_vm._v(" "+_vm._s(item.scheduled_prod_date || "---")+" ")]}},{key:"item.project",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.get_project_name(item.project))+" ")]}},{key:"item.production_line",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.get_production_line_name(item.production_line))+" ")]}},{key:"item.status",fn:function({ item }){return [_c('ChipConstructibleStatus',{attrs:{"constructible":item}})]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","to":'/units/' + item.uuid}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-map-legend ")]),_vm._v(" "+_vm._s(_vm.$store.getters.t("view_meaning_action_of_view"))+" ")],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }