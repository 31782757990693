<template>
    <div>
        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height d-flex flex-column" v-if="modd">
                    <v-card-title>
                        {{ $store.getters.t("design") }}:
                        {{ modd.name }}
                    </v-card-title>
                    <v-card-text v-if="!design_metrics">
                        {{ $store.getters.t("missing_design_metadata") }}
                    </v-card-text>
                    <v-card-text v-else>
                        {{ $store.getters.t("revision") }}:
                        {{ modd.revision }}
                        {{ is_newest ? "(latest)" : "(old)" }}
                        <br />
                        {{ $store.getters.t("analysis") }}:
                        <IconAnalysisStatus :value="modd.meta.analysis" />
                        <br />
                        {{ $store.getters.t("width") }}:
                        {{ design_metrics.x }} mm,
                        {{ $store.getters.t("length") }}:
                        {{ design_metrics.y }} mm
                        <br />
                        {{ $store.getters.t("thickness") }}:
                        {{ design_metrics.z }} mm
                        <br />
                        {{ $store.getters.t("bruto_area") }}:
                        {{ design_metrics.bruto }}
                        m², {{ $store.getters.t("neto_area") }}:
                        {{ design_metrics.neto }}
                        m²
                        <br />
                        {{ $store.getters.t("tipo") }}:
                        {{ modd.tipo }}
                    </v-card-text>

                    <v-card-actions class="mt-auto align-end" v-if="modd">
                        <v-spacer></v-spacer>
                        <ButtonDialogDetails :modd="modd" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height">
                    <v-card-title>
                        {{ $store.getters.t("files") }}
                    </v-card-title>
                    <TablePackageFiles :pck="res_pck" />
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1" v-if="!hide_constructibles">
            <v-col>
                <v-card outlined>
                    <v-card-title>
                        {{ $store.getters.t("Constructibles") }}
                    </v-card-title>
                    <TableConstructiblesEmbedable :modules="modules" :hide_columns="['design_name', 'module_name']" />
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ButtonDeleteDesign v-if="modd_uuid" :uuid="modd_uuid" @completed="deleted" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TableConstructiblesEmbedable from "/src/components/constructibles/TableConstructiblesEmbedable";
import TablePackageFiles from "/src/components/files/TablePackageFiles";
import ButtonDialogDetails from "/src/components/designs/ButtonDialogDetails";
import ButtonDeleteDesign from "/src/components/designs/ButtonDeleteDesign";
import IconAnalysisStatus from "/src/components/renderers/IconAnalysisStatus";

export default {
    components: {
        TableConstructiblesEmbedable,
        TablePackageFiles,
        ButtonDialogDetails,
        ButtonDeleteDesign,
        IconAnalysisStatus,
    },
    props: {
        hide_constructibles: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    computed: {
        modd() {
            return this.$store.state.v2.selected.selected_modd;
        },
        modd_uuid() {
            return this.modd?.uuid || false;
        },
        res_pck() {
            return this.$store.getters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "resources",
                level: "module_design"
            })?.[0]
        },
        other_revisions() {
            return this.$store.state.v2.constructibles.module_designs.filter(
                (modd) => modd.name == this.modd.name
            );
        },
        is_newest() {
            let revs = this.other_revisions.map((rev) => rev.revision);
            return this.modd?.revision >= Math.max(...revs);
        },
        modules() {
            return this.$store.state.v2.constructibles.modules.filter(
                (mod) => mod.design == this.modd?.uuid
            );
        },
        design_metrics() {
            return {
                x: this.modd.meta?.metrics?.x || 0,
                y: this.modd.meta?.metrics?.y || 0,
                z: this.modd.meta?.metrics?.z || 0,
                bruto: this.modd.meta?.metrics?.bruto || 0,
                neto: this.modd.meta?.metrics?.neto || 0,
            };
        },
    },
    methods: {
        deleted() {
            this.$router.go(-1);
        },
    },
};
</script>