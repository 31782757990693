<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                {{ $store.getters.t("emails") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ $store.getters.t("emails") }}
            </v-card-title>

            <v-card-text>
                {{ $store.getters.t("emails_setup_help") }}
                <br />
                <br />
                <v-select outlined dense :label="$store.getters.t('email_when_msg')" v-model="noti_setup.email_when_msg"
                    :items="email_when_noti_options" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" :loading="loading" color="warning" @click="save">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    data: () => ({
        loading: false,
        dialog: false,
        noti_setup: {},
    }),
    computed: {
        user() {
            return this.$store.state.v2.session.user_object;
        },
        email_when_noti_options() {
            return [
                {
                    text: this.$store.getters.t("email_when_msg_none"),
                    value: "none",
                },
                {
                    text: this.$store.getters.t("email_when_msg_each_message"),
                    value: "each_message",
                },
                {
                    text: this.$store.getters.t(
                        "email_when_msg_each_message_5min"
                    ),
                    value: "each_message_5min",
                },
                {
                    text: this.$store.getters.t("email_when_msg_eod"),
                    value: "eod",
                },
            ];
        },
    },
    watch: {
        dialog(d) {
            if (d) this.refresh();
        },
    },
    methods: {
        save() {
            this.loading = true;
            return backend_api_v2
                .post("/my_user/patch_noti_setup/", {
                    noti_setup: this.noti_setup,
                })
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("v2/session/getUserObject");
                })
                .catch((e) =>
                    console.log(`Cant update notification setup: ${e}`)
                )
                .finally(() => (this.loading = false));
        },
        refresh() {
            this.noti_setup = { ...this.user.auth.noti_setup };
        },
    },
    mounted() {
        this.refresh();
    },
};
</script>