<template >
    <v-row class="d-flex my-3" :key="level + '_form_row'">
        <v-col>
            <h4 class="mb-2 font-weight-light grey--text">
                {{ $store.getters.t(level || "") }}
            </h4>
        </v-col>
        <v-col>
            <v-select outlined dense hide-hints v-model="setup.detection" :items="LEVEL_DETECTION"
                label="Detection system"></v-select>
        </v-col>
        <v-col>
            <v-combobox outlined dense hide-hints v-model="setup.match" :items="suggestions" label="Value"></v-combobox>
        </v-col>
    </v-row>
</template>


<script>
import {
    LEVEL_DETECTION,
    IFC_PROP_TYPES,
    IFC_CLASS_TYPES
} from "./definitions";
export default {
    props: ["value", "level"],
    data: () => ({
        "setup": {},
        LEVEL_DETECTION,
    }),
    computed: {
        suggestions() {
            if (this.setup.detection == "ifc_class") {
                return IFC_CLASS_TYPES
            }
            else {
                return IFC_PROP_TYPES
            }
        },
    },
    watch: {
        value: {
            handler(v) {
                this.setup = v
            },
            immediate: true,
        },
        setup(s) { this.$emit("input", s) }
    },
}
</script>