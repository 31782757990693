<template>
    <td class="text-center align-center">
        <div>
            <v-btn v-if="!edit_mode" :disabled="internal_value == 0 || loading" icon @click="sub_one" outlined
                color="error" small>
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <span class="title ma-2" @keyup.enter="save" @click="edit">
                <v-icon :value="true" v-if="loading"> mdi-loading mdi-spin </v-icon>
                <input ref="countInput" @focus="$event.target.select()" @blur="save" v-model="internal_value"
                    v-else-if="edit_mode" />
                <span v-else> {{ internal_value }} </span>
            </span>
            <v-btn v-if="!edit_mode" :disabled="internal_value == total_count || loading" icon @click="sum_one" outlined
                color="success" small>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
    </td>
</template>

<style lang="scss" scoped>
input {
    width: 60px;
    border: 1px solid rgb(143, 143, 143);
    border-radius: 3px;
}
</style>

<script>
import _ from "lodash";
export default {
    props: ["part"],
    data: () => ({
        loading: false,
        edit_mode: false,
        internal_value: 0
    }),
    computed: {
        ready_count() {
            return this.part.ready_count
        },
        total_count() {
            return this.part.count
        },
    },
    watch: {
        edit_mode(em) {
            if (em) this.$nextTick(() => this.$refs.countInput.focus())
        },
        ready_count(rc) { this.internal_value = rc },
        internal_value(iv) {
            if (iv != this.ready_count) this.updateReadyCount(iv)
        },
    },
    methods: {
        save() {
            this.internal_value = Number(this.internal_value);
            if (this.internal_value < 0) this.internal_value = 0
            if (this.internal_value > this.total_count) this.internal_value = this.total_count
            this.edit_mode = false;
        },
        edit() {
            this.edit_mode = true;
        },
        updateReadyCount: _.debounce(function (new_ready_count) {
            this.loading = true
            const payload = {
                part_uuids: this.part.uuids,
                ready_count: new_ready_count,
            };
            this.$store.dispatch("v2/selected_logistics/setReadyPartCount", payload)
                .then(() => this.loading = false)
        }, 1000),
        sum_one() {
            this.internal_value += 1
        },
        sub_one() {
            this.internal_value -= 1
        },
    },
    mounted() {
        this.internal_value = this.ready_count
    },
};
</script>