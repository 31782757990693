<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card outlined class="fill-height">
                    <v-card-title>
                        {{ $store.getters.t("production_issues") }}
                    </v-card-title>
                    <v-card-text v-if="loading">
                        <v-skeleton-loader type="text@3"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else>
                        {{ $store.getters.t("issues") }}: {{ issues_count }}
                        <br />
                        {{ $store.getters.t("anno_cat_quality_control") }}:
                        {{ qc_count }} <br />
                        {{ $store.getters.t("anno_cat_prod_issue") }}:
                        {{ pr_count }} <br />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined class="fill-height">
                    <!-- <v-card-title> Full List </v-card-title> -->
                    <v-card-text v-if="loading">
                        <v-skeleton-loader type="text@3"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else>
                        <IssuesTable />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- Drawer -->
        <DrawerIssueDetail />
    </v-container>
</template>

<script>
import DrawerIssueDetail from "../../components/issues/DrawerIssueDetail.vue"
import IssuesTable from "/src/components/issues/IssuesTable.vue"

export default {
    components: {
        IssuesTable,
        DrawerIssueDetail
    },
    data: () => ({ loading: false }),
    computed: {
        project() {
            return this.$store.state.v2.selected.selected_project
        },
        issues() {
            return this.$store.state.v2.records.issues
        },
        issues_count() {
            return this.issues.length
        },
        qc_count() {
            return this.issues.filter((a) => a.category == "quality_control").length
        },
        pr_count() {
            return this.issues.filter((a) => a.category == "prod_issue").length
        },
    },
    watch: {
        project() {
            this.load_issues()
        },
    },
    methods: {
        load_issues() {
            if (this.project) {
                this.loading = true
                this.$store
                    .dispatch("v2/records/loadIssues", this.project.uuid)
                    .finally(() => (this.loading = false))
            }
        },
    },
    mounted() {
        this.load_issues()
    },
}
</script>