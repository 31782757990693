
import store from '/src/store'
import bus from '/src/utils/event_bus'
import INITIAL_STATE from "/src/utils/defaults";
import { state_init } from "./state_init";


const viewerMode = function (to, from, next) {
    store.commit("setViewerMode");
    next()
}

const logisticsMode = function (to, from, next) {
    store.commit("setLogisticsMode");
    next()
}

const managerMode = function (to, from, next) {
    store.commit("setManagerMode");
    next()
}


const onlyManagersGuard = function (to, from, next) {
    let auth = store.state.v2.session.user_object.auth
    if (auth.company_admin || auth.company_manager || auth.is_superuser) {
        next()
    } else {
        bus.$emit('notification', { timeout: 3000, text: "You don't have permissions to enter this view. Contact your company admin", color: "error" })
        next(false)
    }
}

const onlyAdminsGuard = function (to, from, next) {
    let auth = store.state.v2.session.user_object.auth
    if (auth.company_admin || auth.is_superuser) {
        next()
    } else {
        bus.$emit('notification', { timeout: 3000, text: "You don't have permissions to enter this view. Contact your company admin", color: "error" })
        next(false)
    }
}


const hasSessionObjectGuard = function (to, from, next) {
    if (store.state.v2.session.user_object._flag == "not-initialized") {
        store.dispatch('v2/session/getUserObject')
            .then(() => {
                store.dispatch('v2/company/loadProjects')
                store.dispatch('v2/company/loadStandardLibraries')
                next()
            })
    } else {
        next()
    }
}

const check_login_subroutine = function (to, next) {
    const is_android = INITIAL_STATE.IS_ANDROID
    // Android lock
    if (is_android && !store.getters["v2/session/androidLicensed"]) {
        bus.$emit('notification', { timeout: 3000, text: "Don't have Android license enabled for this user. Contact your company admin.", color: "error" })
        next({ name: 'login', params: { redirect_route: to.path } })
    }
    // Auth lock
    else if (store.state.v2.session.authenticated) {
        next()
    } else {
        console.log("here add prop redirect to the next route")
        next({ name: 'login', params: { redirect_route: to.path } })
    }
}

const isAuthenticated = function (to, from, next) {

    if (store.state.v2.session.user_object._flag == "not-initialized") {
        // Get user
        console.log("[i] Initializing user object.")
        store.dispatch('v2/session/getUserObject')
            .then(() => {
                check_login_subroutine(to, next)
            })
    } else {
        check_login_subroutine(to, next)
    }


}



const hasModuleForecast = function (to, from, next) {
    let company = store.state.v2.session.user_object.auth.company
    if (company.m_prod_management) {
        next()
    } else {
        bus.$emit('notification', { timeout: 3000, text: "Can't access Forecast Module. Contact your company admin", color: "warning" })
        next(false)
    }
}


const hasModuleProduction = function (to, from, next) {
    let company = store.state.v2.session.user_object.auth.company
    if (company && company.m_core) {
        next()
    } else {
        bus.$emit('notification', { timeout: 3000, text: "Can't access Production Module. Contact your company admin", color: "warning" })
        next(false)
    }
}


const PROD_USER_VIEWS = [
    "home",
    "login",
    "qr",
    "account",
    "project_viewer",
    "viewer",
    "assd_viewer",
    "ass_viewer",
    "modd_viewer",
    "mod_viewer",
]


// MAIN ASYNC STORE ACTIONS DISPATCHER
// ALL ASYNC LOADERS CAN BE CENTALIZED HERE IF NEEDED
const asyncStoreDispatcher = function (to, from, next) {

    // Initial state only on first load
    if (from.name == null) state_init(store, to)


    // Promise list for route
    let promises = []

    // Flags
    let go_to_first = false

    // Determine UUIDs & loading flags
    const current_pr = store.state.v2.selected.selected_project?.uuid
    const following_pr = to.params?.project_uuid
    const load_pr = following_pr && current_pr != following_pr

    const current_mod = store.state.v2.selected.selected_mod?.uuid
    const following_mod = to.params?.mod_uuid
    const load_mod = following_mod && current_mod != following_mod
    const clean_mod = !following_mod

    const current_ass = store.state.v2.selected.selected_ass?.uuid
    const following_ass = to.params?.ass_uuid
    const load_ass = following_ass && current_ass != following_ass
    const clean_ass = !following_ass

    const current_modd = store.state.v2.selected.selected_modd?.uuid
    const following_modd = to.params?.modd_uuid
    const load_modd = following_modd && current_modd != following_modd

    const current_assd = store.state.v2.selected.selected_assd?.uuid
    const following_assd = to.params?.assd_uuid
    const load_assd = following_assd && current_assd != following_assd



    // Project loader
    // This only works if the project changes actually in route
    if (load_pr) {
        store.commit("pushLoading", "project")

        console.log("[v] Selecting project in route watcher. Will launch store selector")


        promises.push(store.dispatch("v2/selected/loadProject", following_pr))
        promises.push(store.dispatch("v2/constructibles/loadMyFirstConstructible", following_pr))
        go_to_first = store.getters["v2/session/iam_prod_worker"] && !PROD_USER_VIEWS.includes(from.name)

    }

    // Set viewer mode
    if (following_mod || following_modd) store.commit("v2/viewer/setLevel", "module")
    if (following_ass || following_assd) store.commit("v2/viewer/setLevel", "assembly")

    // ALWAYS remove designs mode for prod workers
    if (store.getters["v2/session/iam_prod_worker"]) store.commit("v2/viewer/setDesignsMode", false);

    // Load selections for viewer
    if (load_mod) {
        store.commit("pushLoading", "constructible_" + following_mod)
        console.log("[v] Loading module through route watcher.")
        promises.push(store.dispatch("v2/selected/loadModule", following_mod))
    }
    if (clean_mod) {
        store.commit("v2/selected/setModule", null)
    }
    if (load_ass) {
        store.commit("pushLoading", "constructible_" + following_ass)
        console.log("[v] Loading assembly through route watcher.")
        promises.push(store.dispatch("v2/selected/loadAssembly", following_ass))
    }
    if (clean_ass) {
        store.commit("v2/selected/setAssembly", null)
    }
    if (load_modd) {
        store.commit("pushLoading", "constructible_" + following_modd)
        console.log("[v] Loading module design through route watcher.")
        promises.push(store.dispatch("v2/selected/loadModuleDesign", following_modd))
    }
    if (load_assd) {
        store.commit("pushLoading", "constructible_" + following_assd)
        console.log("[v] Loading assembly design through route watcher.")
        promises.push(store.dispatch("v2/selected/loadAssemblyDesign", following_assd))
    }


    // IMPORTANTE. SI ESTA EN EL VIEWER Y ES PROD USER, AL CARGAR PRIMER PANEL
    // SE TIENE QUE REDIRIGIR AL USER AL PRIMER PANEL (O MODULO)

    Promise.all(promises)
        // redirect to viewer if prod user
        .then(() => {
            if (go_to_first) next({ name: "viewer" })
        })
        // Finally call next() when all promises ran
        .finally(() => {
            store.commit("popLoading", "project")
            store.commit("popLoadingIncludes", "constructible")
            next()
        })
}


const afterEachGuard = function (to) {
    store.commit("setRouteName", to.name)
    document.title = `produuz.it · ${to.name}`
}

export {
    viewerMode,
    managerMode,
    logisticsMode,
    onlyManagersGuard,
    onlyAdminsGuard,
    hasSessionObjectGuard,
    isAuthenticated,
    hasModuleForecast,
    hasModuleProduction,
    asyncStoreDispatcher,
    afterEachGuard,
}