<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                clearable
                @click:clear="clear()"
                :value="value"
                :label="label"
                dense
                outlined
                class="mx-1"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker v-model="internal" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="save()"> OK </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: ["value", "label"],
    data: () => ({ internal: "", menu: false }),
    watch: {
        value(v) {
            this.internal = v;
        },
    },
    methods: {
        save() {
            this.$emit("input", this.internal);
            this.menu = false;
        },
        clear() {
            this.$emit("input", null);
        },
    },
    mounted() {
        this.internal = this.value;
    },
};
</script>