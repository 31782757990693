<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-if="icon != undefined" icon>
                <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-else color="error" text class="mr-2">
                <v-icon left>mdi-delete-forever</v-icon>
                {{ $store.getters.t("delete_design") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("delete_design") }}
            </v-card-title>
            <v-card-text v-if="multiple">
                {{ $store.getters.t("delete_design_warning_multiple") }}:
                <ul>
                    <li v-for="d in designs_multiple" :key="d.uuid">
                        {{ d.name }} [rev {{ d.revision }}] [{{ d.uuid }}]
                    </li>
                </ul>
            </v-card-text>
            <v-card-text v-else-if="design">
                {{ $store.getters.t("delete_design") }}
                <ul>
                    <li>{{ design.name }} [{{ design.uuid }}]</li>
                </ul>
                {{ $store.getters.t("delete_design_warning") }}:
            </v-card-text>
            <v-card-text>
                <v-checkbox v-model="delete_files" :label="$store.getters.t('delete_also_files') + ':'" />
                <ul v-if="filenames.length != 0">
                    <li v-for="(f, i) in filenames" :key="i">{{ f }}</li>
                </ul>
                <span v-else> - No files - </span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="delete_design()" color="error" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("delete") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: ["uuid", "icon", "multiple", "uuids"],
    data: () => ({
        dialog: false,
        loading: false,
        delete_files: false,
    }),
    computed: {
        design() {
            return this.$store.getters["v2/constructibles/allDesigns"].find(
                (d) => d.uuid == this.uuid
            );
        },
        designs_multiple() {
            return this.$store.getters["v2/constructibles/allDesigns"].filter(
                (d) => this.uuids.includes(d.uuid)
            );
        },
        pck() {
            return this.$store.getters["v2/cloudstorage/resourcesPackage"];
        },
        design_names() {
            if (this.multiple) {
                return this.designs_multiple.map((d) => d.name);
            } else {
                return this.design ? [this.design.name] : [];
            }
        },
        files() {
            return this.pck?.files?.filter((cf) =>
                // this.design_names.includes(cf.short_name)
                this.design_names.includes(cf.meta.design_name)
            );
        },
        filenames() {
            return (
                this.files
                    ?.map((cf) => `${cf.name} v${cf.meta?.version}`)
                    .sort() || []
            );
        },
    },
    methods: {
        async remove_files_from_project() {
            let promises = [];
            this.files.forEach((cf) => {
                promises.push(
                    this.$store.dispatch(
                        "v2/cloudstorage/deleteCloudFile",
                        cf.uuid
                    )
                );
            });
            return Promise.all(promises);
        },
        async delete_design() {
            this.loading = true;
            if (this.delete_files) await this.remove_files_from_project();
            const uuids = this.multiple ? this.uuids : [this.uuid];
            this.$store
                .dispatch("v2/constructibles/multiDeleteDesigns", uuids)
                .finally(() => {
                    this.loading = false;
                    this.$emit("completed");
                });
            this.dialog = false;
        },
    },
};
</script>
