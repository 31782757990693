<template>
    <v-select
        class="ma-2"
        outlined
        dense
        :label="$store.getters.t('document_status')"
        :items="statuses"
        v-model="selected_status"
    >
    </v-select>
</template>

<script>
const STATUSES = ["for_information", "for_ordering", "temporary"];
export default {
    props: ["value"],
    data: () => ({ selected_status: "for_information" }),
    computed: {
        statuses() {
            return STATUSES.map((sta) => ({
                text: this.$store.getters.t("rep_status_" + sta),
                value: sta,
            }));
        },
    },
    watch: {
        value: {
            handler: function (v) {
                this.selected_status = v;
            },
            immediate: true,
        },
        selected_status(status) {
            this.$emit("input", status);
        },
    },
};
</script>