<template>
    <div class="fill-height viewer-layout">
        <div>
            <h1 class="display-1 grey--text ma-5">
                <!-- <v-icon x-large color="grey"> mdi-arrow-left </v-icon> -->
                {{ $store.getters.t("select_item") }}
            </h1>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<script>
export default {

}
</script>