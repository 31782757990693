<template>
    <div class="scrollmenu ifc-div" :class="{
        'd-flex': !hide,
        'flex-column': !hide,
        'flex-grow-1': !hide,
        backgrounded: hide,
    }">
        <div class="floating-controls d-flex flex-column flex-grow-1">
            <div class="d-flex flex-grow-1">
                <v-spacer></v-spacer>
                <SelectorAssemblies />
                <v-btn icon class="mr-5" @click="clipping = !clipping" :color="clipping ? 'accent' : 'darkgrey'">
                    <v-icon>mdi-arrow-expand-up</v-icon>
                </v-btn>
                <v-btn icon @click="recenter3D" class="mr-5">
                    <v-icon>mdi-image-filter-center-focus</v-icon>
                </v-btn>
            </div>
            <div class="d-flex flex-grow-1 mt-2">
                <v-spacer></v-spacer>
                <!-- <SelectorFace /> -->
            </div>
        </div>
        <IFCJSViewModule :urls="urls" />
    </div>
</template>

<style lang="scss" scoped>
.floating-controls {
    position: absolute;
    right: 0;
    top: 10px;
}

.ifc-div {
    position: relative;
}

.backgrounded {
    display: none !important;
}
</style>

<script>
import SelectorAssemblies from "./SelectorAssemblies.vue";
import bus from "/src/utils/event_bus";
import IFCJSViewModule from "./IFCJSViewModule.vue";
export default {
    components: { SelectorAssemblies, IFCJSViewModule },
    props: ["hide"],
    data: () => ({ clipping: false }),
    computed: {
        urls() {
            let wifc = this.$store.getters["v2/viewer/getIFC"];
            if (wifc) return [wifc.presigned_url];
            else return [];
        },
    },
    methods: {
        recenter3D() {
            bus.$emit("events/viewer/recenter_3d");
        },
    },
    watch: {
        clipping(c) {
            bus.$emit("events/viewer/3d_clip_plane", c);
        },
    },
};
</script>