<template>
    <div>
        <div class="d-flex my-3">
            <h4 class="mb-2 font-weight-light grey--text">
                {{ $store.getters.t("docs_as_text") }}
            </h4>
            <v-spacer> </v-spacer>
            <v-btn small text @click="add_item()">
                <v-icon left> mdi-plus </v-icon>
                ADD
            </v-btn>
        </div>
        <form ref="form">
            <div class="d-flex flex-row" v-for="(spec, i) in specs" :key="i">
                <v-text-field class="mx-1" outlined v-model="spec.name" label="Name">
                </v-text-field>
                <v-textarea class="mx-1" outlined v-model="spec.text" label="Text">
                </v-textarea>
                <v-btn small class="ml-3 mt-1" icon @click="delete_item(param.value, i)">
                    <v-icon> mdi-minus </v-icon>
                </v-btn>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data: () => ({
        specs: [],
    }),
    methods: {
        add_item() {
            // param is the name of the param like "wood" or whatever.
            if (!this.specs) this.specs = [];
            this.specs.push({ name: "", text: "", });
        },
        delete_item(i) {
            this.specs.splice(i, 1);
        },
    },
};
</script>