<template>
    <v-btn-toggle class="mx-2" v-model="selected_level" rounded>
        <v-btn v-for="l in levels" :key="l.value" :value="l.value" small> {{ l.text }} </v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        selected_level: "all",
        levels: [
            { text: "All", value: "all" },
            { text: "Modules", value: "module" },
            { text: "Assemblies", value: "assembly" },
        ]
    }),
    watch: {
        "$route.query.level": {
            immediate: true,
            handler: function (level) {
                this.selected_level = level
            },
        },
        value(v) {
            this.selected_level = v
        },
        selected_level: {
            immediate: true,
            handler: function (nl) {
                let level = nl
                if (!nl) level = "all"
                if (this.$route.query.level == level) return
                this.$router.push({ name: this.$route.name, query: { level } })
                this.$emit("input", level)
            }
        }
    }
};
</script>