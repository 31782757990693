<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-if="icon != undefined" icon>
                <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" v-else color="accent" text class="mr-2">
                <v-icon left>mdi-delete-forever</v-icon>
                {{ $store.getters.t("delete") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("delete_files") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("delete_files_warning") }}:
                <ul>
                    <li v-for="cf in cfs" :key="cf.uuid">
                        {{ cf.name }} [v{{ cf.meta.version }}]
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="delete_files()" color="error" :loading="loading" :disabled="loading">
                    {{ $store.getters.t("delete") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>






<script>
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "../../utils/apiv2";
export default {
    props: ["cfs", "icon"],
    data: () => ({
        loading: false,
        dialog: false,
    }),
    computed: {
        uuids() {
            return this.cfs.map(cf => cf.uuid)
        },
    },
    methods: {
        delete_files() {
            this.loading = true
            this.dialog = false
            Promise.all(
                this.cfs.map(cf => backend_api_v2.delete(`cloudstorage/files/${cf.uuid}/`))
            )
                .then(() =>
                    bus.$emit("events/cloudstorage/reloadProjectPackages")
                )
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Can't delete some files!",
                        color: "error",
                    });
                }).finally(() => {
                    this.loading = false
                    this.$emit("completed")
                })
        },
    }
}
</script>