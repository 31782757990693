<template>
    <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                    <v-icon> mdi-swap-horizontal </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("change_company") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card :loading="loading ? 'accent' : false">
            <v-card-title class="headline">
                {{ $store.getters.t("change_company") }}
            </v-card-title>
            <v-alert outlined type="error" class="my-3 mx-9">
                RESTRICTED FEATURE FOR PRODUUZ.IT STAFF
            </v-alert>
            <v-card-text v-if="companies.length == 0 && loading">
                <v-skeleton-loader type="list-item-avatar-three-line@2">
                </v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
                <v-container>
                    <v-row>
                        <v-col cols="3" v-for="company in companies" :key="company.uuid">
                            <v-card @click="change_company(company.uuid)" outlined :color="selected_company == company.uuid ? 'accent' : ''
                                " width="200">
                                <v-img v-if="company.logo_url" contain height="70" :src="company.logo_url" />
                                <v-avatar height="70" v-else> <v-icon> mdi-factory </v-icon> </v-avatar>
                                <v-card-subtitle class="text-truncate">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                {{ company.name }}
                                            </span>
                                        </template>
                                        {{ company.name }}
                                    </v-tooltip>
                                </v-card-subtitle>
                            </v-card> </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import _ from "lodash"
import { backend_api_v2 } from "/src/utils/apiv2"
import { nameSort } from "/src/utils/basics.js";
// import bus from "/src/utils/event_bus"
export default {
    data: () => ({
        loading: false,
        dialog: false,
        companies: [],
    }),
    computed: {
        selected_company() {
            return this.$store.state.v2.session.user_object?.auth?.company?.uuid
        }
    },
    methods: {
        change_company(company) {
            this.loading = true
            backend_api_v2
                .post("/staff/set_my_company/", { company })
                .then(() => {
                    this.dialog = false
                    this.loading = false
                    this.$store.dispatch("initialLoad")
                    this.$store.dispatch("refreshBaseUserData", false)
                })
        },
    },
    watch: {
        dialog(d) {
            if (d) {
                this.loading = true
                backend_api_v2
                    .get("/staff/companies/")
                    .then(({ data }) => this.companies = data.sort((a, b) => nameSort(a.name, b.name)))
                    .then(() => {
                        this.loading = false
                    })
            } else {
                this.$emit("close")
            }
        },
    },
}
</script>