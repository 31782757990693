import { backend_api_v2 } from '/src/utils/apiv2'


export default {
    namespaced: true,
    state: {
        langs: {},
        selected_lang: "en",
    },
    mutations: {
        setLang: (state, payload) => {
            state.langs = { ...state.langs }
            state.langs[payload.lang] = payload.data
        },
        setSelectedLang: (state, lang) => state.selected_lang = lang
    },
    actions: {
        loadLang(context, lang) {
            context.commit("setSelectedLang", lang)
            if (lang in context.state.langs) return
            return backend_api_v2.get('/translations/' + lang)
                .then(({ data }) => {
                    context.commit('setLang', { lang, data })
                })
                .catch(e => console.log(`Cant load lang: ${e}`))
        },
    },
    getters: {
        translate: state => key => state.langs[state.selected_lang]?.[key] || key.replaceAll("_", " ")
    }
}