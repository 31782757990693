<template>
    <div class="mx-1 mb-1">
        <v-btn v-for="cf in button_cfs" :key="cf.uuid" width="100%" color="accent" class="mt-1" small
            @click="selectCF(cf.uuid)">
            {{ cf.short_name }}
        </v-btn>
        <!-- Start of extra linked file packages -->
        <v-btn v-for="pck in extra_pcks" :key="pck.uuid" width="100%" color="primary" class="mt-1" small
            @click="selectCF(pck.files[0].uuid)">
            {{ pck.name }}
        </v-btn>
        <v-dialog v-model="detail_dialog" fullscreen hide-overlay transition="dialog-bottom-transition"
            @click="detail_dialog = false">
            <v-card>
                <v-toolbar dense dark color="secondary" class="fronted">
                    <v-toolbar-title>{{ detail_name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="recenterPDF">
                            <v-icon>mdi-image-filter-center-focus</v-icon>
                        </v-btn>
                        <v-btn icon dark @click="detail_dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div class="pa-5" v-if="text">{{ text }}</div>
                <PDFView v-else-if="url && !picture_mode" :url="url" name="dialog_detail" />
                <PictureView v-else-if="url && picture_mode" :url="url" />
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss" scoped>
.fronted {
    z-index: 10;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import { nameSort } from "/src/utils/basics";
import bus from "/src/utils/event_bus";
import PDFView from "../PDFView.vue";
import { EXTRA_PCKS_FOR_VIEWER } from "../setup";
import { cloudfile_is_picture } from "/src/utils/resources";
export default {
    components: { PDFView },
    data: () => ({ detail_dialog: false, cf_uuid: "" }),
    computed: {
        // Buttons
        ...mapGetters({
            pcks: "v2/viewer/allPackages",
            res_pck: "v2/viewer/resPackage",
        }),
        ...mapState({
            mode: "v2.viewer.right_panel_mode",
        }),
        panel_mode() {
            return this.$store.state.v2.viewer.right_panel_mode;
        },
        details() {
            return this.res_pck?.files.filter(cf => cf.meta.section == "detail") || []
        },
        docs() {
            return this.res_pck?.files.filter(cf => cf.meta.section == "doc") || []
        },
        button_cfs() {
            if (this.panel_mode == "details") {
                return [...this.details].sort((a, b) => nameSort(a.short_name, b.short_name))
            } else {
                return [...this.docs].sort((a, b) => nameSort(a.short_name, b.short_name))
            }
        },
        extra_pcks() {
            if (this.panel_mode == "details") return [];
            return this.pcks.filter(pck => EXTRA_PCKS_FOR_VIEWER.includes(pck.name))
        },

        // Viewer itself
        cf() {
            let cfs = this.pcks.map(pck => pck.files).flat(1)
            return cfs.find(cf => cf.uuid == this.cf_uuid)
        },
        url() {
            return this.cf?.presigned_url
        },
        text() {
            return this.cf?.meta?.text
        },
        detail_name() {
            return this.cf?.short_name
        },
        picture_mode() {
            return cloudfile_is_picture(this.cf)
        },
        pdf_mode() {
            return this.$store.state.v2.viewer.pdf_mode;
        },
    },
    methods: {
        selectCF(cf_uuid) {
            this.cf_uuid = cf_uuid;
            this.detail_dialog = true;
        },
        recenterPDF() {
            bus.$emit("events/viewer/recenter_pdf_dialog_detail");
        },
    },
};
</script>