<template>
    <v-dialog v-model="dialog" max-width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="icon == undefined" color="error" :loading="loading" :disabled="loading">
                <v-icon left>mdi-delete</v-icon>
                {{ $store.getters.t("delete") }}
            </v-btn>
            <v-btn v-bind="attrs" v-on="on" icon v-else :loading="loading" :disabled="loading">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">{{
                $store.getters.t("delete_annotation_question")
            }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="deleteIssue()" color="error">
                    <v-icon left>mdi-delete</v-icon>
                    {{ $store.getters.t("delete") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ButtonIssueDelete",
    props: ["issue", "icon"],
    data: () => ({
        dialog: false,
        loading: false,
    }),
    methods: {
        deleteIssue() {
            this.loading = true;
            this.$store
                .dispatch("v2/records/deleteIssue", this.issue.uuid)
                .then(() => this.$emit("deleted"))
                .finally(() => (this.loading = false));
            this.dialog = false;
        },
    },
};
</script>