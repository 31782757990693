<template>
    <v-card outlined class="fill-height">
        <v-card-title> {{ $store.getters.t("styles") }} </v-card-title>
        <v-card-subtitle> {{ $store.getters.t("company_files_for_ui") }} </v-card-subtitle>
        <v-card-text>
            <div class="d-flex">
                <span class="mr-2 my-1">
                    <IconBoolean :value="has_company_logo" /> {{ $store.getters.t("logo") }}
                </span>
                <v-spacer></v-spacer>
                <DialogThumbnails class="mx-2" v-if="has_company_logo" title="company_logo"
                    :files="files.filter(cf => cf.meta.section == 'logo')" />
                <UploadFilePackageField pictures label="logo" v-model="pck_uuid" section="logo" small :package_params="{
                    company: company_uuid,
                    name: 'resources',
                }" />
            </div>
            <div class="d-flex">
                <span class="mr-2 my-1">
                    <IconBoolean :value="has_company_banner" />
                    {{ $store.getters.t("sticker_banner") }}
                </span>
                <span class="ml-2 my-1"> (1000*160 px) </span>
                <v-spacer></v-spacer>
                <DialogThumbnails class="mx-2" title="company_banners" v-if="has_company_banner"
                    :files="files.filter(cf => cf.meta.section == 'banner')" />
                <UploadFilePackageField pictures multiple label="banners" v-model="pck_uuid" section="banner" small
                    :package_params="{
                        company: company_uuid,
                        name: 'resources',
                    }" />
            </div>
            <div class="d-flex">
                <span class="mr-2 my-1">
                    <IconBoolean :value="has_company_color" /> Corporate color:
                </span>
                <v-spacer></v-spacer>
                <v-btn @click="color_dialog = true" icon>
                    <v-icon> mdi-palette </v-icon>
                </v-btn>
                <v-btn @click="delete_color" :loading="deleting_color" :disabled="deleting_color || !has_company_color"
                    icon>
                    <v-icon> mdi-delete </v-icon>
                </v-btn>
            </div>
        </v-card-text>
        <v-dialog v-model="color_dialog" width="300">
            <v-card>
                <v-color-picker v-model="company_color" mode="hexa"></v-color-picker>
                <v-card-actions>
                    <v-spacer> </v-spacer>
                    <v-btn color="success" @click="save_color" :loading="saving_color" :disabled="saving_color">
                        {{ $store.getters.t("save") }}
                    </v-btn>
                    <v-btn color="secondary" @click="color_dialog = false">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import bus from "/src/utils/event_bus"
import IconBoolean from "/src/components/renderers/IconBoolean"
import UploadFilePackageField from "../fields/uploads/UploadFilePackageField.vue"
import DialogThumbnails from "../renderers/DialogThumbnails.vue"
export default {
    components: {
        IconBoolean,
        UploadFilePackageField,
        DialogThumbnails
    },
    data: () => ({
        pck_uuid: null,
        color_dialog: false,
        company_color: "",
        saving_color: false,
        deleting_color: false,
    }),
    computed: {
        company() { return this.$store.state.v2.company.company },
        company_uuid() { return this.company?.uuid },
        meta() { return this.company?.meta || {} },
        pck() {
            return this.$store.getters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "resources",
                level: "company",
            })?.[0]
        },
        files() { return this.pck?.files || [] },
        has_company_logo() { return this.files.some(cf => cf.meta.section == "logo") },
        has_company_banner() { return this.files.some(cf => cf.meta.section == "banner") },
        has_company_color() { return this.meta?.company_color },
    },
    watch: {
        color_dialog(d) {
            if (d) this.company_color = this.meta.company_color
        },
        pck() {
            // Set data pck_uuid because it's writable by upload component
            this.pck_uuid = this.pck?.uuid
        },
        pck_uuid() {
            // If it's written, we need to reload packages because now it exists.
            bus.$emit("events/cloudstorage/reloadCompanyPackages")
        },
    },
    methods: {
        save_color() {
            this.saving_color = true
            let meta = { ...this.company.meta }
            meta.company_color = this.company_color?.hex ? this.company_color.hex : this.company_color
            this.$store
                .dispatch("v2/company/patchCompany", { meta })
                .finally(() => {
                    bus.$emit("notification", {
                        timeout: 3000,
                        text: `Color saved.`,
                        color: "info",
                    })
                    this.color_dialog = false
                    this.saving_color = false
                })
        },
        delete_color() {
            this.deleting_color = true
            let meta = { ...this.company.meta }
            delete meta.company_color
            this.$store
                .dispatch("v2/company/patchCompany", { meta })
                .finally(() => {
                    bus.$emit("notification", {
                        timeout: 3000,
                        text: `Color removed.`,
                        color: "info",
                    })
                    this.deleting_color = false
                })
        }
    },
}
</script>