import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
// import bus from '/src/utils/event_bus'
// import router from '/src/router'

// THIS MODULE SHOULD HOLD PROJECT CONSTUCTIBLES. NOT MORE NOT LESS.
// AS AN EXTRA, SHOULD HOLD STDLIB DESIGNS.

export default {
    namespaced: true,
    state: {
        // Designs
        module_designs: [],
        assembly_designs: [],
        part_designs: [],
        // STDLIB designs
        stdlib_module_designs: [],
        stdlib_assembly_designs: [],
        // Actuals
        batches: [],
        modules: [],
        assemblies: [],
        parts: [],
    },
    mutations: {
        // Designs
        setModuleDesigns: base.mutations.setter("module_designs"),
        createModuleDesign: base.mutations.creator("module_designs"),
        updateModuleDesign: base.mutations.updater("module_designs"),
        deleteModuleDesign: base.mutations.deleter("module_designs"),

        setAssemblyDesigns: base.mutations.setter("assembly_designs"),
        createAssemblyDesigns: base.mutations.creator("assembly_designs"),
        updateAssemblyDesign: base.mutations.updater("assembly_designs"),
        deleteAssemblyDesigns: base.mutations.deleter("assembly_designs"),


        // FOR NOW PART DESIGNS ARE NOT USED DIRECTLY
        // setPartDesigns: base.mutations.setter("part_designs"),
        // createPartDesign: base.mutations.creator("part_designs"),
        // updatePartDesign: base.mutations.updater("part_designs"),
        // deletePartDesign: base.mutations.deleter("part_designs"),

        // Multi mutations for Designs

        // Actuals
        setBatches: base.mutations.setter("batches"),
        createBatch: base.mutations.creator("batches"),
        updateBatch: base.mutations.updater("batches"),
        deleteBatch: base.mutations.deleter("batches"),

        setModules: base.mutations.setter("modules"),
        createModule: base.mutations.creator("modules"),
        updateModule: base.mutations.updater("modules"),
        deleteModule: base.mutations.deleter("modules"),

        setAssemblies: base.mutations.setter("assemblies"),
        createAssembly: base.mutations.creator("assemblies"),
        updateAssembly: base.mutations.updater("assemblies"),
        deleteAssembly: base.mutations.deleter("assemblies"),

        setParts: base.mutations.setter("parts"),
        createPart: base.mutations.creator("parts"),
        updatePart: base.mutations.updater("parts"),
        deletePart: base.mutations.deleter("parts"),


        // GENERAL MULTIPLE DELETION
        multiDeleteByUuids: (state, uuids) => {
            Object.keys(state).forEach((k) => state[k] = state[k].filter(d => !uuids.includes(d.uuid)))
        },
    },
    actions: {
        // Designs
        loadModuleDesigns: base.actions.loader_pr("constructibles/module_designs/", "ModuleDesigns"),
        loadModuleDesignsByDate: base.actions.loader_date("constructibles/module_designs/", "ModuleDesigns"),
        postModuleDesign: base.actions.poster("constructibles/module_designs/", "ModuleDesign"),
        putModuleDesign: base.actions.putter("constructibles/module_designs/", "ModuleDesign"),
        deleteModuleDesign: base.actions.deleter("constructibles/module_designs/", "ModuleDesign"),
        refreshModuleDesign: base.actions.refresher("constructibles/module_designs/", "ModuleDesign"),
        refreshModuleDesigns: base.actions.multi_refresher("constructibles/module_designs/", "ModuleDesign"),

        loadAssemblyDesigns: base.actions.loader_pr("constructibles/assembly_designs/", "AssemblyDesigns"),
        loadAssemblyDesignsByDate: base.actions.loader_date("constructibles/assembly_designs/", "AssemblyDesigns"),
        postAssemblyDesign: base.actions.poster("constructibles/assembly_designs/", "AssemblyDesign"),
        putAssemblyDesign: base.actions.putter("constructibles/assembly_designs/", "AssemblyDesign"),
        deleteAssemblyDesign: base.actions.deleter("constructibles/assembly_designs/", "AssemblyDesign"),
        refreshAssemblyDesign: base.actions.refresher("constructibles/assembly_designs/", "AssemblyDesign"),
        refreshAssemblyDesigns: base.actions.multi_refresher("constructibles/assembly_designs/", "AssemblyDesign"),


        // FOR NOW PART DESIGNS ARE NOT USED DIRECTLY
        // loadPartDesigns: base.actions.loader_pr("constructibles/part_designs/", "PartDesigns"),
        // loadPartDesignsByDate: base.actions.loader_date("constructibles/part_designs/", "PartDesigns"),
        // postPartDesign: base.actions.poster("constructibles/part_designs/", "PartDesign"),
        // putPartDesign: base.actions.putter("constructibles/part_designs/", "PartDesign"),
        // deletePartDesign: base.actions.deleter("constructibles/part_designs/", "PartDesign"),

        // Multiple Actions for Designs
        multiDeleteDesigns(context, uuids) {
            return backend_api_v2.post('constructibles/designs/bulk_delete/', uuids)
                .then(() => context.commit("multiDeleteByUuids", uuids))
                .catch(e => { console.log(`Cant batch delete designs: ${e}`) })
        },
        launchAnalysisForDesigns(context, uuids) {
            return backend_api_v2.post('constructibles/designs/launch_analysis/', uuids)
                .then(() => console.log(`Launched analysis for: ${uuids}`))
                .catch(e => { console.log(`Cant batch delete: ${e}`) })
        },

        // Actuals
        loadBatches: base.actions.loader_pr("constructibles/batches/", "Batches"),
        loadBatchesByDate: base.actions.loader_date("constructibles/batches/", "Batches"),
        postBatch: base.actions.poster("constructibles/batches/", "Batch"),
        putBatch: base.actions.putter("constructibles/batches/", "Batch"),
        deleteBatch: base.actions.deleter("constructibles/batches/", "Batch"),

        loadModules: base.actions.loader_pr("constructibles/modules/", "Modules"),
        loadModulesByDate: base.actions.loader_date("constructibles/modules/", "Modules"),
        postModule: base.actions.poster("constructibles/modules/", "Module"),
        putModule: base.actions.putter("constructibles/modules/", "Module"),
        deleteModule: base.actions.deleter("constructibles/modules/", "Module"),
        refreshModule: base.actions.refresher("constructibles/modules/", "Module"),
        refreshModules: base.actions.multi_refresher("constructibles/modules/", "Module"),

        loadAssemblies: base.actions.loader_pr("constructibles/assemblies/", "Assemblies"),
        loadAssembliesByDate: base.actions.loader_date("constructibles/assemblies/", "Assemblies"),
        postAssembly: base.actions.poster("constructibles/assemblies/", "Assembly"),
        putAssembly: base.actions.putter("constructibles/assemblies/", "Assembly"),
        deleteAssembly: base.actions.deleter("constructibles/assemblies/", "Assembly"),
        refreshAssembly: base.actions.refresher("constructibles/assemblies/", "Assembly"),
        refreshAssemblies: base.actions.multi_refresher("constructibles/assemblies/", "Assembly"),

        loadParts: base.actions.loader_pr("constructibles/parts/", "Parts"),
        loadPartsByDate: base.actions.loader_date("constructibles/parts/", "Parts"),
        postPart: base.actions.poster("constructibles/parts/", "Part"),
        putPart: base.actions.putter("constructibles/parts/", "Part"),
        deletePart: base.actions.deleter("constructibles/parts/", "Part"),

        // Multiple Actions for Actuals
        multiDeleteActuals(context, uuids) {
            return backend_api_v2.post('constructibles/actuals/bulk_delete/', uuids)
                .then(() => context.commit("multiDeleteByUuids", uuids))
                .catch(e => { console.log(`Cant batch delete actuals: ${e}`) })
        },
        multiPatchActuals(context, payload) {
            return backend_api_v2.post('constructibles/actuals/bulk_patch/', payload)
                .then(() => console.log("[i] Patched."))
                .catch(e => { console.log(`Cant batch delete actuals: ${e}`) })
        },

        // Others
        loadMyFirstConstructible(context, project_uuid) {
            return backend_api_v2.get('company/projects/' + project_uuid + '/first_constructible/')
                .then(({ data }) => {
                    if (data.is_a == "Assembly")
                        context.commit('setAssemblies', [data.assembly_list_object])
                    else if (data.is_a == "Module")
                        context.commit('setModules', [data.module_list_object])
                })
                .catch(e => { console.log(`Cant load first constructible: ${e}`) })
        },
    },
    getters: {
        // LISTS
        allDesigns: state => [...state.module_designs, ...state.assembly_designs,],
        allActuals: state => [...state.modules, ...state.assemblies,],
        myList: (state, getters, rootState) => level => {
            let actuals = getters.annotatedActualsFromLevel(level)
            const by_date = rootState.v2.company.company?.work_by_date
            if (by_date) {
                const my_batches_uuids = getters.myBatches.map(b => b.uuid)

                // Sorted straight by priority (no repeated numbers for single date).
                return actuals
                    .filter(a => my_batches_uuids.includes(a.batch))
                    .sort((a, b) => {
                        const dateA = new Date(a.scheduled_prod_date);
                        const dateB = new Date(b.scheduled_prod_date);

                        // Compare dates first
                        if (dateA < dateB) return -1;
                        if (dateA > dateB) return 1;

                        // If dates are equal, compare priorities
                        return a.priority - b.priority;
                    })
            } else {
                // Sorted by phase-batch-priority.
                let sorted_list = []
                getters.myBatches.forEach(batch => {
                    sorted_list.push(
                        ...actuals.filter(con => con.batch == batch.uuid)
                            .sort((a, b) => a.priority - b.priority)
                    )
                })
                return sorted_list
            }
        },
        myFirst: (state, getters, rootState, rootGetters) => level => {
            let sta = rootGetters["v2/session/my_selected_station"]
            let list = getters.myList(level).filter(con => con.stations_status[sta] != "ready" && con.status != "ready")
            if (list.length != 0)
                return list[0]
            else return false
        },
        myListNoFirst: (state, getters, rootState, rootGetters) => (level) => {
            let sta = rootGetters["v2/session/my_selected_station"]
            let first = getters.myFirst(level)
            let no_first = getters.myList(level)
                .filter(con => con.uuid != first.uuid)
            let futures = no_first
                .filter(con => con.stations_status[sta] != "ready" && con.status != "ready")
            let readies = no_first
                .filter(con => con.stations_status[sta] == "ready" || con.status == "ready")
            const my_list = [...futures, ...readies]
            return my_list
        },
        annotatedActualsFromLevel: (state) => (level) => {
            if (level == "assembly") {
                return state.assemblies.map(assd => ({ ...assd, level: "assembly" }));
            } else if (level == "module") {
                return state.modules.map(modd => ({ ...modd, level: "module" }));
            } else {
                return [
                    ...state.modules.map(modd => ({ ...modd, level: "module" })),
                    ...state.assemblies.map(assd => ({ ...assd, level: "assembly" }))
                ];
            }
        },
        // This annotates the class, for when you mix both designs in same list.
        annotatedDesignsFromLevel: (state) => (level) => {
            if (level == "assembly") {
                return state.assembly_designs.map(assd => ({ ...assd, level: "assembly" }));
            } else if (level == "module") {
                return state.module_designs.map(modd => ({ ...modd, level: "module" }));
            } else {
                return [
                    ...state.module_designs.map(modd => ({ ...modd, level: "module" })),
                    ...state.assembly_designs.map(assd => ({ ...assd, level: "assembly" }))
                ];
            }
        },
        annotatedDesignsFromLevelOnlyLastRevisions: (state, getters) => (level) => {
            let designs = getters.annotatedDesignsFromLevel(level)

            // Use reduce to create a mapping of names to objects with the highest revision
            const result = designs.reduce((acc, obj) => {
                if (!acc[obj.name] || acc[obj.name].revision < obj.revision) {
                    acc[obj.name] = obj;
                }
                return acc;
            }, {});

            // Convert the result object back to an array
            return Object.values(result);
        },

        // Batch and batch state
        myBatches: (state, getters, rootState) => {
            let user_object = rootState.v2.session.user_object;
            let phases = rootState.v2.selected.selected_project?.phases || []
            let production_line = user_object
                ? user_object.auth?.selected_production_line
                : null;
            let batches = [...state.batches]
            if (production_line) {
                batches = batches.filter((b) => b.production_line == production_line);
            }
            batches.sort((a, b) => {
                // Sort by phase and batch
                let idx_phase_a = phases.indexOf(a.phase)
                let idx_phase_b = phases.indexOf(b.phase)
                if (idx_phase_a == idx_phase_b) return a.priority - b.priority
                else return idx_phase_a - idx_phase_b
            })
            return batches
        },


        // BY UUID GETTERS
        designNameByUuid: (state, getters) => (uuid, include_rev = true) => {
            let d = getters.allDesigns.find((d) => d.uuid == uuid)
            if (include_rev) return `${d ? d?.name : ""} rev ${d?.revision}`
            else return `${d ? d?.name : ""}`

        },
        getLevelFromActualUuid: (state, getters) => uuid => {
            let actual = getters["annotatedActualsFromLevel"]("all")
                .find(actual => actual.uuid == uuid)
            return actual?.level || "unknown"
        },


        // Boolean flags
        haveModules: state => state.modules.length != 0,
        haveModuleDesigns: state => state.module_designs.length != 0,
        haveAssemblies: state => state.assemblies.length != 0,
        haveAssemblyDesigns: state => state.assembly_designs.length != 0,
        isBatchReadyByUuid: (state, getters) => uuid => {
            let locked = true;
            let actuals = getters.allActuals.filter(actual => actual.batch == uuid)
            if (actuals.length == 0) return false
            actuals.forEach((actual) => {
                if (actual.status != "ready") locked = false;
            });
            return locked;
        },
    }
}