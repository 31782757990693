import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
// import bus from '/src/utils/event_bus'
// import router from '/src/router'

export default {
    namespaced: true,
    state: {
        reports: [],
        loading: false,
    },
    mutations: {
        // Status
        setLoading: state => state.loading = true,
        setReady: state => state.loading = false,

        // Reports
        setReports: base.mutations.setter("reports"),
        replaceReport: base.mutations.updater("reports"),
        createReport: base.mutations.creator("reports"),
        updateReport: base.mutations.updater("reports"),
        deleteReport: base.mutations.deleter("reports"),
    },
    actions: {
        // Reports
        loadReports: base.actions.loader_pr("reports/reports/", "Reports"),
        postReport: base.actions.poster("reports/reports/", "Report"),
        putReport: base.actions.putter("reports/reports/", "Report"),
        deleteReport: base.actions.deleter("reports/reports/", "Report"),
        refreshReport: base.actions.refresher("reports/reports/", "Report"),

        putReportNoJob(context, payload) {
            const url = "reports/reports/";
            const finalUrl = `${url}${payload?.uuid}/?no_job=true`;

            return backend_api_v2.put(finalUrl, payload)
                .then(({ data }) => {
                    context.commit('updateReport', data)
                    return { data }
                });
        }
    },
    getters: {
    }
}