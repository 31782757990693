<template>
    <div class="d-flex flex-row justify-space-between">
        <v-checkbox v-model="task.checked"
            :disabled="(task.picture && file_count == 0) || (task.require_form && form_invalid)" :label="task.text"
            class="mb-0 mt-0" @click.passive="$emit('clickcheckbox')"></v-checkbox>
        <v-spacer></v-spacer>
        <div v-if="task.picture && !task.checked">
            <div>
                {{ file_count }}
                {{ $store.getters.t("_picture_saved") }}
                <v-btn v-if="file_count != 0" @click="cleanFiles" :disabled="deleting" :loading="deleting" color="warning"
                    small class="ml-2">
                    <v-icon left small>mdi-image-remove</v-icon>

                    {{ $store.getters.t("clean") }}
                </v-btn>
                <v-btn v-if="pck" @click="$emit('clickupload')" color="info" small class="ml-2">
                    <v-icon left small>mdi-camera-plus</v-icon>
                    {{ $store.getters.t("upload_picture") }}
                </v-btn>
            </div>
        </div>
        <v-badge v-if="task.require_form && !task.checked" color="red" dot bordered overlap :value="form_invalid">
            <ButtonTaskCustomForm :title="task.text" :schema="task.form_schema" :value="task.form" @update="updateForm" />
        </v-badge>
        <ChecklistDocsPackageViewer :checklist_item="task" />
    </div>
</template>

<script>
import ChecklistDocsPackageViewer from "../cloudfiles/ChecklistDocsPackageViewer.vue"
import ButtonTaskCustomForm from "./ButtonTaskCustomForm.vue"
export default {
    components: {
        ChecklistDocsPackageViewer,
        ButtonTaskCustomForm,
    },
    props: ["task", "pck"],
    data: () => ({ deleting: false }),
    computed: {
        current_task_files() {
            let cfs = this.pck?.files.filter(cf => {
                if (cf.meta.text != this.task.text) return false
                if (cf.meta.production_station != this.task.production_station) return false
                if (cf.meta.production_line != this.task.production_line) return false
                return true
            })
            return cfs
        },
        file_count() {
            return this.current_task_files?.length || 0
        },
        form_invalid() {
            return !this.task?.form?.valid
        }
    },
    methods: {
        cleanFiles() {
            this.deleting = true
            Promise.all(
                this.current_task_files
                    .map(cf => this.$store.dispatch("v2/cloudstorage/deleteCloudFile", cf.uuid))
            ).finally(() => {
                this.deleting = false
                this.$emit('reload')
            })
        },
        updateForm(form) {
            this.$emit("updateForm", form)
        }
    }
}
</script>