<template>
    <v-dialog v-model="dialog" width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                Transfer & Clone
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title> TRANSFER OR CLONE PROJECT </v-card-title>
            <v-card-text>
                <v-form ref="form" @keyup.enter.native="save">

                    <v-card-subtitle class="pl-0"> Mode </v-card-subtitle>
                    <v-select outlined dense :label="$store.getters.t('mode')" v-model="form.mode"
                        :items="['clone', 'transfer']" />
                    <v-select v-if="form.mode == 'transfer'" outlined dense
                        :label="$store.getters.t('transfer_partner')" v-model="form.transfer_partner"
                        :items="partners" />
                    <PitTextField label="New project name" outlined dense v-model="form.new_project_name" clearable
                        maxlength="160" />

                    <!-- Alerts -->
                    <v-alert type="info" v-if="form.mode == 'transfer' && partners.length == 0">
                        You don't have partner companies to make a tranfer.
                    </v-alert>
                    <v-alert type="info" v-if="form.mode == 'clone'">
                        The project will be cloned into your company.
                    </v-alert>
                    <v-alert type="warning" v-if="form.mode == 'transfer' && partners.length != 0">
                        The project will be transfered to the partner company and your company will lose control
                        over
                        it. Please
                        double check everything before transfering.
                    </v-alert>

                    <v-card-subtitle class="pl-0"> Transfer/clone options </v-card-subtitle>
                    <v-checkbox class="my-0" label="Project files (even if files are not used in designs)"
                        v-model="form.clone_files"></v-checkbox>
                    <v-checkbox class="my-0" label="Clone project IFC PS (recommended only when transfer)"
                        v-model="form.clone_ifcps"></v-checkbox>
                    <v-checkbox class="my-0"
                        label="Designs (needed files will be transfered, this includes module designs, assembly designs, subassembly designs and part designs)"
                        v-model="form.clone_designs"></v-checkbox>
                    <v-checkbox class="my-0"
                        label="Constructibles (includes modules, assemblies, subassemblies and parts)"
                        v-model="form.clone_constructibles"></v-checkbox>
                    <v-checkbox class="my-0" label="Clean constructibles status and checklist"
                        v-model="form.clean_const_status"></v-checkbox>
                    <!-- <v-checkbox class="my-0" label="Parts" v-model="form.clone_parts"></v-checkbox>
                    <v-checkbox class="my-0" label="Clean parts status" v-model="form.clean_parts_status"></v-checkbox> -->
                    <!-- <v-checkbox class="my-0" label="Reports" v-model="form.clone_reports"></v-checkbox>
                    <v-checkbox class="my-0" label="Issues" v-model="form.clone_issues"></v-checkbox> -->
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" :disabled="loading" color="success" @click="save">
                    {{ $store.getters.t(form.mode) }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api_v2 } from '/src/utils/apiv2'
import bus from "@/utils/event_bus";
import PitTextField from "@/components/fields/PitTextField.vue";

export default {
    components: {PitTextField},
    data: () => {
        return {
            partners: [
            ],
            loading: false,
            dialog: false,
            form: {
                mode: "clone",
                transfer_partner: null,
                new_project_name: null,
                clone_files: false,
                clone_ifcps: false,
                clone_designs: false,
                clone_constructibles: false,
                clean_const_status: false,
                // clone_parts: false,
                // clean_parts_status: false,
                // clone_reports: false,
                // clone_issues: false,
            },

        };
    },
    computed: {
        project_uuid() {
            return this.$route.params.project_uuid;
        },
    },
    methods: {
        load_partners() {
            this.loading = true
            backend_api_v2.get("my_company/partners/")
                .then(({ data }) => {
                    this.loading = false
                    console.log(data)
                    this.partners = data.map(p => ({ text: p.name, value: p.uuid }))
                })
        },
        save() {
            this.loading = true
            backend_api_v2.post(`company/projects/${this.project_uuid}/launch_transfer/`, this.form)
                .then(() => {
                    this.dialog = false
                    bus.$emit('notification', { timeout: 5000, text: "Launched. A message will be sent when the operation is completed.", color: "info" })
                })
                .catch(() =>
                    bus.$emit('notification', { timeout: 5000, text: "Something is going wrong.", color: "error" }))
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.load_partners()
    },
};
</script>