<template>
    <div class="d-flex flex-column align-space-between mb-1">
        <v-btn v-if="has_package" small color="secondary" class="ml-1 mr-1" @click="dialog = true">
            <v-icon left>mdi-robot-industrial</v-icon>
            {{ $store.getters.t("mob2") }}
        </v-btn>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition"
            @click="dialog = false">
            <v-card>
                <v-toolbar dense dark color="secondary" class="fronted">
                    <v-toolbar-title>
                        {{ $store.getters.t("operations") }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="recenter_image">
                            <v-icon>mdi-image-filter-center-focus</v-icon>
                        </v-btn>
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-btn @click="url = file.presigned_url" v-for="file in files" :key="file.uuid" small color="accent"
                    class="ma-1">
                    {{ file.short_name }}
                </v-btn>
                <PictureView :url="url" />
            </v-card>
        </v-dialog>
    </div>
</template>


<style lang="scss" scoped>
.fronted {
    z-index: 10;
}
</style>

<script>
import bus from "/src/utils/event_bus";
export default {
    name: "ButtonMachineDrawings",
    components: {},
    data: () => ({
        dialog: false,
        loading: false,
        // files: [],
        url: "",
    }),
    computed: {
        has_package() {
            return this.files.length != 0;
        },
        files() {
            const packages = this.$store.getters[
                "v2/cloudstorage/packagesByNameAndLevel"
            ]({
                name: "mob2_operations_drawings",
                level: "assembly",
            })
            return packages[0]?.files || [];
        },
    },
    methods: {
        recenter_image() {
            bus.$emit("events/viewer/recenter_mob2_operation");
        },
    },
};
</script>