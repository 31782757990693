<template>
    <v-dialog v-model="dialog" max-width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-if="icon == undefined" color="accent" :loading="loading"
                :disabled="loading">
                <v-icon left>mdi-comment-edit-outline</v-icon>
                {{ $store.getters.t("edit_issue") }}
            </v-btn>
            <v-btn v-bind="attrs" v-on="on" icon v-else :loading="loading" :disabled="loading">
                <v-icon>mdi-comment-edit-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline"> {{
                $store.getters.t("edit_issue")
            }} </v-card-title>
            <v-card-text>
                <v-form v-if="form">
                    <span> UUID:{{ form.uuid }} </span><br><br>
                    <v-text-field dense :label="$store.getters.t('title')" v-model="form.title" outlined></v-text-field>
                    <v-textarea dense :label="$store.getters.t('text')" v-model="form.text" outlined></v-textarea>
                    <SelectIssueCat v-model="form.category" />
                </v-form>
                <div v-else>
                    <v-skeleton-loader type="text@3"></v-skeleton-loader>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="saveIssue()" color="success">
                    <v-icon left>mdi-check</v-icon>
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectIssueCat from "/src/components/fields/SelectIssueCat.vue"
export default {
    name: "ButtonIssueSolve",
    components: {
        SelectIssueCat
    },
    props: ["issue", "icon"],
    data: () => ({
        dialog: false,
        form: null,
        loading: false,
    }),
    watch: {
        dialog() { this.buildForm() },
        issue() { this.buildForm() }
    },
    methods: {
        buildForm() {
            this.form = { ...this.issue }
        },
        saveIssue() {
            this.loading = true;
            this.$store
                .dispatch("v2/records/putIssue", this.form)
                .finally(() => (this.loading = false));
            this.dialog = false;
        },
    },
};
</script>