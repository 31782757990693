<template>
    <EditableMatrixCell :oldrev="oldrev" :count="count" :value="count" @update_count="updateCount($event)" @sub_one="subOne"
        @sum_one="sumOne" />
</template>

<script>
import EditableMatrixCell from './EditableMatrixCell.vue';
export default {
    components: { EditableMatrixCell },
    props: ["design", "phase", "oldrev"],
    computed: {
        count() {
            if (!this.design) return 0;
            let getter
            if (this.design.level == "assembly") {
                getter = "v2/quantities/getAssdPhaseCount"
            } else if (this.design.level == "module") {
                getter = "v2/quantities/getModdPhaseCount"
            } else {
                console.log("[!] Warning, unknown design level")
                return 0
            }
            let cobj = this.$store.getters[getter]({
                phase: this.phase, design: this.design,
            })
            if (!cobj) return -1
            else return cobj.count
        },
    },
    watch: {
        // Creates count obj
        count: {
            immediate: true,
            handler: function (c) {
                if (c == -1) this.$nextTick(this.createCount)
            }
        }
    },
    methods: {
        createCount() {
            let mutation
            if (this.design.level == "assembly") {
                mutation = "v2/quantities/newAssdPhaseCount"
            } else if (this.design.level == "module") {
                mutation = "v2/quantities/newModdPhaseCount"
            }
            this.$store.commit(mutation, {
                phase: this.phase, design: this.design,
            })
        },
        updateCount(count) {
            if (this.design.level == "assembly") {
                return this.$store.commit("v2/quantities/putAssdCountOnPhase", {
                    phase: this.phase, design: this.design, count
                })
            }
            if (this.design.level == "module") {
                return this.$store.commit("v2/quantities/putModdCountOnPhase", {
                    phase: this.phase, design: this.design, count
                })
            }
        },
        subOne() {
            if (this.design.level == "assembly") {
                return this.$store.commit("v2/quantities/subOneAssdToPhase", {
                    phase: this.phase, design: this.design
                })
            }
            if (this.design.level == "module") {
                return this.$store.commit("v2/quantities/subOneModdToPhase", {
                    phase: this.phase, design: this.design
                })
            }
        },
        sumOne() {
            if (this.design.level == "assembly") {
                return this.$store.commit("v2/quantities/sumOneAssdToPhase", {
                    phase: this.phase, design: this.design
                })
            }

            if (this.design.level == "module") {
                return this.$store.commit("v2/quantities/sumOneModdToPhase", {
                    phase: this.phase, design: this.design
                })
            }
        },
    },
}
</script>





