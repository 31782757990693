<template>
    <v-card-text ref="renderzone"> </v-card-text>
</template>

<script>
import JSONFormatter from "json-formatter-js";

export default {
    props: ["json"],
    watch: {
        json: {
            deep: true,
            handler: function () {
                this.$nextTick(this.format);
            },
            immediate: true
        },
    },
    methods: {
        format() {
            let deep = 2;
            const options = {
                theme: this.$vuetify.theme.dark ? "dark" : "",
            };
            const formatter = new JSONFormatter(this.json, deep, options);
            const rendered = formatter.render();
            let node = this.$refs["renderzone"];
            if (node) {
                node.innerHTML = "";
                node.appendChild(rendered);
            }
        },
    },
    mounted() {
        console.log("mounted json renderer")
    }
};
</script>