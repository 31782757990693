<template>
    <div class="d-flex flex-column">
        <v-dialog v-model="dialog" width="900">
            <template v-slot:activator="{ on, attrs }">
                <v-btn outlined :small="small" v-bind="attrs" v-on="on">
                    <v-icon left :small="small">{{ button_icon }}</v-icon>
                    {{ $store.getters.t(label) }}
                </v-btn>
                <span class="grey--text caption">
                    {{ files_count }} file(s).
                </span>
            </template>

            <v-card>
                <v-card-title> {{ label }} </v-card-title>
                <v-card-text>
                    <div class="d-flex">
                        <div v-if="!pck" class="d-flex flex-column mr-3" style="width: 450px">
                            <v-skeleton-loader type="text@3"></v-skeleton-loader>
                        </div>
                        <div v-else class="d-flex flex-column mr-3" style="width: 450px">
                            <br />
                            {{ files_count }}
                            {{
                                pictures
                                ? $store.getters.t("_picture_saved")
                                : $store.getters.t("_files_saved")
                            }}
                            <br />
                            <FileRow v-for="file in files" :file="file" :key="file.uuid" @deleted="debouncedLoadPackage" />
                        </div>
                        <div :id="uppy_box_id"></div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn v-if="files_count != 0" :loading="cleaning" :disabled="cleaning || !this.section"
                        @click="cleanFiles()" color="warning" small class="ml-2">
                        <v-icon left small v-if="pictures">
                            mdi-image-remove
                        </v-icon>
                        <v-icon left small v-else> mdi-file-remove-outline </v-icon>
                        {{ $store.getters.t("clean") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" color="secondary">
                        {{ $store.getters.t("done") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import bus from "/src/utils/event_bus"
import { backend_api_v2 } from "/src/utils/apiv2"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"
import { v4 as uuidv4 } from "uuid"
import FileRow from "./FileRow.vue"
import _ from "lodash"

export default {
    components: { FileRow },
    props: {
        package_name: { type: String, default: uuidv4 },
        package_params: { default: {} },
        value: { default: null }, // Value is file package uuid
        label: { type: String, default: "files" },
        multiple: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        pictures: { type: Boolean, default: false },
        section: { default: null },
    },
    data: () => ({ uppy: null, dialog: false, pck: null, cleaning: false }),
    computed: {
        pck_uuid() {
            return this.pck?.uuid
        },
        files() {
            return this.pck?.files?.filter(cf => cf.meta.section == this.section) || []
        },
        files_count() {
            return this.files.length || 0
        },
        button_icon() {
            if (this.pictures) return "mdi-camera-plus"
            else if (this.multiple) return "mdi-file-multiple"
            else return "mdi-file"
        },
        uppy_box_id() {
            return "uppy" + this._uid
        },
    },
    watch: {
        dialog(d) {
            if (d) this.$nextTick(this.reloadUppy)
            else this.uppy.close()
        },
        value() {
            this.loadPackage()
        },
    },
    methods: {
        cleanFiles() {
            if (!this.value || !this.section) return
            this.cleaning = true
            backend_api_v2
                .delete(`/cloudstorage/packages/${this.value}/`)
                .then(() => {
                    this.pck = null
                    this.$emit("input", null)
                    this.cleaning = false
                })
                .catch(() => {
                    this.cleaning = false
                })
        },
        newPackage() {
            backend_api_v2
                .post("/cloudstorage/packages/get_or_create/", {
                    name: this.package_name,
                    ...this.package_params,
                })
                .then(({ data }) => {
                    this.pck = data
                    this.$emit("input", data.uuid)
                })
        },
        debouncedLoadPackage: _.debounce(function () {
            this.loadPackage()
        }, 1000),
        loadPackage() {
            if (!this.value) this.$nextTick(() => this.newPackage())
            else
                backend_api_v2
                    .get(`/cloudstorage/packages/${this.value}/`)
                    .then(({ data }) => {
                        this.pck = data
                    })
                    .catch(() => {
                        this.pck = null
                    })
        },
        get_upload_parameters(file) {
            return backend_api_v2
                .post(`/cloudstorage/packages/${this.value}/presign_for_new_file/`, {
                    name: file.name,
                    type: file.type,
                    meta: { section: this.section }
                })
                .then((r) => r.data)
        },
        check_files() {
            return this.multiple || this.files_count == 0
        },
        reloadUppy() {
            const maxNumberOfFiles = this.multiple ? 50 : 1
            const allowedFileTypes = this.pictures
                ? ["image/*", "video/*"]
                : undefined
            this.uppy = new Uppy({
                debug: true,
                onBeforeFileAdded: this.check_files,
                restrictions: {
                    maxFileSize: 30000000,
                    maxNumberOfFiles,
                    minNumberOfFiles: 1,
                    allowedFileTypes,
                },
            })
                .use(Dashboard, {
                    theme: this.$vuetify.theme.dark ? "dark" : "light",
                    target: "#" + this.uppy_box_id,
                    inline: true,
                    hideUploadButton: false,
                    width: 450,
                    height: 400,
                    proudlyDisplayPoweredByUppy: false,
                })
                .use(AwsS3, {
                    metaFields: ["name"],
                    getUploadParameters: this.get_upload_parameters,
                })
            this.uppy.on("complete", this.debouncedLoadPackage)
        },
    },
    mounted() {
        // Fill with value or default
        this.loadPackage()
    },
}
</script>