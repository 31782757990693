<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" text color="accent">
                <v-icon left> mdi-order-bool-ascending-variant </v-icon>
                {{ $store.getters.t("linked_details_button_text") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("linked_details") }}
            </v-card-title>
            <v-card-subtitle>
                {{ $store.getters.t("linked_details_help") }}
            </v-card-subtitle>
            <v-card-text>
                <v-select :items="detail_items" v-model="selected_details" label="Details" multiple outlined dense>
                </v-select>
                <v-select :items="doc_items" v-model="selected_docs" label="Docs" multiple outlined dense>
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="save()" color="warning">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "/src/utils/apiv2";

export default {
    props: ["assd", "multiple", "uuids"],
    data: () => ({
        dialog: false,
        loading: false,
        selected_details: [],
        selected_docs: [],
    }),
    computed: {
        project_pck() {
            return this.$store.getters["v2/cloudstorage/resourcesPackage"]
        },
        selected_pck() {
            return this.$store.getters["v2/viewer/resPackage"]
        },
        detail_items() {
            return this.project_pck?.files.filter(cf => cf.meta.section == "detail").map(cf => ({ text: cf.name, value: cf.uuid }))
        },
        doc_items() {
            return this.project_pck?.files.filter(cf => cf.meta.section == "doc").map(cf => ({ text: cf.name, value: cf.uuid }))
        },
        currently_selected_detail_items() {
            return this.selected_pck?.files.filter(cf => cf.meta.section == "detail").map(cf => cf.uuid)
        },
        currently_selected_doc_items() {
            return this.selected_pck?.files.filter(cf => cf.meta.section == "doc").map(cf => cf.uuid)
        },
        selected_cf_uuids() {
            return [...this.selected_details, ...this.selected_docs]
        },
        non_selected_cf_uuids() {
            return this.project_pck?.files
                .filter(cf => ["detail", "doc"].includes(cf.meta.section) && !this.selected_cf_uuids.includes(cf.uuid))
                .map(cf => cf.uuid)
        },
    },
    watch: {
        dialog(d) {
            if (!d) return
            if (this.multiple) this.clear_selection();
            else this.fill_selection();
        },
    },
    methods: {
        clear_selection() {
            this.selected_details = [];
            this.selected_docs = [];
        },
        fill_selection() {
            this.selected_details = this.currently_selected_detail_items;
            this.selected_docs = this.currently_selected_doc_items;
        },
        save() {
            this.loading = true;
            this.dialog = false;
            let target_uuids = []
            if (this.multiple) {
                target_uuids = this.uuids
            } else {
                target_uuids.push(this.assd.uuid)
            }
            const payload = {
                "add_files": this.selected_cf_uuids,
                "remove_files": this.non_selected_cf_uuids,
                "designs": target_uuids,
            }
            backend_api_v2.post("/cloudstorage/assign_dets_and_docs/", payload)
                .finally(() => {
                    bus.$emit("events/cloudstorage/reloadDesignPackages")
                    this.loading = false;
                })

        },
    },
    mounted() {
        this.fill_selection();
    },
};
</script>