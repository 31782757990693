<template>
    <tr v-on:mouseenter="onHover(_uid)" v-on:mouseleave="onHover()" height="50px"
        :class="{ 'active-row-l': hover && !dark, 'active-row-d': hover && dark, }">
        <td>
            <v-list-item-title>
                {{ $store.getters.t("production_line") }}
            </v-list-item-title>
        </td>
        <td v-for="batch in batches" :key="batch.uuid">
            <v-list-item-title class="d-flex">
                <SelectBatchProductionLine :batch="batch" :asyncmode="false" />
                <ButtonConstructiblesSortingDialog icon :batch="batch" />
            </v-list-item-title>
        </td>
        <td align="right">
            <v-list-item-title>
            </v-list-item-title>
        </td>
    </tr>
</template>

<style lang="scss">
@import "./tables.scss";
</style>


<script>
import SelectBatchProductionLine from "../SelectBatchProductionLine.vue";
import ButtonConstructiblesSortingDialog from "../../sorting/ButtonConstructiblesSortingDialog.vue";
import { rowMixin } from "./mixins"
export default {
    mixins: [rowMixin],
    props: ["batches", "phase"],
    components: {
        SelectBatchProductionLine,
        ButtonConstructiblesSortingDialog,
    },
    computed: {
        target_row_total() {
            if (!this.design) return 0;
            let gettr
            if (this.design.level == "assembly") {
                gettr = this.$store.getters["v2/quantities/getAssdPhaseCount"]
            }
            else if (this.design.level == "module") {
                gettr = this.$store.getters["v2/quantities/getModdPhaseCount"]
            }
            else { return 0 }
            let cobj = gettr({ design: this.design, phase: this.phase })
            return cobj?.count || 0
        },
        row_total() {
            if (!this.design) return 0;
            let gettr
            if (this.design.level == "assembly") {
                gettr = this.$store.getters["v2/quantities/getAssdBatchCount"]
            }
            else if (this.design.level == "module") {
                gettr = this.$store.getters["v2/quantities/getModdBatchCount"]
            }
            else { return 0 }

            return this.batches.reduce((a, b) => a + gettr({
                design: this.design, phase: this.phase, batch: b
            })?.count || 0, 0)

        },
        is_total_ok() {
            return this.row_total == this.target_row_total
        },
        totals_text() {
            return `${this.row_total}/${this.target_row_total}`
        }
    }
}
</script>