<template>
    <v-container class="fill-height">
        <v-row justify="center">
            <v-col align="center" cols="4">
                <h1 class="display-2 mb-2 font-weight-light">
                    {{ $store.getters.t("welcome_to") }}
                </h1>
                <img v-if="dark" src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_dark.svg" />
                <img v-else src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo.svg" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {},
    data: () => ({}),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
    },
};
</script>