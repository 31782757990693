import { paginated_factory_v2, backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"

let LASTS = {}

function loadPackagesFactory(keyword, api_query_level) {
    if (!api_query_level) {
        api_query_level = keyword;
    }

    const loadPackages = function (context, uuid) {

        // Register last call (ensure last called fills)
        LASTS[keyword] = uuid

        // Fill it
        return paginated_factory_v2(`/cloudstorage/packages/?${api_query_level}=${uuid}`)
            .then(({data}) => {
                // Accept last
                if (LASTS[keyword] == uuid)
                    context.commit(`set_${keyword}_packages`, data)
                // Reject prebounce
                else console.log("[i] Rejecting loadPackages commit. Not last call.")
                return data
            })
            .catch(e => console.log(`Cant load packages: ${e}`))
    }
    return loadPackages
}


function cleanDuplicatesInPackage(context, uuid) {
    // Warning, this doesn't update store. Needs proper update.
    return backend_api_v2.post(`/cloudstorage/packages/${uuid}/clean_duplicates/`)
        .then(({ data }) => {
            return { data }
        })
        .catch(e => console.log(`Cant clean package: ${e}`))
}


function deleteCloudFileByPckAndName(context, payload) {
    // Warning, this doesn't update store. Needs proper update.
    return backend_api_v2.post(`/cloudstorage/packages/${payload.pck}/clean_files_by_name/`, { name: payload.name })
        .then(({ data }) => {
            return { data }
        })
        .catch(e => console.log(`Cleant package: ${e}`))
}



export default {
    namespaced: true,
    state: {
        company_packages: [],
        project_packages: [],
        module_packages: [],
        assembly_packages: [],
        module_design_packages: [],
        assembly_design_packages: [],
        parent_assembly_design_packages: [],
    },
    mutations: {
        set_company_packages: (state, data) => state.company_packages = data,
        set_project_packages: (state, data) => state.project_packages = data,
        set_module_packages: (state, data) => state.module_packages = data,
        set_assembly_packages: (state, data) => state.assembly_packages = data,
        set_module_design_packages: (state, data) => state.module_design_packages = data,
        set_assembly_design_packages: (state, data) => state.assembly_design_packages = data,
        set_parent_assembly_design_packages: (state, data) => state.parent_assembly_design_packages = data,

        deleteCloudFile: (state, uuid) => {
            Object.keys(state).forEach((k) => {
                if ("files" in state[k]) {
                    state[k].files = state[k].files.filter(cf => cf.uuid != uuid)
                }
            })
        },
    },
    actions: {
        loadCompanyPackages: loadPackagesFactory("company"),
        loadProjectPackages: loadPackagesFactory("project"),
        loadModulePackages: loadPackagesFactory("module"),
        loadAssemblyPackages: loadPackagesFactory("assembly"),
        loadModuleDesignPackages: loadPackagesFactory("module_design"),
        loadAssemblyDesignPackages: loadPackagesFactory("assembly_design"),
        loadParentAssemblyDesignPackages: loadPackagesFactory("parent_assembly_design", "assembly_design"),

        deleteCloudFile: base.actions.deleter("cloudstorage/files/", "CloudFile"),
        deleteCloudFileByPckAndName,
        cleanDuplicatesInPackage,
    },
    getters: {
        packagesByNameAndLevel: state => ({ name, level }) => {
            if (name == null) return state[`${level}_packages`]
            else if (Array.isArray(name))
                return state[`${level}_packages`].filter(pckg => name.includes(pckg.name))
            else
                return state[`${level}_packages`].filter(pckg => pckg.name == name)
        },
        packageByUuid: state => uuid => state.project_packages.find(p => p.uuid == uuid)
            || state.module_packages.find(p => p.uuid == uuid)
            || state.assembly_packages.find(p => p.uuid == uuid)
            || state.module_design_packages.find(p => p.uuid == uuid)
            || state.assembly_design_packages.find(p => p.uuid == uuid),
        resourcesPackage: state => state.project_packages.find((pck) => pck.name == "resources"),


        // Full project last revisions
        hasFullProjectResources: (state, getters) => {
            let files = getters.resourcesPackage?.files || []
            let pr_files = files
                .filter(cf => ["project_ifc", "module_drawing"]
                    .includes(cf.meta.section))
            return pr_files.length != 0
        },


    },

}
