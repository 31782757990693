<template>
    <table class="table">
        <col width="250px" />
        <thead class="header-item" :class="dark ? 'dark-header' : 'light-header'">
            <th class="title"> {{ $store.getters.t("design") }} </th>
            <th v-for="batch in batches_in_phase" :key="batch.uuid">
                <DropdownBatchOptions :batch="batch" :project="project" :designs="in_phase_designs" />
            </th>
            <th align="right" class="title">
                {{ $store.getters.t("total_constructibles") }}
            </th>
        </thead>
        <tbody>
            <RowPLSelection :batches="batches_in_phase" :phase="selected_phase" />
            <!-- Module rows -->
            <RowBatches v-for="modd in in_phase_modds" :design="modd" :batches="batches_in_phase" :phase="selected_phase"
                :key="modd.uuid" />
            <!-- Module totals -->
            <RowTotalsBatch v-if="show_module_totals" :batches="batches_in_phase" level="module" :phase="selected_phase" />
            <!-- Assembly rows -->
            <RowBatches v-for="assd in in_phase_assds" :design="assd" :batches="batches_in_phase" :phase="selected_phase"
                :key="assd.uuid" />
            <!-- Assembly totals -->
            <RowTotalsBatch v-if="show_assembly_totals" :batches="batches_in_phase" level="assembly"
                :phase="selected_phase" />
            <!-- Absolute totals -->
            <RowTotalsBatch v-if="show_all_totals" :batches="batches_in_phase" level="all" :phase="selected_phase" />
        </tbody>
    </table>
</template>


<style lang="scss">
@import "./tables.scss";
</style>



<script>
import DropdownBatchOptions from "../DropdownBatchOptions.vue";
import RowBatches from "./RowBatches.vue"
import RowTotalsBatch from "./RowTotalsBatch.vue"
import RowPLSelection from "./RowPLSelection.vue"
import { tableMixin } from "./mixins"

import _ from "lodash";

export default {
    name: "ProjectPhaseManager",
    mixins: [tableMixin],
    components: {
        DropdownBatchOptions,
        RowBatches,
        RowTotalsBatch,
        RowPLSelection,
    },
    computed: {
        // assd_batch_counts() {
        //     return this.$store.state.v2.quantities.assd_batch_counts
        // },
        batches_in_phase() {
            return this.batches.filter(b => b.phase == this.selected_phase)
        },
        buuids() {
            // Returns uuids of batches in this phase
            return this.batches_in_phase.map(b => b.uuid)
        },
        in_phase_modds() {
            const in_phase = this.$store.state.v2.quantities.modd_phase_counts
                .filter(c => c.phase == this.selected_phase && c.count != 0)
                .map(c => c.design)
            const in_batch = this.$store.state.v2.quantities.modd_batch_counts
                .filter(c => this.buuids.includes(c.batch) && c.count != 0)
                .map(c => c.design)
            const duuis = _.uniq([...in_phase, ...in_batch])
            return this.modds.filter(d => duuis.includes(d.uuid))
        },
        in_phase_assds() {
            // Counts in phase or batch
            const in_phase = this.$store.state.v2.quantities.assd_phase_counts
                .filter(c => c.phase == this.selected_phase && c.count != 0)
                .map(c => c.design)
            const in_batch = this.$store.state.v2.quantities.assd_batch_counts
                .filter(c => this.buuids.includes(c.batch) && c.count != 0)
                .map(c => c.design)
            const duuis = _.uniq([...in_phase, ...in_batch])
            return this.assds.filter(d => duuis.includes(d.uuid))
        },
        in_phase_designs() {
            return this.in_phase_assds.concat(this.in_phase_modds)
        },
    },
    methods: {
        // getPhaseFullTotal(phase) {
        //     if (!phase) {
        //         return _.sum(this.assd_batch_counts.map((c) => c.count));
        //     } else {
        //         return _.sum(
        //             this.assd_batch_counts
        //                 .filter((c) => c.phase == phase)
        //                 .map((c) => c.count)
        //         );
        //     }
        // },
        // getPhaseFullTotalArea(phase) {
        //     // Fill area map
        //     let area_map = {};
        //     this.designs.forEach((w) => {
        //         let size = w.meta?.metrics?.bruto_area || 0;
        //         let wuuid = w.uuid;
        //         area_map[wuuid] = size;
        //     });
        //     if (!phase) {
        //         let mm2 = _.sum(
        //             this.assd_batch_counts.map(
        //                 (c) => c.count * (area_map[c.design] || 0)
        //             )
        //         );
        //         return mm2;
        //     } else {
        //         let mm2 = _.sum(
        //             this.assd_batch_counts
        //                 .filter((c) => c.phase == phase && c.count != 0)
        //                 .map((c) => c.count * (area_map[c.design] || 0))
        //         );
        //         return mm2;
        //     }
        // },
    },
};
</script>