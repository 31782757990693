<template>
    <div>
        <div class="my-2">
            <v-progress-circular size="24" class="mr-2" indeterminate v-if="loading" />
            <v-icon class="mr-2" v-else> mdi-wall </v-icon>
            UUID: {{ item.uuid.substring(0, 8) }}
        </div>
        <div class="my-2">
            <v-icon class="mr-2"> mdi-home-modern </v-icon>
            {{ project_name }}
        </div>
        <div class="my-2">
            <v-icon class="mr-2"> mdi-garage-variant </v-icon>
            {{ batch_name }}
        </div>
        <div class="mb-5">
            <h3>Status</h3>
            <ChipConstructibleStatus v-if="panel" :constructible="panel" inline />
        </div>
        <v-sheet class="pa-2 my-2" outlined rounded>
            <h4>{{ $store.getters.t("times") }}</h4>
            <ForecastTimesTable :item="item" />
        </v-sheet>
        <ReadyPanelProofRenderer v-if="panel && production_line" :panel="panel" :production_site="production_line" />
    </div>
</template>
<script>

import { backend_api_v2 } from "/src/utils/apiv2";
import ChipConstructibleStatus from "/src/components/renderers/ChipConstructibleStatus.vue";
import ReadyPanelProofRenderer from "./ReadyPanelProofRenderer.vue";
import ForecastTimesTable from "./ForecastTimesTable";
export default {
    components: {
        ChipConstructibleStatus,
        ReadyPanelProofRenderer,
        ForecastTimesTable,
    },
    props: ["item"],
    data: () => ({
        panel: null,
        design: null,
        loading: false,
        batch: null,
    }),
    computed: {
        assembly_uuid() {
            return this.item.uuid;
        },
        production_line() {
            const uuid = this.batch?.production_line;
            const ps = this.$store.state.company.production_sites.find(
                (ps) => ps.uuid == uuid
            );
            return ps;
        },
        project_name() {
            const pr = this.$store.state.v2.company.projects.find(
                (p) => p.uuid == this.batch?.project
            );
            return pr ? `${pr?.name} [${pr?.super_project}]` : "...";
        },
        batch_name() {
            return this.batch
                ? `${this.batch.name} [${this.batch.phase}]`
                : "...";
        },
    },
    watch: {
        assembly_uuid: {
            handler: function (uuid) {
                // Async load the panel when it changes
                this.loading = true;
                this.panel = null;
                this.batch_uuid = null;
                backend_api_v2
                    .get(`/constructibles/assemblies/${uuid}/`)
                    .then(({ data }) => {
                        this.panel = { ...data };
                        this.loading = false;
                        this.reload_batch(data.batch);
                    })
                    .catch((e) => {
                        this.loading = false;
                        console.log(`Cant load panel: ${e}`);
                    });
            },
            immediate: true,
        },
    },
    methods: {
        reload_batch(uuid) {
            this.batch = null;
            backend_api_v2
                .get(`/constructibles/batches/${uuid}/`)
                .then(({ data }) => {
                    this.batch = { ...data };
                })
                .catch((e) => {
                    console.log(`Cant load batch: ${e}`);
                });
        },
    },
};
</script>