<template>
    <v-app-bar flat class="app-bar" :color="dark ? 'black' : 'white'" outlined>
        <v-select hide-details dense v-model="selected_phase" :items="phases_list" label="Matrix" outlined
            class="phase-select mx-1"></v-select>
        <SelectConstructible />
        <ButtonDesignsFilters :designs=designs @input="filtered_designs = $event" />
        <ButtonPhaseAdd v-if="selected_phase == '0'" :project="project" />
        <ButtonBatchAdd :phase="selected_phase" v-else />
        <ButtonPhaseSortingDialog icon v-if="selected_phase == '0'" :project="project" />
        <ButtonBatchSortingDialog icon v-else :phase="selected_phase" />
        <v-spacer></v-spacer>
        <ButtonResetCounts @reset="cleanAllCounters" />
        <ButtonSaveCounts />
        <v-btn :disabled="generating" :loading="generating" color="error" @click="generate_dialog = true">
            {{ $store.getters.t("generate") }}
        </v-btn>
        <DialogGenerateConstructibles v-model="generate_dialog" />
    </v-app-bar>
</template>

<style lang="scss" scoped>
.phase-select {
    max-width: 300px;
}

.app-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;
}
</style>

<script>
import ButtonPhaseAdd from "../../quantities/header/ButtonPhaseAdd.vue";
import ButtonBatchAdd from "../../quantities/header/ButtonBatchAdd.vue";
import ButtonSaveCounts from "../../quantities/header/ButtonSaveCounts.vue";
import ButtonResetCounts from "../../quantities/header/ButtonResetCounts.vue";
import DialogGenerateConstructibles from "../../quantities/header/DialogGenerateConstructibles.vue";
import SelectConstructible from "../../designs/SelectConstructible.vue";
import ButtonDesignsFilters from "../../quantities/header/ButtonDesignsFilters.vue";
import ButtonPhaseSortingDialog from "../../sorting/ButtonPhaseSortingDialog.vue";
import ButtonBatchSortingDialog from "../../sorting/ButtonBatchSortingDialog.vue";

export default {
    components: {
        ButtonPhaseAdd,
        ButtonBatchAdd,
        ButtonResetCounts,
        DialogGenerateConstructibles,
        ButtonSaveCounts,
        SelectConstructible,
        ButtonDesignsFilters,
        ButtonPhaseSortingDialog,
        ButtonBatchSortingDialog,
    },
    props: [],
    data: () => ({
        selected_phase: "0",
        filtered_designs: [],
        generate_dialog: false,
    }),
    computed: {
        generating() {
            return this.$store.state.v2.quantities.generating
        },
        phases_list() {
            let phases_list = [
                { header: "Edit phases:" },
                { text: "Project phases", value: "0" },
                { divider: true },
                { header: "Edit batches:" },
            ];
            if (this.project) {
                [...this.project.phases].forEach((ph) => {
                    phases_list.push({ text: "Batches in " + ph, value: ph });
                });
            }
            return phases_list;
        },
        project() {
            return this.$store.state.v2.selected.selected_project;
        },
        dark() {
            return this.$vuetify.theme.dark;
        },
        designs() {
            const level = this.$route.query.level
            return this.$store.getters["v2/constructibles/annotatedDesignsFromLevel"](level);
        },
    },
    watch: {
        selected_phase(sp) { this.$nextTick(() => this.$emit("selected_phase", sp)) },
        filtered_designs(fd) { this.$emit("filtered_designs", fd) }
    },
    methods: {
        cleanAllCounters() {
            this.$store.dispatch("v2/quantities/cleanAllCounters");
        },
        // allCountersFromSaveOrClean() {
        //     this.$store.dispatch("v2/quantities/allCountersFromSaveOrClean");
        // }
    },
}
</script>
