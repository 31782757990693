<template>
    <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-if="listitem != undefined" v-bind="attrs" v-on="on">
                <v-list-item-icon>
                    <v-icon v-if="loading" color="accent"> mdi-loading mdi-spin </v-icon>
                    <v-icon v-else> mdi-account-hard-hat </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    user
                </v-list-item-title>
            </v-list-item>
            <v-card v-else outlined width="190" v-bind="attrs" v-on="on" :loading="loading ? 'accent' : false"
                loader-height="2">
                <div class="d-flex">
                    <v-icon outlined class="ml-2 mr-1 my-1">
                        mdi-account-hard-hat
                    </v-icon>
                    <div class="ml-1 caption grey--text d-flex flex-column">
                        <span class="mt-1 d-inline-block text-truncate" style="max-width: 135px">
                            {{ username }}
                        </span>
                        <span class="mb-1 d-inline-block text-truncate" style="max-width: 135px">
                            {{ production_station_name }}
                            [{{ production_line_name }}]
                        </span>
                    </div>
                </div>
            </v-card>
        </template>
        <v-list dense>
            <!-- <template v-if="management_permission || admin_permission">
                <v-list-item v-if="viewer_mode" @click="switch_to_manage">
                    <v-list-item-icon>
                        <v-icon> mdi-swap-horizontal </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("management_mode") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="switch_to_viewer">
                    <v-list-item-icon>
                        <v-icon> mdi-map-legend </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("panel_viewer_mode") }}
                    </v-list-item-title>
                </v-list-item>
            </template> -->
            <ButtonStaffChangeCompany v-if="i_am_staff" />
            <ButtonStation @close="closeMenu" />
            <v-list-item v-if="management_permission || admin_permission" to="/company/settings">
                <v-list-item-icon>
                    <v-icon> mdi-factory </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
        $store.getters.t("company")
    }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item disabled>
                <v-list-item-icon>
                    <v-icon> mdi-hammer-screwdriver </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Change Production Station</v-list-item-title>
            </v-list-item> -->
            <v-list-item @click="refreshBaseUserData">
                <v-list-item-icon>
                    <v-icon> mdi-sync </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("resync") }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="is_android" @click="hardRefresh">
                <v-list-item-icon>
                    <v-icon> mdi-power-plug-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("hard_resync") }}
                </v-list-item-title>
            </v-list-item>
            <ButtonForceDark />
            <v-list-item to="/account">
                <v-list-item-icon>
                    <v-icon> mdi-account </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ $store.getters.t("account") }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
                <v-list-item-icon>
                    <v-icon> mdi-logout </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
        $store.getters.t("logout")
    }}</v-list-item-title>
            </v-list-item>
            <v-subheader>
                Version: {{ build_version }}<br>
            </v-subheader>
            <v-subheader>
                <span class="connection-text">Connection</span>
                <div :class="connectionStatus ? 'led-red' : 'led-green'"></div>
            </v-subheader>
        </v-list>
    </v-menu>
</template>

<style scoped>
.connection-text {
    margin-right: 5px;
}

.led-green {
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
}

.led-red {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
}
</style>

<script>
import ButtonStation from "/src/components/left_menu/ButtonStation";
import ButtonForceDark from "/src/components/left_menu/ButtonForceDark";
import INITIAL_STATE from "/src/utils/defaults";
import ButtonStaffChangeCompany from "./ButtonStaffChangeCompany.vue";
export default {
    components: { ButtonStation, ButtonForceDark, ButtonStaffChangeCompany },
    props: ["listitem"],
    data: () => ({ loading: false, menu: false }),
    computed: {
        connectionStatus() {
            return this.$store.state.connection_lost;
        },
        viewer_mode() {
            return this.$store.state.app_mode == "production";
        },
        i_am_staff() {
            return this.$store.state.v2.session.user_object?.auth?.pit_staff;
        },
        is_android() {
            return INITIAL_STATE.IS_ANDROID;
        },
        build_version() {
            return INITIAL_STATE.BUILD_VERSION;
        },
        production_line_name() {
            let pl_name =
                this.$store.state.v2.session.user_object.auth
                    .selected_production_line_name;
            return pl_name || this.$store.getters.t("no_station");
        },
        production_station_name() {
            return this.$store.state.v2.session.user_object.auth
                .selected_production_station_name;
        },
        username() {
            return this.$store.state.v2.session.user_object.username;
        },
        management_permission() {
            return this.$store.state.v2.session.user_object.auth
                ?.company_manager;
        },
        admin_permission() {
            return this.$store.state.v2.session.user_object.auth?.company_admin;
        },
    },
    methods: {
        logout() {
            this.$store.commit("v2/session/cleanSession");
            this.$router
                .push("/login")
                .catch(() =>
                    console.log(
                        "[!] Can't go to login. Maybe already in login page."
                    )
                );
        },
        switch_to_manage() {
            this.$store.commit("setManagerMode");
        },
        switch_to_viewer() {
            this.$store.commit("setViewerMode");
        },
        refreshBaseUserData() {
            this.loading = true;
            let proms = [
                this.$store.dispatch("initialLoad"),
                this.$store.dispatch("refreshBaseUserData", false)
            ]
            Promise.all(proms).finally(() => (this.loading = false));
        },
        hardRefresh() {
            location.reload();
        },
        closeMenu() {
            this.menu = false;
        },
    },
};
</script>