import { Capacitor } from '@capacitor/core';
import BUILD_VERSION from '/src/utils/version';

let INITIAL_STATE = {}

if (process.env.NODE_ENV !== 'development') {
    // THIS COMES FROM DJANGO RENDERING spa.html TEMPLATE
    INITIAL_STATE = window.__INITIAL_STATE__
    INITIAL_STATE.ENABLE_LOGISTICS = false
} else {
    INITIAL_STATE.VUE_APP_ROOT_API = 'http://localhost:8181/api/v2/'
    INITIAL_STATE.PROD_ENV = 'development'
    INITIAL_STATE.ENABLE_LOGISTICS = true
}

INITIAL_STATE.IS_ANDROID = Capacitor.getPlatform() === 'android'
INITIAL_STATE.BUILD_VERSION = BUILD_VERSION
// INITIAL_STATE.IS_ANDROID = true

export default INITIAL_STATE
