<template>
    <div>
        <PitTextField v-if="edit_batch_mode" label="Batch name" v-model="edit_batch_name" append-icon="mdi-check"
            @enter="save_batch" @esc="edit_batch_mode = false" @click_append="save_batch" :error="batch_edit_is_duplicate">
        </PitTextField>
        <v-menu bottom right v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :color="batch.show_in_viewer ? '' : 'grey'" x-large block class="text-transform-none" text
                    v-bind="attrs" v-on="on">
                    <v-icon small left v-if="batch.show_in_viewer">mdi-eye</v-icon>
                    <v-icon small left v-else>mdi-eye-off</v-icon>
                    {{ batch.name }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="edit_batch">
                    <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("change_batch_name") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="numbers_dialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-numeric-3-box-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("set_multiple_numbers") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="hide_dialog = true">
                    <v-list-item-icon>
                        <v-icon v-if="batch.show_in_viewer">mdi-eye-off</v-icon>
                        <v-icon v-else>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t(batch.show_in_viewer ? "hide_batch" : "show_batch") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clone_dialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("clone_batch") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item @click="delete_dialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-delete-forever</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $store.getters.t("delete_batch") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- MULTIPLE NUMBERS DIALOG -->
        <v-dialog v-model="numbers_dialog" max-width="600">
            <v-card @keyup.enter="set_multiple_numbers">
                <v-card-title class="headline">
                    {{ $store.getters.t("set_multiple_numbers") }}
                </v-card-title>
                <v-card-text>
                    {{ $store.getters.t("multiple_numbers_warning") }}
                    <br /><br />
                    <div class="d-flex flex-row">
                        <v-text-field type="number" outlined dense v-model="multi_count" :label="$store.getters.t('count')">
                        </v-text-field>
                        <v-select class="mx-1" outlined dense multiple v-model="selected_designs" :items="designs_list"
                            :label="$store.getters.t('designs')">
                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0">{{ item.text }}</span>
                                <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ selected_designs.length - 1 }} others)
                                </span>
                            </template>
                        </v-select>
                        <v-btn icon @click="select_all_designs">
                            <v-icon> mdi-checkbox-multiple-marked </v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="set_multiple_numbers()" color="error">{{
                        $store.getters.t("set_numbers")
                    }}</v-btn>
                    <v-btn @click="numbers_dialog = false" color="secondary">{{
                        $store.getters.t("close")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- HIDE DIALOG -->
        <v-dialog v-model="hide_dialog" max-width="400">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("hide_batch") }}
                </v-card-title>
                <v-card-text>
                    {{ $store.getters.t("hide_batch") }}
                    {{ batch.name }} [{{ batch.phase }}]
                    {{ $store.getters.t("for_project") }}:
                    {{ project ? project.name : "---" }}
                    <br />
                    <br />
                    {{ $store.getters.t("hiding_batch_help_text") }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="hide_loading" :loading="hide_loading" v-if="hidden" @click="updateHidingBatch(true)"
                        color="success">
                        {{ $store.getters.t("show") }}
                    </v-btn>
                    <v-btn :disabled="hide_loading" :loading="hide_loading" v-else @click="updateHidingBatch(false)"
                        color="warning">
                        {{ $store.getters.t("hide") }}
                    </v-btn>
                    <v-btn @click="hide_dialog = false" color="secondary">
                        {{ $store.getters.t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- CLONE DIALOG -->
        <v-dialog v-model="clone_dialog" max-width="600">
            <v-card @keyup.enter="clone_batch">
                <v-card-title class="headline">
                    {{ $store.getters.t("clone_batch") }}
                </v-card-title>
                <v-card-text v-if="clone_status_text">
                    {{ this.clone_status_text }}
                </v-card-text>
                <v-card-text v-else>
                    {{ $store.getters.t("clone_batch_warning") }}
                    <br /><br />
                    <div class="d-flex flex-row">
                        <!-- <v-text-field outlined dense v-model="clone_batch_name" :label="$store.getters.t('batch_name')">
                        </v-text-field> -->
                        <PitTextField v-model="clone_batch_name" :label="$store.getters.t('batch_name')"></PitTextField>
                    </div>
                    {{ $store.getters.t("confirm") }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="clone_batch()" color="error" :disabled="clone_status_text.length != 0">{{
                        $store.getters.t("clone_batch")
                    }}</v-btn>
                    <v-btn @click="clone_dialog = false" color="secondary">{{
                        $store.getters.t("close")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DELETE DIALOG -->

        <v-dialog v-model="delete_dialog" max-width="600">
            <v-card>
                <v-card-title class="headline">
                    {{ $store.getters.t("delete_batch") }}
                </v-card-title>
                <v-card-text>{{ $store.getters.t("delete_batch") }}:
                    {{ batch.name }} [{{ batch.phase }}]
                    {{ $store.getters.t("for_project") }}:
                    {{ project ? project.name : "---" }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="delete_batch()" :disabled="delete_disable" color="error">{{
                        $store.getters.t("delete")
                    }}</v-btn>
                    <v-btn @click="delete_dialog = false" color="secondary">{{
                        $store.getters.t("close")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss" scoped>
.text-transform-none {
    text-transform: none !important;
}
</style>

<script>
import _ from "lodash";
import PitTextField from "../fields/PitTextField.vue";

const STORE_COUNTS = {
    "assembly": "assd_batch_counts",
    "module": "modd_batch_counts",
}
const PUT_ON_BATCH_ACTIONS = {
    "assembly": "v2/quantities/putAssdCountOnBatch",
    "module": "v2/quantities/putModdCountOnBatch",
}

export default {
    name: "DropdownBatchOptions",
    components: { PitTextField },
    props: ["project", "batch", "designs"],
    data: () => ({
        numbers_dialog: false,
        multi_count: 0,
        selected_designs: [],

        hide_dialog: false,
        hide_loading: false,

        clone_dialog: false,
        clone_batch_name: "",
        clone_status_text: "",

        delete_dialog: false,
        delete_disable: false,

        edit_batch_name: "",
        edit_batch_mode: false,
    }),
    computed: {
        hidden() {
            return !this.batch.show_in_viewer;
        },
        phase_batches() {
            return this.$store.state.v2.constructibles.batches.filter(
                (b) => b.phase == this.batch.phase
            );
        },
        batch_edit_is_duplicate() {
            return (
                this.batch.name != this.edit_batch_name &&
                this.phase_batches
                    .map((b) => b.name)
                    .includes(this.edit_batch_name)
            );
        },
        designs_list() {
            return _.orderBy(this.designs, "name").map((design) => ({
                text: design.name,
                value: design.uuid,
            }));
        },
    },
    methods: {
        async updateHidingBatch(show_in_viewer) {
            this.hide_loading = true;
            let batch = { ...this.batch, show_in_viewer };
            this.$store
                .dispatch("v2/constructibles/putBatch", batch)
                .finally(() => {
                    this.hide_loading = false;
                    this.hide_dialog = false;
                });
        },
        async delete_batch() {
            this.delete_disable = true;
            await this.$store
                .dispatch("v2/constructibles/deleteBatch", this.batch.uuid)
        },
        edit_batch() {
            this.edit_batch_mode = true;
            this.edit_batch_name = this.batch.name;
        },
        async save_batch() {
            if (this.batch_edit_is_duplicate) return;
            let batch = { ...this.batch, name: this.edit_batch_name };
            await this.$store
                .dispatch("v2/constructibles/putBatch", batch)
            this.edit_batch_mode = false;
        },
        set_multiple_numbers() {
            this.selected_designs.forEach((design) => {
                let level = this.designs.find(d => d.uuid == design)?.level
                this.$store.commit(PUT_ON_BATCH_ACTIONS[level], {
                    phase: this.batch.phase,
                    batch: this.batch.uuid,
                    count: Number(this.multi_count),
                    design,
                });
            });
            this.numbers_dialog = false;
        },
        async clone_batch() {

            // Create batches
            this.clone_status_text = "Clone batch...";
            let new_batch = {
                name: this.clone_batch_name,
                phase: this.batch.phase,
                project: this.batch.project,
                show_in_viewer: this.batch.show_in_viewer,
                priority: this.batch.priority,
                production_line: this.batch.production_line,
            };
            await this.$store.dispatch("v2/constructibles/postBatch", new_batch);

            await new Promise((r) => setTimeout(r, 2000)); // Just for user awarenes
            // Update batch counts
            this.clone_status_text = "Updating batch counts...";
            let nb = this.$store.state.v2.constructibles.batches.find(
                (b) =>
                    b.name == this.clone_batch_name &&
                    b.phase == this.batch.phase
            );
            // Iterate designs
            this.designs.forEach((design) => {
                let level = design?.level
                // Get old batch-design count
                let count_obj = this.$store.state.v2.quantities[STORE_COUNTS[level]].find(
                    (co) =>
                        co.phase == this.batch.phase &&
                        co.batch == this.batch.uuid &&
                        co.design == design.uuid
                );
                // Set new batch-design count
                if (count_obj) {
                    this.$store.commit(
                        PUT_ON_BATCH_ACTIONS[level],
                        {
                            phase: nb.phase,
                            batch: nb.uuid,
                            design: design.uuid,
                            count: count_obj.count,
                        }
                    );
                }
            });

            // Finish
            await new Promise((r) => setTimeout(r, 2000)); // Just for user awarenes
            this.clone_status_text = "Finished.";
            await new Promise((r) => setTimeout(r, 2000)); // Just for user awarenes
            this.clone_dialog = false;
            this.clone_status_text = "";
        },
        select_all_designs() {
            if (this.selected_designs.length == 0)
                this.selected_designs = this.designs.map((d) => d.uuid);
            else this.selected_designs = [];
        },
    },
};
</script>