<template>
    <div class="width-100" :class="dark ? 'dmain' : 'lmain'">
        <div class="d-flex width-100">
            <div class="d-flex flex-column" :class="dark ? 'dhead' : 'lhead'">
                <div class="rowlab">
                    {{ $store.getters.t("production_line") }}
                </div>
                <v-list class="pa-0" style="background-color: transparent">
                    <v-hover
                        v-slot:default="{ hover }"
                        v-for="pl in production_lines"
                        :key="pl.uuid"
                    >
                        <ChartProdLineHeader :pl="pl" :hover="hover" />
                    </v-hover>
                </v-list>
            </div>

            <div class="d-flex flex-column h-scroll" id="h-scroll-box">
                <div
                    class="timescale"
                    :style="timescale_style"
                    :class="dark ? 'dhead' : 'lhead'"
                >
                    <div v-for="step in timesteps" :key="step.getTime()">
                        <Timestamp :value="step" />
                    </div>
                </div>
                <template v-for="(pl, i) in production_lines">
                    <div
                        class="prodline-row d-flex"
                        :class="dark ? 'dmain' : 'lmain'"
                        :style="timescale_style"
                        v-for="(row, j) in pl.batches_rows"
                        :key="pl.uuid + '_' + j"
                    >
                        <NowVertLine
                            v-if="i == 0 && j == 0"
                            :rows_count="rows_count"
                            :x="now_x"
                        />
                        <BatchItem
                            v-for="batch in row"
                            :key="batch.uuid"
                            :batch="batch"
                            :timeframe="timeframe"
                        />
                    </div>
                </template>
                <div
                    class="prodline-row d-flex"
                    :class="dark ? 'dmain' : 'lmain'"
                    :style="timescale_style"
                >
                    <!-- This is a placeholder to pick height because of the NowVertLine tag -->
                </div>
            </div>
        </div>
        <OverviewRightDrawer :item="selected_item" />
    </div>
</template>

<style lang="scss" scoped>
@import "./style.scss";
.pointer {
    cursor: pointer;
}
</style>

<script>
import _ from "lodash";
import bus from "/src/utils/event_bus";
import { get_position } from "/src/components/pm/timesteps";
import BatchItem from "./BatchItem.vue";
import OverviewRightDrawer from "./OverviewRightDrawer";
import NowVertLine from "./NowVertLine";
import Timestamp from "../renderers/Timestamp.vue";
import ChartProdLineHeader from "./ChartProdLineHeader";
export default {
    components: { BatchItem, OverviewRightDrawer, Timestamp, NowVertLine, ChartProdLineHeader },
    props: ["production_lines", "timeframe"],
    data: () => ({ selected_item: null }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        rows_count() {
            return this.production_lines
                .map((pl) => pl.batches_rows.length)
                .reduce((a, b) => a + b);
        },
        timesteps() {
            return this.$store.state.overview.timesteps;
        },
        tsl() {
            return this.$store.state.overview.tsl;
        },
        now_x() {
            return get_position(this.timeframe, Date.now(), this.tsl);
        },
        timescale_style() {
            return { width: this.timesteps.length * 8 + "em" };
        },
    },
    watch: {
        timesteps() {
            this.scroll_to_now();
        },
        production_lines() {
            this.scroll_to_now();
        },
    },
    methods: {
        scroll_to_now: _.debounce(function () {
            console.log("[i] Scrolling to NOW");
            try {
                var elem = document.getElementById("h-scroll-box");
                // Convert em to pixels
                const pixel_scroll =
                    parseFloat(getComputedStyle(elem).fontSize) * this.now_x;
                elem.scrollLeft = pixel_scroll - 100;
            } catch {
                console.log("[!] Can't scroll to NOW");
            }
        }, 300),
    },
    mounted() {
        bus.$on("scroll_to_now", this.scroll_to_now);
        this.scroll_to_now();
    },
    beforeDestroy() {
        bus.$off("scroll_to_now");
    },
};
</script>