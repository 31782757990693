<template>
    <div class="d-flex">
        <v-btn v-for="branch in branches" :key="branch" :loading="loading" :disabled="loading"  outlined class="ma-1"
            @click="set_branch(branch)"> {{ branch || "unknown" }} </v-btn>
        <v-dialog v-model="dialog" max-width="1000">
            <v-card v-if="!loading">
                <v-card-title class="headline">  {{ $store.getters.t("bill_of_materials") }} - {{ selected_branch }} ({{ $store.getters.t("parts_not_in_database") }}) </v-card-title>
                <v-card-subtitle> {{ $store.getters.t("select_part_to_hightlight") }} </v-card-subtitle>
                <v-data-table :page.sync="page" :headers="headers" :items="table_items"
                    :no-data-text="$store.getters.t('no_parts_on_this_branch')">
                    <template v-slot:item.select="{ item }">
                        <v-btn color="primary" small @click="select(item.uuids)"> {{ $store.getters.t("select") }}
                        </v-btn>
                    </template>
                </v-data-table>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" color="secondary"> {{ $store.getters.t("close") }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { clean_non_logistics_branches } from "./branches"
import { backend_api_v2 } from '/src/utils/apiv2'
export default {
    name: "ButtomUnknownsBOM",
    data: () => ({
        // State
        selected_branch: "unknown",
        dialog: false,
        loading: false,  // used with strings
        page: 1,
        last_ass_loaded: null,

        // Async loaded
        db_parts_gids: [],

        // Static
        headers: [
            { text: "BRANCH", value: "branch" },
            { text: "IFC Name", value: "name" },
            { text: "IFC GID", value: "gid" },
            { text: "IFC Object Type", value: "object_type" },
            { text: "Zone", value: "zone" },
            { text: "Select", value: "select" },
        ],
    }),
    computed: {
        assembly() {
            return this.$store.state.v2.selected.selected_ass
        },
        viewer_bom() {
            return this.$store.state.v2.viewer.bom
        },
        branches() {
            if (this.loading) return ["unknown"]
            let branches = this.non_logistics_parts.map(part => part.branch)
            return clean_non_logistics_branches(branches)
        },
        non_logistics_parts() {
            let nlps = this.viewer_bom.filter(p => !this.db_parts_gids.includes(p.gid))
            return nlps
        },
        table_items() {
            let rows = []
            this.non_logistics_parts
                .forEach(part => {
                    let row = {
                        gid: part.gid,
                        name: part.name,
                        branch: part.branch,
                        tag: part.ifc_tag,
                        object_type: part.object_type,
                        zone: part.zone,
                        uuids: [part.gid],
                    }
                    if (this.selected_branch == row.branch) {
                        rows.push(row);
                    }
                })
            return rows
        }
    },
    watch: {
        dialog(d) {
            if (!d) {
                this.page = 1;
            }
        },
        assembly(ass) {
            if (ass?.uuid != this.last_ass_loaded) this.loadDBPartUuids()
        }
    },
    methods: {
        set_branch(branch) {
            this.selected_branch = branch
            this.dialog = true
        },
        select(uuids) {
            this.$store.commit("v2/viewer/setSelectedPartsUuids", uuids);
            this.dialog = false;
        },
        loadDBPartUuids() {
            let assuuid = this.assembly?.uuid
            this.last_ass_loaded = assuuid
            if (!assuuid) return
            this.loading = true
            this.db_parts_gids = []
            let url = `/constructibles/assemblies/${assuuid}/part_gids/`
            backend_api_v2.get(url).then(({ data }) => {
                this.db_parts_gids = data
                this.loading = false
            });
        }
    },
    mounted() {
        this.loadDBPartUuids()
    }
};
</script>
