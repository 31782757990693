<template>
    <v-container fluid class="fill-height align-space-around  login-container">
        <v-row justify="center">
            <!-- <div class="text-center ma-5">
                <h1 class="display-2 font-weight-light mb-2">
                    {{ $store.getters.t("welcome_to") }}
                </h1>
                <img v-if="dark" src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/produuzit_neg.svg" />
                <img v-else src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/produuzit.svg" />
            </div>
            <br /> -->

            <v-col xl="2" lg="3" class="ma-10">
                <!-- <img v-if="dark" src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_dark.svg" />
                <img v-else src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo.svg" /> -->
                <img src="https://woodwall-public.s3.eu-central-1.amazonaws.com/img/pit_logo_clear.svg" />
            </v-col>
            <v-col xl="2" lg="3" class="ma-4">
                <v-form ref="changepass" v-if="token_enter_mode && recovery_mode">
                    <h3 class="font-weight-light white--text" color="white">
                        {{ $store.getters.t("check_email_token_warning") }}
                    </h3>
                    <v-text-field solo light class="mx-1" dense v-model="token" :label="$store.getters.t('token')"
                        name="token" />
                    <v-text-field solo light class="mx-1 hacky-white-error" dense :label="$store.getters.t('password')"
                        v-model="password_A" type="password" autocomplete="new-password" :rules="[rules.password]">
                    </v-text-field>
                    <v-text-field solo light class="mx-1 hacky-white-error" dense
                        :label="$store.getters.t('password_again')" v-model="password_B" type="password"
                        autocomplete="new-password" :rules="[rules.password, rules.matchPassword]">
                    </v-text-field>
                    <v-row class="mx-0 my-2">
                        <v-btn outlined :loading="loading" :disabled="loading" color="white"
                            @click="token_enter_mode = false">
                            <v-icon left> mdi-arrow-left </v-icon>
                            {{ $store.getters.t("back") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outlined :loading="loading" color="white" @click="post_new_password">
                            {{ $store.getters.t("save") }} </v-btn>
                    </v-row>
                </v-form>
                <v-form @keyup.enter.native="launch_recovery" v-else-if="recovery_mode">
                    <v-text-field solo light v-model="email" :label="$store.getters.t('email')" name="email"
                        class="hacky-white-error" autocomplete="email" append-icon="mdi-email" type="email" />
                    <v-row class="mx-0">
                        <v-btn outlined :loading="loading" :disabled="loading" color="white" @click="recovery_mode = false">
                            <v-icon left> mdi-arrow-left </v-icon>
                            {{ $store.getters.t("back") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn outlined :loading="loading" color="white" @click="launch_recovery"> {{
                            $store.getters.t("recovery")
                        }} </v-btn>
                    </v-row>
                </v-form>
                <v-form @keyup.enter.native="login" v-else>
                    <v-text-field solo light v-model="username" autocomplete="username" :label="$store.getters.t('login')"
                        class="hacky-white-error" name="login" append-icon="mdi-account" type="text" :error="error" />
                    <v-text-field solo light v-model="password" autocomplete="password" id="password"
                        class="hacky-white-error" :label="$store.getters.t('password')" name="password"
                        append-icon="mdi-lock" type="password" :error="error" :error-messages="error
                            ? $store.getters.t('wrong_credentials')
                            : ''
                            " />
                    <div class="mx-auto mb-6" v-if="otp_needed">
                        <span>OTP needed...</span>
                        <v-otp-input label="OTP" max-width="100" v-model="otp" @finish="login"></v-otp-input>
                    </div>
                    <!-- <v-text-field class="mx-1" outlined append-icon="mdi-qrcode" v-if="otp_needed" label="OTP"
                        v-model="otp">
                    </v-text-field> -->
                    <v-row class="mx-0">
                        <a class="white--text" @click="recovery_mode = true">
                            {{ $store.getters.t("password_recovery") }}
                        </a>
                        <v-spacer></v-spacer>
                        <v-btn outlined :loading="loading" :disabled="loading" color="white" @click="login">{{
                            $store.getters.t("login")
                        }}</v-btn>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss">
.login-container {
    background-image: url(https://woodwall-public.s3.eu-central-1.amazonaws.com/img/connectinq_fade.jpg); // Update the path accordingly
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.v-application .hacky-white-error .error--text {
    color: rgb(247, 236, 236) !important;
    caret-color: rgb(255, 241, 241) !important;
}
</style>

<script>
import bus from "/src/utils/event_bus";
import { backend_api_v2 } from "/src/utils/apiv2";
export default {
    props: ["redirect_route"],
    components: {},
    data: () => ({
        loading: false,
        username: localStorage.getItem("last_username"),
        password: "",
        otp: "",
        password_A: "", // for recovery
        password_B: "", // for recovery
        email: "",
        token: "",
        error: false,
        recovery_mode: false,
        token_enter_mode: false,
        otp_needed: false,
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
        authenticated() {
            return this.$store.state.v2.session.authenticated;
        },
        session_username() {
            return this.$store.state.v2.session.user_object
                ? this.$store.state.v2.session.user_object.username
                : "";
        },
        rules() {
            return {
                password: (v) =>
                    (v && v.length >= 8) ||
                    "Your password must contain at least 8 characters.",
                matchPassword: () =>
                    this.password_A == this.password_B ||
                    "Passwords not matching.",
            };
        },
    },
    watch: {
        username(u) {
            this.error = false;
            localStorage.setItem("last_username", u);
        },
        password() {
            this.error = false;
        },
    },
    methods: {
        login() {
            this.loading = true;
            bus.$emit("notification", {
                timeout: 1000,
                text: "Trying to login",
                color: "info",
            });
            this.dialog = false;
            let payload = {
                username: this.username,
                password: this.password,
            };
            if (this.otp != "") payload.otp = this.otp;
            this.$store
                .dispatch("v2/session/login", payload)
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Logged In",
                        color: "success",
                    });
                    this.error = false;
                    this.loading = false;
                    this.$store.dispatch("initialLoad")
                    this.redirect();
                })
                .catch((e) => {
                    if (e.response?.status == 401) {
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: "Wrong credentials.",
                            color: "warning",
                        });
                        this.error = true;
                    } else if (
                        e.response?.status == 400 &&
                        "otp" in e.response.data
                    ) {
                        console.log("[i] OPT Needed.");
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: e.response.data.otp[0],
                            color: "info",
                        });
                        this.otp_needed = true;
                    } else if (
                        e.response?.status == 400 &&
                        "otp_invalid" in e.response.data
                    ) {
                        console.log("[i] OPT Invalid.");
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: "OTP invalid",
                            color: "error",
                        });
                        this.otp_needed = true;
                    } else {
                        bus.$emit("notification", {
                            timeout: 3000,
                            text: "Can't perform a login now. Try again or contact support.",
                            color: "error",
                        });
                        throw e;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        launch_recovery() {
            this.loading = true;
            backend_api_v2
                .post("/my_user/password_reset/", { email: this.email })
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Token sended.",
                        color: "success",
                    });
                    this.token_enter_mode = true;
                })
                .catch((e) => {
                    let message = "Can't send token.";
                    if (e.response.status == 400)
                        message = e.response.data.email[0];
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: message,
                        color: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
        post_new_password() {
            if (!this.$refs.changepass.validate()) {
                bus.$emit("notification", {
                    timeout: 1000,
                    text: "Password wrong.",
                    color: "error",
                });
                return
            }
            this.loading = true;
            backend_api_v2
                .post("/my_user/password_reset/confirm/", {
                    token: this.token,
                    password: this.password_A,
                })
                .then(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Password changed.",
                        color: "success",
                    });
                    this.token_enter_mode = false;
                    this.recovery_mode = false;
                })
                .catch((e) => {
                    let message = "Can't change password.";
                    if (e.response.status == 404) message = "Invalid token";
                    if (e.response.status == 400)
                        message = e.response.data.password[0];
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: message,
                        color: "error",
                    });
                })
                .finally(() => (this.loading = false));
        },
        redirect() {
            if (this.redirect_route) {
                console.log("[i] Redirecting to prev route!");
                this.$router
                    .push(this.redirect_route)
                    .catch((e) => console.log(e));
            } else if (this.authenticated) {
                console.log("[i] Redirecting to viewer.");
                this.$router.push("viewer").catch((e) => console.log(e));
            }
        },
    },
    mounted() {
        if (this.authenticated) this.redirect();
    },
};
</script>