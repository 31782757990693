export function state_init(store, route) {
    console.log("[i] Setting state from url")
    console.log("THIS ROUTE:", route)
    console.log("THIS STATE:", store.state)

    // Get url params
    let project_uuid = route.params.project_uuid;
    let assd_uuid = route.params.assd_uuid;
    let ass_uuid = route.params.ass_uuid;
    let modd_uuid = route.params.modd_uuid;
    let mod_uuid = route.params.mod_uuid;

    // project views
    if (project_uuid) {
        // Select at menu
        store.commit("v2/selected/setProjectSelection", project_uuid)
        if (route.name == "project_viewer") {
            console.log("[i] Setting viewer mode (project viewer). This to be reviewed")

            store.dispatch("v2/viewer/buildProjectResourcesAsSelectedWall");
            store.commit("setViewerMode");
            store.commit("v2/viewer/setDesignsMode", true);
        }
        else {
            console.log("[i] Setting manager mode because of route having pr_uuid and not being a viewer route.")
            //  But, if the company has by_date mode, this is wrong I think.
            store.commit("setManagerMode");
        }
    }




    // viewer: assembly designs
    else if (assd_uuid) {
        store.commit("v2/viewer/setDesignsMode", true);
        store.commit("setViewerMode");
        store
            .dispatch("v2/selected/loadAssemblyDesign", assd_uuid)
            .then(() => {
                let pr_uuid = store.state.v2.selected.selected_assd?.project
                store.commit("v2/selected/setProjectSelection", pr_uuid)
            });
    }

    // viewer assembly
    else if (ass_uuid) {
        store.commit("v2/viewer/setDesignsMode", false);
        store.commit("setViewerMode");
        store
            .dispatch("v2/selected/loadAssembly", ass_uuid)
            .then(() => {
                let pr_uuid = store.state.v2.selected.selected_assd?.project;
                store.commit("v2/selected/setProjectSelection", pr_uuid)
            });
    }



    // viewer: module designs
    else if (modd_uuid) {
        store.commit("v2/viewer/setDesignsMode", true);
        store.commit("setViewerMode");
        store
            .dispatch("v2/selected/loadModuleDesign", modd_uuid)
            .then(() => {
                let pr_uuid = store.state.v2.selected.selected_modd?.project
                store.commit("v2/selected/setProjectSelection", pr_uuid)
            });
    }

    // viewer module
    else if (mod_uuid) {
        store.commit("v2/viewer/setDesignsMode", false);
        store.commit("setViewerMode");
        store
            .dispatch("v2/selected/loadModule", mod_uuid)
            .then(() => {
                let pr_uuid = store.state.v2.selected.selected_modd?.project;
                store.commit("v2/selected/setProjectSelection", pr_uuid)
            });
    }

    else if ([
        "company_settings",
        "company_reports",
        "production_overview",
    ].includes(route.name)) {
        store.commit("v2/selected/setProjectSelection", "all")
    }

    // Other views are manager mode, unless a prod user
    else {
        console.log("[i] Setting manager mode here only if not prod user.")
        if (store.getters["v2/session/iam_prod_worker"]) {
            store.commit("setViewerMode");
        }
        else store.commit("setManagerMode");

    }
}