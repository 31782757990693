<template>
    <div>
        <v-sheet class="ma-2" v-if="loading_status" width="500">
            <v-progress-linear color="accent" height="30" :value="loading_status.count / loading_status.total * 100">
                Parsing parts: {{ loading_status.count }} of {{ loading_status.total }}. </v-progress-linear>
        </v-sheet>
        <v-btn-toggle loading color="accent" multiple dense v-model="selected_zones" class="mr-3">
            <v-btn v-for="z in zones" :key="z" :value="z">{{ z }}</v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>

function convertToIntegers(arr) {
  return arr.map(item => {
    const num = parseInt(item, 10);
    return isNaN(num) ? item : num;
  });
}

import bus from "/src/utils/event_bus";
export default {
    name: "SelectorZones",
    data: () => ({
        selected_zones: [],
    }),
    computed: {
        loading_status() { return this.$store.state.v2.viewer.bom_loading_status },
        zones() { return this.$store.getters["v2/viewer/zones"] },
        default_zones() {
            try {
                let default_zones =
                    this.$store.state.v2.session.user_object.auth
                        .selected_production_station_zones || [];
                return default_zones.length != 0 ? default_zones : this.zones;
            } catch {
                return this.zones;
            }
        },
    },
    watch: {
        selected_zones(sz) {
            this.$store.commit("v2/viewer/setSelectedZones", sz);
        },
    },
    methods: {
        set_defaults() {
            setTimeout(() => {
                this.selected_zones = convertToIntegers(this.default_zones)
                this.$store.commit(
                    "v2/viewer/setSelectedZones",
                    this.selected_zones
                );
            }, 2000);
        },
    },
    mounted() {
        this.set_defaults();
        bus.$on("events/viewer/default_zone_selection", this.set_defaults);
    },
    beforeDestroy() {
        bus.$off("events/viewer/default_zone_selection");
    },
};
</script>