var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$store.getters.t("styles"))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$store.getters.t("company_files_for_ui"))+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-2 my-1"},[_c('IconBoolean',{attrs:{"value":_vm.has_company_logo}}),_vm._v(" "+_vm._s(_vm.$store.getters.t("logo"))+" ")],1),_c('v-spacer'),(_vm.has_company_logo)?_c('DialogThumbnails',{staticClass:"mx-2",attrs:{"title":"company_logo","files":_vm.files.filter(cf => cf.meta.section == 'logo')}}):_vm._e(),_c('UploadFilePackageField',{attrs:{"pictures":"","label":"logo","section":"logo","small":"","package_params":{
                company: _vm.company_uuid,
                name: 'resources',
            }},model:{value:(_vm.pck_uuid),callback:function ($$v) {_vm.pck_uuid=$$v},expression:"pck_uuid"}})],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-2 my-1"},[_c('IconBoolean',{attrs:{"value":_vm.has_company_banner}}),_vm._v(" "+_vm._s(_vm.$store.getters.t("sticker_banner"))+" ")],1),_c('span',{staticClass:"ml-2 my-1"},[_vm._v(" (1000*160 px) ")]),_c('v-spacer'),(_vm.has_company_banner)?_c('DialogThumbnails',{staticClass:"mx-2",attrs:{"title":"company_banners","files":_vm.files.filter(cf => cf.meta.section == 'banner')}}):_vm._e(),_c('UploadFilePackageField',{attrs:{"pictures":"","multiple":"","label":"banners","section":"banner","small":"","package_params":{
                    company: _vm.company_uuid,
                    name: 'resources',
                }},model:{value:(_vm.pck_uuid),callback:function ($$v) {_vm.pck_uuid=$$v},expression:"pck_uuid"}})],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-2 my-1"},[_c('IconBoolean',{attrs:{"value":_vm.has_company_color}}),_vm._v(" Corporate color: ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.color_dialog = true}}},[_c('v-icon',[_vm._v(" mdi-palette ")])],1),_c('v-btn',{attrs:{"loading":_vm.deleting_color,"disabled":_vm.deleting_color || !_vm.has_company_color,"icon":""},on:{"click":_vm.delete_color}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.color_dialog),callback:function ($$v) {_vm.color_dialog=$$v},expression:"color_dialog"}},[_c('v-card',[_c('v-color-picker',{attrs:{"mode":"hexa"},model:{value:(_vm.company_color),callback:function ($$v) {_vm.company_color=$$v},expression:"company_color"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","loading":_vm.saving_color,"disabled":_vm.saving_color},on:{"click":_vm.save_color}},[_vm._v(" "+_vm._s(_vm.$store.getters.t("save"))+" ")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.color_dialog = false}}},[_vm._v(" "+_vm._s(_vm.$store.getters.t("close"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }