<template>
    <table>
        <tr>
            <td>Actual start:</td>
            <td><Timestamp :value="item.actual_start" /></td>
        </tr>
        <tr>
            <td>Actual end:</td>
            <td><Timestamp :value="item.actual_end" /></td>
        </tr>
        <tr>
            <td>Forecasted start:</td>
            <td><Timestamp :value="item.fc_start" /></td>
        </tr>
        <tr>
            <td>Forecasted end:</td>
            <td><Timestamp :value="item.fc_end" /></td>
        </tr>
    </table>
</template>
<style scoped>
th,
td {
    padding: 3px;
}
</style>
<script>
import Timestamp from "/src/components/renderers/Timestamp";
export default {
    components: {
        Timestamp,
    },
    props: ["item"],
};
</script>