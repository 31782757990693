<template>
    <v-container fluid>
        <v-row>
            <span class="ml-5 mt-3 grey--text text--lighten-2 display-1">
                Loading wall...
            </span>
        </v-row>
    </v-container>
</template>

<script>
// http://localhost:8080/app/external/c7239ca0/20001/AAA
export default {
    props: ["company_uuid8", "project_number", "wall_name"],
    computed: {
        project() {
            console.log("computing project");
            return this.$store.state.projects.projects.find(
                (p) => p.project_number == this.project_number
            );
        },
        wall() {
            console.log("computing wall");
            return this.$store.state.projects.project_walls.find(
                (w) => w.name == this.wall_name
            );
        },
    },
    watch: {
        project: {
            handler: "loadProject",
            immediate: true,
        },
        wall: {
            handler: "loadWall",
            immediate: true,
        },
    },
    methods: {
        loadProject(p) {
            if (p) this.$store.dispatch("projects/loadProject", p.uuid);
        },
        loadWall(w) {
            if (w)
                this.$router
                    .push("/walls/" + w.uuid)
                    .catch(() => console.log("[!] Can't update route."));
        },
    },
};
</script>