
export function getCoordinateShiftDueToScale(size, scale) {
    var newWidth = scale * size.width;
    var newHeight = scale * size.height;
    var dx = (newWidth - size.width) / 2;
    var dy = (newHeight - size.height) / 2;
    return {
        x: dx,
        y: dy,
    };
}

export function getCoords(elem) {
    // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop =
        window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { x: Math.round(left), y: Math.round(top) };
}


export function getRelativePosition(canvas, point, originalSize, scale) {
    var domCoords = getCoords(canvas);

    var canvasX = point.x - domCoords.x;
    var canvasY = point.y - domCoords.y;

    var relativeX = canvasX / ((originalSize.width * scale) / 2) - 1;
    var relativeY = canvasY / ((originalSize.height * scale) / 2) - 1;
    return { x: relativeX, y: relativeY };
}


export function scaleFrom(originalSize, zoomOrigin, currentScale, newScale) {
    var currentShift = getCoordinateShiftDueToScale(
        originalSize,
        currentScale
    );
    var newShift = getCoordinateShiftDueToScale(originalSize, newScale);

    var zoomDistance = newScale - currentScale;

    var shift = {
        x: currentShift.x - newShift.x,
        y: currentShift.y - newShift.y,
    };

    var output = {
        x: zoomOrigin.x * shift.x,
        y: zoomOrigin.y * shift.y,
        z: zoomDistance,
    };
    return output;
}