<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                EDIT INFO
            </v-btn>
        </template>

        <v-card>
            <v-card-title> Company Info </v-card-title>

            <v-card-text>
                <v-form ref="form" @keyup.enter.native="save">
                    <v-text-field outlined dense class="mx-1" v-model="name" label="Company Name">
                    </v-text-field>
                    <v-text-field outlined dense class="mx-1" v-model="address" label="Address">
                    </v-text-field>
                    <v-text-field outlined dense class="mx-1" v-model="administration_email" label="Admin email">
                    </v-text-field>
                    <v-text-field outlined dense class="mx-1" v-model="contact_email" label="Contact email">
                    </v-text-field>
                    <v-autocomplete outlined dense clearable class="mx-1" v-model="location" :items="locations"
                        label="Locations">
                    </v-autocomplete>

                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" :disabled="loading" color="success" @click="save">
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
const locations = require('./locations.json');
// const formattedLocations = locations.map(location => ({ text: location, value: location }));

export default {
    data: () => {
        return {
            loading: false,
            dialog: false,
            name: "",
            address: "",
            administration_email: "",
            contact_email: "",
            location: "",
            locations: locations,
        };
    },
    watch: {
        company() {
            this.fill_form();
        },
        dialog(d) {
            if (d) this.fill_form();
        },
    },
    computed: {
        company() { return this.$store.state.v2.company.company },
    },
    methods: {
        fill_form() {
            this.name = this.company.name;
            this.address = this.company.address;
            this.administration_email = this.company.administration_email;
            this.contact_email = this.company.contact_email;
            this.location = this.company.location;
        },
        save() {
            this.loading = true;
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch("v2/company/patchCompany", {
                        name: this.name,
                        address: this.address,
                        administration_email: this.administration_email,
                        contact_email: this.contact_email,
                        location: this.location,
                    })
                    .then(() => {
                        this.loading = false;
                        this.dialog = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                this.dialog = false;
            }
        },
    },
    mounted() {
        this.fill_form();
    },
};
</script>