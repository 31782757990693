<template>
    <v-dialog v-model="dialog" width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-badge color="accent" v-model="active" dot> <v-icon> mdi-filter-settings-outline </v-icon> </v-badge>
            </v-btn>
        </template>
        <v-card>
            <v-card-title> Designs Filters </v-card-title>
            <v-card-text>
                <v-select hide-details clearable dense v-model="selected_ifc_assembly_name" :items="ifc_assembly_names"
                    label="Assembly name" outlined class="phase-select mx-2 mb-0"></v-select>
                <v-text-field label="Search" outlined dense class="ma-2" v-model="search" clearable></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="clear">
                    {{ $store.getters.t("clear") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["designs"],
    data: () => ({
        dialog: false,
        selected_ifc_assembly_name: null,
        search: "",
    }),
    computed: {
        active() {
            return Boolean(this.selected_ifc_assembly_name || this.search)
        },
        ifc_assembly_names() {
            let ass_names = this.designs
                .map((assd) => assd.meta?.ifc_assembly_meta?.name)
                .filter((an) => an != "" && an);
            return [...new Set(ass_names)];
        },
        filtered_designs() {
            if (!this.designs) return []
            let fd = this.designs
            if (this.selected_ifc_assembly_name) fd = fd.filter(d =>
                d.meta?.ifc_assembly_meta?.name == this.selected_ifc_assembly_name
            )
            if (this.search && this.search != "") fd = fd.filter(d =>
                d.name.toLowerCase().includes(this.search.toLowerCase())
            )
            return fd
        }
    },
    methods: {
        clear() {
            this.selected_ifc_assembly_name = null
            this.search = ""
        }
    },
    watch: {
        designs() {
            console.log("updating desings input for filter")
        },
        filtered_designs: {
            immediate: true,
            handler(fd) {
                console.log("[i] Updating designs filter.")
                this.$emit("input", fd)
            }
        }
    },
}
</script>