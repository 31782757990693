var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-1",attrs:{"loading":_vm.loading,"outlined":"","height":"55"}},[_c('v-btn',{attrs:{"small":"","block":"","color":"secondary","text":"","to":{
    name: `constructibles_manager_${_vm.level}_design_detail`,
    query: {
        ..._vm.$route.query,
    },
    params: {
        ..._vm.level == 'assembly' && { assd_uuid: _vm.duuid },
        ..._vm.level == 'module' && { modd_uuid: _vm.duuid },
    }
}}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.name))]),(!_vm.loading)?_c('IconRevision',{attrs:{"rev":_vm.design.revision}}):_vm._e()],1),_c('br')]),(!_vm.loading)?_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.design.meta.dwg_count)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-floor-plan ")])],1)]}}],null,false,430625592)},[_c('span',[_vm._v(_vm._s(_vm.design.meta.dwg_count)+" drawings.")])]),_c('IconHasIFC',{attrs:{"value":_vm.design.meta.has_ifc}}),_c('IconAnalysisStatus',{attrs:{"value":_vm.design.meta.analysis}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }