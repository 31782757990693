<template>
    <v-sheet class="pa-2 my-2" outlined rounded>
        <div class="d-flex">
            <h4>{{ $store.getters.t("batches_order") }}</h4>
            <v-spacer></v-spacer>
            <ButtonBatchesPLSortingDialog v-if="item" :item="item" />
        </div>
        <div v-for="batch in item.batches" :key="batch.uuid">
            {{ batch.pl_ordering }} - {{ batch.name }}
        </div>
    </v-sheet>
</template>

<script>
import ButtonBatchesPLSortingDialog from "/src/components/sorting/ButtonBatchesPLSortingDialog";
export default {
    components: {
        ButtonBatchesPLSortingDialog,
    },
    props: ["item"],
};
</script>