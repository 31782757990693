<template>
    <div id="canvas-container" ref="canvasContainer">
        <canvas @click="pick" id="three-canvas" ref="threeCanvas"></canvas>
        <v-overlay :value="loading" :absolute="true" :opacity="0.4">
            <div class="d-flex flex-column align-center">
                <v-progress-circular indeterminate color="white"></v-progress-circular>
                <span class="display-1 font-weight-light mt-4"> {{ $store.getters.t("loading_ifc") }} </span>
            </div>
        </v-overlay>
    </div>
</template>

<style lang="scss" scoped>
#canvas-container {
    height: calc(100% - 100px);
    width: 100%;
}

#three-canvas {
    height: 100%;
    width: 100%;
    // background-color: lightblue;
    // touch-action: none;
}
</style>

<script>
import { ifcViewerMixin, MODEL_KEY } from "./ifcViewerMixin"


export default {
    name: "IFCJSViewAssembly",
    mixins: [ifcViewerMixin],
    computed: {
        design() { return this.$store.state.v2.selected.selected_assd },
        bom() { return this.$store.state.v2.viewer.bom },
        layer_names() { return this.$store.getters["v2/viewer/zones"] },
        selected_zones() { return this.$store.state.v2.viewer.selected_zones },
    },
    watch: {
        selected_zones(sz) {
            this.render_layers(sz)
        },
        guid_maps() {
            this.build_layer_subsets()
        },
        bom() {
            this.build_layer_subsets()
        },
        model() {
            this.build_layer_subsets()
        }
    },
    methods: {
        build_layer_subsets() {
            let zones = this.layer_names
            // Deferrer.
            let model = this.ifc_models[MODEL_KEY]
            if (
                !this.guid_maps[MODEL_KEY] ||
                this.bom.length == 0 ||
                !model
            ) {
                console.log("⌚[produuz.it IFC Viewer] Can't build zones right now. Skipping...")
                return
            }

            let mid = model.mesh.modelID

            // Clean subsets
            Object.keys(this.layer_subsets).forEach(k => {
                this.layer_subsets[k].removeFromParent()
            })
            this.layer_subsets = {}

            try {
                for (const zone of zones) {
                    const zonestr = zone.toString()
                    let zone_guids = this.bom.filter(p => p.zone == zone).map(p => p.gid)
                    let zone_eids = zone_guids.map((uuid) => this.guid_maps[MODEL_KEY]?.[uuid])
                    let subset = this.ifcLoader.ifcManager.createSubset({
                        modelID: mid,
                        scene: this.scene,
                        ids: zone_eids,
                        removePrevious: true,
                        customID: "zone_" + zonestr,
                    })
                    this.layer_subsets[zonestr] = subset
                    this.scene.add(subset)
                }
                console.log("✅[produuz.it IFC Viewer] Built zone subsets")
            } catch {
                console.log("⛔[produuz.it IFC Viewer] Can't build zone subsets")
            }
        },
        render_layers(sz) {
            console.log("[i][produuz.it IFC Viewer] Selected zones", sz)

            // Remove main model
            this.ifc_models[MODEL_KEY]?.removeFromParent()

            // Render subsets
            let zonestrings = Object.keys(this.layer_subsets)
            let selected_zonestrings = sz?.map(zone => zone.toString()) || []
            for (const zone of zonestrings) {
                if (selected_zonestrings.includes(zone)) {
                    this.scene.add(this.layer_subsets[zone])
                }
                else {
                    this.layer_subsets[zone].removeFromParent()
                }
            }
        },
    },
}
</script>
