<template>
    <v-select outlined dense class="mx-1" v-model="selectedProductionLines" :items="production_lines"
        :label="$store.getters.t('production_line')" multiple></v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({ selectedProductionLines: [] }),
    computed: {
        production_lines() {
            return this.$store.state.v2.factories.production_lines?.map((pro) => ({
                text: pro.name,
                value: pro.uuid,
            }));
        },
    },
    watch: {
        selectedProductionLines(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selectedProductionLines = v;
            },
            immediate: true,
        },
    }
};
</script>