import base from "./base"

export default {
    namespaced: true,
    state: {
        webhooks: [],
    },
    mutations: {
        // Webhooks
        setWebhooks: base.mutations.setter("webhooks"),
        createWebhook: base.mutations.creator("webhooks"),
        updateWebhook: base.mutations.updater("webhooks"),
        deleteWebhook: base.mutations.deleter("webhooks"),

    },
    actions: {
        // Webhooks
        loadWebhooks: base.actions.loader("integrations/webhooks/", "Webhooks"),
        postWebhook: base.actions.poster("integrations/webhooks/", "Webhook"),
        putWebhook: base.actions.putter("integrations/webhooks/", "Webhook"),
        deleteWebhook: base.actions.deleter("integrations/webhooks/", "Webhook"),

    },
    getters: {
    }
}
