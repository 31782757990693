<template>
    <div>
        <v-dialog v-model="dialog" width="700">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon small> mdi-eye </v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title> {{ $store.getters.t(title) }} </v-card-title>
                <v-card-text v-for="cf in files" :key="cf.uuid">
                    <span> {{ cf.name }}
                        <IconRevision :rev="cf.meta.version" />
                    </span>
                    <v-img :src="cf.presigned_url"> </v-img>
                </v-card-text>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import IconRevision from "./IconRevision.vue"
export default {
    components: { IconRevision },
    props: ["files", "title"],
    data: () => ({
        dialog: false,
    })
}
</script>