<template>
    <div>
        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height d-flex flex-column" v-if="assd">
                    <v-card-title>
                        {{ $store.getters.t("design") }}:
                        {{ assd.name }}
                    </v-card-title>
                    <v-card-text v-if="!design_metrics">
                        {{ $store.getters.t("missing_design_metadata") }}
                    </v-card-text>
                    <v-card-text v-else>
                        {{ $store.getters.t("revision") }}:
                        {{ assd.revision }}
                        {{ is_newest ? "(latest)" : "(old)" }}
                        <br />
                        {{ $store.getters.t("analysis") }}:
                        <IconAnalysisStatus :value="assd.meta.analysis" />
                        <br />
                        {{ $store.getters.t("width") }}:
                        {{ design_metrics.x }} mm,
                        {{ $store.getters.t("length") }}:
                        {{ design_metrics.y }} mm
                        <br />
                        {{ $store.getters.t("thickness") }}:
                        {{ design_metrics.z }} mm
                        <br />
                        {{ $store.getters.t("bruto_area") }}:
                        {{ design_metrics.bruto }}
                        m², {{ $store.getters.t("neto_area") }}:
                        {{ design_metrics.neto }}
                        m²
                        <br />
                        {{ $store.getters.t("tipo") }}:
                        {{ assd.tipo }}
                    </v-card-text>

                    <v-card-actions class="mt-auto align-end" v-if="assd">
                        <v-spacer></v-spacer>
                        <ButtonDialogDetails :assd="assd" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <v-card outlined class="fill-height" v-if="res_pck">
                    <v-card-title>
                        {{ $store.getters.t("files") }}
                    </v-card-title>
                    <TablePackageFiles :pck="res_pck" />
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1" v-if="!hide_constructibles">
            <v-col>
                <v-card outlined>
                    <v-card-title>
                        {{ $store.getters.t("Constructibles") }}
                    </v-card-title>
                    <TableConstructiblesEmbedable :assemblies="assemblies"
                        :hide_columns="['design_name', 'assembly_name']" />
                </v-card>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col>
                <ButtonDeleteDesign v-if="assd_uuid" :uuid="assd_uuid" @completed="deleted" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TableConstructiblesEmbedable from "/src/components/constructibles/TableConstructiblesEmbedable";
import TablePackageFiles from "/src/components/files/TablePackageFiles";
import ButtonDialogDetails from "/src/components/designs/ButtonDialogDetails";
import ButtonDeleteDesign from "/src/components/designs/ButtonDeleteDesign";
import IconAnalysisStatus from "/src/components/renderers/IconAnalysisStatus";

export default {
    components: {
        TableConstructiblesEmbedable,
        TablePackageFiles,
        ButtonDialogDetails,
        ButtonDeleteDesign,
        IconAnalysisStatus,
    },
    props: {
        hide_constructibles: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    computed: {
        project_uuid() {
            return this.$route.params.project_uuid;
        },
        assd_uuid() {
            return this.assd?.uuid || false;
        },
        assd() {
            return this.$store.state.v2.selected.selected_assd;
        },
        res_pck() {
            return this.$store.getters["v2/cloudstorage/packagesByNameAndLevel"]({
                name: "resources",
                level: "assembly_design"
            })?.[0]
        },
        other_revisions() {
            return this.$store.state.v2.constructibles.assembly_designs.filter(
                (assd) => assd.name == this.assd.name
            );
        },
        is_newest() {
            let revs = this.other_revisions.map((rev) => rev.revision);
            return this.assd?.revision >= Math.max(...revs);
        },
        assemblies() {
            return this.$store.state.v2.constructibles.assemblies.filter(
                (ass) => ass.design == this.assd?.uuid
            );
        },
        design_metrics() {
            return {
                x: this.assd.meta?.metrics?.x || 0,
                y: this.assd.meta?.metrics?.y || 0,
                z: this.assd.meta?.metrics?.z || 0,
                bruto: this.assd.meta?.metrics?.bruto || 0,
                neto: this.assd.meta?.metrics?.neto || 0,
            };
        },
    },
    methods: {
        deleted() {
            this.$router.go(-1);
        },
    },
};
</script>