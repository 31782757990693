<template>
    <div>
        <v-tooltip bottom v-for="(chip, i) in chips" :key="i">
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    class="mx-1"
                    x-small
                    outlined
                    :color="chip.color"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon v-if="chip.icon" x-small left>
                        {{ chip.icon }}
                    </v-icon>
                    {{ chip.text }}
                </v-chip>
            </template>
            <span> {{ chip.help_text }} </span>
        </v-tooltip>
    </div>
</template>


<script>
export default {
    props: ["value"],
    computed: {
        chips() {
            const status = this.value;
            let chips = [];
            if (status.includes("busy")) {
                chips.push({
                    color: "accent",
                    icon: "mdi-progress-clock",
                    text: "busy",
                    help_text: "This file is locked by an ongoing operation.",
                });
            }
            if (status.includes("uploaded") && status.length == 1) {
                chips.push({
                    color: "grey",
                    icon: false,
                    text: "uploaded",
                    help_text: "Successfully uploaded file.",
                });
            }
            if (status.includes("scanned") && !status.includes("busy")) {
                chips.push({
                    color: "indigo",
                    icon: "mdi-magnify",
                    text: "details",
                    help_text:
                        "Details/Specs scanned.",
                });
            }
            if (status.includes("splitted") && !status.includes("busy")) {
                chips.push({
                    color: "deep-purple accent-4",
                    icon: "mdi-set-split",
                    text: "splitted",
                    help_text: "This IFC was already splitted.",
                });
            }
            if (status.includes("prepare_viewer") && !status.includes("busy")) {
                chips.push({
                    color: "deep-purple accent-1",
                    icon: "mdi-ruler-square",
                    text: "prepared",
                    help_text:
                        "The IFC was deep analyzed, enabling viewer metadata and dwgs, panel areas...",
                });
            }
            if (status.includes("designs") && !status.includes("busy")) {
                chips.push({
                    color: "success",
                    icon: "mdi-check",
                    text: "designs",
                    help_text:
                        "This file is already scanned for design generation.",
                });
            }
            return chips;
        },
    },
};
</script>