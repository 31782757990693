<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" :loading="loading" :disabled="loading" v-bind="attrs" v-on="on" outlined color="error">
                <v-icon left> mdi-atom-variant </v-icon>
                {{ $store.getters.t("force_analysis") }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{ $store.getters.t("force_analysis") }}
            </v-card-title>
            <v-card-text>
                {{ $store.getters.t("force_analysis_help") }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="enqueue_analysis()" outlined color="error">
                    <v-icon left> mdi-atom-variant </v-icon>
                    {{ $store.getters.t("force_analysis") }}
                </v-btn>
                <v-btn @click="dialog = false" color="secondary">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: ["uuids"],
    data: () => ({
        dialog: false,
        loading: false,
    }),
    methods: {
        async enqueue_analysis() {
            this.loading = true;
            this.dialog = false;
            const uuids = this.uuids
            this.$store
                .dispatch("v2/constructibles/launchAnalysisForDesigns", uuids)
                .finally(() => {
                    this.loading = false;
                    this.$emit("completed");
                });
        },
    },
};
</script>