<template>
    <DatePicker
        v-model="start_date"
        label="Start date"
        :loading="loading ? 'accent' : false"
    />
</template>


<script>
import bus from "/src/utils/event_bus";
import DatePicker from "../DatePicker.vue";
export default {
    components: {
        DatePicker,
    },
    props: ["batch"],
    data: () => ({
        start_date: "",
        loading: false,
    }),
    watch: {
        batch: {
            handler: function (b) {
                this.start_date = b.start_date;
            },
            immediate: true,
        },
        start_date(dd) {
            if (dd != this.batch.start_date) this.update_batch();
        },
    },
    methods: {
        update_batch() {
            this.$store.commit("overview/setTouched");
            let new_batch = {
                ...this.batch,
                start_date: this.start_date,
            };
            this.loading = true;
            this.$store
                .dispatch("v2/constructibles/putBatch", new_batch)
                .then(() =>
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: `Batch number updated.`,
                        color: "success",
                    })
                )
                .finally(() => (this.loading = false));
        },
    },
};
</script>