<template>
    <v-dialog v-model="dialog" width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon> mdi-cube-scan </v-icon>
                <!-- {{ $store.getters.t("view_meaning_action_of_view") }} -->
            </v-btn>
        </template>

        <v-card>
            <v-card-title> Part Detail </v-card-title>

            <v-card-text>
                <v-img
                    v-for="pic in part.drawings"
                    :key="pic.name"
                    :src="pic.presigned_url"
                ></v-img>
                revision: {{ part.revision }} <br />
                ifc_id: {{ part.ifc_id }} <br />
                zone: {{ part.zone }} <br />
                reference: {{ part.reference }} <br />
                ifc_tag: {{ part.ifc_tag }} <br />
                name: {{ part.name }} <br />
                object_type: {{ part.object_type }} <br />
                ifc_type: {{ part.ifc_type }} <br />
                has_cuts: {{ part.has_cuts }} <br />
                width: {{ part.width }} <br />
                height: {{ part.height }} <br />
                length: {{ part.length }} <br />
                thickness: {{ part.thickness }} <br />
                material: {{ part.material }} <br />
            </v-card-text>
            <v-card-text>
                <div
                    v-for="(meta_value, meta_key) in part.metadata"
                    :key="meta_key"
                >
                    {{ meta_key }}: {{ meta_value }}
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["part"],
    data: () => ({
        dialog: false,
        // part_example: {
        //     id: 42144,
        //     uuid: "48be1a0a-ebe0-4ddc-bc90-6053360d6c4b",
        //     revision: 0,
        //     ifc_id: "3IM0Wnjsj4rRuMQ4ObvY2O",
        //     zone: 0,
        //     reference: "P224",
        //     ifc_tag: "IDd2580831-b76b-44d5-be16-684625e62098",
        //     name: "BI_PLAAT_2",
        //     object_type: "12*629",
        //     ifc_type: "IfcPlate",
        //     has_cuts: false,
        //     width: "629.00",
        //     height: "0.00",
        //     length: "2630.47",
        //     thickness: "12.00",
        //     metadata: {
        //         NAME: "BI_PLAAT_2",
        //         ZONE: -2,
        //         Class: "3",
        //         Phase: 3,
        //         Width: 629,
        //         Finish: "",
        //         Height: 629,
        //         Length: 2630.5,
        //         Volume: 0.016,
        //         Weight: 19.55,
        //         NetArea: 2.78863724687779,
        //         MATERIAL: "FERMACELL",
        //         GrossArea: 1.65456699365234,
        //         NetVolume: 0.0162940353845215,
        //         Reference: "P224",
        //         IsExternal: "Nee",
        //         GrossVolume: 0.0162940353845215,
        //         LoadBearing: false,
        //         ifc_material: "MISCELLANEOUS/FERMACELL",
        //         "Area per tons": 142.621,
        //         "Top elevation": " +2.630",
        //         "Bottom elevation": " -0.000",
        //         CrossSectionArea: 0.000144,
        //         "Net surface area": 2.789,
        //         "Preliminary mark": "",
        //         "Gross footprint area": 0,
        //     },
        //     position: {
        //         axis: [0, 0, 1],
        //         ifc_type: "IfcAxis2Placement3DWrapper",
        //         location: [0, 0, -6],
        //         ref_direction: [1, 0, 0],
        //     },
        //     product_position: {
        //         axis: [0, 0, 1],
        //         ifc_type: "IfcAxis2Placement3DWrapper",
        //         location: [200, 22940, -7.105427357601e-15],
        //         ref_direction: [1, 0, 0],
        //     },
        //     shape: {
        //         type: "shape_points",
        //         points: [
        //             { x: 0, y: 0 },
        //             { x: 629, y: 0 },
        //             { x: 629, y: 2630.47216796875 },
        //             { x: 0, y: 1686.97229003906 },
        //             { x: 0, y: 0 },
        //         ],
        //     },
        //     section: { type: "missing" },
        //     cuts: [],
        //     material: "FERMACELL",
        // },
    }),
};
</script>