<template>
    <div>
        <QuantitiesHeader @selected_phase="selected_phase = $event" @filtered_designs="filtered_designs = $event" />
        <v-row v-if="generating">
            <v-col>
                <h2 class="ml-3 font-weight-light grey--text">
                    Generating constructibles...
                </h2>
            </v-col>
        </v-row>
        <v-row v-else-if="lock_matrix">
            <v-col>
                <h2 class="ml-3 font-weight-light grey--text">
                    Loading project information. Wait...
                </h2>
            </v-col>
        </v-row>
        <v-row v-else-if="selected_phase == '0'" no-gutters>
            <TableQuantitiesPhases :project="project" :designs="filtered_designs_lasts" />
        </v-row>
        <v-row v-else no-gutters>
            <TableQuantitiesBatches :project="project" :designs="filtered_designs_lasts"
                :selected_phase="selected_phase" />
        </v-row>
    </div>
</template>


<script>
import QuantitiesHeader from "/src/components/quantities/header/QuantitiesHeader.vue";
import TableQuantitiesPhases from "../../components/quantities/tables/TableQuantitiesPhases.vue"
import TableQuantitiesBatches from "../../components/quantities/tables/TableQuantitiesBatches.vue"
import _ from "lodash";
import { nameSort } from "/src/utils/basics.js";
export default {
    components: {
        QuantitiesHeader,
        TableQuantitiesPhases,
        TableQuantitiesBatches,
    },
    data: () => ({
        filtered_designs: [],
        selected_phase: '0',
        timeout: null,
    }),
    computed: {
        generating() {
            return this.$store.state.v2.quantities.generating;
        },
        project() {
            return this.$store.state.v2.selected.selected_project;
        },
        batches() {
            return _.orderBy(this.$store.state.v2.constructibles.batches, "priority");
        },
        actuals() {
            return this.$store.getters["v2/constructibles/allActuals"]
        },
        lock_matrix() {
            return this.$store.getters["v2/quantities/lockMatrix"];
        },
        filtered_designs_lasts() {
            // Return last revision of each design
            let revisions = [];
            this.filtered_designs?.forEach((d) => {
                let i = revisions.findIndex((r) => r.name == d.name);
                if (i == -1) {
                    revisions.push(d);
                } else {
                    let r = revisions[i];
                    if (d.revision > r.revision) {
                        revisions[i] = d;
                    }
                }
            });
            this.actuals.forEach((actual) => {
                let d = this.filtered_designs?.find((d) => d.uuid == actual.design);
                if (d && !revisions.includes(d)) {
                    revisions.push(d);
                }
            });
            revisions.sort((a, b) => nameSort(a.name, b.name));
            return revisions;
        },
    },
};
</script>
